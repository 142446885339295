import {useMutation, useQuery} from "@apollo/client";
import "antd/dist/antd.css";
import {Button, Space, Table} from "antd";
import {useNotification} from "hooks";
import {GET_ALL_BOOKS} from "dashboard/query";
import {DELETE_BOOK} from "dashboard/mutations";
import React from "react";
import {modal} from "utils/modal";
import CreateBookModal from "../modals/createBookModal";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import MobileTable from "../../MobileTable/MobileTable";
import {useMediaQuery} from "react-responsive";
import EditBookModal from "../modals/EditBooks";

export const categories = [
  "Adaptability",
  "Career Readiness",
  "Communication",
  "Creativity",
  "Decision Making",
  'Diversity and Inclusion',
  'Differently Abled',
  "Emotional Intelligence",
  "Engagement",
  "Health and Wellbeing",
  "Leadership",
  'Negotiation',
  "Problem Solving",
  "Professionalism",
  'Reinvent Your Career',
  "Relationships",
  "Resilience",
  "Self Management",
  "Teamwork",
  // "Time Management",
  // "Work Ethic",
  // "Attention to detail",
];

const BookTable = () => {
  const [deleteBook] = useMutation(DELETE_BOOK);
  const query = useQuery(GET_ALL_BOOKS, {fetchPolicy: "network-only"});
  const notification = useNotification();

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 700px)",
  });

  const onDeleteBook = (id: string) => {
    deleteBook({variables: {id}}).then((response) => {
      if (response?.data?.deleteBook) {
        notification({type: "success", message: `Book ID:${id} has been successfully deleted`});
        query.refetch();
      } else {
        notification({type: "error"});
      }
    });
  };

  const columns: any = [
    {
      title: "Title",
      render: (value: any) => value?.title,
    },
    {
      title: "Category",
      render: (value: any) => value?.category,
    },
    {
      title: "Author",
      render: (value: any) => value?.author,
    },
    {
      title: "Link",
      render: (value: any) => value?.link,
    },
    {
      title: "Avatar",
      render: (value: any) => value?.avatar,
    },
    {
      title: "Action",
      key: "action",
      render: (value: any) => (
        <Space size="middle">
          <Button
            onClick={() =>
              modal.showModal(ConfirmationModal, {
                title: "Are you sure?",
                message: "Do you want to delete an attachment?",
                onDelete: () => {
                  onDeleteBook(value.id);
                  modal.hideModal();
                },
              })
            }
          >
            Delete
          </Button>

          <Button
            onClick={() =>
              modal.showModal(EditBookModal, {
                bookQuery: query,
                id: value.id,
                book: value,
              })
            }
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  if (!query?.loading && query?.data) {
    return (
      <>
        <div className="admin__table">
          <h1 style={{flex: 1, margin: "auto"}}> Questions </h1>
          <div>
            <Button
              onClick={() =>
                modal.showModal(CreateBookModal, {
                  bookQuery: query,
                })
              }
            >
              Add Books
            </Button>
          </div>
        </div>
        {!isTabletOrMobileDevice ? (
          <Table columns={columns} scroll={{x: 2000}}
                 dataSource={[...query?.data?.getAllBooks].map((item: any) => ({...item, key: item?.id}))}/>
        ) : (
          <MobileTable data={query?.data?.getAllBooks} columns={columns}/>
        )}
      </>
    );
  }

  return null;
};

export default BookTable;
