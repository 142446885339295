import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { WithCurrentUserHook } from '../hoc/withCurrentUserQuery';

const ProtectedFeature = ({ children, featureDesc = '', disabled }: any) => {
  const { loading, currentUser } = WithCurrentUserHook();
  const history = useHistory();

  if (disabled) {
    return <div>{children}</div>;
  }

  if (!loading && !currentUser) {
    return (
      <div>
        <p>
          {' '}
          Log in
          {featureDesc}
        </p>
        <Button onClick={() => history.push('/login')}> Log In </Button>
      </div>
    );
  }

  if (!loading && currentUser) {
    return <div>{children}</div>;
  }

  return <p> Loading </p>;
};

export default ProtectedFeature;
