import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { v4 as uuid } from 'uuid';
import { Formik, Form } from 'formik';
import { GET_SKILL_CHECK, SET_SKILL_CHECK_BY_QUESTION } from './query';
import { Button } from 'antd';
import { PageLayout, StandardMarginDiv } from '../components';
import Question from './Question';

import './styles.less';

const Quiz = () => {
  const { id } = useParams();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { loading, data } = useQuery(GET_SKILL_CHECK, {
    variables: { id },
    fetchPolicy: 'cache-first',
  });
  const [setSkillChecksByQuestion] = useMutation(SET_SKILL_CHECK_BY_QUESTION);

  useEffect(() => {
    return () => {
      setIsSubmitted(false);
    };
  }, []);

  const onSubmit = (values, actions) => {
    if (isSubmitted) {
      actions.resetForm();
      setIsSubmitted(false);
      return false;
    }

    setDisabled(true);
    let sendData = {};
    const questions = data?.getUserSkillCheckByQuestionId?.questions;

    questions.forEach((question) => {
      sendData[question.question] = question.answers.map((answer) =>
        Array.isArray(values[question.question])
          ? values[question.question].includes(answer.title)
          : answer.title === values[question.question],
      );
    });

    setSkillChecksByQuestion({
      variables: {
        id,
        answers: sendData,
      },
    })
      .then(() => {
        setIsSubmitted(true);
      })
      .finally(() => {
        setDisabled(false);
      });
  };

  const questions = data?.getUserSkillCheckByQuestionId?.questions;

  return (
    <PageLayout loading={loading}>
      <StandardMarginDiv>
        <div className="quiz">
          <h1 className="page-title">
            {data?.getUserSkillCheckByQuestionId?.title}
          </h1>
          <p className="page-description">
            {data?.getUserSkillCheckByQuestionId?.description}
          </p>

          <Formik initialValues={{}} onSubmit={onSubmit}>
            {({ values, setFieldValue, handleSubmit }) => {
              let allCorrectAnswers = 0;
              if (isSubmitted) {
                allCorrectAnswers = questions?.filter((item) => {
                  const correctAnswers = item.answers.filter(
                    (i) => i.isCorrect,
                  );
                  return correctAnswers.every((answer) =>
                    Array.isArray(values[item.question])
                      ? values[item.question].includes(answer.title)
                      : answer.title === values[item.question],
                  );
                });
              }

              const filledQuestions = Object.values(values).filter(
                (item) => item.length,
              );
              return (
                <Form>
                  {questions && (
                    <ol className="quiz__list">
                      {questions.map((question) => {
                        return (
                          <Question
                            key={uuid()}
                            isSubmitted={isSubmitted}
                            question={question}
                            setFieldValue={setFieldValue}
                            values={values}
                          />
                        );
                      })}
                    </ol>
                  )}
                  {isSubmitted && (
                    <p className="quiz__total-result">
                      You have {allCorrectAnswers.length}/
                      {data?.getUserSkillCheckByQuestionId?.questions?.length}{' '}
                      correct answers
                    </p>
                  )}
                  <Button
                    disabled={
                      disabled || filledQuestions.length !== questions?.length
                    }
                    onClick={handleSubmit}
                  >
                    {isSubmitted ? 'Take test again' : 'Submit'}
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </div>
      </StandardMarginDiv>
    </PageLayout>
  );
};

export default Quiz;
