import React from 'react'
import { useFormik } from 'formik';
import { Button, Form, Input, message } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { useLocation } from 'react-router';
import { HELPipedia_ORANGE } from 'styles/colors';

const RESET_PASSWORD = gql`
  mutation resetPassword($password: String, $passwordOld: String, $passwordConfirmation: String, $tokenInput: String) {
    resetPassword(password: $password, passwordOld: $passwordOld, passwordConfirmation: $passwordConfirmation, tokenInput: $tokenInput) {
        completed
        error
    }
  }
`;

const ChangePassword = (props) => {
  const [resetPassword, { data }] = useMutation(RESET_PASSWORD);
  const tokenInput = window.localStorage.getItem('token');
  const isHelpipedia= useLocation().pathname.includes("helpipedia")
  const formik = useFormik({
    initialValues: {
      passwordConfirmation: '',
      password: '',
      passwordOld: ''
    },
    onSubmit: async (values) => {
      const result = await resetPassword({ variables: { ...values, tokenInput } });
      if (result.data.resetPassword.completed) {
        message.success('Password Reset');
      } else {
        message.error(result.data.resetPassword.error);
      }
    },
  });
  return (
    <div>
      <h1 style={{ marginBottom: '20px', color:isHelpipedia && 'black' }}>CHANGE PASSWORD</h1>
      <Form className='settings__form' onFinish={formik.handleSubmit}>
        <p className='heading' style={{ fontSize: "17px", marginLeft: '10px',color:isHelpipedia && HELPipedia_ORANGE }}>Have a new favorite password? It’s a good idea to change things up once in a while anyway. Let’s make this quick!</p>
        <Form.Item style={{ width: props.isTabletOrMobileDevice ? "100%" : '80%', margin: 0 }}>
          <p className='heading' style={{ fontSize: '17px', marginLeft: '10px',color:isHelpipedia && HELPipedia_ORANGE }}>Old Password</p>
          <Input.Password
            id="passwordOld"
            name="passwordOld"
            required
            className='settings__fields'
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            onChange={formik.handleChange}
            value={formik.values.passwordOld}
            style={{borderColor: isHelpipedia && HELPipedia_ORANGE}}
          />
        </Form.Item>
        <Form.Item style={{ width: props.isTabletOrMobileDevice ? "100%" : '80%', margin: 0 }}>
          <p className='heading' style={{ fontSize: '17px', marginLeft: '10px',color:isHelpipedia && HELPipedia_ORANGE }}>New Password</p>
          <Input.Password
            id="password"
            name="password"
            required
            className='settings__fields'
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            onChange={formik.handleChange}
            value={formik.values.password}
            style={{borderColor: isHelpipedia && HELPipedia_ORANGE}}
          />
        </Form.Item>
        <Form.Item style={{ width: props.isTabletOrMobileDevice ? "100%" : '80%', margin: 0 }}>
          <p className='heading' style={{ fontSize: '17px', marginLeft: '10px',color:isHelpipedia && HELPipedia_ORANGE }}>Confirm Password</p>
          <Input.Password
            id="passwordConfirmation"
            name="passwordConfirmation"
            required
            className='settings__fields'
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            onChange={formik.handleChange}
            value={formik.values.passwordConfirmation}
            style={{borderColor: isHelpipedia && HELPipedia_ORANGE}}
          />
        </Form.Item>

        <Form.Item style={{ width: '100%' }}>
          <Button type="primary" htmlType="submit" className="settings__button-submit"
           style={{backgroundColor:isHelpipedia&& HELPipedia_ORANGE, borderColor: isHelpipedia && HELPipedia_ORANGE}}>
            Change Password
          </Button>
        </Form.Item>
      </Form >
    </div>)
}



export default ChangePassword