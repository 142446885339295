// @ts-nocheck
import React from 'react';
import 'reoverlay/lib/ModalWrapper.css';
// @ts-nocheck
import { Button, Form, Input, message, Select, Spin } from 'antd';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_COACH,
  CREATE_COACH_MUTATION,
  EDIT_VIDEO_COACH,
  GET_VIDEO_BY_COACH,
  SEND_EMAIL_TO_COACH,
} from '../mutations';
import { GET_ALL_CERTIFICATIONS } from '../query';
import { modal } from 'utils/modal';
import ModalScrollable from '../../components/ModalScrollable/ModalScrollable';
import ReactPlayer from "react-player";

const SelectCertifications = ({ formik }) => {
  const query = useQuery(GET_ALL_CERTIFICATIONS);

  if (query.loading || !query?.data || !query?.data?.getAllCertifications) {
    return null;
  }

  return (
    <Form.Item name="certifications" label="Certifications">
      <Select
        onChange={(e) => formik.setFieldValue('certifications', e)}
        defaultValue={formik.values.certifications}
        type="text"
        mode="tags"
        placeholder="Coach Certifications"
      >
        {query.data.getAllCertifications.map((cert, index) => (
          <Select.Option key={index} value={cert}> {cert} </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const CoachCreateForm = ({ coach, close }: any) => {
  const { data , refetch} = useQuery(GET_VIDEO_BY_COACH, {
    variables: {
      id: coach.id,
    },
  });
  const [createUser,{loading}] = useMutation(CREATE_COACH);
  const [coachUploadVideo,{loading:videoLoading}] = useMutation(EDIT_VIDEO_COACH);
  const [sendEmailToCoach] = useMutation(SEND_EMAIL_TO_COACH);

  const defaultMargin = {
    marginTop: "22px",
  };
  const inputStyles = {
    width: "100%",
  };

  const textAreaStyles = {
    width: "100%",
    height:'200px',
  }

  const onChange = ({ target: {
    validity,
    files: [file],
  }, }: any) => validity.valid && formik.setFieldValue("video", file);

  const formik = useFormik({
    initialValues: {
      id: coach.id,
      firstName: coach.userProfile.firstName,
      lastName: coach.userProfile.lastName,
      calendlyUserName: coach.userProfile.calendlyUserName,
      email: coach.email,
      bio: coach.userProfile.bio,
      file: null,
      coachingStyle: coach.userProfile.coachingStyle,
      certifications: coach.userProfile.certifications || [],
      rate: coach?.userProfile?.rate,
      calendlyLinks: coach?.calendlyLinks
    },
    onSubmit: async (values) => {
      const { firstName, email, coachingStyle, certifications, lastName, calendlyUserName, file, bio, id, rate, calendlyLinks, video } = values;

      try {
        await createUser({
          variables: {
            input: {
              id: parseInt(id, 10),
              file,
              coachingStyle,
              certifications,
              firstName,
              lastName,
              calendlyUserName,
              bio,
              email,
              rate,
              calendlyLinks
            },
          },
        });

        if(video){
        await coachUploadVideo({
            variables:{
              id:coach.id,
              file:video
            }
          })
        }

        message.success('Updated Profile');
        close();
        refetch()
      } catch (e) {
        message.error('Error updating profile');
      }
    },
  });
  return (
  <Spin spinning={loading ||videoLoading} size="large">
    <Form layout="vertical" onFinish={formik.handleSubmit}>
    {!coach.accountCreated && (
      <div>
        <p className="coach__modal-title"> Coach has never logged in </p>
        {coach.email == null && <p className="coach__modal-title"> Add an email to this coach to send them their credentials </p>}
        <Button
          disabled={coach.email == null}
          onClick={() =>
            sendEmailToCoach({
              variables: { userId: coach.id },
            })
          }
        >
          Send email with account details
        </Button>
      </div>
    )}
    <div className="coach__modal-player">
      <ReactPlayer controls height="auto" width="100%" url={data?.getVideoByCoachId?.url} />
    </div>

    <Form.Item label="Intro Video">
      <input type="file" accept="video/*" onChange={onChange} />
    </Form.Item>

    <Form.Item label="Email" name="email" style={defaultMargin}>
      <Input
        id="email"
        name="email"
        type="email"
        defaultValue={formik.values.email}
        onChange={formik.handleChange}
        value={formik.values.email}
        style={inputStyles}
      />
    </Form.Item>

    <Form.Item label="First Name" name="firstName" style={defaultMargin}>
      <Input
        id="firstName"
        name="firstName"
        type="firstName"
        defaultValue={formik.values.firstName}
        onChange={formik.handleChange}
        value={formik.values.firstName}
        style={inputStyles}
      />
    </Form.Item>

    <Form.Item label="Last Name" name="lastName" style={defaultMargin}>
      <Input
        id="lastName"
        name="lastName"
        type="lastName"
        defaultValue={formik.values.lastName}
        onChange={formik.handleChange}
        value={formik.values.lastName}
        style={inputStyles}
      />
    </Form.Item>

    <Form.Item label="Calendly User Name" name="calendlyUserName" style={defaultMargin}>
      <Input
        id="calendlyUserName"
        name="calendlyUserName"
        type="calendlyUserName"
        defaultValue={formik.values.calendlyUserName}
        onChange={formik.handleChange}
        value={formik.values.calendlyUserName}
        style={inputStyles}
      />
    </Form.Item>

    <Form.Item label="Calendly Url" name="calendlyLinks" style={defaultMargin}>
      <Input
        id="calendlyLinks"
        name="calendlyLinks"
        type="calendlyLinks"
        defaultValue={formik.values.calendlyLinks}
        onChange={formik.handleChange}
        value={formik.values.calendlyLinks}
        style={inputStyles}
      />
    </Form.Item>

    <Form.Item label="Coaching Style" name="coachingStyle" style={defaultMargin}>
      <Input
        id="coachingStyle"
        name="coachingStyle"
        type="coachingStyle"
        defaultValue={formik.values.coachingStyle}
        onChange={formik.handleChange}
        value={formik.values.coachingStyle}
        style={inputStyles}
      />
    </Form.Item>

    <SelectCertifications formik={formik} />
    {coach.userProfile.avatarUrl && (
      <img
        style={{
          height: '200px',
          width: '200px',
          borderRadius: '50%',
          marginBottom: '20px',
        }}
        src={coach.userProfile.avatarUrl}
      />
    )}
    <div style={{ marginBottom: '20px' }}>
      <label htmlFor="filePicker">Upload Profile Picture</label>
      <input
        buttonText="Upload Profile Image"
        accept="image/*"
        id="filePicker"
        type="file"
        onChange={({
                     target: {
                       validity,
                       files: [file],
                     },
                   }: any) => validity.valid && formik.setFieldValue('file', file)}
      />
    </div>

    <Form.Item label="Bio" name="bio" style={defaultMargin}>
      <Input.TextArea id="bio" name="bio" defaultValue={formik.values.bio} onChange={formik.handleChange} value={formik.values.bio} style={textAreaStyles} />
    </Form.Item>

    <Form.Item label="Coach Rate" name="rate" style={defaultMargin}>
      <Input
        id="rate"
        name="rate"
        type="rate"
        defaultValue={formik.values.rate}
        onChange={formik.handleChange}
        value={formik.values.rate}
        style={inputStyles}
      />
    </Form.Item>

    <Button type="primary" htmlType="submit" className="coach__modal-btn">
      Save
    </Button>
  </Form>
  </Spin>
  );
};

const PendingCoachForm = ({ coach, close }: any) => {
  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted
  const [createUser] = useMutation(CREATE_COACH_MUTATION);

  const defaultMargin = {
    marginTop: '22px',
  };
  const inputStyles = {
    width: '100%',
  };

  const formik = useFormik({
    initialValues: {
      id: coach.id,
      firstName: coach.firstName,
      coachingStyle: coach.coachingStyle,
      lastName: coach.lastName,
      email: coach.email,
      sendEmail: false,
    },
    onSubmit: async (values) => {
      const { firstName, email, lastName, coachingStyle, bio, sendEmail } = values;
      try {
        await createUser({
          variables: {
            input: {
              id: coach.id,
              sendEmail,
              coachingStyle,
              firstName,
              lastName,
              bio,
              email,
            },
          },
        });
        message.success('Updated Profile');
        close();
      } catch (err) {
        message.error('Error creating coach');
      }
    },
  });

  return (
    <Form layout="vertical" onFinish={formik.handleSubmit}>
      <Form.Item label="Email" name="email" style={defaultMargin}>
        <Input
          disabled
          id="email"
          name="email"
          type="email"
          defaultValue={formik.values.email}
          onChange={formik.handleChange}
          value={formik.values.email}
          style={inputStyles}
        />
      </Form.Item>

      <Form.Item label="First Name" name="firstName" style={defaultMargin}>
        <Input
          id="firstName"
          name="firstName"
          type="firstName"
          defaultValue={formik.values.firstName}
          onChange={formik.handleChange}
          value={formik.values.firstName}
          style={inputStyles}
        />
      </Form.Item>

      <Form.Item label="Last Name" name="lastName" style={defaultMargin}>
        <Input
          id="lastName"
          name="lastName"
          type="lastName"
          defaultValue={formik.values.lastName}
          onChange={formik.handleChange}
          value={formik.values.lastName}
          style={inputStyles}
        />
      </Form.Item>

      <Form.Item label="Coaching Style" name="coachingStyle" style={defaultMargin}>
        <Input
          id="coachingStyle"
          name="coachingStyle"
          type="coachingStyle"
          defaultValue={formik.values.coachingStyle}
          onChange={formik.handleChange}
          value={formik.values.coachingStyle}
          style={inputStyles}
        />
      </Form.Item>

      <Form.Item label="Bio" name="bio" style={defaultMargin}>
        <Input.TextArea size="large" rows={10} id="bio" name="bio" defaultValue={formik.values.bio} onChange={formik.handleChange} value={formik.values.bio} style={inputStyles} />
      </Form.Item>
      <Button type="primary" htmlType="submit">
        Create Expert Account
      </Button>
    </Form>
  );
};

const ConfirmModal = ({ coach, query }: any) => {
  const closeModal = () => {
    query.refetch();
    modal.hideModal();
  };

  return (
    <ModalScrollable title="Edit Coach">
      <p>
        {coach.firstName} {coach.lastName}
      </p>
      {coach.__typename === 'CoachRequest' && <PendingCoachForm close={closeModal} coach={coach} />}

      {coach.__typename !== 'CoachRequest' && <CoachCreateForm close={closeModal} coach={coach} />}
    </ModalScrollable>
  );
};

export default ConfirmModal;