import { gql } from '@apollo/client';

export const CREATE_ASSESSMENT = gql`
    mutation createAssessment($name: String!, $url: String!) {
        createAssessment(name: $name, url: $url) {
            id
        }
    }
`;

export const DELETE_ADMIN_VIEWS = gql`
    mutation deleteAdminViews {
        deleteAdminViews
    }
`;

export const UPLOAD_ATTACHMENT_FILE = gql`
    mutation UploadAttachmentFileMutation($file: Upload!, $type: AttachmentTypeEnum, $name: String) {
        uploadAttachmentFile(file: $file, type: $type, name: $name) {
            filename
            mimetype
            encoding
            url
        }
    }
`;

export const CREATE_SKILL_CHECK = gql`
    mutation createSkillCheckQuestions($title: String!, $description: String, $questions: JSON) {
        createSkillCheckQuestions(title: $title, description: $description, questions: $questions) {
            id
        }
    }
`;

export const CREATE_SELF_REFLECTION = gql`
    mutation createSelfReflectionQuestions($title: String!, $description: String, $questions: JSON) {
        createSelfReflectionQuestions(title: $title, description: $description, questions: $questions) {
            id
        }
    }
`;

export const DELETE_JOURNEY = gql`
    mutation deleteJourney($id: ID!) {
        deleteJourney(id: $id)
    }
`;

export const DELETE_QUESTION = gql`
    mutation deleteQuestions($id: ID!) {
        deleteQuestions(questionId: $id)
    }
`;

export const DELETE_ATTACHMENT = gql`
    mutation deleteAttachment($id: ID!) {
        deleteAttachment(attachmentId: $id)
    }
`;

export const DELETE_SNIPPET = gql`
    mutation deleteSnippet($id: ID!) {
        deleteSnippet(id: $id)
    }
`;

export const DELETE_COACH = gql`
    mutation deleteUser($userId: ID) {
        deleteUser(userId: $userId) {
            id
        }
    }
`;

export const GET_ALL_BOOKEDS = gql`
    query getAllBookeds {
        getAllBookeds {
            id
            userId
            userName
            createdAt
            coachName
            coachId
        }
    }
`;

export const GET_ALL_USERS = gql`
    query getAllUser {
        getAllUser {
            id
            email
            subscriptionStatus
            dateEnding
            userProfile {
                firstName
                lastName
            }
        }
    }
`;

export const GET_USER_BY_EMAIL = gql`
    query getUserByEmail($email: String) {
        getUserByEmail(email: $email) {
            id
            email
            subscriptionStatus
            dateEnding
            userProfile {
                firstName
                lastName
            }
        }
    }
`;

export const APPROVE_USER = gql`
    mutation approveUser($userId: ID, $status: String) {
        approveUser(userId: $userId, status: $status)
    }
`;

export const BUY_SUBSCRIPTION = gql`
    mutation buySubscription($userId: ID, $subscriptionType: String) {
        buySubscription(userId: $userId, subscriptionType: $subscriptionType)
    }
`;

export const CREATE_COACH = gql`
    mutation updateUser($input: coachInput) {
        updateUser(input: $input) {
            id
        }
    }
`;

export const CREATE_COACH_MUTATION = gql`
    mutation createCoach($input: coachInput) {
        createCoach(input: $input)
    }
`;

export const GET_VIDEO_BY_COACH = gql`
    query getVideoByCoachId($id:ID){
        getVideoByCoachId(id:$id){
            id
            name
            url
            length
        }
    } `;


export const EDIT_VIDEO_COACH = gql`
    mutation  coachUploadVideo($id:ID, $file:Upload){
        coachUploadVideo(id:$id , file:$file)
    }
`;

export const SEND_EMAIL_TO_COACH = gql`
    mutation sendEmailToCoach($userId: ID) {
        sendEmailToCoach(userId: $userId)
    }
`;

export const CREATE_BOOK = gql`
    mutation createBook($title: String, $category: String, $capsuleTopic: String, $author: String, $link: String, $avatar: String, $file: Upload) {
        createBook(title: $title, category: $category, capsuleTopic: $capsuleTopic, author: $author, link: $link, avatar: $avatar, file: $file) {
            id
            title
            category
            capsule_topic
            author
            link
            avatar
        }
    }
`;

export const EDIT_BOOK = gql`
    mutation editBook($id: ID, $title: String, $category: String, $capsuleTopic: String, $author: String, $link: String, $avatar: String, $file: Upload) {
        editBook(id: $id, title: $title, category: $category, capsuleTopic: $capsuleTopic, author: $author, link: $link, avatar: $avatar, file: $file)
    }
`;

export const DELETE_BOOK = gql`
    mutation deleteBook($id: ID) {
        deleteBook(id: $id) {
            id
        }
    }
`;
