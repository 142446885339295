// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// 1520 lines!!!!
import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Button } from 'antd';
import { CaretRightOutlined, FrownOutlined, MehOutlined, SmileOutlined } from '@ant-design/icons';
import { Clock, FileText } from 'react-feather';
import { userQuery } from '../hoc/withCurrentUserQuery';
import CapsuleEditForm from './CapsuleComponents/CapsuleEditForm';
import CapsuleCoachCard from './CapsuleComponents/CapsuleCoachCard';
import PageLayout from '../components/PageLayout';
import StandardMarginDiv from '../components/StandardMarginDiv';
import Tags from '../components/SkillTags/tags';
import SimpleButton from '../components/Buttons/SimpleButton';
import { CurrentCapsuleQuery } from './query';
import { VIEW_CAPSULE, SAVE_EVENT_MUTATION, REVIEW_CAPSULE } from './mutation';
import { HELPipedia_GREEN, HELPipedia_ORANGE, HELPipedia_ORANGE_ALPHA, STEERUS_GREEN, STEERUS_GREEN_ALPHA } from '../styles/colors';
import './styles.less';

const CapsuleContainer = () => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const isModeEdit = location.pathname.includes('edit');
  const currentUser = useQuery(userQuery);
  const [emojiClicked, setEmojiClicked] = useState(false)
  const [reviewSelected, setReviewSelected] = useState(0)

  const query = useQuery(CurrentCapsuleQuery, {
    variables: { id: params.id },
    fetchPolicy: 'cache-first',
  });

  const [saveEvent] = useMutation(SAVE_EVENT_MUTATION);
  const [reviewCapsule] = useMutation(REVIEW_CAPSULE);
  const [handleViewCapsule] = useMutation(VIEW_CAPSULE, {
    variables: { viewCapsuleCapsuleId: params.id },
  });
  let totalVotes = query?.data?.getCapsule?.allReviews.length;
  let avg = query?.data?.getCapsule?.allReviews.reduce(function (acc, obj) {
    return acc + obj.reviews;
  }, 0);
  let totalAvg;
  if (query?.data?.getCapsule?.allReviews.length === 0) {
    totalAvg = 0;
  } else {
    totalAvg = Number(avg / totalVotes).toFixed(2);
  }
  const review = (rating) => {
    setReviewSelected(rating)
    setEmojiClicked(true)
    reviewCapsule({
      variables: { capsuleId: query?.data?.getCapsule?.id, review: rating },
    })
      .then((response) => {
        console.log('success');
        setEmojiClicked(false)
        setReviewSelected(0)

      try {
        query.refetch().then(res => {

          let totalVotes = res?.data?.getCapsule?.allReviews.length;
          let avg = res?.data?.getCapsule?.allReviews.reduce(function (acc, obj) {
            return acc + obj.reviews;
          }, 0);

          if (res?.data?.getCapsule?.allReviews.length === 0) {
            totalAvg = 0;
          } else {
            totalAvg =Number(avg / totalVotes).toFixed(2);
          }
console.log(totalAvg, avg,totalVotes );

        })
      } catch (error) {
        console.log(error);
      }  

      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleViewCapsule();
  }, []);

  useEffect(() => {
    if (!isModeEdit && query?.data?.getCapsule?.id) {
      if (!query?.loading && !query?.data?.getCapsule?.views) {
        saveEvent({
          variables: {
            eventName: 'CAPSULE_VIEWED',
            misc: JSON.stringify({ capsule_id: query?.data?.getCapsule?.id }),
          },
        });
      }
    }
  }, [query]);

  if (isModeEdit && !query.loading && query.data && !currentUser.loading && currentUser.data) {
    const capsule = query.data.getCapsule;
    const statusCapsule = capsule.type;
    let capsuleContent;
    if (capsule.currentContent.version == params.version) {
      capsuleContent = capsule.currentContent;
    } else {
      capsuleContent = capsule.futureContent.filter((content) => content.version == params.version);
      if (capsuleContent.length) {
        [capsuleContent] = capsuleContent;
      } else {
        capsuleContent = capsule.pastContent.filter((content) => content.version == params.version);
        if (capsuleContent.length) {
          [capsuleContent] = capsuleContent;
        } else {
          window.location.reload();
        }
      }
    }

    return (
      <PageLayout loading={query.loading}>
        <StandardMarginDiv>
          <CapsuleEditForm
            capsuleQuery={query}
            statusCapsule={statusCapsule}
            currentUser={currentUser.data.currentUser}
            content={capsuleContent}
            capsuleId={query.data.getCapsule.id}
            id={capsuleContent.id}
          />
        </StandardMarginDiv>
      </PageLayout>
    );
  }
  const isHelpipedia= useLocation().pathname.includes("helpipedia")
  let useThis;
  useThis = query?.data?.getCapsule?.currentContent;
  if (location.pathname.includes('preview') && !query.loading && query.data && query.data.getCapsule) {
    let content;
    useThis = undefined;
    if (query?.data?.getCapsule?.currentContent.version == params.version) {
      useThis = query?.data?.getCapsule?.currentContent;
    } else {
      content = query?.data?.getCapsule?.futureContent.filter((content) => content.version == params.version);
      if (content.length) {
        useThis = content[0];
      } else {
        content = query?.data?.getCapsule?.pastContent.filter((content) => content.version == params.version);
        if (content.length) {
          useThis = content[0];
        }
      }
    }
  }
  const learnGoalsJSX =
    useThis?.learnGoals &&
    useThis?.learnGoals.length > 0 &&
    useThis?.learnGoals?.map((goal, index) => (
      <p className="capsule__description"  style={{color:isHelpipedia?'black':''}} key={index}>
        {goal}
      </p>
    ));

  const capsuleContentJSX = useThis?.subcapsuleContent.map((subContent) => {
   
    return (
      <div className="capsule__subContentItem" key={subContent.id}>
        <CaretRightOutlined className="capsule__caretIcon"  style={{color:isHelpipedia?'black':STEERUS_GREEN }} />
        <h2 className="capsule__subtitle capsule__subtitleContent" onClick={() => history.push(`${location.pathname.includes('helpipedia')?"/helpipedia":""}/capsule/class/${subContent.id}`)}
         style={{color:isHelpipedia?'black':''}}>
          {subContent.title}
        </h2>
        {subContent?.video?.length && false && (
          <div style={{ display: 'flex' }}>
            <Clock style={{ marginTop: '4px' }} />
            <p className="capsule__description"  style={{color:isHelpipedia?'black':''}}>{Math.ceil(subContent?.video?.length / 60)} m</p>
          </div>
        )}
      </div>
    );
  });
  const openAttachment = (attachment) => {
    saveEvent({
      variables: {
        eventName: 'ATTACHMENT_CLICKED',
        misc: JSON.stringify({
          url: attachment.url,
          capsule_id: useThis.id,
        }),
      },
    });
    if (attachment.url.includes('pdf')) {
      history.push(location.pathname.includes('helpipedia')?'/helpipedia/pdf':'/pdf', {
        url: attachment.url,
      });
    } else {
      window.open(attachment.url, '_Blank');
    }
  };

  return (
    <PageLayout loading={query.loading}>
      <StandardMarginDiv>
        {query.data && (
          <div className="enhancedMargins capsule">
            <h1 className="capsule__title"  style={{color:isHelpipedia?'black':''}}>{useThis?.title}</h1>
            {useThis?.briefAbout && (
              <>
                <h2 className="capsule__subtitle" style={{color:isHelpipedia?'black':''}}>In this capsule</h2>
                <p className="capsule__description"  style={{color:isHelpipedia?'black':''}}>{useThis?.briefAbout}</p>
              </>
            )}
            {useThis?.about && (
              <>
                <h2 className="capsule__subtitle" style={{color:isHelpipedia?'black':''}}>Why you need to take this capsule</h2>
                <p className="capsule__description">{useThis?.about}</p>
              </>
            )}
            {useThis?.learnGoals && useThis?.learnGoals.length > 0 && (
              <>
                <h2 className="capsule__subtitle"  style={{color:isHelpipedia?'black':''}}>Learning Objectives</h2>
                {learnGoalsJSX}
              </>
            )}
            <div className="capsule__content">
              <div className="capsule__contentInnerLeft">
                <CapsuleCoachCard data={query?.data?.getCapsule?.coach?.userProfile} />
                <div className="capsule__rating">
                  <h2 className="capsule__subtitle"  style={{color:isHelpipedia?'black':''}}>Did you learn from this Capsule?</h2>
                  <div className="capsule__ratingIcons">
                    <FrownOutlined className="capsule__ratingIcon" style={{ color: emojiClicked && reviewSelected == 1 ? isHelpipedia?HELPipedia_ORANGE_ALPHA: STEERUS_GREEN_ALPHA : isHelpipedia?HELPipedia_ORANGE: STEERUS_GREEN }} onClick={() => review(1)} />
                    <MehOutlined className="capsule__ratingIcon" style={{ color: emojiClicked && reviewSelected == 3 ? isHelpipedia?HELPipedia_ORANGE_ALPHA: STEERUS_GREEN_ALPHA : isHelpipedia?HELPipedia_ORANGE: STEERUS_GREEN }} onClick={() => review(3)} />
                    <SmileOutlined className="capsule__ratingIcon" style={{ color: emojiClicked && reviewSelected == 5 ?isHelpipedia?HELPipedia_ORANGE_ALPHA: STEERUS_GREEN_ALPHA : isHelpipedia?HELPipedia_ORANGE: STEERUS_GREEN }} onClick={() => review(5)} />
                  </div>
                  <p className="capsule__description"  style={{color:isHelpipedia?'black':''}}>
                    Avg. = {totalAvg} #Votes = {totalVotes}
                  </p>
                </div>
              </div>
              <div className="capsule__contentInnerRight">
                {capsuleContentJSX}
                {useThis?.skillCheckUrl !== null && (
                  <div className="capsule__subContentItem">
                    <CaretRightOutlined style={{ color: isHelpipedia?'black':STEERUS_GREEN }} className="capsule__caretIcon" />
                    <h2
                      className="capsule__subtitle capsule__subtitleContent"
                      onClick={() => {
                        history.push(`${location.pathname.includes('helpipedia')?"/helpipedia":""}/capsule/${useThis?.id}/skillCheck`);
                      }}
                      style={{color:isHelpipedia?'black':''}} >
                      Take the quiz
                    </h2>
                  </div>
                )}
                {useThis?.attachmentObjects?.length > 0 && (
                  <div>
                    <h2 className="capsule__subtitle"  style={{color:isHelpipedia?'black':''}}> Class Materials </h2>
                    {useThis?.attachmentObjects?.map((attachment) => (
                      <div style={{ display: 'flex', alignItems: 'center' }} key={attachment.id}>
                        <FileText className="capsule__caretIcon" />
                        <SimpleButton title={'Open ' + (attachment.type || attachment.name)} click={() => openAttachment(attachment)} />
                      </div>
                    ))}
                  </div>
                )}
                {useThis?.softskillTags && useThis?.softskillTags.length > 0 && (
                  <div className="capsule__tags">
                    <h2 className="capsule__subtitle" style={{color:isHelpipedia?'black':''}} >Soft Skills</h2>
                    <Tags tags={useThis?.softskillTags} />
                  </div>
                )}
                {useThis?.tags && useThis?.tags.length > 0 && (
                  <div className="capsule__tags">
                    <h2 className="capsule__subtitle"  style={{color:isHelpipedia?'black':''}}>Tags</h2>
                    <Tags tags={useThis?.tags} />
                  </div>
                )}
                {useThis?.subcapsuleContent
                  .filter((x) => x.bookUrl !== null)
                  .map((bookItem) => (
                    <div key={bookItem.id}>
                      <Button onClick={() => window.open(x.bookURL, '_blank')}>Open link to book</Button>
                    </div>
                  ))}
              </div>
            </div>

            <iframe src="https://e.widgetbot.io/channels/955994758092845086/958174257215594496" height="600" width="100%"></iframe>
          </div>
        )}
      </StandardMarginDiv>
    </PageLayout>
  );
};

export default CapsuleContainer;
