import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { ErrorContent } from './ErrorContent';

class ErrorBoundary extends Component {
  constructor() {
    super();
    this.state = {
      hasError: false,
    };
    this.resetHandler = this.resetHandler.bind(this);
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  resetHandler() {
    this.setState({ hasError: false });
    window.location.href = '/';
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? <ErrorContent reset={this.resetHandler} /> : children;
  }
}

export default withRouter(ErrorBoundary);
