// import { gql, useMutation, useQuery } from '@apollo/client';
// import { PageLayout, StandardMarginDiv } from '../../components';
// import { Button, Radio } from 'antd';
// import React, { useState } from 'react';
// import { GET_ASSESSMENTS, questionQuery, submitSoftSkillsAssessment } from './request';
// import { empty, sum, vals } from './utils';
// import './softSkills.less';
// import { useMediaQuery } from 'react-responsive';
// import Question10 from '../Questions/Question10';
// import Question11 from '../Questions/Question11';
// import Question12 from '../Questions/Question12';
// import Question13 from '../Questions/Question13';
// import Question14 from '../Questions/Question14';
// import Question15 from '../Questions/Question15';
// import ButtonInsideLink from '../../components/Buttons/ButtonInsideLink';
// import Question16 from 'assessments/Questions/Question16';

// const answers = ['Strongly Agree', 'Agree', 'Neither Agree Nor Disagree', 'Disagree', 'Strongly Disagree'];
// const softSkills = ['STEM Related', 'Liberal Arts Related', 'Professional Program (such as healthcare, business, law , etc)', 'Other'];

// const CANCELED = gql`
//   mutation cancledNovetly($assessment: Boolean) {
//     cancledNovetly(assessment: $assessment)
//   }
// `;

// const AssessmentSoftSkills = () => {
//   const { data: assessmentData } = useQuery(GET_ASSESSMENTS, {
//     variables: { id: 4 },
//   });

//   const [canceledNovelty] = useMutation(CANCELED, {
//     variables: {
//       assessment: true,
//     },
//   });

//   const isTabletOrMobileDevice = useMediaQuery({
//     query: '(max-width: 700px)',
//   });

//   const { loading, data: questionData } = useQuery(questionQuery);
//   const [mutateAnswer, { loading: loadingTest }] = useMutation(submitSoftSkillsAssessment);
//   const [name, setName] = useState('');
//   const [description, setDescription] = useState('');

//   const [question12, setQuestion12] = useState({
//     associate: '',
//     bachelor: '',
//     teaching_credential: '',
//     doctoral: '',
//     certificate: '',
//     master: '',
//     high_school: '',
//     trade_school: '',
//     uneducated:false
//   });

//   const [associate, setAssociate] = useState('');
//   const [bachelor, setBachelor] = useState('');
//   const [master, setMaster] = useState('');
//   const [doctoral, setDoctoral] = useState('');
//   const [school_high, setSchoolHigh] = useState('');
//   const [school_trade, setSchoolTrade] = useState('');
//   const [other, setOther] = useState('');

//   const [showComplete, setShowComplete] = useState(false);
//   const [years, setYears] = useState(0);

//   const [error, setError] = useState(false);
//   const [employmentDetails, setEmploymentDetails] = useState({});

//   const [checkbox, setCheckbox] = useState({
//     accounting: false,
//     agriculture: false,
//     arts: false,
//     construction: false,
//     education: false,
//     govermment: false,
//     healtcare: false,
//     hospitality: false,
//     manufacturing: false,
//     mining: false,
//     real_estate: false,
//     retail: false,
//     science: false,
//     transport: false,
//     utilities: false,
//     wholesale: false,
//     other: false,
//   });

//   const [communication, setCommunication] = useState({
//     0: { value: '', error: false },
//     1: { value: '', error: false },
//     2: { value: '', error: false },
//     3: { value: '', error: false },
//     4: { value: '', error: false },
//     5: { value: '', error: false },
//     6: { value: '', error: false },
//     7: { value: '', error: false },
//   });

//   const [critical, setCritical] = useState({
//     0: { value: '', error: false },
//     1: { value: '', error: false },
//     2: { value: '', error: false },
//     3: { value: '', error: false },
//     4: { value: '', error: false },
//     5: { value: '', error: false },
//     6: { value: '', error: false },
//   });

//   const [teamWork, setTeamWork] = useState({
//     0: { value: '', error: false },
//     1: { value: '', error: false },
//     2: { value: '', error: false },
//     3: { value: '', error: false },
//     4: { value: '', error: false },
//     5: { value: '', error: false },
//     6: { value: '', error: false },
//     7: { value: '', error: false },
//     8: { value: '', error: false },
//   });

//   const [professionalism, setProfessionalism] = useState({
//     0: { value: '', error: false },
//     1: { value: '', error: false },
//     2: { value: '', error: false },
//     3: { value: '', error: false },
//     4: { value: '', error: false },
//     5: { value: '', error: false },
//   });

//   const [adaptability, setAdaptability] = useState({
//     0: { value: '', error: false },
//     1: { value: '', error: false },
//     2: { value: '', error: false },
//     3: { value: '', error: false },
//     4: { value: '', error: false },
//     5: { value: '', error: false },
//     6: { value: '', error: false },
//   });

//   const [decisionMaking, setDecisionMaking] = useState({
//     0: { value: '', error: false },
//     1: { value: '', error: false },
//     2: { value: '', error: false },
//     3: { value: '', error: false },
//     4: { value: '', error: false },
//   });

//   const [selfManagement, setSelfManagement] = useState({
//     0: { value: '', error: false },
//     1: { value: '', error: false },
//     2: { value: '', error: false },
//     3: { value: '', error: false },
//     4: { value: '', error: false },
//     5: { value: '', error: false },
//   });

//   const [engagement, setEngagement] = useState({
//     0: { value: '', error: false },
//     1: { value: '', error: false },
//     2: { value: '', error: false },
//     3: { value: '', error: false },
//     4: { value: '', error: false },
//   });

//   const [empathy, setEmpathy] = useState({
//     0: { value: '', error: false },
//     1: { value: '', error: false },
//     2: { value: '', error: false },
//     3: { value: '', error: false },
//     4: { value: '', error: false },
//     5: { value: '', error: false },
//     6: { value: '', error: false },
//   });

//   const radioHandler = (value, index, id) => {
//     if (id === 1)
//       setCommunication((prevState) => ({
//         ...prevState,
//         [index]: { value, error: false },
//       }));
//     if (id === 2)
//       setCritical((prevState) => ({
//         ...prevState,
//         [index]: { value, error: false },
//       }));
//     if (id === 3)
//       setTeamWork((prevState) => ({
//         ...prevState,
//         [index]: { value, error: false },
//       }));
//     if (id === 4)
//       setProfessionalism((prevState) => ({
//         ...prevState,
//         [index]: { value, error: false },
//       }));
//     if (id === 5)
//       setAdaptability((prevState) => ({
//         ...prevState,
//         [index]: { value, error: false },
//       }));
//     if (id === 6)
//       setDecisionMaking((prevState) => ({
//         ...prevState,
//         [index]: { value, error: false },
//       }));
//     if (id === 7)
//       setSelfManagement((prevState) => ({
//         ...prevState,
//         [index]: { value, error: false },
//       }));
//     if (id === 8)
//       setEngagement((prevState) => ({
//         ...prevState,
//         [index]: { value, error: false },
//       }));
//     if (id === 9)
//       setEmpathy((prevState) => ({
//         ...prevState,
//         [index]: { value, error: false },
//       }));
//   };

//   const errorHandler = (id) => {
//     if (id === 1) return { ...communication };
//     if (id === 2) return { ...critical };
//     if (id === 3) return { ...teamWork };
//     if (id === 4) return { ...professionalism };
//     if (id === 5) return { ...adaptability };
//     if (id === 6) return { ...decisionMaking };
//     if (id === 7) return { ...selfManagement };
//     if (id === 8) return { ...engagement };
//     if (id === 9) return { ...empathy };

//     return null;
//   };

//   const submitHandler = async () => {
//     try {
//       setError(false);

//       const validateQuestion = (obj) => {
//         let flag;

//         Object.keys(obj).forEach((item) => {
//           if (obj[item]) flag = true;
//         });
//         return flag;
//       };

//       const response1 = empty(communication, setCommunication);
//       const response2 = empty(critical, setCritical);
//       const response3 = empty(teamWork, setTeamWork);
//       const response4 = empty(professionalism, setProfessionalism);
//       const response5 = empty(adaptability, setAdaptability);
//       const response6 = empty(decisionMaking, setDecisionMaking);
//       const response7 = empty(selfManagement, setSelfManagement);
//       const response8 = empty(engagement, setEngagement);
//       const response9 = empty(empathy, setEmpathy);

//       if (response1 || response2 || response3 || response4 || response5 || response6 || response7 || response8 || response9 || !name) {
//         throw 'Error';
//       }

//       if (!validateQuestion(question12)) {
//         throw 'Error';
//       }

//       //question16
//       if (!validateQuestion(employmentDetails)) {
//         throw 'Error';
//       }

//       const comm = sum(communication, 8).toFixed(2);
//       const critic = sum(critical, 7).toFixed(2);
//       const team = sum(teamWork, 9).toFixed(2);
//       const proff = sum(professionalism, 7).toFixed(2);
//       const adapt = sum(adaptability, 7).toFixed(2);
//       const decis = sum(decisionMaking, 5).toFixed(2);
//       const self = sum(selfManagement, 6).toFixed(2);
//       const engag = sum(engagement, 5).toFixed(2);
//       const empath = sum(empathy, 7).toFixed(2);

//       const data = questionData?.getSoftSkillsQuestions.map((item) => {
//         if (item.id === 1) {
//           return {
//             ...item,
//             value: comm,
//             content: item.content.map((itemContent, index) => {
//               return { ...itemContent, value: communication[index].value };
//             }),
//           };
//         } else if (item.id === 2) {
//           return {
//             ...item,
//             value: critic,
//             content: item.content.map((itemContent, index) => {
//               return { ...itemContent, value: critical[index].value };
//             }),
//           };
//         } else if (item.id === 3) {
//           return {
//             ...item,
//             value: team,
//             content: item.content.map((itemContent, index) => {
//               return { ...itemContent, value: teamWork[index].value };
//             }),
//           };
//         } else if (item.id === 4) {
//           return {
//             ...item,
//             value: proff,
//             content: item.content.map((itemContent, index) => {
//               return { ...itemContent, value: professionalism[index].value };
//             }),
//           };
//         } else if (item.id === 5) {
//           return {
//             ...item,
//             value: adapt,
//             content: item.content.map((itemContent, index) => {
//               return { ...itemContent, value: adaptability[index].value };
//             }),
//           };
//         } else if (item.id === 6) {
//           return {
//             ...item,
//             value: decis,
//             content: item.content.map((itemContent, index) => {
//               return { ...itemContent, value: decisionMaking[index].value };
//             }),
//           };
//         } else if (item.id === 7) {
//           return {
//             ...item,
//             value: self,
//             content: item.content.map((itemContent, index) => {
//               return { ...itemContent, value: selfManagement[index].value };
//             }),
//           };
//         } else if (item.id === 8) {
//           return {
//             ...item,
//             value: engag,
//             content: item.content.map((itemContent, index) => {
//               return { ...itemContent, value: engagement[index].value };
//             }),
//           };
//         } else if (item.id === 9) {
//           return {
//             ...item,
//             value: empath,
//             content: item.content.map((itemContent, index) => {
//               return { ...itemContent, value: empathy[index].value };
//             }),
//           };
//         }
//         return item;
//       });

//       const response = await mutateAnswer({
//         variables: {
//           fields: JSON.stringify(data),
//           data: JSON.stringify({
//             full_name: name,
//             description: description,
//             school: {
//               associate: associate,
//               bachelor: bachelor,
//               master: master,
//               doctoral: doctoral,
//               other: other,
//               school_high,
//               school_trade,
//             },
//             certificates: {
//               ...question12,
//             },
//             years: years,
//             ...checkbox,
//             ...employmentDetails,
//           }),
//         },
//       });
//       canceledNovelty().then((r) => r);
//       if (response.data.submitSoftSkillsAssessment.response) setShowComplete(true);
//     } catch (e) {
//       setError(true);
//     }
//   };

//   return (
//     <PageLayout loading={loading || loadingTest}>
//       <StandardMarginDiv>
//         {!loading && !showComplete ? (
//           <>
//             <p className='heading'> {assessmentData?.getAssessment?.title} </p>
//             <h2 className='test__about'>
//               {' '}
//               Thank you for taking this survey. The survey will help you identify your soft skills strengths and
//               weaknesses. You can then use what you learn to
//               bolster your effectiveness. Please answer honestly, this will give you an accurate look at your strengths
//               and areas where you want to improve.{' '}
//             </h2>
//             <h2 className='test__about'>
//               {' '}
//               {assessmentData?.getAssessment?.coach?.userProfile?.firstName} {assessmentData?.getAssessment?.coach?.userProfile?.lastName}{' '}
//             </h2>
//             <div>
//               <div className='testBlock'>
//                 <h2>Statements About My Soft Skills</h2>
//                 <div className='testBlock'>
//                   {questionData?.getSoftSkillsQuestions.map((item, index) => (
//                     <div className='test' key={item.title + index}>
//                       <div className='test__questions'>
//                         <div className='test__questions-header'>
//                           <h4 className='test__questions-title'>
//                             {index + 1}. {item.title} <b className={'required__star'}>*</b>
//                           </h4>
//                           {!isTabletOrMobileDevice && (
//                             <div className='testBlock__head'>
//                               <div className='testBlock__headTitles'>
//                                 {answers.map((item,index) => (
//                                   <h3 key={index}>{item}</h3>
//                                 ))}
//                               </div>
//                             </div>
//                           )}
//                         </div>
//                         {item?.content.map((ques, index) => {
//                           const questionError = errorHandler(item.id) ? errorHandler(item.id)[index]?.error : false;
//                           return (
//                             <div className='test__questions-block' key={ques?.question + index}>
//                               <h4
//                                 className={`test__questions-block-title ${questionError ? 'hello ' : ''}`}>{ques?.question}</h4>
//                               <div className='test__questions-block-radio'>
//                                 <Radio.Group
//                                   size='large'
//                                   onChange={(e) => {
//                                     radioHandler(e.target.value, index, item.id);
//                                   }}
//                                 >
//                                   {vals.map((value, index) => (
//                                     <Radio key={value.value + index +new Date().toString()+index} value={value.value}>
//                                       {isTabletOrMobileDevice ? answers[index] : null}
//                                     </Radio>
//                                   ))}
//                                 </Radio.Group>
//                               </div>
//                             </div>
//                           );
//                         })}
//                       </div>
//                     </div>
//                   ))}

//                   <div className='soft-skills'>
//                     <Question10 description={description} setDescription={setDescription} />
//                     <Question11 name={name} setName={setName} />
//                     <Question12 isTabletOrMobileDevice={isTabletOrMobileDevice} setQuestion12={setQuestion12}
//                                 softSkills={softSkills} question12={question12}/>
//                     <Question13
//                       schoolTrade={school_trade}
//                       setSchoolTrade={setSchoolTrade}
//                       schoolHigh={school_high}
//                       setSchoolHigh={setSchoolHigh}
//                       doctoral={doctoral}
//                       setDoctoral={setDoctoral}
//                       other={other}
//                       setOther={setOther}
//                       setAssociate={setAssociate}
//                       associate={associate}
//                       master={master}
//                       setMaster={setMaster}
//                       bachelor={bachelor}
//                       setBachelor={setBachelor}
//                     />
//                     <Question14 years={years} setYears={setYears} />
//                     <Question15 setCheckbox={setCheckbox} />
//                     <Question16
//                       employmentDetails={employmentDetails}
//                       setEmploymentDetails={setEmploymentDetails}
//                     />
//                   </div>
//                 </div>

//                 {error && <p style={{ color: 'red' }}>Please answer all of the questions </p>}
//                 <Button onClick={submitHandler} disabled={loadingTest}>
//                   {' '}
//                   Submit{' '}
//                 </Button>
//               </div>
//             </div>
//           </>
//         ) : !loading ? (
//           <div className='assessments__completed'>
//             <p className='heading'> Thank you for taking this assessment! Your results are on their way to your email
//               right now! </p>
//             <ButtonInsideLink style='center' targetBlank={false} title={'Go Back'} link='/marketplace'>
//               Go Back
//             </ButtonInsideLink>
//           </div>
//         ) : null}
//       </StandardMarginDiv>
//     </PageLayout>
//   );
// };

// export default AssessmentSoftSkills;


import { gql, useMutation, useQuery } from '@apollo/client';
import { PageLayout, StandardMarginDiv } from '../../components';
import { Button, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { GET_ASSESSMENTS, questionQuery, submitSoftSkillsAssessment } from './request';
import { empty, sum, vals } from './utils';
import './softSkills.less';
import { useMediaQuery } from 'react-responsive';
import Question10 from '../Questions/Question10';
import Question11 from '../Questions/Question11';
import Question12 from '../Questions/Question12';
import Question13 from '../Questions/Question13';
import Question14 from '../Questions/Question14';
import Question15 from '../Questions/Question15';
import Question16 from 'assessments/Questions/Question16';
import ButtonInsideLink from '../../components/Buttons/ButtonInsideLink';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import ActiveRadio from './../../assets/assessments/radio_active.png'
import InActiveRadio from './../../assets/assessments/radio_inactive.png'
import back from './../../assets/assessments/ic_back.png'
import forward from './../../assets/assessments/ic_forward.png'
import CommunicationImage from './../../assets/assessments/img_communication.png'
import CriticalImage from './../../assets/assessments/img_critical.png'
import TeamWorkImage from './../../assets/assessments/img_team_work.png'
import ProfessionalismImage from './../../assets/assessments/img_professional.png'
import SelfManagementImage from './../../assets/assessments/img_self_management.png'
import AdaptabilityImage from './../../assets/assessments/img_adaptability.png'
import DecisionMakingImage from './../../assets/assessments/img_decision_making.png'
import EmpathyImage from './../../assets/assessments/img_empathy.jpg'
import EngagementImage from './../../assets/assessments/img_engagement.jpg'

const answers = ['Strongly Agree', 'Agree', 'Neither Agree Nor Disagree', 'Disagree', 'Strongly Disagree'];
const softSkills = ['STEM Related', 'Liberal Arts Related', 'Professional Program (such as healthcare, business, law , etc)', 'Other'];

const CANCELED = gql`
  mutation cancledNovetly($assessment: Boolean) {
    cancledNovetly(assessment: $assessment)
  }
`;

const AssessmentSoftSkills = () => {
  const { data: assessmentData } = useQuery(GET_ASSESSMENTS, {
    variables: { id: 4 },
  });

  const [canceledNovelty] = useMutation(CANCELED, {
    variables: {
      assessment: true,
    },
  });

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-width: 700px)',
  });

  const { loading, data: questionData } = useQuery(questionQuery);
  const [mutateAnswer, { loading: loadingTest }] = useMutation(submitSoftSkillsAssessment);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('')
  const [questionIndex, setQuestionIndex] = useState(0)
  const [valueIndex, setValueIndex] = useState(0)
  const [showQuestion10, setShowQuestion10] = useState(false)
  const [showQuestion11, setShowQuestion11] = useState(false)
  const [showQuestion12, setShowQuestion12] = useState(false)
  const [showQuestion13, setShowQuestion13] = useState(false)
  const [showQuestion14, setShowQuestion14] = useState(false)
  const [showQuestion15, setShowQuestion15] = useState(false)
  const [showQuestion16, setShowQuestion16] = useState(false)
  const [hideRadio, setHideRadio] = useState(false)
  const [fetchQuestion, setFetchQuestion] = useState({ getSoftSkillsQuestions: [] })

  const [question12, setQuestion12] = useState({
    associate: '',
    bachelor: '',
    teaching_credential: '',
    doctoral: '',
    certificate: '',
    master: '',
    high_school: '',
    trade_school: '',
    uneducated: false
  });

  const [associate, setAssociate] = useState('');
  const [bachelor, setBachelor] = useState('');
  const [master, setMaster] = useState('');
  const [doctoral, setDoctoral] = useState('');
  const [school_high, setSchoolHigh] = useState('');
  const [school_trade, setSchoolTrade] = useState('');
  const [other, setOther] = useState('');
  const [backgroundImage, setBackgroundImage] = useState(CommunicationImage)

  const [showComplete, setShowComplete] = useState(false);
  const [years, setYears] = useState(0);

  const [error, setError] = useState(false);

  const [checkbox, setCheckbox] = useState({
    accounting: false,
    agriculture: false,
    arts: false,
    construction: false,
    education: false,
    govermment: false,
    healtcare: false,
    hospitality: false,
    manufacturing: false,
    mining: false,
    real_estate: false,
    retail: false,
    science: false,
    transport: false,
    utilities: false,
    wholesale: false,
    other: false,
  });
  const [employmentDetails, setEmploymentDetails] = useState({});
  const communicationLength = 8
  const criticalLength = 7
  const teamWorkLength = 9
  const professionalismLength = 6
  const adaptabilityLength = 7
  const decisionMakingLength = 5
  const selfManagementLength = 6
  const engagementLength = 5
  const empathyLength = 7
  const [communication, setCommunication] = useState({
    0: { value: '', error: true },
    1: { value: '', error: true },
    2: { value: '', error: true },
    3: { value: '', error: true },
    4: { value: '', error: true },
    5: { value: '', error: true },
    6: { value: '', error: true },
    7: { value: '', error: true },
  });

  const [critical, setCritical] = useState({
    0: { value: '', error: true },
    1: { value: '', error: true },
    2: { value: '', error: true },
    3: { value: '', error: true },
    4: { value: '', error: true },
    5: { value: '', error: true },
    6: { value: '', error: true },
  });

  const [teamWork, setTeamWork] = useState({
    0: { value: '', error: true },
    1: { value: '', error: true },
    2: { value: '', error: true },
    3: { value: '', error: true },
    4: { value: '', error: true },
    5: { value: '', error: true },
    6: { value: '', error: true },
    7: { value: '', error: true },
    8: { value: '', error: true },
  });

  const [professionalism, setProfessionalism] = useState({
    0: { value: '', error: true },
    1: { value: '', error: true },
    2: { value: '', error: true },
    3: { value: '', error: true },
    4: { value: '', error: true },
    5: { value: '', error: true },
    6: { value: '', error: true },
  });

  const [adaptability, setAdaptability] = useState({
    0: { value: '', error: true },
    1: { value: '', error: true },
    2: { value: '', error: true },
    3: { value: '', error: true },
    4: { value: '', error: true },
    5: { value: '', error: true },
    6: { value: '', error: true },
  });

  const [decisionMaking, setDecisionMaking] = useState({
    0: { value: '', error: true },
    1: { value: '', error: true },
    2: { value: '', error: true },
    3: { value: '', error: true },
    4: { value: '', error: true },
  });

  const [selfManagement, setSelfManagement] = useState({
    0: { value: '', error: true },
    1: { value: '', error: true },
    2: { value: '', error: true },
    3: { value: '', error: true },
    4: { value: '', error: true },
    5: { value: '', error: true },
  });

  const [engagement, setEngagement] = useState({
    0: { value: '', error: true },
    1: { value: '', error: true },
    2: { value: '', error: true },
    3: { value: '', error: true },
    4: { value: '', error: true },
  });

  const [empathy, setEmpathy] = useState({
    0: { value: '', error: true },
    1: { value: '', error: true },
    2: { value: '', error: true },
    3: { value: '', error: true },
    4: { value: '', error: true },
    5: { value: '', error: true },
    6: { value: '', error: true },
  });
  useEffect(() => {
    if (!loading) {
      setQuestionData()
    }
  }, [loading])
  const getPercentValue = (contentLength, index) => {
    const initialValue = 100 / contentLength;
    const percent = initialValue * index
    return percent
  }
  const setQuestionData = async () => {
    setTitle(questionData.getSoftSkillsQuestions[questionIndex].title)
    const newArr = questionData.getSoftSkillsQuestions.map(item => {
  
      return {
        ...item, content: item.content.map((ques, i) => {

          return { ...ques, values: vals, percent: getPercentValue(item.content.length, i + 1) }
        }
        )
      }
    })
    setFetchQuestion(newArr)
  }


  const radioHandler = (value, index, id) => {
    setError(false)
    if (id === 1) {
      setCommunication((prevState) => ({
        ...prevState,
        [index]: { value, error: false },
      }));
    }
    if (id === 2) {
      setCritical((prevState) => ({
        ...prevState,
        [index]: { value, error: false },
      }));
    }
    if (id === 3) {
      setTeamWork((prevState) => ({
        ...prevState,
        [index]: { value, error: false },
      }));
    }
    if (id === 4) {
      setProfessionalism((prevState) => ({
        ...prevState,
        [index]: { value, error: false },
      }));
    }
    if (id === 5) {
      setAdaptability((prevState) => ({
        ...prevState,
        [index]: { value, error: false },
      }));

    }
    if (id === 6) {
      setDecisionMaking((prevState) => ({
        ...prevState,
        [index]: { value, error: false },
      }));

    }
    if (id === 7) {
      setSelfManagement((prevState) => ({
        ...prevState,
        [index]: { value, error: false },
      }));
    }
    if (id === 8) {
      setEngagement((prevState) => ({
        ...prevState,
        [index]: { value, error: false },
      }));
    }
    if (id === 9) {
      setEmpathy((prevState) => ({
        ...prevState,
        [index]: { value, error: false },
      }));
    }
    updateRadioValue(id, value)
  };

  const setNextQuestion = (questionIndex, image, title) => {
    setQuestionIndex(questionIndex)
    setValueIndex(0)
    setTitle(title)
    setBackgroundImage(image)
  }

  const errorHandler = (id) => {
    if (id === 1) return { ...communication };
    if (id === 2) return { ...critical };
    if (id === 3) return { ...teamWork };
    if (id === 4) return { ...professionalism };
    if (id === 5) return { ...adaptability };
    if (id === 6) return { ...decisionMaking };
    if (id === 7) return { ...selfManagement };
    if (id === 8) return { ...engagement };
    if (id === 9) return { ...empathy };

    return null;
  };

  const submitHandler = async () => {
    try {
      setError(false);

      const validateQuestion = (obj) => {
        let flag;

        Object.keys(obj).forEach((item) => {
          if (obj[item]) flag = true;
        });
        return flag;
      };

       //question16
      if (!validateQuestion(employmentDetails)) {
        throw 'Error';
      }
      const comm = sum(communication, 8).toFixed(2);
      const critic = sum(critical, 7).toFixed(2);
      const team = sum(teamWork, 9).toFixed(2);
      const proff = sum(professionalism, 7).toFixed(2);
      const adapt = sum(adaptability, 7).toFixed(2);
      const decis = sum(decisionMaking, 5).toFixed(2);
      const self = sum(selfManagement, 6).toFixed(2);
      const engag = sum(engagement, 5).toFixed(2);
      const empath = sum(empathy, 7).toFixed(2);

      const data = questionData?.getSoftSkillsQuestions.map((item) => {
        if (item.id === 1) {
          return {
            ...item,
            value: comm,
            content: item.content.map((itemContent, index) => {
              return { ...itemContent, value: communication[index].value };
            }),
          };
        } else if (item.id === 2) {
          return {
            ...item,
            value: critic,
            content: item.content.map((itemContent, index) => {
              return { ...itemContent, value: critical[index].value };
            }),
          };
        } else if (item.id === 3) {
          return {
            ...item,
            value: team,
            content: item.content.map((itemContent, index) => {
              return { ...itemContent, value: teamWork[index].value };
            }),
          };
        } else if (item.id === 4) {
          return {
            ...item,
            value: proff,
            content: item.content.map((itemContent, index) => {
              return { ...itemContent, value: professionalism[index].value };
            }),
          };
        } else if (item.id === 5) {
          return {
            ...item,
            value: adapt,
            content: item.content.map((itemContent, index) => {
              return { ...itemContent, value: adaptability[index].value };
            }),
          };
        } else if (item.id === 6) {
          return {
            ...item,
            value: decis,
            content: item.content.map((itemContent, index) => {
              return { ...itemContent, value: decisionMaking[index].value };
            }),
          };
        } else if (item.id === 7) {
          return {
            ...item,
            value: self,
            content: item.content.map((itemContent, index) => {
              return { ...itemContent, value: selfManagement[index].value };
            }),
          };
        } else if (item.id === 8) {
          return {
            ...item,
            value: engag,
            content: item.content.map((itemContent, index) => {
              return { ...itemContent, value: engagement[index].value };
            }),
          };
        } else if (item.id === 9) {
          return {
            ...item,
            value: empath,
            content: item.content.map((itemContent, index) => {
              return { ...itemContent, value: empathy[index].value };
            }),
          };
        }
        return item;
      });
      
      const response = await mutateAnswer({
        variables: {
          fields: JSON.stringify(data),
          data: JSON.stringify({
            full_name: name,
            description: description,
            school: {
              associate: associate,
              bachelor: bachelor,
              master: master,
              doctoral: doctoral,
              other: other,
              school_high,
              school_trade,
            },
            certificates: {
              ...question12,
            },
            years: years,
            ...checkbox,
            ...employmentDetails,
          }),
        },
      });
      canceledNovelty().then((r) => r);
      if (response.data.submitSoftSkillsAssessment.response) setShowComplete(true);
    } catch (e) {
     
      setError(true);
    }
  };
  const updateRadioValue = (id, value) => {

    const newArr = fetchQuestion.map(item => {
      if (id == item.id) {
        return {
          ...item, content: item.content.map(ques => {

            if (ques.question == fetchQuestion[questionIndex].content[valueIndex].question) {
              return {
                ...ques, values: ques.values.map(val => {
                  if (val.value == value) {
                    return { ...val, isChecked: true }
                  }
                  else
                    return { ...val, isChecked: false }
                })
              }
            }
            else {
              return ques
            }
          })
        }
      }
      else
        return item
    })

    setFetchQuestion(newArr)
  }

  const RadioButton = ({ isChecked, choice, value, index }) => {
    const id = fetchQuestion[questionIndex]?.id

    return <div className='radio-div' onClick={() => {

      radioHandler(value.value, index, id)
    }}>
      {isChecked ? <img src={ActiveRadio} /> : <img src={InActiveRadio} />
      }
      <p>{choice}</p>
    </div>
  }
  const reduceIndex = () => {
    if (questionIndex == 8) {
      if (valueIndex > 0) {
        setValueIndex(prev => prev - 1)
      }
      else if (valueIndex == 0) {
        setBackgroundImage(EngagementImage)
        setQuestionIndex(7)
        setValueIndex(engagementLength - 1)
        setTitle(questionData.getSoftSkillsQuestions[7].title)
      }
    }

    if (questionIndex == 7) {
      if (valueIndex > 0) {
        setValueIndex(prev => prev - 1)
      }
      else if (valueIndex == 0) {
        setBackgroundImage(SelfManagementImage)
        setQuestionIndex(6)
        setValueIndex(selfManagementLength - 1)
        setTitle(questionData.getSoftSkillsQuestions[6].title)
      }
    }

    if (questionIndex == 6) {
      if (valueIndex > 0) {
        setValueIndex(prev => prev - 1)
      }
      else if (valueIndex == 0) {
        setBackgroundImage(DecisionMakingImage)
        setQuestionIndex(5)
        setValueIndex(decisionMakingLength - 1)
        setTitle(questionData.getSoftSkillsQuestions[5].title)
      }
    }
    if (questionIndex == 5) {
      if (valueIndex > 0) {
        setValueIndex(prev => prev - 1)
      }
      else if (valueIndex == 0) {
        setBackgroundImage(AdaptabilityImage)
        setQuestionIndex(4)
        setValueIndex(adaptabilityLength - 1)
        setTitle(questionData.getSoftSkillsQuestions[4].title)
      }
    }

    if (questionIndex == 4) {
      if (valueIndex > 0) {
        setValueIndex(prev => prev - 1)
      }
      else if (valueIndex == 0) {
        setBackgroundImage(ProfessionalismImage)
        setQuestionIndex(3)
        setValueIndex(professionalismLength - 1)
        setTitle(questionData.getSoftSkillsQuestions[3].title)
      }
    }
    if (questionIndex == 3) {
      if (valueIndex > 0) {
        setValueIndex(prev => prev - 1)
      }
      else if (valueIndex == 0) {
        setBackgroundImage(TeamWorkImage)
        setQuestionIndex(2)
        setValueIndex(teamWorkLength - 1)
        setTitle(questionData.getSoftSkillsQuestions[2].title)
      }
    }
    if (questionIndex == 2) {
      if (valueIndex > 0) {
        setValueIndex(prev => prev - 1)
      }
      else if (valueIndex == 0) {
        setBackgroundImage(CriticalImage)
        setQuestionIndex(1)
        setValueIndex(criticalLength - 1)
        setTitle(questionData.getSoftSkillsQuestions[1].title)
      }
    }
    if (questionIndex == 1) {
      if (valueIndex > 0) {
        setValueIndex(prev => prev - 1)
      }
      else if (valueIndex == 0) {
        setBackgroundImage(CommunicationImage)
        setQuestionIndex(0)
        setValueIndex(communicationLength - 1)
        setTitle(questionData.getSoftSkillsQuestions[0].title)
      }
    }
    if (questionIndex == 0) {
      if (valueIndex > 0) {
        setValueIndex(prev => prev - 1)
      }

    }

    if (showQuestion10) {
      setBackgroundImage(EmpathyImage)
      setHideRadio(false)
      setShowQuestion10(false)
      setValueIndex(empathyLength - 1)
      setQuestionIndex(8)
      setTitle(questionData.getSoftSkillsQuestions[8].title)
    }

    if (showQuestion11) {
      setShowQuestion11(false)
      setShowQuestion10(true)
    }
    if (showQuestion12) {
      setShowQuestion12(false)
      setShowQuestion11(true)
    }
    if (showQuestion13) {
      setShowQuestion13(false)
      setShowQuestion12(true)
      setQuestion12({
        associate: '',
        bachelor: '',
        teaching_credential: '',
        doctoral: '',
        certificate: '',
        master: '',
        high_school: '',
        trade_school: '',
        uneducated: false
      })
    }
    if (showQuestion14) {
      setShowQuestion14(false)
      setShowQuestion13(true)
    }
    if (showQuestion15) {
      setShowQuestion15(false)
      setShowQuestion14(true)
      setCheckbox({
        accounting: false,
        agriculture: false,
        arts: false,
        construction: false,
        education: false,
        govermment: false,
        healtcare: false,
        hospitality: false,
        manufacturing: false,
        mining: false,
        real_estate: false,
        retail: false,
        science: false,
        transport: false,
        utilities: false,
        wholesale: false,
        other: false,
      })
    }
    if (showQuestion16) {
      setShowQuestion16(false)
      setShowQuestion15(true)
   
    }
  }

  const moveForward = () => {
    const id = fetchQuestion[questionIndex]?.id

    const questionError = errorHandler(id) ? errorHandler(id)[valueIndex]?.error : false;
    setError(questionError)
    if (id == 1) {
      if (!questionError) {

        if (communicationLength - 1 == valueIndex) {
          setNextQuestion(1, CriticalImage, questionData.getSoftSkillsQuestions[1].title)
        }
        else {
          setValueIndex(prev => prev + 1)
        }
      }
    }
    if (id == 2) {
      if (!questionError) {
        if (criticalLength - 1 == valueIndex) {
          setNextQuestion(2, TeamWorkImage, questionData.getSoftSkillsQuestions[2].title)
        }
        else {
          setValueIndex(prev => prev + 1)
        }
      }
    }
    if (id == 3) {
      if (!questionError) {
       
        if (teamWorkLength - 1 == valueIndex) {
          setNextQuestion(3, ProfessionalismImage, questionData.getSoftSkillsQuestions[3].title)
        }
        else {
          setValueIndex(prev => prev + 1)
        }
      }
    }
    if (id === 4) {
      if (!questionError) {
       if (professionalismLength - 1 == valueIndex) {
          setNextQuestion(4, AdaptabilityImage, questionData.getSoftSkillsQuestions[4].title)
        }
        else {
          setValueIndex(prev => prev + 1)
        }
      }
    }
    if (id === 5) {
      if (!questionError) {
        if (adaptabilityLength - 1 == valueIndex) {
          setNextQuestion(5, DecisionMakingImage, questionData.getSoftSkillsQuestions[5].title)
        }
        else {
          
          setValueIndex(prev => prev + 1)
        }
      }
    }
    if (id === 6) {
      if (!questionError) {
        if (decisionMakingLength - 1 == valueIndex) {
          setNextQuestion(6, SelfManagementImage, questionData.getSoftSkillsQuestions[6].title)
        }
        else {
          setValueIndex(prev => prev + 1)
        }
      }
    }
    if (id === 7) {
      if (!questionError) {
        if (selfManagementLength - 1 == valueIndex) {
          setNextQuestion(7, EngagementImage, questionData.getSoftSkillsQuestions[7].title)
        }
        else {
          setValueIndex(prev => prev + 1)
        }
      }
    }
    if (id === 8) {
      if (!questionError) {
        if (engagementLength - 1 == valueIndex) {
          setNextQuestion(8, EmpathyImage, questionData.getSoftSkillsQuestions[8].title)
        }
        else {
          setValueIndex(prev => prev + 1)
        }
      }
    }
    if (id === 9) {
      if (!questionError) {
        if (empathyLength - 1 == valueIndex) {
          setHideRadio(true)
          setShowQuestion10(true)
          setBackgroundImage(CommunicationImage)
        }
        else {
          setValueIndex(prev => prev + 1)
        }
      }
    }
  }
  const validateQuestion = (obj) => {
    let flag;

    Object.keys(obj).forEach((item) => {
      if (obj[item]) flag = true;
    });
    return flag;
  };
  const next = () => {
    if (showQuestion10) {
      setShowQuestion10(false)
      setShowQuestion11(true)
    }
    if (showQuestion11 && name.trim() != '') {
      setShowQuestion11(false)
      setShowQuestion12(true)
    }
    // if (showQuestion12 && !validateQuestion(question12)) {
    //   setError(true);
    //   throw 'Error';
    // }
    // else if (showQuestion12 && validateQuestion(question12)) {
     if (showQuestion12) {
      // setError(false);
      setShowQuestion12(false)
      setShowQuestion13(true)
    }
    if (showQuestion13) {
      setShowQuestion13(false)
      setShowQuestion14(true)
    }
    if (showQuestion14) {
      setShowQuestion14(false)
      setShowQuestion15(true)
    }
    if (showQuestion15) {
      setShowQuestion15(false)
      setShowQuestion16(true)
     
    }
    if(showQuestion16){
      submitHandler()
    }

  }
  return (
    <PageLayout loading={loading || loadingTest}>
      {!loading && !showComplete && fetchQuestion.length>0  ? <div className='backImage-test' style={{ backgroundImage: `url(${backgroundImage})` }}>
        
        {!hideRadio && <h1 className='questionTitle'> {questionIndex + 1}. {title}</h1>}
        {!hideRadio && <ProgressBar progress={fetchQuestion[questionIndex]?.content[valueIndex].percent} height={10} />}
        {!hideRadio && <div className='question-card'>

          <div className='div-question-heading'>
            <img src={back} onClick={reduceIndex} />
            <p className='question'> {fetchQuestion.length > 0 ? fetchQuestion[questionIndex]?.content[valueIndex].question : ''}</p>
            <img src={forward} onClick={moveForward} />
          </div>

          <div className='radio-container'>
            {fetchQuestion[questionIndex]?.content[valueIndex].values.map((value, index) => (
              <RadioButton
                isChecked={value.isChecked}
                index={valueIndex}
                value={value}
                key={value.value + index + new Date().toString() + index}
                choice={answers[index]}
              />))}
          </div>
          {error && <p className='error'>Please answer the question</p>}
        </div>
        }
        {
          hideRadio && <div className='question-card'>

            <div className='back-icon '>
              <img src={back} onClick={reduceIndex} />
            </div>
            <div className='radio-container soft-skills' >
              {showQuestion10 && <Question10 description={description} setDescription={setDescription} />}
              {showQuestion11 && <Question11 name={name} setName={setName} />}
              {showQuestion12 && <Question12 isTabletOrMobileDevice={isTabletOrMobileDevice} setQuestion12={setQuestion12}
                softSkills={softSkills} question12={question12} />}
              {showQuestion13 && <Question13
                schoolTrade={school_trade}
                setSchoolTrade={setSchoolTrade}
                schoolHigh={school_high}
                setSchoolHigh={setSchoolHigh}
                doctoral={doctoral}
                setDoctoral={setDoctoral}
                other={other}
                setOther={setOther}
                setAssociate={setAssociate}
                associate={associate}
                master={master}
                setMaster={setMaster}
                bachelor={bachelor}
                setBachelor={setBachelor}
              />}
              {showQuestion14 && <Question14 years={years} setYears={setYears} />}
              {showQuestion15 && <Question15 setCheckbox={setCheckbox} />}
              {showQuestion16 && <Question16
                      employmentDetails={employmentDetails}
                      setEmploymentDetails={setEmploymentDetails}
                    />}
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

              <button onClick={next} disabled={loadingTest} className='test-next-button'>{showQuestion16 ? 'Submit' : 'Next'}</button>
            </div>
            {error && <p className='error'>Please answer the question</p>}
          </div>
        }

      </div> : !loading ? (
        <div className='assessments__completed'>
          <p className='heading'> Thank you for taking this assessment! Your results are on their way to your email
            right now! </p>
          <ButtonInsideLink style='center' targetBlank={false} title={'Go Back'} link='/marketplace'>
            Go Back
          </ButtonInsideLink>
        </div>
      ) : null}
    </PageLayout>
  );
};

export default AssessmentSoftSkills;


