import React from 'react';
import {useParams} from 'react-router-dom';
import {StandardMarginDiv, PageLayout} from "../components";
import {quizzes} from "./QuizData";
import './styles.less';

const QuizPage = () => {
  const params = useParams();

  return (
    <PageLayout>
      <StandardMarginDiv>
        <div className="quizContainer">
          {quizzes[params.term]}
        </div>
      </StandardMarginDiv>
    </PageLayout>
  );
};

export default QuizPage;
