import React, { useState, useCallback } from 'react';
import { Button, Input } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';

import './styles.less';

const getMessageAuthor = (message, currentUser) => {
  if (currentUser?.id === message?.fromUser?.id) {
    return 'You';
  }

  let author = message?.fromUser?.email;
  if (
    message?.fromUser?.userProfile?.firstName?.trim()
    || message?.fromUser?.userProfile?.lastName?.trim()
  ) {
    const firstName = message?.fromUser?.userProfile?.firstName ?? '';
    const lastName = message?.fromUser?.userProfile?.lastName ?? '';
    author = `${firstName} ${lastName}`.trim();
  }
  if (!author) {
    author = 'steerus user';
  }

  return author;
};

const messageToJsx = (currentUser) => (message) => {
  const className = currentUser?.id === message?.fromUser?.id
    ? 'chat_box--message_item--my'
    : 'chat_box--message_item';
  const author = getMessageAuthor(message, currentUser);

  return (
    <div key={message.id} className={className}>
      <div>
        <p className="chat_box--message_author">{author}</p>
        <p className="chat_box--message_text">{message.message}</p>
      </div>
    </div>
  );
};

function ChatBox({
  messages = [],
  onFetch,
  hasMore,
  onSendMessage,
  currentUser,
  title = 'THIS CHAT IS PUBLIC – EVERYONE CAN SEE YOUR POST',
}) {
  const [textInput, setTextInput] = useState(null);

  const handleSendMessage = useCallback(() => {
    onSendMessage(textInput);
    setTextInput('');
  }, [onSendMessage, textInput]);

  return (
    <div className="chat_box">
      <div className="chat_header">{title}</div>
      <div id="scrollableDiv" className="chat_box--scroll_wrapper">
        <InfiniteScroll
          dataLength={messages?.length}
          next={onFetch}
          inverse
          hasMore={hasMore}
          className="chat_box--scroll"
          scrollableTarget="scrollableDiv"
        >
          {
            messages.length
              ? messages.map(messageToJsx(currentUser))
              : <div className="chat_box--placeholder-text">No further messages</div>
          }
        </InfiniteScroll>
      </div>

      <div className="chat_box--actions">
        <Input
          value={textInput}
          placeholder="Message..."
          onChange={(e) => setTextInput(e.target.value)}
        />
        <Button
          disabled={!textInput?.trim()}
          onClick={handleSendMessage}
        >
          Send
        </Button>
      </div>
    </div>
  );
}

export default ChatBox;
