exports.marketPlaceConfig = [
    {
        "category": "College",
        "options": [
            {
                "link": "https://lifestreamdigital.com/",
                "description": "LifeStream is your one-stop shop for managing your digital life. Securely store experiences, awards, achievements, and more in nearly any digital media format. Build a stunning portfolio to impress colleges and employers, or just share content easily. Start LifeStreaming Today!",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/Lifesteam+Digital.png"
            },
            {
                "link": "https://planningandvision.com",
                "description": "We ensure that students are more prepared for college than ever before to increase their chances of success. We put families “in the know” about which colleges are within their budget and the expected cost before they even apply. And we help your teenager stand out amongst college applicants and be more marketable in the workforce. We do this by coaching your teen on the 25+ essential skills needed for a highly successful college search and career plan and helping you with financial planning.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/Planning+and+Vision.png"
            },
            {
                "link": "https://oasisnj.org/youth-development/",
                "description": "Oasis NJ provides comprehensive youth development programs aimed at supporting low-income children in Paterson. Their services include the Michael Wagner After-School Academy, which offers academic support and psychosocial assistance, and the Oasis Teen Program, focusing on career exploration and college preparation. They also run Carl’s Kids Summer Camp for socialization and fitness, along with a Saturday program for additional academic enrichment.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/Oasis+NJ.png"
            }
        ]
    },
    {
        "category": "Coaching",
        "options": [
            {
                "link": "https://shiftyourpower.com/",
                "description": "Through my one to one coaching and group workshops, I can help your teen find their purpose, achieve their goals and remove any obstacles that may be inhibiting them.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/Powershift+Coaching.png"
            },
            {
                "link": "https://coachtscorner.com",
                "description": "We prioritize Family, Integrity, Compassion, Authenticity, and Simplicity in our coaching services to inspire the next generation of leaders. We focus on improving personal growth and development, as well as personal relationship skills to ensure a successful future for teens. Our goal is to educate, support, and inspire teens so they can thrive in life. Join us on our mission to create a better future for the next generation of leaders.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/Coach+T+Corner.png"
            },
            {
                "link": "https://peers-not-fears.com",
                "description": "Peers Not Fears emphasizes that leadership skills, like confidence and decision-making, are learned not innate. Our mission is to equip kids with these skills intentionally through our programs, rather than leaving them to learn from life's trials alone. We focus on nurturing confidence, resilience, and awareness, preparing kids to face challenges with empathy.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/Peers+Not+Fears.png"
            },
            {
                "link": "https://www.instagram.com/growyourhappy/",
                "description": "As a Resilience Coach, I guide parents and caregivers of children with challenges to build a positive mindset through habits and practices to build their resilience and Grow Your Happy!",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/growyourhappy.png"
            },
            {
                "link": "https://freetoprosper.com",
                "description": "We offer people of all ages empowerment tools and processes to design and create a life they love living. Through study and practice of the art and science of transformation, you will fast-track success in areas of life that mean the most to you.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/Free+to+Prosper.png"
            },
            {
                "link": "https://denisedrinkwalter.com/",
                "description": "Denise Drinkwalter offers empowerment services for women in midlife, featuring the Midlife EmPOWERment Academy, a 6-month transformational group program. She has created the 'Empowered Women's Guided Journal', a comprehensive self-development tool designed to help women prioritize self-care, set intentions, and cultivate personal growth. Her services focus on supporting women through intentional planning, self-reflection, and emotional well-being.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/Denise+Drinkwalter.png"
            },
            {
                "link": "https://nyworksforchildren.org/",
                "description": "NY Works for Children is a professional development platform for early childhood educators. They offer resources like The Aspire Registry, which supports professional growth for those working with young children. The organization provides coaching, professional development tools, and key resources such as the CBK (Core Body of Knowledge) and ELG (Early Learning Guidelines) to help educators enhance their skills and practice.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/NY+Works+for+Children.png"
            },
            {
                "link": "https://www.mollyfeigalparentingsupport.com/",
                "description": "Molly Feigal Parenting Support offers coaching services designed to help parents navigate the challenges of raising children. Through one-on-one sessions, group workshops, and a structured four-session package, Molly provides insights, tools, and support for parents feeling overwhelmed by their children's behaviors. Services include initial consultations, follow-up sessions, and community group sessions, all aimed at fostering meaningful connections between parents and children while reducing stress and anxiety.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/Molly+Feigal+Parenting+Support.png"
            },
        ]
    },
    {
        "category": "Workforce",
        "options": [
            {
                "link": "https://steerus.io/",
                "description": "STEERus is a professional development platform addressing workplace communication and productivity challenges - we POWER this HUB! We offer training and coaching services focused on critical skills like business confidence and effectiveness such as adaptive leadership, communication, and critical thinking. We also offer Fractional CXO services and management consulting for small business owners to grow revenues via government and corporate contracts.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/STEERus+Image+for+Hub-for-HELP.png"
            },
            {
                "link": "https://www.jerseycares.org/fellowship",
                "description": "Jersey Cares offers a Youth Workforce Development (YWD) Fellowship aimed at low-to-moderate income youth aged 16-24 in New Jersey. The program connects participants with internships at nonprofit organizations, helping them develop essential job and life skills while driving social change. Interns engage in leadership training and community projects, enhancing their professional development and reducing youth unemployment.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/Jersey+Cares.jpg"
            },
        ]
    },
    {
        "category": "Safety",
        "options": [
            {
                "link": "https://kyksfirst.com",
                "description": "Commuting to and from school and home puts children at risk of bullying, accidents, and even abductions. Parents and guardians need to know if the children in their care got on and off the bus or if they took an alternative form of transportation. We focus on keeping your kids safe.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/Keeping+Your+Kids+Safe.png"
            },
            {
                "link": "https://www.liliguanausa.org",
                "description": "Lil' Iguana’s Children’s Safety Foundation focuses on empowering children aged 2-10 through music-driven programs that prevent child abuse, abduction, and accidental injuries. They offer in-person and virtual educational resources for schools and communities, along with online courses for parents and educators. Their award-winning products include activity boxes, lesson guides, and engaging safety lessons designed to nurture confidence and promote well-being among children.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/Lil-Iguana.png"
            },
            {
                "link": "https://acnj.org/positive-youth-development/",
                "description": "The Positive Youth Development initiative by Advocates for Children of New Jersey (ACNJ) focuses on enhancing the well-being and safety of youth aged 9-21. It aims to prevent juvenile justice involvement by advocating for policy changes, elevating youth voices, and connecting community leaders. The initiative emphasizes mental health wellness, educational access, and community safety, promoting a holistic approach to youth development that includes collaboration with families and local organizations to create effective solutions.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/Advocates+for+Children+NJ.png"
            },
        ]
    },
    {
        "category": "Jobs",
        "options": [
            {
                "link": "https://www.interviewexcellence.com/",
                "description": "Clients receive personalized, private coaching focused on their individual goals. In today's highly competitive college and career search environments, it's vitally important to be prepared for important interviews. We help clients prepare for interviews in: college admissions, scholarships, and post-graduate career opportunities.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/Interview+Excellence.png"
            },
        ]
    },
    {
        "category": "Misc",
        "options": [
            {
                "link": "https://creativecontentconsulting.com",
                "description": "Creative Content Consulting provides personalized online branding services for individuals, businesses, and entrepreneurs. They help develop and refine digital presence by aligning visual design with written narratives across multiple platforms, working with partners to create a consistent and compelling brand image.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/Creative+Content.png"
            },
            {
                "link": "https://stevethewebsiteguy.com",
                "description": "Steve the Website Guy is a freelance WordPress designer and developer based in Fort Collins, Colorado. He specializes in creating modern, responsive websites using various page builders like Elementor and Oxygen. His services include website design, maintenance, white-label solutions for agencies, and managed hosting. Steve also offers SEO and content creation, focusing on delivering affordable, reliable web solutions tailored to client needs.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/Steve+the+Website+Guy.png"
            },
        ]
    },
    {
        "category": "Family",
        "options": [
            {
                "link": "https://joyous-journeys.com",
                "description": "Joyous Journeys is a full-service travel agency specializing in sustainable luxury travel. They create customized vacation experiences that emphasize deep cultural immersion and meaningful service opportunities, allowing travelers to connect with local communities. Their curated itineraries aim to transform perspectives and foster personal growth through unique travel experiences.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/Joyous+Journeys.png"
            },
            {
                "link": "https://thegardeninggrandma.com",
                "description": "The Gardening Grandma, led by Patrice Porter, focuses on teaching gardening skills to children and families. She offers mentoring programs that provide hands-on learning experiences, integrating academic lessons with practical life skills. The initiative aims to cultivate a love for gardening and self-sufficiency while promoting confidence and creativity in children through gardening activities.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/Gardening+Grandma.png"
            },
        ]
    },
    {
        "category": "Special Needs",
        "options": [
            {
                "link": "https://spiritofthegame.org",
                "description": "The Spirit of the Game Foundation aims to inspire personal accountability and empower individuals and organizations to positively impact the world. They operate through various pillars, including an international NGO network, coaching coalitions, and impactful networks for women and businesses. Their mission promotes a new definition of success based on personal responsibility and community engagement, encouraging members to focus on making a difference rather than seeking loopholes.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/Spirit+of+the+Game.png"
            },
            {
                "link": "https://gershacademy.org",
                "description": "In 1999, the first Gersh Academy opened its doors with a vision of creating a world where individuals on the autism spectrum are empowered to thrive. Years later, our vision remains the same, but our family has grown much larger. Today, we’re more than just a school. We’re dedicated to fulfilling the hopes and dreams of parents by bringing autism spectrum disorder out of the shadows and into the light. We’re committed to expanding our reach across the globe, in order to create the world we know is possible.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/Gersh+Academy.png"
            },
            {
                "link": "https://nycautismcommunity.org",
                "description": "NYC Autism Community is a nonprofit organization dedicated to supporting families with autistic children in New York City. They host monthly community meetings, events, and innovative tech expos focused on holistic treatments, neurodiversity employment, and creating supportive networks. Their mission is to bring together autism community members, share experiences, and provide accessible resources for families and individuals on the autism spectrum.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/NYC+Autism.png"
            },
        ]
    },
    {
        "category": "STEM",
        "options": [
            {
                "link": "https://counsel-academy.com",
                "description": "Counsel Academy is an online educational platform providing comprehensive learning opportunities for children and students aged 8-25. They offer specialized programs like CAKids (Grades 3-5), CATeens (Grades 6-12), and CAGrads, focusing on developing hard skills, soft skills, and personal growth. Their unique approach includes scientifically validated evaluations, customized academic help, and support for students with special needs like autism and ADHD.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/Counsel+Academy.png"
            },
        ]
    },
    {
        "category": "Community",
        "options": [
            {
                "link": "https://www.youthfoundationofjerseycity.org/",
                "description": "The Youth Foundation of Jersey City is dedicated to providing high-quality educational, recreational, cultural, and community enrichment programs for the youth of Jersey City. They focus on enhancing and expanding existing programs and are currently raising funds to provide Chromebooks to students in need, ensuring access to essential technology.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/Youth+Foundation+Jersey+City.png"
            },
            {
                "link": "https://devilsyouthfoundation.org/about-us/",
                "description": "The Devils Youth Foundation is dedicated to providing equitable access to transformative experiences for youth in New Jersey through sports, entertainment, and music. They address challenges faced by young people by creating and expanding programs in collaboration with community organizations, focusing on physical well-being, life skills, and educational opportunities to enhance quality of life.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/Devils+Youth+Foundation.png"
            },
            {
                "link": "https://corpsnetwork.org/organizations/new-jersey-youth-corps-of-newark-international-youth-organiztion/",
                "description": "The New Jersey Youth Corps of Newark, part of the International Youth Organization, empowers disenfranchised youth aged 16-25 through academic instruction, employability training, and community service. Their year-round program focuses on helping participants earn their HSE/GED and develop essential life skills, fostering personal responsibility and community engagement.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/NJ+Youth+Corps+Newark.png"
            },
            {
                "link": "https://www.nj.gov/labor/career-services/special-services/youth/njyouthcorps.shtml",
                "description": "The New Jersey Youth Corps is a year-round, voluntary program for young adults aged 16 to 25 who lack a high school diploma. Participants engage in community service, educational activities, and training while receiving mentorship. The program focuses on academic development, life skills, employability training, and personal counseling, helping youth build self-esteem and leadership skills. Corpsmembers work in crews on various service projects, earning a stipend while gaining valuable experience and support for transitioning to further education.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/NJ+Youth+Corps.jpg"
            },
            {
                "link": "https://nj4h.rutgers.edu/",
                "description": "The New Jersey 4-H Youth Development Program engages youth in grades K-13 through hands-on learning experiences that promote leadership, citizenship, and life skills. Participants explore diverse areas such as science, arts, and outdoor adventures while focusing on three priority areas: science and technology, healthy living, and citizenship. The program emphasizes fun in learning and community involvement.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/NJ+4-H.png"
            },
            {
                "link": "https://www.princetonnj.gov/1490/Princeton-Youth-Development-Programs",
                "description": "The Princeton Youth Development Programs focus on preventing addiction and supporting youth through various initiatives. They offer mentorship programs for middle school students, gender-responsive chat groups for girls, and life skills training for high school students. Programs include academic support, job skills training, leadership workshops, and community involvement to foster holistic development and civic engagement among youth.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/princeton.png"
            },
            {
                "link": "https://thegreatdiscovery.com/?affiliate=steerus",
                "description": "The Great Discovery is a global e-learning platform designed to connect learners with diverse courses across various fields, from coding to cooking. Users can create free accounts, explore a wide range of courses, and even earn commissions by promoting the platform as affiliates. The site emphasizes empowerment through knowledge, offering unique features like AI-driven language translation for course accessibility and automatic price adjustments based on local economies.",
                "image": "https://prod-new-steerus.s3.us-east-1.amazonaws.com/marketplace/The+Great+Discovery.png"
            },
        ]
    },
];
