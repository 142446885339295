import React from 'react';
import { Button } from 'antd';

import './styles.less';

export const ErrorContent = ({ reset }) => (
  <div className="error-content">
    <div>Sorry... Something went wrong...</div>
    <Button onClick={reset} type="primary">
      Back Home
    </Button>
  </div>
);
