import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Space, Table } from "antd";
import { modal } from "utils/modal";
import { GET_ALL_SNIPPETS, DELETE_SNIPPET } from "dashboard/query";
import { useNotification } from "hooks";
import CreateSnippetModal from "../modals/createSnippetModal";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import MobileTable from "../../MobileTable/MobileTable";
import { useMediaQuery } from "react-responsive";

const SnippetTable = () => {
  const query = useQuery(GET_ALL_SNIPPETS, { fetchPolicy: "network-only" });
  const notification = useNotification();
  const [deleteSnippet] = useMutation(DELETE_SNIPPET);

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 700px)",
  });

  const onDeleteSnippet = (id: string, title: string) => {
    deleteSnippet({ variables: { id } }).then((response) => {
      if (response?.data?.deleteSnippet) {
        notification({ type: "success", message: `${title} has been successfully deleted` });
        query.refetch();
      } else {
        notification({ type: "error" });
      }
    });
  };

  const columns: any = [
    {
      title: "Snippet Title",
      render: (value: any) => value.title,
    },
    {
      title: "Action",
      key: "action",
      render: (record: any) => (
        <Space size="middle">
          <Button
            onClick={() =>
              modal.showModal(CreateSnippetModal, {
                snippet: record,
                snippetQuery: query,
              })
            }
          >
            View
          </Button>
          <Button
            onClick={() =>
              modal.showModal(ConfirmationModal, {
                title: "Are you sure?",
                message: "Do you want to delete a snippet?",
                onDelete: () => {
                  onDeleteSnippet(record.id, record.title);
                  modal.hideModal();
                },
              })
            }
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  if (!query.loading && query.data) {
    return (
      <>
        <div className="admin__table">
          <h1 style={{ flex: 1, margin: "auto" }}> Snippets </h1>
          <div>
            <Button onClick={() => modal.showModal(CreateSnippetModal, { snippetQuery: query })}>Create Snippet</Button>
          </div>
        </div>

        {!isTabletOrMobileDevice ? (
          <Table rowKey="id" columns={columns} dataSource={query?.data?.getAllSnippets} scroll={{ x: 1300 }} />
        ) : (
          <MobileTable data={query?.data?.getAllSnippets} columns={columns} />
        )}
      </>
    );
  }

  return null;
};

export default SnippetTable;
