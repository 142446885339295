import React, { useEffect } from 'react';
import CoachCard from '../MarketplaceComponents/CoachCard';
import { useQuery } from '@apollo/client';
import { USER_QUERY } from '../queries';
import { modal } from '../../utils/modal';
import LoginModal from '../../login/LoginModal';
import { useLocation } from 'react-router';

const Coaches = ({ data, setState }) => {
  const { data: currentUser, refetch } = useQuery(USER_QUERY, {
    fetchPolicy: 'network-only',
  });
  const isHelpipedia= useLocation().pathname.includes('helpipedia')
  useEffect(() => {
    if (currentUser && !currentUser?.currentUser) {
      modal.showModal(LoginModal, {
        onClose: () => modal.hideModal(),
        onCloseNoUser: () => {
          setState(2);
          modal.hideModal();
        },
        refetch: () => refetch(),
      });
    }
  }, [currentUser]);

  return (
    <div className="experts">
      {isHelpipedia?<p style={{color:'black'}} className="experts__title">
        Each month, we add new professionals. Click on the green boxes below to find the professional that is right for you. Your experience with each
        professional will be different and we cannot guarantee results. Our professionals are independent agents. We are always working to
        improve our quality of service. Please let us know how we can improve your experience.{' '}
      </p>:<p className="experts__title">
        Each month, we add new professionals. Click on the green boxes below to find the professional that is right for you. Your experience with each
        professional will be different and we cannot guarantee results. Our professionals are independent agents supporting STEERus. We are always working to
        improve our quality of service. Please let us know how we can improve your experience.{' '}
      </p>}
      {data.map((coach) => (
        <CoachCard
          key={coach.id}
          coachId={coach.id}
          avatar={coach.userProfile.avatarUrl || "https://prod-new-steerus.s3.amazonaws.com/user_avatar.png"}
          name={coach.userProfile.firstName + ' ' + coach.userProfile.lastName}
          info={coach.userProfile.bio}
          rate={coach.userProfile.rate ? coach.userProfile.rate : 'X'}
          link={coach.calendlyLinks ? coach.calendlyLinks : '#'}
          currentUser={currentUser}
        />
      ))}
    </div>
  );
};

export default Coaches;
