import React from 'react';
import { Input } from 'antd';

const Question11 = ({ index = 11, name, setName }) => {
  return (
    <div className="soft-skills-field">
      <h6>
        {index}. Please tell us a little bit about yourself. <b className={'required__star'}>*</b>
      </h6>
      <p>
        Note: we will not share your private information with anyone. We want to mail you directions for accessing out soft skills academy. As well , we would
        like to send you your self assessment results.
      </p>

      <label className="label">
        <span> First and Last Name</span>
        <Input value={name} placeholder="Name" onChange={(e) => setName(e.target.value)} style={{ marginBottom: '12px' }} />
      </label>
    </div>
  );
};

export default Question11;
