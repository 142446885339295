import { graphql, gql } from '@apollo/react-hoc';

const getRecommendedCapsules = graphql(gql`
  query getRecommendedSnippets {
    getRecommendedSnippets {
      id
      coach {
        id
        userProfile {
          firstName
          lastName
        }
      }
    title
      video {
        length
      }
    status
    journeyId
    }
  }
`);

export default getRecommendedCapsules;
