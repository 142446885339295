import React from 'react';

import './styles.less';

function ClassMaterial({ children, iconUrl, onClick }) {
  return (
    <div onClick={onClick} className="material">
      {iconUrl
        && <img src={iconUrl} alt="material_icon" className="material--icon" />}
      <span className="material--text">{children}</span>
    </div>
  );
}

export default ClassMaterial;
