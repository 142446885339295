import React, {useState} from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { StandardMarginDiv, PageLayout } from '../components';
import { Formik } from 'formik';
import { Button, Radio } from 'antd';
import { STEERUS_GREEN } from '../styles/colors';


const JourneySkillCheckPage = () => {
    const GET_SKILLCHECK = gql`
  query getJourneySkillCheck($journeyId: ID, $index: Int) {
    getJourneySkillCheck(journeyId: $journeyId, index: $index)
  }
`;

const CHECK_ANSWER = gql`
  mutation checkJourneySkillCheckAnswer($journeyId: ID, $index: Int, $answer: String) {
    checkJourneySkillCheckAnswer(journeyId: $journeyId, index: $index, answer: $answer)
  }
`;

    const history = useHistory();
    const [index, setIndex] = useState(0);
    const params = useParams();
    const {
        loading,
        data: skillCheckData,
        refetch,
    } = useQuery(GET_SKILLCHECK, {
        variables: {
            index,
            journeyId: params?.journeyId,
        },
    });
    const [checkAnswer, { data: checkAnswerData }] = useMutation(CHECK_ANSWER);
    const [didCheckAnswer, setDidCheckAnswer] = useState(false);

    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-width: 800px)',
    });

    if (loading) {
        return null;
    }
    console.log("skillCheckData ", skillCheckData);
    if (!skillCheckData.getJourneySkillCheck.QuestionText) {
        return (
            <PageLayout>
                <StandardMarginDiv>
                    <p className="heading"> Congrats! You finished the skill check! Great job </p>
                    <Button onClick={() => history.push('/academy')}>Back to the Soft Skills Academy</Button>
                </StandardMarginDiv>
            </PageLayout>
        );
    }
    return (
        <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{}}
            validate={() => ({})}
            onSubmit={async (values) => {
                await checkAnswer({
                    variables: {
                        index,
                        answer: values.answer,
                        journeyId: params?.journeyId,
                    },
                });
            }}
        >
            {({ setFieldValue, handleSubmit }) => (
                <PageLayout>
                    <StandardMarginDiv>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <p className="heading skillcheck__title"> {skillCheckData.getJourneySkillCheck.QuestionText} </p>
                            <Radio.Group onChange={(e) => setFieldValue('answer', e.target.value)}>
                                {skillCheckData.getJourneySkillCheck.answers.map((question) => (
                                    <div style={{ marginTop: 50 }}>
                                        <Radio style={{ fontSize: isTabletOrMobileDevice ? '16px' : '20px' }} value={question}>
                                            {question}
                                        </Radio>
                                    </div>
                                ))}
                            </Radio.Group>
                            {!didCheckAnswer && (
                                <Button
                                    onClick={() => {
                                        setDidCheckAnswer(true);
                                        handleSubmit();
                                    }}
                                    style={{
                                        backgroundColor: STEERUS_GREEN,
                                        color: 'white',
                                        marginTop: 40,
                                    }}
                                >
                                    {' '}
                                    Check Answer{' '}
                                </Button>
                            )}
                            {didCheckAnswer && checkAnswerData?.checkJourneySkillCheckAnswer && (
                                <div style={{ marginTop: '10vh' }}>
                                    <p className="heading"> {checkAnswerData?.checkJourneySkillCheckAnswer?.correct ? 'You got it right' : "That wasn't the correct answer."}</p>
                                    <p> {checkAnswerData?.checkJourneySkillCheckAnswer?.explanation} </p>
                                    <Button
                                        onClick={() => {
                                            refetch({
                                                index: index + 1,
                                                journeyId: params?.journeyId,
                                            });
                                            setDidCheckAnswer(false);
                                            setIndex(index + 1);
                                        }}
                                    >
                                        {' '}
                                        Next question
                                    </Button>
                                </div>
                            )}
                        </div>
                    </StandardMarginDiv>
                </PageLayout>
            )}
        </Formik>
    );
};

export default JourneySkillCheckPage;