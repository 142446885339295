import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const AnonymusNavbar = () => {
  
  if(useLocation().pathname.includes("helpipedia"))
    {
      return (
        <>
          <a
            href="https://docs.google.com/spreadsheets/d/1xDoKSvhMdSHPVjrK6_8K40tXPSS6KbY7Nmt75dHc99Y/edit?resourcekey#gid=1337892464"
            target="_blank"
            rel="noreferrer"
            className="navbar__link"
          >
            Join us
          </a>
          <Link to="/helpipedia/signup" className="navbar__link">
            Register
          </Link>
          <Link to="/helpipedia/login" className="navbar__link" >
            Login
          </Link>
        </>
      )
    }
    else{
  
  return (
    <>
      <a
        href="https://docs.google.com/forms/d/1Q7GLiEsGF-wZV5JKx4Go3gOEkgjhrBllHdB-0extaE8/"
        target="_blank"
        rel="noreferrer"
        className="navbar__link"
      >
        Work with us
      </a>
      <Link to="/signup" className="navbar__link">
        Sign Up
      </Link>
      <Link to="/login" className="navbar__link" >
        Login
      </Link>
    </>

  )
}
}



export default AnonymusNavbar