import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Tabs } from 'antd';
import { StandardMarginDiv, PageLayout } from '../components';
import { GET_MARKETPLACE_COACHES, GET_ALL_BOOKS } from './queries';

import Assessments from './MarketplaceTabs/Assessments';
import Coaches from './MarketplaceTabs/Coaches';
import Books from './MarketplaceTabs/Books';
import Jobs from './MarketplaceTabs/Jobs';
import Games from './MarketplaceTabs/Games';
import MentalHealth from './MarketplaceTabs/MentalHealth';
import Swag from './MarketplaceTabs/Swag';
import SkillAssessments from './MarketplaceTabs/SkillAssessments';

import './styles.less';
import { useLocation } from 'react-router';
import { HELPipedia_ORANGE } from 'styles/colors';

const { TabPane } = Tabs;

const MarketplaceContainer = () => {
  const { search } = useLocation();
  const location = useLocation();
  const [state, setState] = useState('1');

  const { data: marketplaceCoachesData } = useQuery(GET_MARKETPLACE_COACHES);
  const { data: books } = useQuery(GET_ALL_BOOKS);
  const isHelpipedia= location.pathname.includes("helpipedia")

  useEffect(() => {
    if (search.includes('book')) setState('2');
    if (search.includes('coach')) setState('3');

    if (location.state == '3') {
      setState('3');
    }
  }, [search]);

  const memoBooks = useMemo(() => {
    return books ? books?.getAllBooks : [];
  }, [books]);

  const memoCoaches = useMemo(() => {
    return marketplaceCoachesData ? marketplaceCoachesData.getMarketplaceCoach : [];
  }, [marketplaceCoachesData]);

  return (
    <PageLayout>
      <StandardMarginDiv>
        <h1 style={{color:isHelpipedia?'black':''}}>The Marketplace</h1>
        <Tabs activeKey={state} centered onChange={(activeKey) => setState(activeKey)}>
          <TabPane tab="Assessments" key="1">
            <Assessments />
          </TabPane>
          {/* <TabPane tab="Skill Assessments" key="9">
            <SkillAssessments />
          </TabPane> */}
          <TabPane tab="Books" key="2">
            <Books data={memoBooks} />
          </TabPane>
          <TabPane tab="Professionals" key="3">
            <Coaches data={memoCoaches} setState={setState} />
          </TabPane>
          <TabPane tab="Games" key="5">
            <Games />
          </TabPane>
          <TabPane tab="Jobs" key="6">
            <Jobs />
          </TabPane>
          <TabPane tab="Wellness" key="7">
            <MentalHealth />
          </TabPane>
          {/* <TabPane tab="Swag" key="8">
            <Swag />
          </TabPane> */}
        </Tabs>
      </StandardMarginDiv>
    </PageLayout>
  );
};

export default MarketplaceContainer;
