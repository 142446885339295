import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_ALL_BOOKEDS } from '../query';
import { Table } from 'antd';
import { useMediaQuery } from 'react-responsive';
import MobileTable from '../../MobileTable/MobileTable';

const CoachAppointments = () => {
  const { data: appointments } = useQuery(GET_ALL_BOOKEDS);

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-width: 700px)',
  });

  const dataHandler = () => {
    const data = [];
    for (let i = 0; i < appointments?.getAllBookeds.length; i++) {
      const date = appointments?.getAllBookeds[i]?.createdAt;
      const created = new Date(parseInt(date));
      data.push({
        key: appointments?.getAllBookeds[i].id,
        coach: appointments?.getAllBookeds[i].coachName,
        student: appointments?.getAllBookeds[i].userName,
        date: created.toLocaleString(),
      });
    }

    return data;
  };

  const columns = [
    {
      title: 'Coach',
      dataIndex: 'coach',
      key: 'coach',
    },
    {
      title: 'Student',
      dataIndex: 'student',
      key: 'student',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
  ];

  return (
    <div>
      <h1> Coach Appointments </h1>
      {!isTabletOrMobileDevice ? <Table columns={columns} dataSource={dataHandler()} /> : <MobileTable data={dataHandler()} columns={columns} />}
    </div>
  );
};

export default CoachAppointments;
