import React, { useState, useCallback } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';

import Purchased from './Purchased';
import PurchaseSubscription from "./PurchaseSubscription";
import PaymentInProcess from './PaymentInProcess';
import { Button } from 'components';
import { useNotification } from 'hooks';
import { withCurrentUser } from 'hoc';

import './styles.less';

function PaymentForm({setModal,clientSecret, data, paymentData, price, itemToPurchase, onSuccess, subscription, email }) {
  if (!email && !data?.currentUser) {
    return null;
  }

  const stripe = useStripe();
  const elements = useElements();

  const [numberError, setNumberError] = useState('');
  const [expirationError, setExpirationError] = useState('');
  const [cvcError, setCvcError] = useState('');
  const [loading, setLoading] = useState(false);
  const [paymentSucceeded, setPaymentSucceeded] = useState(false);

  const notification = useNotification();
  const confirmCardPayment = useCallback(async (client_secret) => {
    try {
      setLoading(true);
      const username = (
        (data.currentUser?.userProfile?.firstName || '') +
          ' ' +
          data.currentUser?.userProfile?.lastName || ''
      ).trim();
      const { paymentIntent, error } = await stripe.confirmCardPayment(
        client_secret,
        {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              email: email || data.currentUser.email,
              name: username,
            },
          },
        },
      );

      if (paymentIntent) {
        setPaymentSucceeded(true);
      }

      if (error) {
        notification({ type: 'error', message: error.message });
      }
    } catch (error) {
      notification({ type: 'error', message: error.message });
    } finally {
      setLoading(false);
    }
  }, []);

  const handleChangeNumber = useCallback((event) => {
    if (event.error) {
      setNumberError(event.error.message);
    } else {
      setNumberError('');
    }
  }, []);

  const handleChangeExpiration = useCallback((event) => {
    if (event.error) {
      setExpirationError(event.error.message);
    } else {
      setExpirationError('');
    }
  }, []);

  const handleChangeCVC = useCallback((event) => {
    if (event.error) {
      setCvcError(event.error.message);
    } else {
      setCvcError('');
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    const client_secret = clientSecret || (!subscription ? paymentData?.getPaymentIntentByJourneyId?.clientSecret :
      paymentData?.getPaymentIntentByUser?.clientSecret);
      console.log(clientSecret,client_secret);
    confirmCardPayment(client_secret);

  };

  if (paymentSucceeded) {
    return !subscription ? <Purchased setModal={setModal} onSuccess={onSuccess} buttonTitle={ clientSecret!=undefined?"Back to Result":"Back to Journey"} /> : <PurchaseSubscription onSuccess={onSuccess}/>;
  }

  // IMPORTANT: Stripe Card Components must exist on the page during payment.
  // To show PaymentInProcess Component it's necessary to switch visibility of blocks
  // by means of 'display' css-property
  return (
    <>
      <div style={{ display: loading ? 'block' : 'none' }}>
        <PaymentInProcess />
      </div>
      <div style={{ display: loading ? 'none' : 'block' }}>
        <h1>{itemToPurchase}</h1>
        <h2>Enter your card details</h2>
        <form className="payment_form" onSubmit={handleSubmit}>
          <label>
            <div className="payment_form--title">Card Number</div>
            <CardNumberElement
              className="payment_form--input"
              onChange={(e) => handleChangeNumber(e)}
            />
            <div className="payment_form--status error">{numberError}</div>
          </label>

          <label>
            <div className="payment_form--title">Expiration date</div>
            <CardExpiryElement
              className="payment_form--input"
              onChange={(e) => handleChangeExpiration(e)}
            />
            <div className="payment_form--status">{expirationError}</div>
          </label>

          <label>
            <div className="payment_form--title">CVC</div>
            <CardCvcElement
              className="payment_form--input"
              onChange={(e) => handleChangeCVC(e)}
            />
            <div className="payment_form--status">{cvcError}</div>
          </label>

          <label className="payment_form--total">
            Total &nbsp;
            {price}$
          </label>

          <Button type="submit" fullWidth className="payment_form--button">
            PAY
          </Button>
        </form>
      </div>
    </>
  );
}

export default withCurrentUser(PaymentForm);
