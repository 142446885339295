import React from 'react';
import ModalScrollable from '../../components/ModalScrollable/ModalScrollable';

const questionsJSONModal = ({ questions = {} }: any) => (
  <ModalScrollable title="Question JSON:">
    <pre>{JSON.stringify(questions, null, 4)}</pre>
  </ModalScrollable>
);

export default questionsJSONModal;
