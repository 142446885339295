// @ts-nocheck
import React from 'react';
import { Button, message, Form, Input, Select, Spin } from 'antd';
import { useFormik } from 'formik';
import { gql, useQuery, useMutation } from '@apollo/client';
import { modal } from 'utils/modal';
import ModalScrollable from '../../components/ModalScrollable/ModalScrollable';
import { useState } from 'react';

const CREATE_CAPSULE = gql`
  mutation createCapsule($title: String, $coachId: ID) {
    createCapsule(title: $title, coachId: $coachId) {
      id
    }
  }
`;

const GetCoachesQuery = gql`
  query getCoaches {
    getCoaches {
      CoachUser {
        id
        userProfile {
          firstName
          lastName
        }
      }
    }
  }
`;

const CoachCreateForm = ({ push, query }: any) => {
  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted

  const [createUser] = useMutation(CREATE_CAPSULE);
  const [error, setError] = useState("");
  const inputStyles = {
    width: '100%',
  };
  const initCoachId = query.data.getCoaches.CoachUser[0].id;

  const formik = useFormik({
    initialValues: {
      title: '',
      coachId: initCoachId,
    },
    onSubmit: async (values) => {
      try {
        const response = await createUser({
          variables: {
            title: values.title,
            coachId: values.coachId,
          },
        });
        if (response.data && response.data.createCapsule && response.data.createCapsule.id == "Conflict") {
          setError("Capsule already exist");
          return false;
        }
        message.success('Capsule Created');
      } catch (e) {
        message.error('Error occured');
      }
      push();
    },
  });

  return (
    <Form layout="vertical" onFinish={formik.handleSubmit}>
      <Form.Item label="Capsule Title" name="title">
        <Input id="title" name="title" label="title" type="title" required onChange={formik.handleChange} value={formik.values.title} style={inputStyles} />
      </Form.Item>

      <Form.Item name="coachId" label="Coach">
        <Select defaultValue={formik.values.coachId} value={formik.values.coachId} onChange={(e) => formik.setFieldValue('coachId', e)}>
          {query.data.getCoaches.CoachUser.map((coach) => (
            <Select.Option key={coach.id} value={coach.id}>
              {coach.userProfile.firstName} {coach.userProfile.lastName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {error && <p style={{color: "red", fontSize: "14px"}}>{error}</p>}
      <Form.Item>
        <Button style={inputStyles} type="primary" htmlType="submit">
          Create Capsule
        </Button>
      </Form.Item>
    </Form>
  );
};

const CreateCoachModal = ({ capsulesQuery }: any) => {
  const closeModal = () => {
    modal.hideModal();
    capsulesQuery.refetch();
  };

  const query = useQuery(GetCoachesQuery);

  if (query.loading && !query?.data?.getCoaches) {
    return <Spin></Spin>;
  }

  return (
    <ModalScrollable title="Create Capsule">
      <CoachCreateForm push={closeModal} query={query} />
    </ModalScrollable>
  );
};

export default CreateCoachModal;
