import React from 'react';
import toucan from '../../assets/logo/toucan.png';
import ButtonLink from '../../components/Buttons/ButtonLink';

const GenericMarketplace = ({ link, description, image }) => {
  return (
    <div className="games">
      <div className="games__inner">
        <div className="games__card" style={{height:250,width:400}}>
          <img src={image} alt="Logo" className="games__logo" />
          <ButtonLink style="centre" title="LEARN MORE" link={link} />
        </div>
        <div className="games__card games__introduce">
          <p className="games__slogan">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default GenericMarketplace;