import React from "react";
import { useQuery } from "@apollo/client";
import { GET_USERS_WAITING_APPROVE } from "../query";
import { Tabs, Badge } from "antd";
import JourneyTable from "../AdminDashboardTabs/JourneyTable";
import AttachmentsTable from "../AdminDashboardTabs/AttachmentsTable";
import QuestionsTable from "../AdminDashboardTabs/QuestionTable";
import CapsuleTable from "../AdminDashboardTabs/CapsuleTable";
import SnippetTable from "../AdminDashboardTabs/SnippetTable";
import PlaylistTable from "../AdminDashboardTabs/PlaylistTable";
import CoachTable from "../AdminDashboardTabs/CoachTable";
import CoachAppointments from "../AdminDashboardTabs/CoachAppointments";
import UsersTable from "../AdminDashboardTabs/UsersTable";
import HeroBox from "../DashboardComponents/HeroBox";
import StudentRequests from "../AdminDashboardTabs/StudentRequests";
import BookTable from "../AdminDashboardTabs/BookTable";
import MetricsDashboard from "../AdminDashboardTabs/MetricsDashboard";
import AssessmentDashboard from "dashboard/AdminDashboardTabs/AssessmentDashboard";
import Badges from "dashboard/AdminDashboardTabs/Badges";

const AdminView = () => {
  const { data: waitingUsers } = useQuery(GET_USERS_WAITING_APPROVE);

  const { TabPane } = Tabs;
  return (
    <>
      <Tabs defaultActiveKey="1" centered>
        <TabPane tab="Journey" key="1">
          <JourneyTable />
        </TabPane>
        <TabPane tab="Attachments" key="2">
          <AttachmentsTable />
        </TabPane>
        <TabPane tab="Questions" key="3">
          <QuestionsTable />
        </TabPane>
        <TabPane tab="Capsules" key="4">
          <CapsuleTable />
        </TabPane>
        <TabPane tab="Books" key="5">
          <BookTable />
        </TabPane>
        <TabPane tab="Snippets" key="6">
          <SnippetTable />
        </TabPane>
        <TabPane tab="Playlists" key="7">
          <PlaylistTable />
        </TabPane>
        <TabPane tab="Experts" key="8">
          <CoachTable />
        </TabPane>
        <TabPane tab="Users" key="9">
          <UsersTable />
        </TabPane>
        <TabPane tab="Coach Appointments" key="10">
          <CoachAppointments />
        </TabPane>
        {/* <TabPane tab="Analytics" key="11">
          <HeroBox title="View in depth analytics" color="#001F5F">
            <a
              href="http://data.usesteerus.com"
              target="_blank"
              rel="noreferrer"
              className="ant-btn"
            >
              View Analytics
            </a>
          </HeroBox>
        </TabPane> */}
        <TabPane
          tab={
            <>
              <span>Students Requests</span>
              <Badge
                dot={waitingUsers?.getUsersWaitingApprove?.length > 0}
                offset={[-3, -5]}
              />
            </>
          }
          key="12"
        >
          <StudentRequests users={waitingUsers?.getUsersWaitingApprove} />
        </TabPane>
        <TabPane
          tab={
            <>
            <span>Metrics</span>
            </>
          }
        >
          <MetricsDashboard />
        </TabPane>
        <TabPane
          key="14"
          tab={
            <><span>Assessments</span></>
          }
        >
          <AssessmentDashboard />
        </TabPane>
        <TabPane
          key="15"
          tab={
            <><span>Badges</span></>
          }
        >
          <Badges />
        </TabPane>
      </Tabs>
    </>
  );
};

export default AdminView;
