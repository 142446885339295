import { gql } from '@apollo/client';

export const GET_ASSESSMENTS = gql`
  query getAssessment($id: ID) {
    getAssessment(id: $id) {
      title
      link
      about
      meta
      coach {
        userProfile {
          firstName
          lastName
        }
      }
      id
    }
  }
`;
export const GET_NEW_ASSESSMENTS = gql`
  query getSkillAssessmentQuestions($assessmentId: ID) {
    getSkillAssessmentQuestions(assessmentId: $assessmentId)
  }
`;
export const  submitSkillAssessment = gql`

mutation  submitSkillAssessment($assessmentId: ID, $email: String, $answers: [Int]){ 
  submitSkillAssessment(email: $email, assessmentId: $assessmentId, answers:$answers)
}`

export const questionQuery = gql`
  query getSoftSkillsQuestions {
    getSoftSkillsQuestions
  }
`;

export const submitSoftSkillsAssessment = gql`

mutation submitSoftSkillsAssessment($fields: JSON, $data:JSON){ 
submitSoftSkillsAssessment(fields: $fields, data:$data) {
  response
  error
}
}`

export const submitSuperPower = gql`
mutation submitSuperpowerAssessment($fields: JSON, $data:JSON){
  submitSuperpowerAssessment(fields: $fields, data:$data) }
`