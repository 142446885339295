import { gql } from '@apollo/client';

export const GET_MARKETPLACE_COACHES = gql`
  query getMarketplaceCoach {
    getMarketplaceCoach {
      id
      email
      coachActive
      calendlyLinks
      userProfile {
        bio
        lastName
        firstName
        rate
        avatarUrl
      }
    }
  }
`;

export const USER_QUERY = gql`
  query currentUser {
    currentUser {
      role
        novelty
      email
      userProfile {
        firstName
        lastName
        bio
        calendlyUserName
        lastWatchedCapsule {
          id
          title
          nextSubcapsules {
            id
          }
        }
      }
      id
    }
  }
`;

export const GET_ALL_BOOKS = gql`
  query getAllBooks {
    getAllBooks {
      id
      title
      category
      capsule_topic
      author
      link
      avatar
    }
  }
`;

export const GET_BOOK = gql`
  query getBook($id: ID) {
    getBook(id: $id) {
      id
      title
      category
      capsule_topic
      author
      link
      avatar
    }
  }
`;

export const GET_BOOK_BY_CATEGORY = gql`
  query getBookByCategory($category: String) {
    getBookByCategory(cateogry: $category) {
      id
      title
      category
      capsule_topic
      author
      link
      avatar
    }
  }
`;
