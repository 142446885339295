import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, Link } from 'react-router-dom';
import Logo from 'components/Logo/Logo';
import { useQuery } from '@apollo/client';
import { userQuery } from '../../hoc/withCurrentUserQuery';

import './styles.less';
import UserNavbar from './Navbar/UserNavbar';
import AnonymusNavbar from './Navbar/AnonymusNavbar';
import MobileNavBar from './Navbar/MobileNavBar';

const Navbar = ({ children }: any) => {
  const location = useLocation()

  const currentUser = useQuery(userQuery, { fetchPolicy: 'network-only' });
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-width: 800px)',
  });

  if (currentUser?.loading) {
    return null;
  }

  if (
    !currentUser?.loading
    && currentUser?.data?.currentUser == null
    && window.localStorage.getItem('token') != null
  ) {
    currentUser.startPolling(1000);
  }

  if (!currentUser?.loading && currentUser?.data?.currentUser !== null) {
    currentUser.stopPolling();
  }

  if (
    currentUser?.data?.currentUser == null
    && (window.localStorage.getItem('token') == null
      || window.localStorage.getItem('token') === ' '
      || window.localStorage.getItem('token') === '')
  ) {
    currentUser.stopPolling();
  }

  const getLinks = () => (
    <>
    {
      location.pathname.includes("helpipedia") ?
      <Link to="/helpipedia/lesson" className="navbar__link">
      Lessons
    </Link>: <Link to="/academy" className="navbar__link">
        Academy
      </Link>
    }
     { location.pathname.includes("helpipedia") ?
      <Link to="/helpipedia/marketplace" className="navbar__link">
       Marketplace
    </Link>: <Link to="/marketplace" className="navbar__link">
        Marketplace
      </Link>
}
      {currentUser?.data?.currentUser ? <UserNavbar /> : <AnonymusNavbar />}
    </>
  );

  if (isTabletOrMobileDevice) {
    return (
      <MobileNavBar getLinks={getLinks} />
    );
  }

  return (
    <>
      <div className="navbar">
        {location.pathname === '/signup/us4warriors' ?
          <div className="navbar__logo-block">
            <Logo />
            <img
              style={{ width: "100%", height: "50px" }}
              className="navbar__sub-logo "
              alt="Us4Warriors Logo"
              src={require('../../assets/logo/Us4W_Logo.png')} />
          </div> :
          location.pathname === '/signup/jabord' ?
            <div className="navbar__logo-block">
              <Logo />
              <img
                className="navbar__sub-logo "
                alt="Jabord LOGO"
                src={require('../../assets/logo/Jabord_LOGO.png')} />
            </div> : location.pathname === '/signup/ge' ?
              <div className="navbar__logo-block">
                <img
                  style={{ width: "60%", height: "60%", marginLeft: "50px" }}
                  alt="GE Logo"
                  src={require('../../assets/logo/ge_logo.png')} />
              </div>

              : <Logo />
        }

        <div className='navbar__links'>{getLinks()}</div>
      </div>
      {children}
    </>
  );
};

export default Navbar;
