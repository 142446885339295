import React, { useState } from 'react';
import { Input, Radio, Checkbox } from 'antd';
import "./Question16.css";

const answers = [
  { id: true, answer: 'Yes' },
  { id: false, answer: 'No' },
];

const Question16 = ({ employmentDetails, setEmploymentDetails, questionIndex = 16 }) => {
  const jobSearchExperiencesList = [
    'There are no or very few jobs for which I am qualified.',
    'I apply for jobs, but I never hear back or I seldom hear back from the employer.',
    'I never, or only rarely get requests for an interview.',
    'I never or only rarely get asked for a follow-up interview.',
    'I never or only rarely hear the results of an interview.',
    'Although I am asked for interviews, I seldom or never get offers.',
    'Although I have received one or more job offers, the terms of employment are not agreeable to me.',
    'None of the above',
    // 'Other'
  ];

  const handleBlur = (e) => {
    setEmploymentDetails({ ...employmentDetails, otherReason: e.target.value });
  };

  const checkboxChangeHandler = (selectedExperiences) => {
    let previousSelection = [];
    if (employmentDetails.jobSearchExperiences && employmentDetails.jobSearchExperiences.length) {
      previousSelection = [...employmentDetails.jobSearchExperiences];
    }
    const delta = selectedExperiences.filter(experience => !previousSelection.includes(experience));

    if (employmentDetails.jobSearchExperiences && employmentDetails.jobSearchExperiences.length == 1 && ["None of the above", "Other"].includes(employmentDetails.jobSearchExperiences[0]) && selectedExperiences.length > 1) {
      let obj = {...employmentDetails, jobSearchExperiences: selectedExperiences.filter(experience => experience != employmentDetails.jobSearchExperiences[0])};
      if (delta[0] != "Other" && employmentDetails.otherReason)
        delete obj["otherReason"];

        setEmploymentDetails({ ...obj });

      return;
    }
    let obj = { ...employmentDetails, jobSearchExperiences: selectedExperiences.includes("None of the above") || selectedExperiences.includes("Other") ? delta : selectedExperiences }
    if (!selectedExperiences.includes("Other") && employmentDetails.otherReason) {
      delete obj["otherReason"];
    }

    setEmploymentDetails({ ...obj });
  };

  const handleIsEmployedChange = e => {
    let obj = { ...employmentDetails, isEmployed: e.target.value };

    if (!e.target.value && [true, false].includes(employmentDetails.isEmployedInFieldOfChoice))
      delete obj["isEmployedInFieldOfChoice"];

    setEmploymentDetails({ ...obj });
  };

  return (
    <div className="soft-skills-field">
      <h6>{questionIndex}. Employment Status</h6>
      <div className="test__questions-block">
        <h4 className="test__questions-block-title">
          Are you currently employed?<b className={'required__star'}>*</b>
        </h4>
        <div className="test__questions-block-radio">
          <Radio.Group
            size="large"
            onChange={handleIsEmployedChange}
            value={employmentDetails.isEmployed}
            required // Make it required
          >
            {answers.map((value, index) => (
              <Radio key={value + index} value={value.id}>
                {value.answer}
              </Radio>
            ))}
          </Radio.Group>
        </div>
      </div>


      {/* Conditionally render the "Field of Choice" question */}
      {employmentDetails.isEmployed && (
        <div className="test__questions-block">
          <h4 className="test__questions-block-title">
            Are you employed in your field of choice?<b className={'required__star'}>*</b>
          </h4>
          <div className="test__questions-block-radio">
            <Radio.Group
              size="large"
              onChange={(e) => setEmploymentDetails({ ...employmentDetails, isEmployedInFieldOfChoice: e.target.value })}
              value={employmentDetails.isEmployedInFieldOfChoice}
              required
            >
              {answers.map((value, index) => (
                <Radio key={value + index} value={value.id}>
                  {value.answer}
                </Radio>
              ))}
            </Radio.Group>
          </div>
        </div>
      )}
      <div className="test__questions-block">
        <h4 className="test__questions-block-title">
          Are you currently looking for a job?<b className={'required__star'}>*</b>
        </h4>
        <div className="test__questions-block-radio">
          <Radio.Group
            size="large"
            onChange={(e) => setEmploymentDetails({ ...employmentDetails, isLookingForJob: e.target.value })}
            value={employmentDetails.isLookingForJob}
            required
          >
            {answers.map((value, index) => (
              <Radio key={value + index} value={value.id}>
                {value.answer}
              </Radio>
            ))}
          </Radio.Group>
        </div>
      </div>

      {/* Additional Questions - Render only if lookingForJob is 'Yes' */}
      {employmentDetails.isLookingForJob && (
        <>
          <div className="test__questions-block">
            <h4 className="test__questions-block-title">
              For how long have you been looking for a job?<b className={'required__star'}>*</b>
            </h4>
            <div className="test__questions-block-radio">
              <label className="label">
                <Input
                  size="medium"
                  placeholder="Enter duration in months"
                  value={employmentDetails.jobSearchDuration}
                  onChange={(e) => setEmploymentDetails({ ...employmentDetails, jobSearchDuration: e.target.value })}
                  required
                />
              </label>
            </div>
          </div>
          <div className="test__questions-block">
            <h4 className="test__questions-block-title">
              Which of the following have you experienced in your job search?<b className={'required__star'}>*</b>
            </h4>
            <div className="test__questions-block-checkbox">
              <Checkbox.Group
                className="test-class"
                value={employmentDetails.jobSearchExperiences}
                style={{ "marginTop": "10px" }}
                options={jobSearchExperiencesList}
                onChange={checkboxChangeHandler}
              >
              </Checkbox.Group>
              {
                employmentDetails.jobSearchExperiences && employmentDetails.jobSearchExperiences.includes("Other") && <Input
                  value={employmentDetails.otherReason}
                  style={{ "display": "flex" }}
                  onBlur={handleBlur}
                  size="medium"
                  placeholder="Please specify other"
                  onChange={e => setEmploymentDetails({ ...employmentDetails, otherReason: e.target.value })}
                />
              }
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Question16;
