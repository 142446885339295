import { PageLayout, StandardMarginDiv } from "components";
import { withCurrentUser } from "hoc";
import React, { useMemo } from "react";
import { useState } from "react";
import Billing from "./SettingComponents/Billing";
import ChangeEmail from "./SettingComponents/ChangeEmail";
import ChangePassword from "./SettingComponents/ChangePassword";
import ResetPassword from "./SettingComponents/ResetPassword";
import MyBadges from "./SettingComponents/MyBadges";
import SubscriptionPayment from "../components/PaymentForm/SubscriptionPayment";
import { useMediaQuery } from "react-responsive";

import "./styles.less";
import { useLocation } from "react-router";
import { HELPipedia_ORANGE } from "styles/colors";

const settings = [
  { id: "profile", name: "Change Profile info" },
  { id: "email", name: "Change Email Address" },
  { id: "password", name: "Change Password" },
  { id: "reset-password", name: "Reset Password" },
  { id: "my-badges", name: "My Badges" },
  // { id: "buy-subscription", name: "Buy Subscription" },
];

const settingsMobile = [
  { id: "profile", name: "Change Profile " },
  { id: "email", name: "Change Email" },
  { id: "password", name: "Change Password" },
  { id: "reset-password", name: "Reset Password" },
  { id: "my-badges", name: "My Badges" },
  // { id: "buy-subscription", name: "Buy Subscription" },
];

const settingsContainer = (props: any) => {
  if (!props.data.currentUser) {
    return null;
  }

  const [state, setState] = useState("email");

  const changeSettings = (id: any) => setState(id);

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 800px)",
  });

  const settingsMemo = useMemo(() => {

    return isTabletOrMobileDevice ? settingsMobile : settings

  }, [isTabletOrMobileDevice, settingsMobile, settings])

  const isHelpipedia= useLocation().pathname.includes("helpipedia")

  return (
    <PageLayout>
      <StandardMarginDiv>
        <div className="settings">
          <div className="settings__panel">
            <p
              className="heading settings__title"
               style={{color: isHelpipedia?HELPipedia_ORANGE:"#91cf4f"}}
            >
              Do you want to …{" "}
            </p>
            {settingsMemo.map((setting) => {
              if (
                (setting.id === "buy-subscription" &&
                  props.data.currentUser.role === "student") ||
                setting.id !== "buy-subscription"
              ) {
                return (
                  <button
                    onClick={() => changeSettings(setting.id)}
                  
                    className={`${isHelpipedia?`settings__buttonHelpipedia ${state === setting.id && "activeHelpipedia"
                  }` :`settings__button ${state === setting.id && "active"
                }`} `}
                    key={setting.id}
                  >
                    {setting.name}
                  </button>
                );
              } else if (
                setting.id === "buy-subscription" &&
                props.data.currentUser.role !== "student"
              ) {
                return null;
              }
            })}
          </div>
          <div className="settings__items">
            {state === "profile" ? (
              <Billing />
            ) : state === "email" ? (
              <ChangeEmail currentUser={props?.data?.currentUser} />
            ) : state === "password" ? (
              <ChangePassword currentUser={props?.data?.currentUser} isTabletOrMobileDevice={isTabletOrMobileDevice} />
            ) : state === "reset-password" ? (
              <ResetPassword currentUser={props?.data?.currentUser} />
            ) : state === "my-badges" ? (
              <MyBadges currentUser={props?.data?.currentUser} />
            ) : (
              state === "buy-subscription" && <SubscriptionPayment />
            )}
          </div>
        </div>
      </StandardMarginDiv>
    </PageLayout>
  );
};

export default withCurrentUser(settingsContainer);
