// @ts-nocheck
import React, { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Radio } from 'antd';
import { Formik } from 'formik';
import { PageLayout, StandardMarginDiv } from '../../components';

import { useMediaQuery } from 'react-responsive';
import { submitSuperPower } from '../SoftSkillsAssesment/request';
import Question10 from '../Questions/Question10';
import Question11 from '../Questions/Question11';
import Question12 from '../Questions/Question12';
import Question13 from '../Questions/Question13';
import Question14 from '../Questions/Question14';
import Question15 from '../Questions/Question15';
import ButtonInsideLink from '../../components/Buttons/ButtonInsideLink';
import './style.less';

const softSkills = ['STEM Related', 'Liberal Arts Related', 'Professional Program (such as healthcare, business, law , etc)', 'Other'];

const GET_ASSESSMENTS = gql`
  query getAssessment($id: ID) {
    getAssessment(id: $id) {
      title
      link
      about
      meta
      coach {
        userProfile {
          firstName
          lastName
        }
      }
      id
    }
  }
`;

const GET_QUESTIONS = gql`
  query getSuperpowerQuestions {
    getSuperpowerQuestions
  }
`;

const AssessmentContainer = () => {
  const [showComplete, setShowComplete] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const [disabled, setDisabled] = useState(false);
  const { loading, data: questionData } = useQuery(GET_QUESTIONS);
  const [error, setError] = useState(false);

  const [question12, setQuestion12] = useState({
    associate: '',
    bachelor: '',
    teaching_credential: '',
    doctoral: '',
    certificate: '',
    master: '',
    high_school: '',
    trade_school: '',
    uneducated:false
  });

  const [checkbox, setCheckbox] = useState({
    accounting: false,
    agriculture: false,
    arts: false,
    construction: false,
    education: false,
    govermment: false,
    healtcare: false,
    hospitality: false,
    manufacturing: false,
    mining: false,
    real_estate: false,
    retail: false,
    science: false,
    transport: false,
    utilities: false,
    wholesale: false,
    other: false,
  });

  const [associate, setAssociate] = useState('');
  const [bachelor, setBachelor] = useState('');
  const [master, setMaster] = useState('');
  const [doctoral, setDoctoral] = useState('');
  const [school_high, setSchoolHigh] = useState('');
  const [school_trade, setSchoolTrade] = useState('');
  const [other, setOther] = useState('');
  const [years, setYears] = useState(0);

  const [mutateAnswer, { loading: loadingTest }] = useMutation(submitSuperPower);

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-width: 700px)',
  });

  const { data: assessmentData } = useQuery(GET_ASSESSMENTS, {
    variables: { id: 3 },
  });

  return (
    <PageLayout loading={loading || loadingTest}>
      <StandardMarginDiv>
        {!loading && !showComplete ? (
          <>
            <p className='heading'> {assessmentData?.getAssessment?.title} </p>
            <h2 className='super-power__title'>
              We all have superpowers: the traits that make us unique. “Superpowers” are such a natural part of our
              characters that we often don’t recognize
              them as strengths. And, even if we do, we may not know how to use them.
            </h2>
            <h2 className='super-power__title'>
              This assessment will help you identify areas where you're strong. Once you are more aware of your
              superpowers, you will be better able to draw on
              them.
            </h2>
            <h2 className='super-power__title'>
              It will take only a few minutes to complete the survey. STEERus will and then email your results! And we
              NEVER sell your data or contact info - we
              keep your answers private.
            </h2>

            <h2>
              {' '}
              {assessmentData?.getAssessment?.coach?.userProfile?.firstName} {assessmentData?.getAssessment?.coach?.userProfile?.lastName}{' '}
            </h2>
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{}}
              validate={(values) => {
                const errors = {};
                const { length } = questionData.getSuperpowerQuestions;
                if (Object.keys(values).length !== length) {
                  errors.main = 'Please answer all of the questions';
                }

                return errors;
              }}
              onSubmit={async (values) => {
                setDisabled(true);
                setError(false);
                try {
                  if (!name.trim().length) {
                    throw 'Error';
                  }

                  let errorLocal;

                  Object.keys(question12).forEach(item => {
                    if (question12[item]) {
                      errorLocal = 'none';
                    }
                  });

                  if (!errorLocal) throw 'Error';

                  const toSubmit = { answers: [] };
                  for (const [key, value] of Object.entries(values)) {
                    toSubmit.answers.push({ index: key, answer: value });
                  }
                  const answer = await mutateAnswer({
                    variables: {
                      fields: toSubmit,
                      data: {
                        full_name: name,
                        description: description,
                        school: {
                          associate: associate,
                          bachelor: bachelor,
                          master: master,
                          doctoral: doctoral,
                          other: other,
                          school_high,
                          school_trade,
                        },
                        certificates: {
                          ...question12,
                        },
                        years: years,
                        ...checkbox,
                      },
                    },
                  });
                  if (answer.data.submitSuperpowerAssessment) {
                    setShowComplete(true);
                  }
                } catch (e) {
                  setDisabled(false);
                  setError(true);
                } finally {
                  setDisabled(false);
                }
              }}
            >
              {({ values, errors, setFieldValue, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    {questionData.getSuperpowerQuestions.map((question: any, idx: number) => (
                      <div style={{ marginTop: 50 }} key={idx}>
                        {<p className='heading'>{question.question} <b className={'required__star'}>*</b></p>}
                        <Radio.Group
                          onChange={(e) => {
                            const answer = e.target.value;
                            setFieldValue(idx, answer);
                          }}
                          value={values[idx]}
                        >
                          {question.possibilities.map((possibility: string) => (
                            <Radio value={possibility}>{possibility}</Radio>
                          ))}
                        </Radio.Group>
                      </div>
                    ))}
                    <div style={{ marginTop: 20 }}>
                      <div>
                        <div className='soft-skills'>
                          <Question10 index={22} description={description} setDescription={setDescription} />
                          <Question11 index={23} name={name} setName={setName} />
                          <Question12 index={24} isTabletOrMobileDevice={isTabletOrMobileDevice}
                                      setQuestion12={setQuestion12} softSkills={softSkills} question12={question12} />
                          <Question13 index={25}
                                      doctoral={doctoral}
                                      schoolTrade={school_trade}
                                      setSchoolTrade={setSchoolTrade}
                                      schoolHigh={school_high}
                                      setSchoolHigh={setSchoolHigh}
                                      setDoctoral={setDoctoral}
                                      other={other}
                                      setOther={setOther}
                                      setAssociate={setAssociate}
                                      associate={associate}
                                      master={master}
                                      setMaster={setMaster}
                                      bachelor={bachelor}
                                      setBachelor={setBachelor}
                          />
                          <Question14 index={26} years={years} setYears={setYears} />
                          <Question15 index={27} setCheckbox={setCheckbox} />
                        </div>
                      </div>

                      <p style={{ color: 'red' }}> {errors.main} </p>
                      {error && <p style={{ color: 'red' }}>Please answer all of the questions</p>}
                      <Button onClick={() => handleSubmit()} disabled={disabled}>
                        {' '}
                        Submit{' '}
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </>
        ) : !loading ? (
          <div className='assessments__completed'>
            <p className='heading'> Thank you for taking this assessment! Your results are on their way to your email
              right now! </p>
            <ButtonInsideLink style='center' targetBlank={false} title={'Go Back'} link='/marketplace'>
              Go Back
            </ButtonInsideLink>
          </div>
        ) : null}
      </StandardMarginDiv>
    </PageLayout>
  );
};

export default AssessmentContainer;
