import React from 'react';

import { graphql, gql } from '@apollo/react-hoc';
import { useQuery } from '@apollo/client';

const userQuery = gql`
  query currentUser {
    currentUser {
      role
      email
      novelty
      dateEnding
      subscriptionStatus
      educationalInstitution
      partner
      userProfile {
        firstName
        lastName
        bio
        calendlyUserName
        lastWatchedCapsule {
          id
          title
          nextSubcapsules {
            id
          }
        }
      }
      id
    }
  }
`;

// @ts-ignore
const withCurrentUserQuery = graphql(userQuery);

export default withCurrentUserQuery;

function WithCurrentUserHook() {
  const { loading, error, data, refetch } = useQuery(userQuery);

  return { loading, error, currentUser: data?.currentUser , refetch};
}

export { userQuery, WithCurrentUserHook };
