import React from "react";

import './styles.less';

type Props = {
  name: string,
  id?: string,
  required?: boolean,
  min?: number,
  value?: string,
  defaultValue?: string,
  placeholder?: string,
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

const InputNumber = ({name, id, required, min, value, defaultValue, placeholder, onChange} : Props) => {

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const charCode = event.which ?? (event.key || event.keyCode);
    if (charCode === 101 || charCode === 69 || charCode === 45 || charCode === 43) {
      event.preventDefault();
    }
  }

  return (
      <input
        type="number"
        id={id}
        name={name}
        required={required}
        value={value}
        min={min}
        step="any"
        placeholder={placeholder}
        defaultValue={defaultValue}
        onKeyPress={onKeyPress}
        onChange={onChange}
        className="input-number"
      />
  )
};

export default InputNumber;