// @ts-nocheck
import React, { useState } from 'react';
import { Button, message, Form, Input, Select, Switch } from 'antd';
import { useFormik } from 'formik';
import { SOFT_SKILLS_TAGS } from 'utils';
import { gql, useQuery, useMutation } from '@apollo/client';
import * as UpChunk from '@mux/upchunk';
import { modal } from 'utils/modal';
import ModalScrollable from '../../components/ModalScrollable/ModalScrollable';

const CREATE_OR_UPDATE_SNIPPET = gql`
  mutation createOrUpdateSnippet($isVisible: Boolean, $id: ID, $title: String, $filePresent: Boolean, $coachId: ID, $softskillTags: [String]) {
    createOrUpdateSnippet(title: $title, isVisible: $isVisible, id: $id, coachId: $coachId, filePresent: $filePresent, softskillTags: $softskillTags)
  }
`;

const GetCoachesQuery = gql`
  query getCoaches {
    getCoaches {
      CoachUser {
        id
        userProfile {
          firstName
          lastName
        }
      }
    }
  }
`;

const CreateSnippetForm = ({ push, snippet, query }: any) => {
  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted
  const [createOrUpdateSnippet] = useMutation(CREATE_OR_UPDATE_SNIPPET);
  const [progress, setProgress] = useState(null);
  const initCoachId = query?.data?.getCoaches?.CoachUser[0].id;

  const inputStyles = {
    width: '100%',
  };

  const formik = useFormik({
    initialValues: {
      id: snippet?.id || null,
      isVisible: snippet?.isVisible,
      softskillTags: snippet?.softskillTags || [],
      title: snippet?.title || '',
      coachId: snippet?.coachId || initCoachId || '',
    },
    onSubmit: async (values) => {
      message.loading({
        duration: 0,
        content: 'Uploading your changes',
        key: 'loading',
      });

      const variables = {
        filePresent: values?.file !== undefined,
        title: values?.title,
        isVisible: values?.isVisible,
        softskillTags: values?.softskillTags,
        coachId: values?.coachId,
      };

      if (values.id) {
        variables.id = values.id;
      }

      const result = await createOrUpdateSnippet({
        variables,
      });

      if (result?.data?.createOrUpdateSnippet?.upload?.url) {
        const upload = UpChunk.createUpload({
          endpoint: result?.data?.createOrUpdateSnippet?.upload?.url,
          file: values.file,
          chunkSize: 5120, // Uploads the file in ~5mb chunks
        });
        upload.on('progress', (progress) => {
          setProgress(progress.detail);
        });

        upload.on('success', () => {
          message.destroy('loading');
          message.success('Completed');
          push();
        });
      } else {
        message.destroy('loading');
        message.success('Completed');
        push();
      }
    },
  });

  const onChange = ({
    target: {
      validity,
      files: [file],
    },
  }: any) => validity.valid && formik.setFieldValue('file', file);

  const isNotDeletedCoach = query?.data?.getCoaches?.CoachUser.some((coach) => coach.id === formik.values.coachId);

  return (
    <Form layout="vertical" onFinish={formik.handleSubmit}>
      {progress && <h1>Uploading{Math.round(progress)}%</h1>}
      <Form.Item label="Snippet Title" name="title">
        <Input
          id="title"
          name="title"
          label="title"
          type="title"
          required
          onChange={formik.handleChange}
          value={formik.values.title}
          defaultValue={formik.values.title}
          style={inputStyles}
        />
      </Form.Item>

      <Form.Item name="coachId" label="Coach">
        <Select
          defaultValue={isNotDeletedCoach ? formik.values.coachId : `${snippet?.coach?.userProfile?.firstName} ${snippet?.coach?.userProfile?.lastName}`}
          onChange={(e) => formik.setFieldValue('coachId', e)}
        >
          {query.data.getCoaches.CoachUser.map((coach) => (
            <Select.Option key={coach.id} value={coach.id}>
              {coach?.userProfile.firstName} {coach?.userProfile.lastName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="isVisible" label="Show this Snippet">
        <Switch defaultChecked={formik.values.isVisible} onChange={(e) => formik.setFieldValue('isVisible', e)} />
      </Form.Item>

      <Form.Item name="softskillTags" label="SoftSkill Tags">
        <Select
          onChange={(e) => formik.setFieldValue('softskillTags', e)}
          type="text"
          defaultValue={formik.values.softskillTags}
          mode="tags"
          placeholder="SoftSkill Tags"
        >
          {SOFT_SKILLS_TAGS.map((tag) => (
            <Select.Option key={tag} value={tag}>
              {tag}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <input type="file" accept="video/*" onChange={onChange} />

      <Form.Item>
        <Button disabled={progress !== null} style={inputStyles} type="primary" htmlType="submit">
          Save Snippet
        </Button>
      </Form.Item>
    </Form>
  );
};

const CreateSnippetModal = ({ snippetQuery, snippet }: any) => {
  const closeModal = () => {
    modal.hideModal();
    snippetQuery.refetch();
  };

  const query = useQuery(GetCoachesQuery);

  if (query.loading && !query?.data?.getCoaches) {
    return <p> Loading </p>;
  }

  return (
    <ModalScrollable title={`${snippet ? 'Edit Snippet' : 'Create snippet'}`}>
      <CreateSnippetForm push={closeModal} snippet={snippet} query={query} />
    </ModalScrollable>
  );
};

export default CreateSnippetModal;
