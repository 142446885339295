import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/react-hoc';
import { modal } from './utils/modal';

const userQuery = gql`
  query currentUser {
    currentUser {
      role
      email
      novelty
      dateEnding
      subscriptionStatus
      educationalInstitution
      partner
      userProfile {
        firstName
        lastName
        bio
        calendlyUserName
        lastWatchedCapsule {
          id
          title
          nextSubcapsules {
            id
          }
        }
      }
      id
    }
  }
`;

export const AppContext = React.createContext();

export function AppContextProvider({ children, firstLogin, setFirstLogin }) {
  const { data: user } = useQuery(userQuery);

  useEffect(() => {
    if (user?.currentUser?.novelty === 'true') {
      setFirstLogin(true);
    }

    if (user?.currentUser?.novelty === 'false') {
      setFirstLogin(false);
    }

    if(user?.currentUser?.novelty === 'done'){
      setFirstLogin(true);
      modal.hideModal()
    }
  }, [user?.currentUser?.novelty]);

  return <AppContext.Provider value={{ firstLogin, setFirstLogin }}>{children}</AppContext.Provider>;
}
