// @ts-nocheck
import React from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'antd';
import { Reoverlay } from 'reoverlay';
import { useQuery, gql } from '@apollo/client';
import MeetCoachModal from './meetCoachModal';

import { StandardMarginDiv, PageLayout } from '../components';
import { STEERUS_GREEN } from '../styles/colors';

const CurrentCoachQuery = gql`
  query getCoach($firstName: String, $lastName: String, $userInt: Int) {
    getCoach(firstName: $firstName, lastName: $lastName, userInt: $userInt) {
      id
      userProfile {
        upcommingNavigationSessions {
          id
          title
        }
        meetCoachVideo {
          id
          url
        }
        bio
        avatarUrl
        firstName
        lastName
      }
    }
  }
`;

const CoachContainer = (props: any) => {
  const params = useParams();
  const query = useQuery(CurrentCoachQuery, {
    variables: {
      firstName: params.firstName,
      lastName: params.lastName,
      userInt: parseInt(params.id, 10),
    },
  });

  return (
    <PageLayout>
      <StandardMarginDiv>
        {query.data && (
          <div style={{ marginLeft: '5vw' }} className="defaultFlex">
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  position: 'relative',
                  alignItems: 'center',
                }}
              >
                <div className="top-circle" />
                <div className="bottom-circle" />
                <img
                  style={{
                    position: 'absolute',
                    height: '40vh',
                    height: '100%',
                    bottom: 0,
                  }}
                  src={query?.data?.getCoach?.userProfile?.avatarUrl}
                />
              </div>
              {query?.data?.getCoach?.userProfile?.meetCoachVideo?.url && (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    size="large"
                    onClick={() =>
                      Reoverlay.showModal(MeetCoachModal, {
                        videoUrl: query?.data?.getCoach?.userProfile?.meetCoachVideo?.url,
                      })
                    }
                    style={{
                      color: 'white',
                      width: '80%',
                      marginTop: '20px',
                      backgroundColor: STEERUS_GREEN,
                    }}
                  >
                    {' '}
                    Meet the Expert{' '}
                  </Button>
                </div>
              )}
            </div>
            <div style={{ marginLeft: '10vw', marginRight: '10vw' }}>
              <p className="heading">
                {' '}
                {query.data?.getCoach?.userProfile.firstName} {query.data?.getCoach?.userProfile.lastName}{' '}
              </p>

              <p> {query?.data?.getCoach?.userProfile.bio} </p>
              <div
                style={{
                  borderBottomLeftRadius: '6px',
                  borderBottomRightRadius: '6px',
                  backgroundColor: STEERUS_GREEN,
                  borderRadius: '6px',
                  color: 'white',
                  fontWeight: 600,
                  justifyContent: 'space-between',
                  paddingLeft: '5%',
                  paddingRight: '5%',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                }}
                className="defaultFlex"
              >
                <div />
                <div
                  style={{
                    display: 'flex',
                    textAlign: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <p style={{ fontSize: '25px' }}> Expert Since </p>
                  <p style={{ lineHeight: '0px' }}> 2020 </p>
                </div>
                <div />
              </div>
              <Button
                style={{
                  backgroundColor: STEERUS_GREEN,
                  color: 'white',
                  fontWeight: 700,
                  marginTop: '130px',
                  marginLeft: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                Book an Appointment
              </Button>
              {false && (
                <p className="heading" style={{ marginTop: '20px' }}>
                  {' '}
                  Navigation Sessions{' '}
                </p>
              )}
            </div>
          </div>
        )}
      </StandardMarginDiv>
    </PageLayout>
  );
};

export default CoachContainer;
