import { gql, useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import {StandardMarginDiv, PageLayout} from '../components';

const TermsOfUse = () => {
  const CAPTURE_PAYMENT_FOR_SKILL_ASSESSMENT = gql`
  mutation capturePaymentForSkillAssessment(
      $assessmentId: ID
      $email: String
      $price: Int
  ) {
      capturePaymentForSkillAssessment(
          assessmentId: $assessmentId
          price: $price
          email: $email
      )
  }
  `;
  const [mutatePostAnswer] = useMutation(CAPTURE_PAYMENT_FOR_SKILL_ASSESSMENT);
  useEffect(() => {
    const data = {
      email: "azhar.khan025@gmail.com",
      assessmentId: 1,
      price: 5
    }

    mutatePostAnswer({
      variables: data,
    }).then(resp => {
      console.log("response ", resp);
    }).catch(err => {
      console.log("error occurred ", err);
    });

  }, []);

  return (
    <PageLayout>
      <StandardMarginDiv>
        <h1>This is a test page</h1>
      </StandardMarginDiv>
    </PageLayout>
  );
};

export default TermsOfUse;
