import React from 'react';
import { StandardMarginDiv, PageLayout } from '../components';
import ProductItem from './ProductItem';
import { productData } from './ProductData';
import './styles.less';
import BigButton from '../components/Buttons/BigButton';
import ImageHelp from '../assets/images/help.png';

const LandingPage = () => {
  // const productsJSX = productData
  //   .sort((a, b) => (a.title > b.title ? 1 : -1))
  //   .map((product) => {
  //     return <ProductItem key={product.title} image={product.image} title={product.title} description={product.description} />;
  //   });

  return (
    <PageLayout>
      <StandardMarginDiv>
        <div className="landingPage">
          <p className="landingPage__description">
            Employers say that soft skills "make or break" employees. How do YOUR soft skills stack up?
          </p>
          <p className="landingPage__title"> <b>Take our UP Assessment</b> to find out. In ~6 minutes, you will know how you compare to your peers.</p>

          <div className="landingPage__upskill">
            {/* <h2 className="landingPage__subtitle">Ready to upskill?</h2> */}
            
            {/* <BigButton padding="20px 43px" font="20px" mr="150px" title="SIGN UP" link="/signup" style="center" />
            <BigButton padding="20px 43px" mr="10px" title="Login" link="/LOGIN" style="center" font="20px" />
          </div> */}

          
  <div className="button-container">
    <BigButton padding="15px 37px" font="20px"  title="Register" link="/signup" style="center" />
    {/* <p className="text-center">New Learners</p> */}
  </div>
  <div className="button-container">
    <BigButton padding="15px 37px"  title="Login" link="/LOGIN" style="center" font="20px" />
    {/* <p className="text-center">Existing Learner</p> */}
  </div>
</div>





          <div className="home--body" style={{margin:'80px 0 60px 0', padding:0}}>
            <div className="home--intro-text">
              {/* <img width={200} height={160} src={ImageHelp} alt="helpipedia" /> */}
              <p className="main">
              <b>STEERus</b> is an award-winning workforce development startup headquartered in New Jersey, USA.
              NJ Governor Murphy, the City of Philadelphia, NJBIZ, Collision Conference, and dozens of colleges and universities have recognized STEERus for our innovative approach to eLearning.
              </p>
            </div>
            {/* <p className="main1">
              The company was founded by <b>Loralyn Mears, PhD.</b> See her LinkedIn profile HERE
              </p> */}
              <p className="main1">
  The company was founded by <b>Loralyn Mears, PhD.</b> See her LinkedIn profile <a href="https://www.linkedin.com/in/loralynmears" style={{ fontWeight: 'bold', textDecoration: 'underline' }}>HERE</a>
</p>


          </div>
          {/* <div className="landingPage__products">
            <h2 className="landingPage__subtitle" style={{ marginBottom: '20px' }}>
              We have ...
            </h2>
            {productsJSX}
          </div> */}
        </div>
      </StandardMarginDiv>
    </PageLayout>
  );
};

export default LandingPage;
