import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import SteerusLogoBlue from './steerusLogoBlue';

import './styles.less';
import { useQuery } from '@apollo/client';
import { userQuery } from '../../hoc/withCurrentUserQuery';
import { useMediaQuery } from 'react-responsive';
import imgHelp from './../../assets/logo/helpipedia_logo.png'
import imgSteerusLogo from './../../assets/logo/steerus_logo.png'

const Logo = () => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-width: 800px)',
  });
  const currentUser = useQuery(userQuery, { fetchPolicy: 'network-only' });

  if(useLocation().pathname.includes("helpipedia"))
    return <div  className="logo"> <Link to={currentUser.data?.currentUser ? '/helpipedia' : '/helpipedia'}>
      <img src={imgHelp} style={{height:'65px',width:'100%', marginTop:-12}}/>
  {/* <SteerusLogoBlue height={isTabletOrMobileDevice ? "40px" : "50px"} width={isTabletOrMobileDevice ? "40px" : "50px"} /> */}
  
</Link>
<h3 style={{marginLeft:10}}>Powered by </h3> <img src={imgSteerusLogo} style={{height:'65px',width:'50%', marginTop:-12}}/>
</div>
    else
  return (
    <Link to={currentUser.data?.currentUser ? '/academy' : '/'} className="logo">
      <SteerusLogoBlue height={isTabletOrMobileDevice ? "40px" : "50px"} width={isTabletOrMobileDevice ? "40px" : "50px"} />
      <span className="logo__text">
        STEER
        <span className="logo__text-highlighted">us</span>
      </span>
    </Link>
  );
};

export default Logo;