import React from 'react';
import { StandardMarginDiv, PageLayout } from '../components';
import './styles.less';

const LegalDisclaimer = () => {
  return (
    <PageLayout>
      <StandardMarginDiv>
        <div className="policy">
          <p className="policy__description">
            In simple terms, STEERus offers expertise in workforce development. The content and services offered are not provided by medical experts; it is to
            be considered as guidance that you may choose to follow at your own discretion with no guarantee of transformational results and improvement in your
            skills.
          </p>
          <h1 className="policy__title">STEERus Legal Disclaimer Notice</h1>
          <p className="policy__description">
            The information contained within our www.STEERus.io web site and STEER.us eLearning Academy is not a substitute for professional advice such as a
            Medical Doctor, Psychiatrist, or licensed counselor. The information provided by STEERus does not constitute legal or professional advice nor is it
            intended to.
            <br />
            <br />
            Diagnosing psychological or medical conditions is for trained medical professionals (Physicians and Therapists) only and is not the role of a coach.
            Business Coaching is not a substitute for engaging the services of a CPA or Attorney to acquire financial or legal advice.
            <br />
            <br />
            Any decisions you make, and the consequences thereof are your own. Under no circumstances can you hold STEERus liable for any actions that you take.
            You agree not to hold STEERus or our experts liable for any loss or cost incurred by you, or any person related or associated with you, because of
            materials or techniques, or coaching, offered by STEERus.
            <br />
            <br />
            Results are not guaranteed. Everything takes work but not everything works out. STEERus holds no responsibility for the actions, choices, or
            decisions taken or made by you the client.
            <br />
            <br />
            The owners of and contributors to STEERus accept no responsibility or liability whatsoever for any harm - real or imagined - from the use or
            dissemination of information contained herein these sites.
            <br />
            <br />
            If these terms are not agreeable, do not engage our services. By engaging the services of STEERus you have agreed to all terms and conditions.
          </p>
          {/* <p className="policy__description">Diagnosing psychological or medical conditions is for trained medical
            professionals (Physicians and Therapists), and is not the role of a coach. Business Coaching is not a
            substitute for engaging the services of a CPA or Attorney.</p>
          <p className="policy__description">Any decisions you make, and the consequences thereof are your own. Under no
            circumstances can you hold STEERus liable for any actions that you take. You agree not to hold STEERus or
            our experts liable for any loss or cost incurred by you, or any person related or associated with you, as a
            result of materials or techniques, or coaching, offered by STEERus.</p>
          <p className="policy__description">Results are not guaranteed. Everything takes work but not everything works
            out. STEERus holds no responsibility for the actions, choices, or decisions taken or made by you the
            client.</p>
          <p className="policy__description">The owners of and contributors to STEERus accept no responsibility or
            liability whatsoever for any harm - real or imagined - from the use or dissemination of information
            contained here. </p>
          <p className="policy__description">If these terms are not agreeable, do not engage our services.</p>
          <p className="policy__description">By engaging the services of STEERus you have agreed to all terms and
            conditions.</p> */}
        </div>
      </StandardMarginDiv>
    </PageLayout>
  );
};

export default LegalDisclaimer;
