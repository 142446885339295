import React, { useEffect } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { StandardMarginDiv, PageLayout } from '../components';
import { VIEW_SNIPPET } from './mutation';
import './styles.less';
import { useMediaQuery } from 'react-responsive';

const GET_SNIPPET = gql`
  query getSnippet($id: ID) {
    getSnippet(id: $id) {
      title
      id
      views
      video {
        url
        length
      }
    }
  }
`;
const SAVE_EVENT_MUTATION = gql`
  mutation saveEvent($eventName: String, $misc: String) {
    saveEvent(eventName: $eventName, misc: $misc)
  }
`;

const SnippetView = () => {
  const params: { id: string } = useParams();

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-width: 500px)',
  });

  const [handleViewSnippet] = useMutation(VIEW_SNIPPET, {
    variables: { viewSnippetSnippetId: params.id },
  });
  const [saveEvent] = useMutation(SAVE_EVENT_MUTATION);

  const { data: snippetData, loading } = useQuery(GET_SNIPPET, {
    variables: { id: params.id },
  });

  useEffect(() => {
    if (snippetData?.getSnippet?.id) {
      if (!loading && !snippetData?.getSnippet?.views) {
        saveEvent({
          variables: {
            eventName: 'SNIPPET_VIEWED',
            misc: JSON.stringify({
              snippet_id: snippetData?.getSnippet?.id,
            }),
          },
        });
      }
      handleViewSnippet();
    }
  }, [snippetData]);

  return (
    <PageLayout>
      <StandardMarginDiv>
        <div className="snipet-player">
          <ReactPlayer
            controls
            // onEnded={onEnded}
            height={isTabletOrMobileDevice ? '40vh' : '50vh'}
            width={isTabletOrMobileDevice ? '100vh' : '80vw'}
            style={{
              borderBottomLeftRadius: '6px',
              width: '80vw',
            }}
            // onProgress={setProgressFunc}
            url={snippetData?.getSnippet?.video?.url}
          />
        </div>
      </StandardMarginDiv>
    </PageLayout>
  );
};

export default SnippetView;
