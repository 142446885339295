import { gql } from '@apollo/client';

export const GET_ALL_TAGS = gql`
  query getAllTags {
    getAllTags
  }
`;

export const GetCoachesQuery = gql`
  query getCoaches {
    getCoaches {
      CoachUser {
        id
        userProfile {
          firstName
          lastName
        }
      }
    }
  }
`;

export const CurrentCapsuleQuery = gql`
  query getCapsule($id: ID) {
    getCapsule(id: $id) {
      id
      views
      reviews
      allReviews
      coach {
        id
        userProfile {
          firstName
          userInt
          coachingStyle
          lastName
          avatarUrl
          bio
          certifications
        }
      }
      type
      pastContent {
        title
        version
        status
        type
        id
        coachingStyle
        bookUrls
        attachmentObjects {
          id
          url
          type
          name
        }
        isFeatured
        subcapsuleContent {
          id
          attachments
          bookUrl
          title
          tags
          video {
            url
            id
            length
          }
          position
          description
        }
        imageHeaderUrl
        skillCheckUrl
        softskillTags
        tags
        learnGoals
        about
        subcapsuleContent {
          id
          position
        }
        briefAbout
      }

      futureContent {
        title
        version
        status
        type
        id
        bookUrls
        attachmentObjects {
          id
          url
          type
          name
        }
        coachingStyle
        isFeatured
        subcapsuleContent {
          id
          title
          tags
          bookUrl
          attachments
          video {
            url
            id
            length
          }
          position
          description
        }
        softskillTags
        tags
        learnGoals
        about
        imageHeaderUrl
        skillCheckUrl
        subcapsuleContent {
          id
          position
        }
        briefAbout
      }
      currentContent {
        title
        version
        status
        type
        id
        bookUrls
        attachmentObjects {
          id
          url
          type
          name
        }
        coachingStyle
        isFeatured
        subcapsuleContent {
          id
          title
          bookUrl
          tags
          attachments
          video {
            url
            id
            length
          }
          position
          description
        }
        softskillTags
        tags
        imageHeaderUrl
        skillCheckUrl
        learnGoals
        about
        subcapsuleContent {
          id
          position
        }
        briefAbout
      }
    }
  }
`;

export const GetSubcapsuleContentQuery = gql`
  query getSubcapsuleContent($id: ID) {
    getSubcapsuleContent(id: $id) {
      id
      title
      position
      description
      capsuleContentId
      tags
      nextSubcapsules {
        id
        description
        title
      }
      attachmentObjects {
        url
        name
        type
      }
      bookUrl
      video {
        id
        url
      }
    }
  }
`;

export const GET_MESSAGES_QUERY = gql`
  query getMessagesForCapsule($subCapsuleId: ID, $skip: Int, $limit: Int) {
    getMessagesForCapsule(subCapsuleId: $subCapsuleId, skip: $skip, limit: $limit) {
      message
      id
      createdAt
      fromUser {
        email
        userProfile {
          firstName
          lastName
        }
      }
    }
  }
`;
