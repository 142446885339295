import React from 'react';
import ReactPlayer from 'react-player';
import { StandardMarginDiv, PageLayout } from '../components';

const WelcomePage = () => (
  <StandardMarginDiv>
    <PageLayout>
      <h1> We heard you! </h1>
      <p> Employers lament how interns and new graduates lack soft skills. Teachers know that their students need to learn soft skills but it's not in the curriculum and they don't have the right content for their lesson plans. And students want to find their purpose, follow their passion and live meaningful lives. </p>

      <p> We don't have all the answers, but we've taken the first step. Our goal is to set students up for success at school, at work and in life. You drive - we'll navigate. </p>

      <p> Our pedagogy: </p>
      <ReactPlayer
        controls
        light="https://image.mux.com/nlRRK025x2V3CdXw6SNW01yPr8wM9HO9HSrls00YkJUtQI/thumbnail.png?width=512&height=512&fit_mode=pad"
        style={{
          maxWidth: '100%',
          overflow: 'hidden',
        }}
        url="https://stream.mux.com/nlRRK025x2V3CdXw6SNW01yPr8wM9HO9HSrls00YkJUtQI.m3u8"
      />
    </PageLayout>
  </StandardMarginDiv>
);

export default WelcomePage;
