// @ts-nocheck
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// @ts-ignore
import CoachView from './views/CoachView';
import AdminView from './views/AdminView';
import { Button } from 'antd';
import { UserRolesEnum } from 'users/userRoles.enum';
import { StandardMarginDiv, PageLayout, CapsuleCard } from '../components';
import { withCurrentUser, withRecommendedCapsules } from '../hoc';
import './styles.less';

const RecommendedCapsuleList = (props) => {
  if (!props.data || !(props.data.getRecommendedCapsules || props.data.getTopSubCapsulesThisWeek)) {
    return <p> Loading </p>;
  }

  const data = props.data.getRecommendedCapsules || props.data.getTopSubCapsulesThisWeek;

  return (
    <div className="dashboard-box">
      {data.map((capsule) => (
        <CapsuleCard key={capsule.id} {...capsule} />
      ))}
    </div>
  );
};

const CapsuleComponent = withRecommendedCapsules(RecommendedCapsuleList);

const StudentView = ({ currentUser }: any) => {
  const history = useHistory();
  const location = useLocation()
  if (!currentUser) {
    return null;
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {currentUser.userProfile && currentUser.userProfile.lastWatchedCapsule && (
          <HeroBox title={currentUser.userProfile.lastWatchedCapsule.title} color="#001F5F">
            <Button onClick={() => history.push(`${location.pathname.includes("helpipedia")?"/helpipedia":""}/capsule/class/${currentUser.userProfile.lastWatchedCapsule.nextSubcapsules[0].id}`)}>
              {' '}
              Continue watching{' '}
            </Button>
          </HeroBox>
        )}
        <div style={{ margin: '20px' }}>
          <h1 className="dashbord__title"> We Think You'll Like These </h1>
          <CapsuleComponent />
        </div>
      </div>
    </>
  );
};

const DashboardContainer = (props: any) => {
  return (
    <PageLayout>
      <StandardMarginDiv>
        {props.data.currentUser.role === UserRolesEnum.ADMIN && props?.data?.currentUser?.partner !== 'GE' && <AdminView />}
        {props.data.currentUser.role === UserRolesEnum.COACH && <CoachView />}
        {props.data.currentUser.role === UserRolesEnum.STUDENT && <StudentView currentUser={props.data.currentUser} />}
        {props.data.currentUser.role === UserRolesEnum.ADMIN && props?.data?.currentUser?.partner === 'GE' && (
          <StudentView currentUser={props.data.currentUser} />
        )}
      </StandardMarginDiv>
    </PageLayout>
  );
};

export default withCurrentUser(DashboardContainer);
