import React from 'react';

import { graphql, gql } from '@apollo/react-hoc';

const withUpcomingNavigationSessionsQuery = graphql(gql`
  query getUpcomingNavigationSessions {
    getUpcomingNavigationSessions {
      id
      scheduledFor
      url
      title
    }
  }
`);

export default withUpcomingNavigationSessionsQuery;
