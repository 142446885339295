import React from 'react';
import ReactPlayer from 'react-player';

import { useMediaQuery } from 'react-responsive';
import Logo from './steerusLogo';
import { useLocation } from 'react-router';
import imgHelp from '../assets/logo/helpipedia_logo.png'

const pTag = { color: '#001e5f', fontSize: '25px', fontWeight: 500 };

const getMain = ({ leftComponents, college, heroPhrase, rightColor }: any) => (
  <div className="defaultFlex flipFlex">
    <div className="fullWidth hero-helf">{leftComponents}</div>

    {!college && (
      <div
        className="noWidth hero-helf-right"
        style={{
          background: rightColor === null ? 'linear-gradient(180deg, #61FF9B -20.96%, #52DC84 -20.95%, #92D050 53.06%)' : 'none',
          backgroundColor: rightColor,
        }}
      >
        {useLocation().pathname.includes('helpipedia') ? (
          <div className="hero-helf-right__content">
            <p  style={{color:'black'}}>{heroPhrase || "We're here to help."}</p>

            <div className="hero-helf-right__logo-block">
              <img src={imgHelp} style={{ height: '150px', width: '180px' }} />
            </div>
          </div>
        ) : (
          <div className="hero-helf-right__content">
            <p>{heroPhrase || "You're perfect for us."}</p>

            <div className="hero-helf-right__logo-block">
              <Logo />
              <p>STEERus</p>
            </div>
          </div>
        )}
      </div>
    )}
    {college && (
      <div
        className="fullWidth"
        style={{
          background: college === 'signupGeneric' ? 'linear-gradient(180deg, #61FF9B -20.96%, #52DC84 -20.95%, #92D050 53.06%)' : 'none',
          backgroundColor: college === 'signupGeneric' ? 'none' : rightColor,

          width: '70vw',
          height: '100%',
        }}
      >
        <div className="fullWidth" style={{ width: '100%', height: '90vh', overflowY: 'scroll' }}>
          <div style={{ padding: '20px', color: 'white' }}>
            {college !== 'signupGeneric' && (
              <>
                <h1 style={{ color: '#001e5f' }}> A Message From our Founder, Loralyn Mears, PhD </h1>
              </>
            )}

            <p style={pTag}>
              I know how important soft skills are. That's why I'm committed to keeping our Success skills Academy FREE to students, educators, and underserved
              communities.
            </p>

            <p style={pTag}>
              Here’s what I ask of YOU. <b>Give STEERus a chance</b> And tell your friends!
            </p>

            <p style={pTag}>Each week, we add more content. Each Quarter, we do a software upgrade.</p>

            <p style={pTag}> Enter our Academy with a positive attitude. Give us feedback; help us help you. And please, be kind to people and pets. </p>

            <ReactPlayer
              controls
              style={{
                maxWidth: '100%',
                borderBottomLeftRadius: '6px',
                overflow: 'hidden',
              }}
              light="https://image.mux.com/giZMpO8x8g2zqAKcJjalsxlZR3K3B8gfPzuCz3k1oR8/thumbnail.png?width=512&height=512&fit_mode=pad&time=80"
              url="https://stream.mux.com/giZMpO8x8g2zqAKcJjalsxlZR3K3B8gfPzuCz3k1oR8.m3u8"
            />

            <br />
          </div>
        </div>
      </div>
    )}
  </div>
);

const HeroHalf = ({ leftComponents, college, showSignup, rightColor, showRight }: any) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-width: 700px)',
  });

  if (college) {
    return getMain({
      leftComponents,
      showSignup,
      college,
      rightColor,
      showRight,
    });
  }

  if (isTabletOrMobileDevice) {
    return <div style={{ margin: '20px' }}>{leftComponents}</div>;
  }

  return getMain({
    leftComponents,
    college,
    rightColor,
    showRight,
  });
};

export default HeroHalf;
