// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { Button, Form, Input, message, Space, Spin } from 'antd';
import { STEERUS_GREEN } from '../styles/colors';
import { PageLayout, HeroHalf } from '../components';
import './styles.less'
const RESET_PASSWORD = gql`
  mutation resetPassword($password: String, $passwordConfirmation: String, $tokenInput: String) {
    resetPassword(password: $password, passwordConfirmation: $passwordConfirmation, tokenInput: $tokenInput) {
        completed
        error
    }
  }
`;

const SignupForm = ({ history, setLoading }) => {
  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted
  const [resetPassword, { data, loading }] = useMutation(RESET_PASSWORD);
  const params = useParams();

  useEffect(() => {
    setLoading(loading)
  }, [loading])

  const defaultMargin = {
    marginTop: '22px',
  };
  const inputStyles = {
    width: '100%',
  };

  const formik = useFormik({
    initialValues: {
      passwordConfirmation: '',
      password: '',
    },
    onSubmit: async (values) => {
      const result = await resetPassword({ variables: { ...values, tokenInput: params?.token } });
      if (result?.data?.resetPassword.completed) {
        message.success('Password Reset');
        history.push('/login');
      } else {
        message.error(result?.data?.resetPassword.error);
      }
    },
  });
  return (
    <Form layout="vertical" onFinish={formik.handleSubmit}>
     < h1 className="login__title"> Let's get that password reset. </h1>
      <Form.Item label="Password" name="password" style={defaultMargin}>
        <Input
          id="new"
          name="password"
          label="password"
          type="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          style={inputStyles}
        />
      </Form.Item>

      <Form.Item label="Password Confirm" name="passwordConfirmation" style={defaultMargin}>
        <Input
          id="passwordConfirmation"
          type="password"
          name="passwordConfirmation"
          onChange={formik.handleChange}
          value={formik.values.passwordConfirmation}
          style={inputStyles}
        />
      </Form.Item>

      <Form.Item style={defaultMargin}>
        <Button style={inputStyles} type="primary" htmlType="submit">
          Save new Password
        </Button>
      </Form.Item>

    </Form>
  );
};

const App = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false)

  return (
    <PageLayout hideFooter>
      {loading && <Space style={{ position: "fixed", backgroundColor: 'rgba(0, 0, 0, 0.45)', top: 0, left: 0, width: "100%", height: "100vh", zIndex: 100000 }}>
        <Spin style={{ position: "absolute", top: '50%', left: '50%',transform:"translate(-50%, -50%)", width: '100px', height: "100px" }} className='login-spin'/>

      </Space>}
      <HeroHalf
        rightColor={STEERUS_GREEN}
        leftComponents={(
          <div>
            <SignupForm history={history} setLoading={setLoading} />
          </div>
        )}
      />
    </PageLayout>
  );
};

export default App;
