import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="57"
      height="57"
      fill="none"
      viewBox="0 0 57 57"
    >
      <path
        fill="#fff"
        d="M54.072 27.56l-4.656-1.426L38 22.529l3.68-7.134-7.06 3.68-3.68-11.49-1.5-4.657L28.537 0l-.976 3.003-1.427 4.657-3.53 11.415-7.135-3.68 3.68 7.059-11.414 3.605-4.732 1.427L0 28.462l3.004.977 4.58 1.501 11.491 3.605-3.68 7.06 7.06-3.681 3.604 11.416 1.427 4.655.977 3.005.976-3.005 1.502-4.655 3.68-11.491 7.059 3.68-3.755-7.06 11.415-3.604 4.656-1.502L57 28.387l-2.928-.827zm-25.534 5.483a4.519 4.519 0 01-4.506-4.506 4.519 4.519 0 014.506-4.505 4.519 4.519 0 014.506 4.505 4.519 4.519 0 01-4.506 4.506z"
      />
      <path
        fill="#fff"
        d="M48.74 34.244a21.017 21.017 0 01-3.756 7.285c-.976 1.276-2.177 2.403-3.379 3.454a20.73 20.73 0 01-7.36 3.755l-.45 1.578-1.052 3.38a25.333 25.333 0 0013.218-6.535c.45-.45.901-.902 1.352-1.351 3.379-3.68 5.632-8.26 6.458-13.143l-3.454 1.127-1.578.45zM34.32 8.335a20.061 20.061 0 017.21 3.755c1.277.977 2.403 2.178 3.454 3.455 1.728 2.178 3.005 4.581 3.755 7.285l1.578.45 3.379 1.051c-.826-4.88-3.079-9.462-6.458-13.142l-1.352-1.351c-3.68-3.38-8.186-5.633-13.143-6.46l1.052 3.38.526 1.577zM8.336 22.68a21.047 21.047 0 013.755-7.285c.977-1.277 2.178-2.403 3.455-3.455 2.177-1.727 4.58-2.929 7.21-3.68l.525-1.502 1.051-3.379c-4.881.826-9.462 3.004-13.142 6.383-.45.451-.9.902-1.352 1.352-3.38 3.68-5.707 8.26-6.533 13.143l3.379-1.052 1.652-.525zM22.755 48.738a21.04 21.04 0 01-7.285-3.755c-1.276-.976-2.402-2.178-3.379-3.379a21.045 21.045 0 01-3.755-7.285l-1.576-.525-3.38-1.052c.826 4.958 3.078 9.538 6.459 13.143l1.351 1.351a25.85 25.85 0 0013.143 6.46l-1.127-3.38-.45-1.578z"
      />
    </svg>
  );
}

export default Icon;
