import React from 'react';
import { Radio } from "antd";

const RadioAnswer = ({isSubmitted, values, answer, question}) => {
  const isIncorrect = isSubmitted &&
    ((values[question] === answer.title && !answer.isCorrect) ||
      (values[question] !== answer.title && answer.isCorrect));
  const isCorrect = isSubmitted && answer.isCorrect && !isIncorrect;

  return (
    <Radio
      className={`${isCorrect ? 'is-correct' : ''}${isIncorrect ? 'is-incorrect' : ''}`}
      value={answer.title}
    >
      {answer.title}
    </Radio>
  )
};

export default RadioAnswer;