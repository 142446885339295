import { gql } from '@apollo/react-hoc';

export const GET_PAYMENT_INTENT_BY_JOURNEY_ID = gql`
  query Query($getPaymentIntentByJourneyIdJourneyId: ID!, $email: String) {
    getPaymentIntentByJourneyId(
      journeyId: $getPaymentIntentByJourneyIdJourneyId
      email: $email
    ) {
      amount
      currency
      paymentMethodTypes
      metadata {
        userId
        journeyId
        email
      }
      clientSecret
    }
  }
`;

export const GET_PAYMENT_INTENT_FOR_ASSESSMENT = gql`
  query Query($price: Int, $email: String) {
    getPaymentIntentForAssessment(
      email: $email
      price: $price
    ) {
      amount
      currency
      paymentMethodTypes
      metadata {
        price
        email
      }
      clientSecret
    }
  }
`;

export const GET_INTENT_PAYMENT_BY_USER = gql`
  query getPaymentIntentByUser($period:String){
  getPaymentIntentByUser(period:$period){
  amount
    currency
    clientSecret
    paymentMethodTypes
    metadata
  }
}
`;

export const BUY_SUBSCRIPTION = gql`
  mutation buySubscription($subscriptionType:String){
    buySubscription(subscriptionType:$subscriptionType)
  }
`;
