import React from 'react';

function Icon({
  height, width, style,
}: any) {
  return (
    <svg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 335 334"
    >
      <path
        fill="#001F5F"
        d="M316.844 161.495l-27.284-8.359-66.893-21.122 21.565-41.807-41.364 21.567-21.565-67.33-8.798-27.284L167.224 0l-5.721 17.599-8.362 27.284-20.684 66.891L90.65 90.207l21.564 41.366-66.886 21.124-27.726 8.361L0 166.778l17.602 5.723 26.842 8.799 67.331 21.122-21.564 41.367 41.368-21.566 21.121 66.892 8.361 27.279L166.783 334l5.722-17.606 8.798-27.279 21.565-67.331 41.364 21.563-22.002-41.368 66.885-21.118 27.286-8.802 17.602-5.72-17.159-4.844zm-149.62 32.127c-14.524 0-26.403-11.883-26.403-26.402 0-14.523 11.879-26.402 26.403-26.402 14.522 0 26.401 11.879 26.401 26.402 0 14.519-11.879 26.402-26.401 26.402z"
      />
      <path
        fill="#001F5F"
        d="M285.594 200.662c-4.399 15.408-11.879 29.928-22.003 42.685-5.718 7.476-12.759 14.081-19.799 20.243-12.765 10.123-27.283 17.599-43.128 21.999l-2.637 9.248-6.165 19.801c29.046-4.838 55.89-18.038 77.454-38.291 2.64-2.634 5.281-5.281 7.921-7.915 19.798-21.566 33.004-48.404 37.843-77.015l-20.24 6.605-9.246 2.64zM201.104 48.844c15.405 4.4 29.923 11.88 42.245 22.003 7.483 5.723 14.083 12.764 20.242 20.242 10.124 12.761 17.604 26.845 22.003 42.688l9.246 2.641 19.797 6.159c-4.838-28.603-18.038-55.448-37.841-77.013-2.641-2.636-5.281-5.28-7.922-7.917-21.565-19.804-47.966-33.006-77.012-37.846l6.165 19.8 3.077 9.243M48.846 132.896c4.401-15.401 11.88-29.924 22.003-42.689 5.724-7.478 12.764-14.08 20.242-20.24 12.762-10.121 26.846-17.162 42.247-21.561l3.082-8.804 6.16-19.801c-28.604 4.84-55.449 17.603-77.012 37.404-2.637 2.64-5.277 5.281-7.918 7.921-19.807 21.564-33.446 48.404-38.286 77.012l19.801-6.166 9.681-3.076M133.339 285.589c-15.401-4.4-29.924-11.876-42.688-21.999-7.479-5.721-14.077-12.767-19.801-19.801-10.123-12.767-17.602-27.286-22.002-42.691l-9.24-3.076-19.804-6.162c4.84 29.049 18.04 55.887 37.847 77.011 2.64 2.638 5.281 5.281 7.918 7.919 21.563 19.81 48.408 33.01 77.012 37.848l-6.602-19.801-2.64-9.248"
      />
    </svg>
  );
}

export default Icon;
