// @ts-nocheck
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Input } from 'antd';

import {
  StandardMarginDiv,
  PageLayout,
  CapsuleCard,
} from '../components';

import {
  GET_CAPSULES_BY_TERM,
} from './query';
import './styles.less';

const SearchCapsulesContainer = () => {
  const params = useParams();
  const [searchTimeout, setSearchTimeout] = useState(null);

  const { data: capsulesData, refetch: capsulesRefetch, loading } = useQuery(
    GET_CAPSULES_BY_TERM,
    {
      variables: { term: params.term },
      fetchPolicy: 'network-only',
    },
  );

  const handleInputChange = (e) => {
    e.persist();
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(
      setTimeout(() => {
        capsulesRefetch({ term: e.target.value });
      }, 500),
    );
  };

  const totalResults = capsulesData?.getCapsulesByTerm?.length ?? 0;

  return (
    <PageLayout loading={loading}>
      <StandardMarginDiv>
        <Input
          placeholder={params.term === 'all' ? 'Search for terms' : params.term}
          onChange={handleInputChange}
        />
        <p>
          {totalResults}
          {' '}
          Total Results
        </p>

        <h1>
          Capsules:
          {capsulesData?.getCapsulesByTerm?.length}
          {' '}
          Results
        </h1>
        <div className="search--list">
          {capsulesData?.getCapsulesByTerm?.map((capsule) => (
            <CapsuleCard
              key={capsule.id}
              tag={null}
              {...capsule}
            />
          ))}
        </div>
      </StandardMarginDiv>
    </PageLayout>
  );
};

export default SearchCapsulesContainer;
