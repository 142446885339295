import React, {useMemo, useState} from 'react';
import {useMediaQuery} from "react-responsive";
import {Button} from "antd";
import './styles.less'

const MobileTable = ({data = [], columns = [], cappitalize = true}) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 400px)",
  });
  const size = isTabletOrMobileDevice ? 20 : 30

  const [length, setLength] = useState(10)

  const memoData = useMemo(() => {
    return data.length > 20 ? data.slice(0, length) : data
  }, [data, length])

  return (
    <div className="mobile-table">
      <div className="mobile-table__body">
        {memoData.length ? memoData.map((item) => (
          <div key={item.id} className="mobile-table__body-tr">
            {columns.map(({key, render, title}, index) => (
              <div className="mobile-table__body-td" key={index}>
                <div className="mobile-table-title">{title}</div>
                <div className={`mobile-table-content ${cappitalize ? "mobile-table-content__cappitalize" : ""}`}>
                  {key === "action"
                    ? render(item, item)
                    : render
                      ? render(item, item)?.length > size
                        ? render(item, item).slice(0, size) + "..."
                        : render(item, item)
                      : item[key]?.length > size
                        ? item[key].slice(0, size) + "..."
                        : item[key]}
                </div>
              </div>
            ))}
          </div>
        )) : <div>
          <h1 className="empty">Empty</h1>
        </div>}
      </div>

      <div className="mobile-table__footer">
        <Button onClick={() => setLength(prev => prev - 10)} disabled={length < 20}>Previous</Button>

        <Button onClick={() => setLength(prev => prev + 10)} disabled={data.length < length}>More</Button>
      </div>
    </div>
  );
};

export default MobileTable;