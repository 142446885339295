import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./styles.less";
import { HELPipedia_ORANGE, STEERUS_GREEN } from "styles/colors";

const Tags = (props: any) => {
  const { tags } = props;
  const location=useLocation()

  return (
    <div className="capsule__tag" >
      {tags.map((tag: string) => (
        <Link   style={{backgroundColor:location.pathname.includes("helpipedia")?HELPipedia_ORANGE:STEERUS_GREEN}} to={`${location.pathname.includes("helpipedia")?"/helpipedia":""}/search/${tag}`} key={tag} className="capsule__tag-link">
          <p className="capsule__tag-text">{tag}</p>
        </Link>
      ))}
    </div>
  );
};

export default Tags;
