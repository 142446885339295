// @ts-nocheck
import React from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import { gql, useMutation } from '@apollo/client';

import { Button, Form, Input, message } from 'antd';
import { STEERUS_GREEN } from '../styles/colors';
import { PageLayout, HeroHalf } from '../components';

const CREATE_COACH_REQUEST = gql`
  mutation createCoachRequest($input: RequestInput) {
    createCoachRequest(input: $input)
  }
`;

const CoachRequestForm = () => {
  const [createRequest, { data }] = useMutation(CREATE_COACH_REQUEST);
  const history = useHistory();
  const defaultMargin = {
    marginTop: '22px',
  };
  const inputStyles = {
    width: '100%',
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      const result = await createRequest({ variables: { input: values } });
      if (result.data.createCoachRequest) {
        message.success('Your request has been received');
        history.push('/');
      } else {
        message.error('There was an error. Try again later');
      }
    },
  });
  return (
    <div>
      <p style={{ fontWeight: 'bold', fontSize: '30px' }}>Join the Movement</p>
      <p style={{ fontWeight: 'normal', fontSize: '20px' }}>
        {' '}
        We're changing students' lives. Together, we can expert students for greater success at school, at work and in life.
      </p>
      <Form layout="vertical" onFinish={formik.handleSubmit}>
        <Form.Item label="Email" name="Email" style={defaultMargin}>
          <Input
            id="email"
            name="email"
            label="email"
            placeholder="exceptionalcoach@example.com"
            type="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            style={inputStyles}
          />
        </Form.Item>

        <Form.Item label="First Name" name="First Name" style={defaultMargin}>
          <Input
            id="firstName"
            placeholder="Your First Name"
            name="firstName"
            onChange={formik.handleChange}
            value={formik.values.firstName}
            style={inputStyles}
          />
        </Form.Item>

        <Form.Item label="Last Name" name="Last Name" style={defaultMargin}>
          <Input id="lastName" placeholder="Your Last Name" name="lastName" onChange={formik.handleChange} value={formik.values.lastName} style={inputStyles} />
        </Form.Item>

        <Form.Item placeholder="Your First Name" label="Experting Specialty" name="Experting Specialty" style={defaultMargin}>
          <Input id="coachingSpecialty" name="coachingSpecialty" onChange={formik.handleChange} value={formik.values.coachingSpecialty} style={inputStyles} />
        </Form.Item>

        <Form.Item label="Coaching Style" name="Coaching Style" style={defaultMargin}>
          <Input id="coachingStyle" name="coachingStyle" onChange={formik.handleChange} value={formik.values.coachingStyle} style={inputStyles} />
        </Form.Item>

        <Form.Item label="Linkedin" name="Linkedin" style={defaultMargin}>
          <Input
            id="linkedin_url"
            name="linkedin_url"
            placeholder="https://www.linkedin.com/"
            onChange={formik.handleChange}
            value={formik.values.linkedin_url}
            style={inputStyles}
          />
        </Form.Item>

        <Form.Item label="What’s your why of coaching" name="passion" style={defaultMargin}>
          <Input id="passion" name="passion" onChange={formik.handleChange} value={formik.values.passion} style={inputStyles} />
        </Form.Item>

        <Form.Item style={defaultMargin}>
          <Button style={inputStyles} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const coachRequest = () => (
  <PageLayout hideFooter>
    <HeroHalf rightColor={STEERUS_GREEN} leftComponents={<CoachRequestForm />} />
  </PageLayout>
);

export default coachRequest;
