import React, { useState } from 'react';
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const InputFile = ({ file, accept, onChange }) => {
  const [fileList, setFileList] = useState(file.url ? [{
    uid: '-1',
    name: file.name || file.url,
    url: file.url,
  }] : []);

  const handleChangeFile = (info) => {
    let files = [...info.fileList];
    files = files.slice(-1);

    files = files.map((value) => {
      if (value.response) {
        value.url = value.response.url;
      }
      return value;
    });
    setFileList(files);
    onChange(files[0]);
  };

  return (
    <Upload
      beforeUpload={() => false}
      accept={accept}
      onChange={handleChangeFile}
      fileList={[...fileList]}
      showUploadList={{
        showRemoveIcon: false,
      }}
    >
      <Button icon={<UploadOutlined />}>Click to Upload</Button>
    </Upload>
  );
};

export default InputFile;
