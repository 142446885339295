import React, { useEffect } from 'react';
import InfoCard from './InfoCard';
import { CheckCircleFilled } from '@ant-design/icons';
import { Button } from 'components';

const Purchased = ({setModal, onSuccess, buttonTitle }) => {
  let buttonString="Back to Result"
 useEffect(()=>{
  if(buttonTitle==buttonString){
  setTimeout(() => {
    onSuccess()
    console.log("API call");
  }, 2000);
    
  
  }
  console.log(buttonTitle,"title");
 },[])
  return (
    <InfoCard>
      <h1>Purchased!</h1>
      <CheckCircleFilled className="payment_info--icon" />
      <Button type="button" color="white" onClick={buttonTitle!=buttonString?onSuccess:()=>setModal(false)}>
       {buttonTitle!=undefined?buttonTitle:"Back to Journey"}
      </Button>
    </InfoCard>
  );
};

export default Purchased;
