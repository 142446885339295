import React from 'react';
import { withCurrentUser } from '../hoc';
import { StandardMarginDiv, PageLayout } from '../components';

const DashboardContainer = () => (
  <PageLayout>
    <StandardMarginDiv />
  </PageLayout>
);

export default withCurrentUser(DashboardContainer);
