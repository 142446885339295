import React from 'react';
import { Link } from 'react-router-dom';
import './styles.less';

const HelpipediaTags = ({ otherTags }) => {
  const tags = [
    {
      title: 'WELL-BEING',
      link: 'well-being',
    },
    {
      title: 'CAREER',
      link: 'career',
    },
    {
      title: 'STUDENTS',
      link: 'students',
    },
    {
      title: 'COLLEGE',
      // link: 'leadership',
      link: 'college',
    },
    {
      title: 'SPEICAL NEEDS',
      link: 'special needs',
    },
    
    
    {
      title: 'LEADERSHIP',
      // link: 'relationship',
      link: 'leadership',
    },

    
    
    
    
   
  ];

  let tagsJSX;
  if (otherTags) {
    tagsJSX = otherTags.map((tag) => {
      return (
        <Link key={tag} to={`/helpipedia/search/${tag}`} className="HelpipediaTags__link">
          {tag}
        </Link>
      );
    });
  } else {
    tagsJSX = tags.map((tag) => {
      return (
        <Link key={tag.title} to={`/helpipedia/search/${tag.link}`} className="HelpipediaTags__link">
          {tag.title}
        </Link>
      );
    });
  }

  return <div className="HelpipediaTags__container">{tagsJSX}</div>;
};

export default HelpipediaTags;