import React, {useEffect} from "react";
import {useState} from "react";
import CategoriesItem from "../MarketplaceComponents/CategoriesItem";
import BookCard from "../MarketplaceComponents/BookCard";
import {SearchOutlined} from "@ant-design/icons";
import {useMediaQuery} from "react-responsive";
import {Button} from "antd";
import { categories } from '../../dashboard/AdminDashboardTabs/BookTable';

const Books = ({data}) => {
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [text, setText] = useState("");
  const [hidden, setHidden] = useState(false)

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 600px)",
  });


  useEffect(() => {
    setSelectedCategory(data)
  }, [data])

  useEffect(() => {
    if (!text) {
      setSelectedCategory(data)
    }
  }, [text])

  const handleClick = (title) => {
    if (title === "All Books") {
      setSelectedCategory(data);
    } else {
      setSelectedCategory(data.filter((e) => e.category === title));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleInputChange = (e) => {
    setText(e.target.value);
    setSelectedCategory(data.filter((e) => e.title.includes(text)));
  };

  const categoriesJSX = [ "All Books",...categories].map((cat) => {
    return <CategoriesItem key={cat} title={cat} handleClick={handleClick}/>;
  });

  const bookList = selectedCategory.map((book) => {
    return (
      <BookCard
        key={book.id}
        title={book.title}
        author={book.author}
        link={book.link}
        avatar={book.avatar}
      />
    );
  });

  return (
    <div className="books">
      <form className="books__searchForm" onSubmit={handleSubmit}>
        <SearchOutlined
          className="books__searchIcon"
          style={{color: "#92d050"}}
        />
        <input
          type="text"
          className="books__searchInput"
          onChange={handleInputChange}
        />
      </form>

      <div className="books__inner">
        <div className="books__categories">
          <h4 className="books__categoriesTitle">Categories {isTabletOrMobileDevice ?
            <Button onClick={() => setHidden(!hidden)}>{!hidden ? 'Hidden' : "Visible"}</Button> : null}</h4>
          {!hidden && <div className="books__categoriesList">{categoriesJSX}</div>}
        </div>
        {bookList.length > 0 ? (
          <div className="books__booksList">{bookList} </div>
        ) : (
          <div className="books__booksList">
            <p>No Books in this Category.</p>
          </div>
        )}
      </div>
      <p className="books__notice">
        *As an Amazon affiliate partner, we may earn fees from qualifying
        purchases.
      </p>
    </div>
  );
};

export default Books;
