import { gql } from '@apollo/client';

export const GET_JOURNEY_LIST_BY_TERM = gql`
  query getJourneyListByTerm($term: String) {
    getJourneyListByTerm(term: $term) {
      id
      title
      imageUrl
      isCollected
      badgeId
      badgeImageUrl
      price
      videos
      snippets
      capsules
      skillChecks
      selfReflections
      workbooks
      duration
      status
    }
  }
`;