export const formatTime = (seconds = 0) => {
    if (isNaN(seconds)) {
        return 'Invalid Format';
    }

    const intSeconds = Math.trunc(seconds);

    const hours = Math.trunc(intSeconds / 3600) > 0
        ? `${Math.trunc(intSeconds / 3600)}h`
        : '';
    const minutes = `${Math.ceil(intSeconds % 3600 / 60)}min`;

    return `${hours} ${minutes}`;
};

export const SOFT_SKILLS_TAGS = [
    'COMMUNICATION',
    'TEAMWORK',
    'ADAPTABILITY',
    'PROBLEM-SOLVING',
    'CREATIVITY',
    'WORK ETHIC',
    'RELATIONSHIPS',
    'TIME MANAGEMENT',
    'LEADERSHIP',
    'ATTENTION TO DETAIL',
    'RESILIENCE',
    'EMOTIONAL INTELLIGENCE',
]

export const TAGS = [
    "Special Needs",
    "Career",
    "Leadership",
    "Well-being",
    "Student",
    "Other",
];