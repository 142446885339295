import { Button, Table } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import { useState } from 'react';
import CreateAssessmentModal from './CreateAssessmentModal';
import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';


const AssessmentDashboard = (props: any) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedAssessment, setSelectedAssessment] = useState(null);
    const [loading, setLoading] = useState(true);
    const GET_SKILL_ASSESSMENTS = gql`
        query getSkillAssessments {
            getSkillAssessments {
                id
                title
                description
                score040Text
                score4160Text
                score6180Text
                score81100Text
                image
                questionsCsv
                status
                price
                slug
                backgroundImage
                watermark
                highNeedReport
                lowNeedReport
                moderateNeedReport
                someNeedReport
            }
        }
    `;


    const { data, refetch } = useQuery(GET_SKILL_ASSESSMENTS);

    useEffect(() => {
        if (data)
            setLoading(false);
    }, [data]);

    const columns: any = [
        {
          title: "id",
          dataIndex: "id",
          width: "5%",
        },
        {
          title: "Title",
          dataIndex: "title",
          width: "5%",
        },
        {
            title: "Slug",
            dataIndex: "slug",
        },
        {
          title: "Description",
          dataIndex: "description",
          width: "10%",
        },
        {
            title: "A score between 0-40 suggests",
            dataIndex: "score040Text"
        },
        {
            title: "A score between 41-60 suggests",
            dataIndex: "score4160Text"
        },
        {
            title: "A score between 61-80 suggests",
            dataIndex: "score6180Text"
        },
        {
            title: "A score between 81-100 suggests",
            dataIndex: "score81100Text"
        },
        {
            title: "Status",
            dataIndex: "status",
            width: "5%",
        },
        {
            title: "Questions CSV",
            dataIndex: "questionsCsv"
        },
        {
            title: "Actions",
            render: (name: any, record: any) => {
                return <div>
                    <Button onClick={() => {setSelectedAssessment(record); setShowModal(true);}}>Edit</Button>
                </div>
            }
        }
      ];
    
    const onSuccess = () => {
        refetch();
        setShowModal(false);
    };

    return <React.Fragment>
        <h1>Assessments</h1>
        <Button onClick={() => setShowModal(true)} >Create Assessment</Button>
        <Table
            loading={loading}
            columns={columns}
            scroll={{x: 2000}}
            dataSource={data ? data.getSkillAssessments.map((assessment: any) => ({...assessment, key: assessment?.id})) : []}
        />

        {showModal && <Modal
                style={{ height: '100vh' }}
                visible={showModal}
                footer={null}
                onCancel={() => setShowModal(false)}
                destroyOnClose
                centered
                width="100%"
            >
                <CreateAssessmentModal
                    onSuccess={onSuccess}
                    selectedAssessment={selectedAssessment}
                />
            </Modal>
        }
    </React.Fragment>
};

export default AssessmentDashboard;