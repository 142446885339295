import React from "react";
import { useLocation } from "react-router";
import { HELPipedia_ORANGE } from "styles/colors";

const CategoriesItem = ({ title, handleClick }) => {
  const isHelpipedia= useLocation().pathname.includes("helpipedia")
  return (
    <div className="categoriesItem" style={{backgroundColor:isHelpipedia?HELPipedia_ORANGE:''}} onClick={() => handleClick(title)}>
      {title}
    </div>
  );
};

export default CategoriesItem;
