import React, { useEffect } from 'react';
import AssessmentCard from '../MarketplaceComponents/AssessmentCard';
import softskills from '../assets/assessments/1.png';
import superpower from '../assets/assessments/2.png';
import mushyMiddle from '../../assets/assessments/mushy_test_logo.png';
import gallop from '../assets/assessments/3.png';
import test_emotional from '../../assets/logo/test_emotional.png';
import disc from '../../assets/logo/disc.png';
import personality from '../../assets/logo/personalityPerfect.png';
// import testColor from '../../assets/logo/test_color.png';
import viaCharacter from '../../assets/logo/via_character.png';
import { gql, useQuery } from '@apollo/client';

const Assessments = () => {
  const GET_SKILL_ASSESSMENTS = gql`
  query getPublishedSkillAssessments {
      getPublishedSkillAssessments {
          id
          title
          description
          image
          questionsCsv
          status
          price
          slug
      }
  }
`;

const {  data: publishedAssessment } = useQuery(GET_SKILL_ASSESSMENTS);
  return (
    <div className="assessments">
     
      <AssessmentCard
        avatar={superpower}
        title="Find Your Superpowers"
        subtitle="We all have a unique set of SuperPowers: traits that govern how we show up in the world and how we relate to others. This assessment helps you to discover your own SuperPowers, so that you can better leverage them."
        link={`assessments/3`}
        source={'Sue Abuelsamid'}
      />
      <AssessmentCard
        avatar={softskills}
        title="UP Assessment"
        subtitle="Taking an honest inventory of your skills and comparing them to where others are can guide your learning journey here inside our Academy."
        link={`assessments/4`}
      />
      {publishedAssessment && publishedAssessment.getPublishedSkillAssessments.map(item=>{
        return <AssessmentCard
        key={item.title}
        avatar={item.image}
        title={item.title}
        subtitle={item.description}
        link={item.slug}
        
        />
      })}
      <AssessmentCard
        avatar={gallop}
        title="Gallup® Strengths"
        subtitle="More than 20 million people have learned how to convert their talents into strengths using this assessment and work with our Gallup experts to grow."
        link="#"
      />
      <AssessmentCard
        avatar={test_emotional}
        title="Test Your Emotional Intelligence"
        source={'University of California, Berkeley'}
        subtitle="This assessment is outside of STEERus but it’s a lot of fun. Look at the photos and determine what that person’s facial expression is really saying."
        link="https://greatergood.berkeley.edu/quizzes/ei_quiz"
        redirect
      />

      <AssessmentCard
        avatar={disc}
        title="DISC"
        source={'123Test'}
        subtitle="You’ll head outside of STEERus for this, but “free” DISC is one of the most popular personality tests in the world. It’s designed to help boost productivity."
        link="https://www.crystalknows.com/disc-personality-test"
        redirect
      />

      {/*<AssessmentCard*/}
      {/*  avatar={testColor}*/}
      {/*  title="Test Color"*/}
      {/*  source={"Dr. Thierry Leroy"}*/}
      {/*  subtitle="We’re drawn to some colors more than others. The colors that we choose as our favorites say something about our personality. Have a look!"*/}
      {/*  link="http://www.testcolor.com/personalitytest/personalitytest.php"*/}
      {/*  redirect*/}
      {/*/>*/}

      <AssessmentCard
        avatar={personality}
        source={'PersonalityPerfect.com'}
        title="Personality Perfect"
        subtitle="Here’s another free personality test. It’s fun and gives you some idea about what drives you."
        link="https://www.personalityperfect.com/test/free-personality-test/"
        redirect
      />

      <AssessmentCard
        avatar={viaCharacter}
        source={'VIA Institute on Character'}
        title="VIA Character Strengths"
        subtitle="There are 24 character strengths which are categorized within 6 virtues. Go outside our Academy to take this free test. "
        link="https://www.viacharacter.org/pro/steerus/account/register"
        redirect
      />
      <p className="assessments__notice">
        *You will receive a copy of your results by email. You may also share your results with your STEERus coach or mentor.
      </p>
    </div>
  );
};

export default Assessments;
