import React from 'react'
import { useFormik } from 'formik';
import { Button, Form, Input, message } from 'antd';
import { UserRolesEnum } from 'users/userRoles.enum';
import { UPDATE_USER } from '../query';
import { useMutation, useQuery } from '@apollo/client';
import { userQuery } from 'hoc/withCurrentUserQuery';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router';
import { HELPipedia_ORANGE } from 'styles/colors';

const { TextArea } = Input;

const Billing = () => {
  const withCurrentUserQuery = useQuery(userQuery, { pollInterval: 500 });
  const currentUser = withCurrentUserQuery?.data?.currentUser
  const isCoach = currentUser.role === UserRolesEnum.COACH;
  const [updateUser] = useMutation(UPDATE_USER);

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 800px)",
  });

  const formik = useFormik({
    initialValues: {
      firstName: currentUser.userProfile.firstName,
      lastName: currentUser.userProfile.lastName,
      bio: currentUser.userProfile.bio,
      ...(isCoach && {
        calendlyUserName: currentUser.userProfile.calendlyUserName
      }),
    },
    onSubmit: async (values) => {
      updateUser({
        variables: {
          input: {
            ...values,
            id: currentUser.id,
          },
        },
      })
        .then(() => {
          message.success('Updated Profile');
        })
        .catch(() => {
          message.error('There was an error. Try again later');
        });
    },
  });
  const isHelpipedia= useLocation().pathname.includes("helpipedia")
  return (
    <>
      <h1 style={{ color:isHelpipedia && 'black'}}>PROFILE INFO</h1>
      <Form className='settings__form' onFinish={formik.handleSubmit}>
        <Form.Item style={{ width: isTabletOrMobileDevice ? "100%" : '48%' }}>
          <p className='heading' style={{ fontSize: '17px', marginLeft: "10px",color:isHelpipedia && HELPipedia_ORANGE }}>First Name</p>
          <Input
            id="firstName"
            name="firstName"
            required
            className='settings__fields'
            defaultValue={formik.values.firstName}
            onChange={formik.handleChange}
            value={formik.values.firstName}
            style={{borderColor: isHelpipedia && HELPipedia_ORANGE}}
          />
        </Form.Item>

        <Form.Item style={{ width: isTabletOrMobileDevice ? "100%" : '48%' }}>
          <p className='heading' style={{ fontSize: '17px', marginLeft: "10px",color:isHelpipedia && HELPipedia_ORANGE }}>Last Name</p>
          <Input
            id="lastName"
            name="lastName"
            required
            className='settings__fields'
            defaultValue={formik.values.lastName}
            onChange={formik.handleChange}
            value={formik.values.lastName}
            style={{borderColor: isHelpipedia && HELPipedia_ORANGE}}
          />
        </Form.Item>
        {
          isCoach && (
            <Form.Item style={{ width: '100%' }}>
              <p className='heading' style={{ fontSize: '17px', marginLeft: "10px" ,color:isHelpipedia && HELPipedia_ORANGE}}>Сalendly Name</p>
              <Input
                id="calendlyUserName"
                name="calendlyUserName"
                type="calendlyUserName"
                className='settings__fields'
                defaultValue={formik.values.calendlyUserName}
                onChange={formik.handleChange}
                value={formik.values.calendlyUserName}
                style={{borderColor: isHelpipedia && HELPipedia_ORANGE}}
              />
            </Form.Item>
          )
        }
        <Form.Item style={{ width: '100%' }}>
          <p className='heading' style={{ fontSize: '17px', marginLeft: "10px",color:isHelpipedia && HELPipedia_ORANGE }}>Bio</p>
          <TextArea
            rows={5}
            id="bio"
            name="bio"
            required
            className='settings__fields'
            defaultValue={formik.values.bio}
            onChange={formik.handleChange}
            value={formik.values.bio}
            style={{borderColor: isHelpipedia && HELPipedia_ORANGE}}
          />
        </Form.Item>
        <Form.Item style={{ width: '100%' }}>
          <Button type="primary" htmlType="submit" className="settings__button-submit"
            style={{backgroundColor:isHelpipedia&& HELPipedia_ORANGE, borderColor: isHelpipedia && HELPipedia_ORANGE}}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}



export default Billing