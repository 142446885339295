import React from 'react';
import './styles.less'

const HeroBox = ({color, title, children, className, classNameTitle}) => {
  return (
    <div className={className ? className : "analytics__box"} style={{backgroundColor: color}}>
      <p className={classNameTitle ? classNameTitle : "analytics__box-title"}>
        {title}
      </p>
      {children}
    </div>
  );
};

export default HeroBox;
