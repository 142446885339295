import React from 'react';
import {useHistory} from "react-router-dom";
import HeroBox from "../DashboardComponents/HeroBox";
import {Button} from "antd";

const CoachView = () => {
  const history = useHistory();
  return (
    <>
      <div className="coach-view">
        <HeroBox title="Create a new capsule" color="#001F5F" className={"coach-view__box"}  classNameTitle={"coach-view__title"}>
          <Button onClick={() => history.push('/capsules/create')}>
            Create
          </Button>
        </HeroBox>
        <HeroBox title="Quick Stats" color="#92D050" className={"coach-view__box"} classNameTitle={"coach-view__title"}/>
      </div>
      <p className="heading"> Currently In Progress </p>
      <p className="heading"> Your Released Capsules </p>
    </>
  );
};

export default CoachView;
