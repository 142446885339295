import React from 'react'
import { useFormik } from 'formik';
import { Button, Checkbox, Form, message } from 'antd';
import { gql, useMutation } from '@apollo/client';
import { HELPipedia_ORANGE } from 'styles/colors';
import { useLocation } from 'react-router';

const SEND_FORGOT_EMAIL = gql`
  mutation sendForgotPasswordEmail($email: String) {
    sendForgotPasswordEmail(email: $email) 
  }
`;

const ResetPassword = ({ currentUser: any }) => {
  const [forgotPassword] = useMutation(SEND_FORGOT_EMAIL);
  const isHelpipedia= useLocation().pathname.includes("helpipedia")
  const formik = useFormik({
    initialValues: {
      email: '',
      checkbox: false
    },
    onSubmit: async (values) => {
      if (!values.checkbox) message.error('You must agree to be sent a password reset email.');
      else {
        try {
          await forgotPassword({ variables: { email: values.email } });
          message.success('Check your email for a password reset link');
        } catch (e) {
          message.error('Email not found');
        }
      }
    },
  });

  return (
    <div>
      <h1 style={{ marginBottom: '40px', color:isHelpipedia && 'black' }}>RESET PASSWORD</h1>
      <Form className='settings__form' onFinish={formik.handleSubmit}>
        <p className='heading' style={{ fontSize: "19px", marginBottom: '0', marginLeft: '10px',color:isHelpipedia && HELPipedia_ORANGE  }}>You forgot your password? No big deal. It happens to all of us. Let’s reset it now!</p>
        <Form.Item style={{ width: '100%', marginBottom: '50px' }}>
          <p className='heading' style={{ fontSize: '17px', margin: '0 0 5px 10px',color:isHelpipedia && HELPipedia_ORANGE  }}>Email Address</p>
          <input
            id="email"
            name="email"
            required
            className='settings__fields'
            onChange={formik.handleChange}
            value={formik.values.email}
            type='email'
            style={{borderColor: isHelpipedia && HELPipedia_ORANGE}}
          />
        </Form.Item>
        <div className='settings__checkbox-content'>
          <Checkbox
            id="checkbox"
            name="checkbox"
            className='settings__checkbox'
            onChange={formik.handleChange}
            checked={formik.values.checkbox}
          />
          <b className='heading' style={{ fontSize: '19px',color:isHelpipedia && HELPipedia_ORANGE  }}>Send me a password reset link</b>
        </div>
        <Form.Item style={{ width: '100%' }}>
          <Button type="primary" htmlType="submit" className="settings__button-submit"
            style={{backgroundColor:isHelpipedia&& HELPipedia_ORANGE, borderColor: isHelpipedia && HELPipedia_ORANGE}}>
            Send Reset Email
          </Button>
        </Form.Item>
      </Form >
    </div>
  )
}



export default ResetPassword