// @ts-nocheck
import React from 'react';
import { modal } from 'utils/modal';
import { Button, Form, Input, Select, Switch } from 'antd';
import { Formik, FieldArray } from 'formik';
import { SOFT_SKILLS_TAGS } from 'utils';
import { gql, useQuery, useMutation } from '@apollo/client';
import ModalScrollable from '../../components/ModalScrollable/ModalScrollable';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import { GET_ALL_PLAYLISTS } from '../query';

const CREATE_PLAYLIST = gql`
  mutation createPlaylist($viewable: Boolean, $id: ID, $title: String, $playlistContent: JSON, $softskillTags: [String]) {
    createPlaylist(title: $title, viewable: $viewable, id: $id, playlistContent: $playlistContent, softskillTags: $softskillTags) {
      id
    }
  }
`;

const GET_ALL_CAPSULES = gql`
  query getAllCapsules {
    getAllCapsules {
      id
      maxVersion
      currentContent {
        id
        title
        version
        tags
        briefAbout
      }
      coach {
        id
        userProfile {
          coachingStyle
          firstName
          lastName
        }
      }
    }
  }
`;

const GET_ALL_SNIPPETS = gql`
  query getAllSnippets {
    getAllSnippets {
      id
      softskillTags
      isVisible
      coachId
      title
    }
  }
`;

const DELETE_PLAYLIST = gql`
  mutation deletePlaylist($playlistId: ID) {
    deletePlaylist(playlistId: $playlistId)
  }
`;

const EDIT_PLAYLIST = gql`
  mutation editPlaylist($viewable: Boolean, $id: ID, $title: String, $playlistContent: JSON, $softskillTags: [String], $badgeId: String) {
    editPlaylist(title: $title, viewable: $viewable, id: $id, playlistContent: $playlistContent, softskillTags: $softskillTags, badgeId: $badgeId) {
      id
    }
  }
`;

const CreatePlaylistForm = ({ push, playlist, badges }) => {
  const { data: allSnippets } = useQuery(GET_ALL_SNIPPETS);
  const [createPlaylist] = useMutation(CREATE_PLAYLIST);
  const [editPlaylist] = useMutation(EDIT_PLAYLIST);
  const { data: allCapsules } = useQuery(GET_ALL_CAPSULES);
  const inputStyles = {
    width: '100%',
  };

  console.log("playlist ", playlist);

  return (
    <div>
      <Formik
        initialValues={{
          title: playlist?.title,
          viewable: playlist?.viewable,
          id: playlist?.id,
          playlistContent: playlist !== undefined ? playlist?.data.map((x) => x) : [],
          softskillTags: playlist?.softskillTags,
          badgeId: playlist?.badgeId,
        }}
        onSubmit={async (values) => {
          if (playlist === undefined) {
            await createPlaylist({ variables: values });
          } else {
            await editPlaylist({ variables: values });
          }

          push();
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Form.Item label="Playlist Title" name="title">
              <Input
                id="title"
                name="title"
                label="title"
                type="title"
                required
                onChange={formik.handleChange}
                value={formik.values.title}
                defaultValue={formik.values.title}
                style={inputStyles}
              />
            </Form.Item>

            <Form.Item name="viewable" label="Show this Playlist">
              <Switch defaultChecked={formik.values.viewable} onChange={(e) => formik.setFieldValue('viewable', e)} />
            </Form.Item>

            <Form.Item name="softskillTags" label="SoftSkill Tags">
              <Select
                onChange={(e) => formik.setFieldValue('softskillTags', e)}
                type="text"
                defaultValue={formik.values.softskillTags}
                mode="tags"
                placeholder="SoftSkill Tags"
              >
                {SOFT_SKILLS_TAGS.map((tag) => (
                  <Select.Option key={tag} value={tag}>
                    {tag}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="badgeId" label="Badge">
              <Select
                onChange={(e) => formik.setFieldValue('badgeId', e)}
                type="text"
                defaultValue={formik.values.badgeId}
                mode="single"
                placeholder="Select Badge"
              >
                {badges.length > 0 && badges.map((badge) => <Select.Option key={badge.id} value={badge.id}>
                    {badge.caption}
                  </Select.Option>
                )}
              </Select>
            </Form.Item>

            <FieldArray
              name="playlistContent"
              render={(arrayHelpers) => (
                <div>
                  {formik.values.playlistContent.map((friend, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index}>
                      <Select
                        showSearch
                        onChange={(e) => {
                          const values = formik.values.playlistContent;
                          values[index] = JSON.parse(e);
                          formik.setFieldValue('playlistContent', values);
                        }}
                        type="text"
                        style={{ width: '100%' }}
                        defaultValue={friend ? JSON.stringify(friend) : null}
                        placeholder="Capsules/Snippets"
                      >
                        {allSnippets?.getAllSnippets.map((x) => (
                          <Select.Option
                            value={JSON.stringify({
                              id: x.id,
                              type: 'snippet',
                              title: x.title,
                            })}
                          >
                            Snippet - {x?.title}
                          </Select.Option>
                        ))}

                        {allCapsules?.getAllCapsules.map((x) => (
                          <Select.Option
                            value={JSON.stringify({
                              id: x.id,
                              type: 'capsule',
                              title: x.currentContent.title,
                            })}
                          >
                            Capsule - {x?.currentContent?.title}
                          </Select.Option>
                        ))}
                      </Select>
                      <Button style={{ marginTop: '10px', marginBottom: '20px' }} type="button" onClick={() => arrayHelpers.remove(index)}>
                        Delete
                      </Button>
                    </div>
                  ))}
                  <Button style={{ marginTop: '0px' }} type="button" onClick={() => arrayHelpers.push(null)}>
                    Add
                  </Button>
                </div>
              )}
            />

            <Button style={{ marginTop: '20px' }} onClick={() => formik.handleSubmit()} type="submit">
              Save
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};

const CreateSnippetModal = ({ playlist, badges }: any) => {
  const [deleteMutation] = useMutation(DELETE_PLAYLIST);
  const playlistQuery = useQuery(GET_ALL_PLAYLISTS, { fetchPolicy: 'network-only' });

  const closeModal = () => {
    modal.hideModal();
    playlistQuery.refetch();
  };

  return (
    <ModalScrollable title={playlist ? 'Edit this playlist' : 'Create Playlist'}>
      <CreatePlaylistForm push={closeModal} playlist={playlist} badges={badges} />
      <Button
        style={{ marginTop: '10px' }}
        onClick={() =>
          modal.showModal(ConfirmationModal, {
            title: 'Are you sure?',
            message: 'Do you want to delete a playlist?',
            onDelete: () => {
              deleteMutation({ variables: { playlistId: playlist.id } });
              modal.hideModal();
            },
          })
        }
      >
        Delete this Playlist
      </Button>
    </ModalScrollable>
  );
};

export default CreateSnippetModal;
