import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Space, Table } from 'antd';
import { GetCoachesQuery, DELETE_COACH, editActiveCoach } from 'dashboard/query';
import { useNotification } from 'hooks';
import { modal } from 'utils/modal';
import CoachModal from '../modals/coachModal';
import CreateCoachModal from '../modals/createCoachModal';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import MobileTable from '../../MobileTable/MobileTable';
import { useMediaQuery } from 'react-responsive';

const CoachTable = () => {
  const query = useQuery(GetCoachesQuery, { fetchPolicy: 'network-only' });
  const [deleteUser] = useMutation(DELETE_COACH);
  const [approveCoach] = useMutation(editActiveCoach);
  const notification = useNotification();
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-width: 700px)',
  });

  const deleteCouch = (userId: string, title = '') => {
    deleteUser({ variables: { userId } }).then((response) => {
      if (response?.data?.deleteUser) {
        notification({ type: 'success', message: `${title} has been successfully deleted` });
        query.refetch();
      } else {
        notification({ type: 'error' });
      }
    });
  };

  const coachActiveHandler = async (userId: string, active: boolean) => {
    modal.showModal(ConfirmationModal, {
      title: 'Are you sure?',
      message: 'Do you want to change the coach activity?',
      onDelete:  () => {
        approveCoach({
          variables: {
            userId,
            active,
          },
        })

        modal.hideModal();
      },
    });

    await query.refetch();
  };

  const columns: any = [
    {
      title: 'Coach Name',
      render: (value: any) =>
        value.firstName !== undefined ? `${value.firstName} ${value.lastName}` : `${value.userProfile.firstName} ${value.userProfile.lastName}`,
    },
    {
      title: 'Status',
      render: (value: any) => ((value.coachActive === 'false' || value.coachActive === null) ? 'Pending' : 'Approved'),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any) => (
        <Space size='middle'>
          <Button onClick={() => modal.showModal(CoachModal, { coach: record, query })}>View</Button>
          {(record.coachActive === 'false' || record.coachActive === null) &&
            <Button onClick={async () => {
              await coachActiveHandler(record.id, true);
              await query.refetch();
            }}>Add to Marketplace</Button>}
          {record.coachActive === 'true' &&
            <Button onClick={async () => {
              await coachActiveHandler(record.id, false);
              await query.refetch();
            }}>Remove from Marketplace</Button>}
          {record.__typename !== 'CoachRequest' && (
            <Button
              onClick={() =>
                modal.showModal(ConfirmationModal, {
                  title: 'Are you sure?',
                  message: 'Do you want to delete a coach?',
                  onDelete: () => {
                    deleteCouch(record.id, record.userProfile?.firstName);
                    modal.hideModal();
                  },
                })
              }
            >
              Delete
            </Button>
          )}
        </Space>
      ),
    },
  ];

  if (!query.loading && query.data) {
    return (
      <>
        <div className='admin__table'>
          <h1 style={{ flex: 1, margin: 'auto' }}> Coaches </h1>
          <div>
            <Button
              onClick={() =>
                modal.showModal(CreateCoachModal, {
                  coachQuery: query,
                })
              }
            >
              Add Coach
            </Button>
          </div>
        </div>

        {!isTabletOrMobileDevice ? (
          <Table
            columns={columns}
            scroll={{ x: 1300 }}
            rowKey='id'
            dataSource={[...query.data.getCoaches.CoachUser, ...query.data.getCoaches.CoachApply]}
          />
        ) : (
          <MobileTable data={[...query.data.getCoaches.CoachUser, ...query.data.getCoaches.CoachApply]}
                       columns={columns} />
        )}
      </>
    );
  }

  return null;
};

export default CoachTable;
