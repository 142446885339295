// @ts-nocheck
import React from 'react';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import {
  Button, Form, Input, message,
} from 'antd';

import { STEERUS_GREEN } from '../styles/colors';
import { PageLayout, HeroHalf } from '../components';

const RESET_PASSWORD = gql`
  mutation confirmPasswordSet($password: String, $token: String) {
    confirmPasswordSet(password: $password, token: $token) 
  }
`;

const SignupForm = () => {
  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted
  const [resetPassword] = useMutation(RESET_PASSWORD);
  const params = useParams();

  const defaultMargin = {
    marginTop: '22px',
  };
  const inputStyles = {
    width: '100%',
  };

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    onSubmit: async (values) => {
      const result = await resetPassword({ variables: { ...values, token: params?.token } });
      if (result?.data.confirmPasswordSet) {
        message.success('Password Reset');
      } else {
        message.error('Issue creating account, please contract support');
      }
    },
  });
  return (
    <Form layout="vertical" onFinish={formik.handleSubmit}>
      <h1> Create your password </h1>
      <Form.Item label="Password" name="password" style={defaultMargin}>
        <Input
          name="password"
          label="password"
          type="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          style={inputStyles}
        />
      </Form.Item>

      <Form.Item style={defaultMargin}>
        <Button style={inputStyles} type="primary" htmlType="submit">
          Save your password
        </Button>
      </Form.Item>
    </Form>
  );
};

const App = () => {
  const history = useHistory();
  return (
    <PageLayout hideFooter>
      <HeroHalf
        rightColor={STEERUS_GREEN}
        leftComponents={(
          <div>
            <SignupForm history={history} />
          </div>
        )}
      />
    </PageLayout>
  );
};

export default App;
