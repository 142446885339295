import React from 'react';
import ButtonInsideLink from '../../components/Buttons/ButtonInsideLink';

const AssessmentCard = ({avatar, source, title, subtitle, link, redirect = false}) => {
    return (
        <div className="assessmentCard">
            <div className="assessmentCard__avatarContainer" style={{backgroundImage: `url(${avatar})`}}/>
            <div className="assessmentCard__info">
                <h4 className="assessmentCard__title">{title}</h4>
                <p className="assessmentCard__subtitle">{subtitle}</p>
                {source ? <p className="assessmentCard__subtitle">SOURCE: {source}</p> : null}
            </div>
            <ButtonInsideLink link={link} title="take it now" targetBlank={redirect}/>
        </div>
    );
};

export default AssessmentCard;
