import React from 'react';
import "./styles.less";
import { useLocation } from 'react-router';

const ButtonLink = ({ link, title, style = "flex-end", disabled }) => {
  const isHelpipedia= useLocation().pathname.includes("helpipedia")
  return (
    <>
      {!disabled ?
        <a
          href={link}
          style={{ alignSelf: style }}
          className={`buttonLink ${isHelpipedia && 'buttonBg'}`}
          target={link !== "#" ? "_blank" : "_self"}>
          {link !== "#" ? title : "coming soon"}</a> :

        <button 
        className={`buttonLink buttonLink__disabled ${isHelpipedia && 'buttonBg'}`}
        style={{ alignSelf: style}}
        >{link !== "#" ? title : "coming soon"}</button>}
    </>
  );
};

export default ButtonLink;
