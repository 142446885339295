import React from 'react';
import ReactPlayer from 'react-player';
import parse from 'html-react-parser';

import Logo from '../steerusLogo';
import { useLocation } from 'react-router';
import './styles.less'


const HeroHalfItems = ({ leftComponents, college, heroPhrase, rightColor, title, paragraph_one, paragraph_two, paragraph_free, paragraph_four, video, light }: any) => {
  const location = useLocation()

  return (
    <div className="defaultFlex flipFlex">
      <div className="fullWidth hero-helf-items"      >
        {leftComponents}
      </div>

      {!college && (
        <div
          className="noWidth"
          style={{
            background:
              rightColor === null
                ? 'linear-gradient(180deg, #61FF9B -20.96%, #52DC84 -20.95%, #92D050 53.06%)'
                : 'none',
            backgroundColor: rightColor,
            width: '70vw',
            height: '100%',
          }}
        >
          <div
            style={{
              width: '100%',
              height: '90vh',
              display: 'flex',
              marginBottom: '5px',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <p
              style={{
                color: 'white',
                fontSize: '50px',
                fontWeight: 600,
              }}
            >
              {heroPhrase || "You're perfect for us."}
            </p>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
              }}
            >
              <Logo />
              <p
                style={{
                  color: 'white',
                  margin: 'auto 20px auto 20px',
                  fontSize: '60px',
                  fontWeight: 600,
                }}
              >
                STEERus
              </p>
            </div>
          </div>
        </div>
      )}
      {college && (
        <div
          className="fullWidth"
          style={{
            background:
              college === 'signupGeneric'
                ? 'linear-gradient(180deg, #61FF9B -20.96%, #52DC84 -20.95%, #92D050 53.06%)'
                : 'none',
            backgroundColor: college === 'signupGeneric' ? 'none' : rightColor,

            width: '70vw',
            height: '100%',
          }}
        >
          <div className="fullWidth" style={{ width: '100%', height: '90vh', overflowY: 'scroll' }}>
            <div style={{ padding: '20px', color: 'white' }}>
              {college !== 'signupGeneric' && (
                <h1 className='hero-helf-items__title'>{title}</h1>
              )}
              <p className="paragraph" style={{color:useLocation().pathname.includes("helpipedia")?"black":"#001e5f"}} >
                {parse(paragraph_one)}
              </p>
              <p className="paragraph" style={{color:useLocation().pathname.includes("helpipedia")?"black":"#001e5f"}} >
                {parse(paragraph_two)}
              </p>
              <p className="paragraph" style={{color:useLocation().pathname.includes("helpipedia")?"black":"#001e5f"}}>
                {parse(paragraph_free)}
              </p>
              <p className="paragraph" style={{color:useLocation().pathname.includes("helpipedia")?"black":"#001e5f"}}>
                {parse(paragraph_four)}
              </p>
              {
                location.pathname === '/signup/ge' ?
                  <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                    <ReactPlayer
                      controls
                      style={{
                        maxWidth: '100%',
                        borderBottomLeftRadius: '6px',
                        overflow: 'hidden',
                        flex: 1
                      }}
                      light={light || "https://image.mux.com/giZMpO8x8g2zqAKcJjalsxlZR3K3B8gfPzuCz3k1oR8/thumbnail.png?width=512&height=512&fit_mode=pad&time=80"}
                      url={video || "https://stream.mux.com/giZMpO8x8g2zqAKcJjalsxlZR3K3B8gfPzuCz3k1oR8.m3u8"}
                    />
                    <img
                      style={{ width: "40%", height: "40%", marginLeft: "50px" }}
                      alt="GE Logo"
                      src={require('../../assets/logo/official_branding_logo.png')} />
                  </div> :
                  <ReactPlayer
                    controls
                    style={{
                      maxWidth: '100%',
                      borderBottomLeftRadius: '6px',
                      overflow: 'hidden',
                    }}
                    light={light || "https://image.mux.com/giZMpO8x8g2zqAKcJjalsxlZR3K3B8gfPzuCz3k1oR8/thumbnail.png?width=512&height=512&fit_mode=pad&time=80"}
                    url={video || "https://stream.mux.com/giZMpO8x8g2zqAKcJjalsxlZR3K3B8gfPzuCz3k1oR8.m3u8"}
                  />
              }
              <br />
            </div>
          </div>
        </div>
      )}
    </div>)
}


export default HeroHalfItems