import React from 'react';
import { Checkbox } from 'antd';

const Question15 = ({ setCheckbox, index = 15 }) => {
  return (
    <div className="soft-skills-field">
      <h6>{index}. In which industries have you been employed ? Select all that apply.</h6>
      <Checkbox
        onChange={(e) =>
          setCheckbox((prev) => ({
            ...prev,
            accounting: e.target.checked,
          }))
        }
      >
        Accounting , Adminisration, Finance
      </Checkbox>
      <Checkbox
        onChange={(e) =>
          setCheckbox((prev) => ({
            ...prev,
            agriculture: e.target.checked,
          }))
        }
      >
        Agriculture, Forestry, Fishing, Hunting
      </Checkbox>
      <Checkbox
        onChange={(e) =>
          setCheckbox((prev) => ({
            ...prev,
            arts: e.target.checked,
          }))
        }
      >
        Arts, Entertaiment and Recreation
      </Checkbox>
      <Checkbox
        onChange={(e) =>
          setCheckbox((prev) => ({
            ...prev,
            construction: e.target.checked,
          }))
        }
      >
        Construction
      </Checkbox>
      <Checkbox
        onChange={(e) =>
          setCheckbox((prev) => ({
            ...prev,
            education: e.target.checked,
          }))
        }
      >
        Education/ Educational Services
      </Checkbox>
      <Checkbox
        onChange={(e) =>
          setCheckbox((prev) => ({
            ...prev,
            govermment: e.target.checked,
          }))
        }
      >
        Govermment, Public Adminisration, Not-For-Profit
      </Checkbox>
        <Checkbox
            onChange={(e) =>
                setCheckbox((prev) => ({
                    ...prev,
                    healtcare: e.target.checked,
                }))
            }
        >
          Healthcare and Social Assistance
        </Checkbox>
        <Checkbox
            onChange={(e) =>
                setCheckbox((prev) => ({
                    ...prev,
                    hospitality: e.target.checked,
                }))
            }
        >
            Hospitality
        </Checkbox>
        <Checkbox
            onChange={(e) =>
                setCheckbox((prev) => ({
                    ...prev,
                    manufacturing: e.target.checked,
                }))
            }
        >
            Manufacturing
        </Checkbox>
        <Checkbox
            onChange={(e) =>
                setCheckbox((prev) => ({
                    ...prev,
                    mining: e.target.checked,
                }))
            }
        >
            Mining, Petroleum
        </Checkbox>
        <Checkbox
            onChange={(e) =>
                setCheckbox((prev) => ({
                    ...prev,
                    real_estate: e.target.checked,
                }))
            }
        >
            Real Estate
        </Checkbox>
        <Checkbox
            onChange={(e) =>
                setCheckbox((prev) => ({
                    ...prev,
                    retail: e.target.checked,
                }))
            }
        >
            Retail
        </Checkbox>
        <Checkbox
            onChange={(e) =>
                setCheckbox((prev) => ({
                    ...prev,
                    science: e.target.checked,
                }))
            }
        >
            Science and Technology
        </Checkbox>
        <Checkbox
            onChange={(e) =>
                setCheckbox((prev) => ({
                    ...prev,
                  transport: e.target.checked,
                }))
            }
        >
            Transport, Logistics, Warehousing
        </Checkbox>
        <Checkbox
            onChange={(e) =>
                setCheckbox((prev) => ({
                    ...prev,
                    utilities: e.target.checked,
                }))
            }
        >
            Utilities
        </Checkbox>
        <Checkbox
            onChange={(e) =>
                setCheckbox((prev) => ({
                    ...prev,
                    wholesale: e.target.checked,
                }))
            }
        >
            Wholesale
        </Checkbox>

      <Checkbox
        onChange={(e) =>
          setCheckbox((prev) => ({
            ...prev,
            other: e.target.checked,
          }))
        }
      >
        Other
      </Checkbox>
    </div>
  );
};

export default Question15;
