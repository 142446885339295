import { gql } from '@apollo/client';

export const GET_SNIPPETS_BY_TERM = gql`
  query getSnippetsByTerm($term: String) {
    getSnippetsByTerm(term: $term) {
      id
      coachId
      title
      journeyId
      views
      video {
        length
      }
      coach {
        userProfile {
          firstName
          lastName
        }
      }
    }
  }
`;
