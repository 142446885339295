import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./styles.less";


const BigButton = ({ title, link, font , subtitle, padding, mr, style = "flex-end"}) => {
    return (
        <Link
            className={useLocation().pathname.includes("helpipedia")?"bigButtonHelpipedia":"bigButton"}
            to={link}
            style={{alignSelf: style, textAlign: "center", fontSize: font,  padding: padding, marginRight: mr}}
        >
            {title}
            {subtitle &&
                <span style={{display: 'flex', margin: '0', flexDirection: 'column'}}>
                    <span style={{fontSize: font}}></span>
                    <span style={{fontSize: font}}>{subtitle}</span>
                </span>
            }
        </Link>
    );
};

export default BigButton;
