import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Space, Table } from "antd";
import { useNotification } from "hooks";
import { GET_ALL_QUESTIONS, DELETE_QUESTION } from "dashboard/query";
import { modal } from "utils/modal";
import questionsJSONModal from "../modals/questionsJSONModal";
import CreateQuestionModal from "../modals/createQuestionModal";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import MobileTable from "../../MobileTable/MobileTable";
import { useMediaQuery } from "react-responsive";

const QuestionsTable = () => {
  const query = useQuery(GET_ALL_QUESTIONS, { fetchPolicy: "network-only" });
  const notification = useNotification();
  const [deleteQuestion] = useMutation(DELETE_QUESTION);

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 700px)",
  });

  const onDeleteQuestion = (id: string, title: string) => {
    deleteQuestion({ variables: { id } }).then((response) => {
      if (response?.data?.deleteQuestions) {
        notification({ type: "success", message: `${title} has been successfully deleted` });
        query.refetch();
      } else {
        notification({ type: "error" });
      }
    });
  };

  const columns: any = [
    {
      title: "Question Name",
      render: (value: any) => value.title,
      key:"title"
    },
    {
      title: "Description",
      render: (value: any) => value.description,
      key:"description"
    },
    {
      title: "Type",
      render: (value: any) => value.type,
      key:"type"
    },
    {
      title: "Actions",
      key: "action",
      render: (value: any) => (
        <Space size="middle">
          <Button
            onClick={() => {
              modal.showModal(questionsJSONModal, {
                questions: value.questions,
              });
            }}
          >
            Show questions JSON
          </Button>
          <Button
            onClick={() =>
              modal.showModal(ConfirmationModal, {
                title: "Are you sure?",
                message: "Do you want to delete a question?",
                onDelete: () => {
                  onDeleteQuestion(value.id, value.title);
                  modal.hideModal();
                },
              })
            }
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  if (!query.loading && query.data) {
    return (
      <>
        <div className="admin__table">
          <h1 style={{ flex: 1, margin: "auto" }}> Questions </h1>
          <div>
            <Button
              onClick={() =>
                modal.showModal(CreateQuestionModal, {
                  questionsQuery: query,
                })
              }
            >
              Add Questions
            </Button>
          </div>
        </div>
        {!isTabletOrMobileDevice ? (
          <Table rowKey="id" columns={columns} dataSource={query?.data?.getQuestions} scroll={{ x: 1300 }} />
        ) : (
          <MobileTable data={query?.data?.getQuestions} columns={columns} />
        )}
      </>
    );
  }

  return null;
};

export default QuestionsTable;
