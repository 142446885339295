import React, { useEffect, useState } from 'react';
import './mushymiddle.css';
import { Link } from 'react-router-dom';
import arrow_down from './../../assets/assessments/arrow_down.png';
import arrow_down_green from './../../assets/assessments/arrow_down_green.png';

import { PageLayout } from 'components';
import { gql, useQuery } from '@apollo/client';
import { Form, Input } from 'antd';
import { userQuery } from 'hoc/withCurrentUserQuery';
import { useLocation,useParams } from 'react-router-dom'
export default function CommonAssessmentScreen() {

    const GET_SKILL_ASSESSMENT_BY_SLUG = gql`
        query getSkillAssessmentBySlug($slug: String) {
            getSkillAssessmentBySlug(slug: $slug) {
                id
                title
                description
                image
                status
                price
                watermark
                backgroundImage
            }
        }
    `;
    const GET_COACH_BY_ID = gql`
    query getCoachById($id: ID) {
        getCoachById(id: $id) {
            id
        }
    }
`;
    const {data:user,loading:loading} = useQuery(userQuery);
    const [email, setEmail] = useState("")
    const [showMore, setShowMore] = useState(false)
    const [foundCoachId, setCoachId] = useState(null)
    const location = useLocation();
    const {coachId}=useParams()
    const [pageFocused,setPageFocused]=useState(true)
   
    const {  data: landingData } = useQuery(GET_SKILL_ASSESSMENT_BY_SLUG, {
        variables: { slug: coachId!=undefined? location.pathname.slice(location.pathname.includes("helpipedia")?12:1,location.pathname.length-coachId.length-1):location.pathname.slice(location.pathname.includes("helpipedia")?12:1) },
    });
    const { loading:loadingCoach, data: findCoach } = useQuery(GET_COACH_BY_ID, {
        variables: {id:coachId!=undefined?coachId:-1},
    });
  
    var updateInput = (value) => {
        setEmail(value.target.value);
    }

    useEffect(() => {
        if (user !=undefined && user.currentUser!=null && !loading) {
            setEmail(user.currentUser.email);
        }

    }, [user])

    useEffect(() => {
      
        findCoach !=undefined && findCoach.getCoachById != null && setCoachId(findCoach.getCoachById.id)

 }, [loadingCoach])

    useEffect(()=>{
        setTimeout(() => {
            setPageFocused(pageFocused=>!pageFocused)
        }, 1500);
    },[])
    return (
        <PageLayout loading={loading} >
          { pageFocused ?<></>: !loading && landingData?.getSkillAssessmentBySlug!=null ? <div className='backImage' style={{
              backgroundImage: `url(${landingData?.getSkillAssessmentBySlug.image})`
          }}>
                <div>
                    <div className='content-div'>
                        <h1 className='test-h1'>{landingData?.getSkillAssessmentBySlug.title}</h1>
                        <h2 className='about'>
                            {' '}
                            {landingData?.getSkillAssessmentBySlug.description}
                        </h2>

                        <Form className='form'>
                            <Form.Item style={{ width: '100%', margin: 0 }}>
                                <p className='heading' style={{ fontSize: '17px', marginLeft: '10px' }}>Email</p>
                                <Input
                                    id="currentEmail"
                                    name="currentEmail"
                                    required
                                    className='field'
                                    defaultValue={email}
                                    onChange={updateInput}
                                    value={email}
                                    type="email"
                                />
                            </Form.Item>


                        </Form>
                        {email == ""
                            ? <Link to="/" className="assessment_get_skill" onClick={(event) => event.preventDefault()}>START THE ASSESSMENT</Link>
                            : <Link key={'get_skills'} to={{ pathname: `${coachId!=undefined? location.pathname.slice(0,location.pathname.length-coachId.length-1):location.pathname}/question`,
                             state: { landingData:landingData?.getSkillAssessmentBySlug, coachId:foundCoachId, email: email, pathname:`${coachId!=undefined? location.pathname.slice(0,location.pathname.length-coachId.length-1):location.pathname}`, id: landingData?.getSkillAssessmentBySlug.id  } }} className="assessment_get_skill">
                                START THE ASSESSMENT
                            </Link>
                        }

                        <div className='learn-more-div'>
                            <h2 className='learn-more'>
                                {' '}
                                Learn more about the privacy of your data
                                {' '}
                            </h2>
                            <img onClick={() => setShowMore(!showMore)} src={showMore ? arrow_down_green : arrow_down} className='icon' />
                        </div>
                        {showMore && <h4 className='learn-more' style={{ marginLeft: "10%", marginRight: '10%' }}>
                            {' '}
                            <i> By submitting your email address above, you understand and agree that your information will be saved and used anonymously for analysis with other data to perform research. We may occasionally contact you to suggest relevant content,  promotional offers, or recommend a coach. If you choose to work with a coach, your consent is required for them to see your report. We will not share your individual survey responses with your employer or boss without your consent. If you do not want your data to be collected, please do not fill out the survey. If you ever want your data deleted or would like to opt out of marketing, please contact hello@steerus.io. For more information about the data we collect, see our Privacy Policy.</i>{' '}
                        </h4>}
                    </div>
                </div>
            </div>: !loading && <div style={{ flexDirection: 'column', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                    <p style={{ color: 'black' }}>Invalid link.</p>
                    <Link key={'get_skills'} to={`/marketplace`} className="assessment_get_skill">
                        Go Back
                    </Link>  </div>}

        </PageLayout>
    )
}
