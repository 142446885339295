import React from 'react';
import { Input } from 'antd';

const { TextArea } = Input;

const Question10 = ({ description, setDescription, index = 10 }) => {
  return (
    <div className="soft-skills-field">
      <h6>{index}. Please share any comments , ideas or needs regarding soft skills development, training , and coaching.</h6>
      <TextArea rows={4} value={description} onChange={(e) => setDescription(e.target.value)} />
    </div>
  );
};

export default Question10;
