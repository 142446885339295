import { gql } from '@apollo/react-hoc';

export const GET_JOURNEY_BY_ID = gql`
  query Query($getUserJourneyById: ID!) {
    getUserJourneyById(id: $getUserJourneyById) {
      id
      title
      isCollected
      badgeId
      allReviews
      softskillTags
      tags
      imageUrl
      description
      price
      duration
      publishStatus
      status
      views
      video {
        id
        url
        name
        length
      }
      capsules {
        id
        coach {
          userProfile {
            firstName
            lastName
            avatarUrl
            userInt
          }
        }
        currentContent {
          imageHeaderUrl
          id
          title
          briefAbout
          videoInfo {
            numberOfVideos
            time
          }
        }
        status
        views
        type
      }
      coach {
        userId
        firstName
        lastName
        bio
        userInt
        avatarUrl
        coachingStyle
        calendlyUserName
      }
      snippets {
        id
        journeyId
        title
        status
        views
        coach {
          userProfile {
            firstName
            lastName
          }
        }
        video {
          length
        }
      }
      workbooks {
        id
        name
        url
        type
      }
      selfReflections {
        id
        title
      }
      assessments {
        id
        name
        url
        type
      }
      skillChecks {
        id
        title
      }
    }
  }
`;

export const CREATE_MESSAGE = gql`
  mutation createJourneyMessage($journeyId: ID, $message: String) {
    createJourneyMessage(journeyId: $journeyId, message: $message) {
      message
      id
      createdAt
      fromUser {
        id
        email
        userProfile {
          firstName
          lastName
        }
      }
    }
  }
`;

export const ASSIGN_MATERIALS_TO_JOURNEY = gql`
    mutation assignMaterialsToJourney(
        $id: ID!, 
        $capsules: [ID], 
        $snippets: [ID], 
        $attachments: [ID], 
        $questions: [ID]
    ) {
        assignMaterialsToJourney(
            id: $id, 
            capsules: $capsules, 
            snippets: $snippets, 
            attachments: $attachments, 
            questions: $questions
        )
    }
`;

export const GET_MESSAGES = gql`
  query getMessagesForJourney($journeyId: ID, $skip: Int, $limit: Int) {
    getMessagesForJourney(journeyId: $journeyId, skip: $skip, limit: $limit) {
      message
      id
      createdAt
      fromUser {
        id
        email
        userProfile {
          firstName
          lastName
        }
      }
    }
  }
`;

export const GetCoachesQuery = gql`
    query getCoaches {
        getCoaches {
            CoachUser {
                id
                userProfile {
                    firstName
                    lastName
                }
            }
        }
    }
`;

export const GetAllApprovedCapsules = gql`
    query getAllApprovedCapsules {
        getAllApprovedCapsules {
            id
            currentContent {
                title
            }
        }
    }
`;

export const GetAllSnippets = gql`
    query getAllSnippets {
        getAllSnippets {
            id
            title
            views
        }
    }
`;

export const GetAllAttachments = gql`
    query getAttachments {
        getAttachments {
            id
            name
        }
    }
`;

export const GetAllQuestions = gql`
    query getQuestions {
        getQuestions {
            id
            title
        }
    }
`;


export const VIEW_JOURNEY = gql`
mutation Mutation($id: ID!) {
  viewJourney(id: $id)
}
`