import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import TextCard from 'components/TextCard';

function SnippetCard({ onClick, title, coach, id, journeyId, time, className, views }) {
  const history = useHistory();
const location= useLocation()
  const handleClick = useCallback(() => {
    if (journeyId) {
     
      history.push(location.pathname.includes("helpipedia")?`/helpipedia/journey/${journeyId}`:`/journey/${journeyId}`);
      return;
    }

    if (onClick) {
      onClick();
    } else {
     
      history.push(location.pathname.includes("helpipedia")?`/helpipedia/snippet/${id}`:`/snippet/${id}`);
    }
  }, [history, id, onClick]);

  const footerText = coach ? `${coach.firstName} ${coach.lastName}` : null;

  return (
    <TextCard
      className={className}
      onClick={handleClick}
      title={title}
      time={time}
      footerText={footerText}
      color={views ? 'grey' : 'green'}
    />
  );
}

export default SnippetCard;
