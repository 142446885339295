import { gql } from '@apollo/react-hoc';

export const GET_USER_SELF_REFLECTION_BY_ID = gql`
  query getUserSelfReflectionById($id: ID!) {
    getUserSelfReflectionByQuestionId(questionId: $id) {
      id
      title
      description
      questions
      answers
    }
  }
`;

export const SET_USER_SELF_REFLECTION_BY_ID = gql`
  mutation Mutation($id: ID!, $answers: JSON) {
    setUserSelfReflectionByQuestionId(questionId: $id, answers: $answers) {
      id
      answers
    }
  }
`;
