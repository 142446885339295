import React from 'react';

import { graphql, gql } from '@apollo/react-hoc';

const withTopCapsules = graphql(gql`
  query getTopSubCapsulesThisWeek {
    getTopSubCapsulesThisWeek {
      id
      coach {
        id
        userProfile {
          firstName
          avatarUrl
          lastName
        }
      }

      currentContent {
        videoInfo {
          numberOfVideos
          time
        }
        imageHeaderUrl
        id
        briefAbout
        title
      }
      status
      journeyId
      type
    }
  }
`);

export default withTopCapsules;
