import React from 'react';
import { openPopupWidget } from "react-calendly";
import { useLocation } from 'react-router';

const CalendlyButton = ({url}) => {
  const isHelpipedia= useLocation().pathname.includes("helpipedia")
  const onClick = () => openPopupWidget({ url });
  return <button onClick={onClick}  className={`${isHelpipedia?'simpleButtonHelpipedia':'simpleButton'}`}>book now</button>;
};

export default CalendlyButton;
