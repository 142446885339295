// @ts-nocheck
import React, {useState} from 'react';
import {useFormik} from 'formik';
import {Link, useHistory} from 'react-router-dom';
import {gql, useMutation} from '@apollo/client';
import {Button, Checkbox, Form, Input, message, Space, Spin} from 'antd';
import {userQuery} from '../hoc/withCurrentUserQuery';
import {HeroHalfPartner, PageLayout} from '../components';

const LOGIN = gql`
    mutation vipSignUpGe($email:String, $password:String,$firstName:String,$lastName:String ){
        vipSignUpGe(email:$email, password:$password,firstName:$firstName,lastName:$lastName){
            tokens {
                accessToken
                refreshToken
            }
            error {
                field
                message
            }
            user {
                id
                confirmedEmail
                role
            }
        }
    }
`;

const SignupForm = ({history, setLoading}) => {
  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted
  const [vipSignUpGe] = useMutation(LOGIN);
  const inputStyles = {
    width: '100%',
  };


  const formik = useFormik({
    initialValues: {
      email: '',
      tos: false,
      password: '',
      firstName: "",
      lastName: ""
    },
    onSubmit: async (values) => {
      setLoading(true)
      if (!values.tos) {
        message.error('You must agree to our Terms of Service');
      } else {
        const result = await vipSignUpGe({
          variables: {...values},
          refetchQueries: [{query: userQuery}],
        });
        if (result?.data?.vipSignUpGe.error) {
          setLoading(false)
          message.error('Your email address is not a VIP');
        } else {
          localStorage.setItem('token', result.data.vipSignUpGe.tokens.accessToken);
          localStorage.setItem(
            'refreshToken',
            result?.data?.vipSignUpGe?.tokens?.refreshToken,
          );

          history.push('/academy');
          setLoading(false)
          window.location.reload(true)
        }
      }
    },
  });

  const validateMessages = {
    required: '${label} is required.'
  }

  return (
    <Form
      layout="vertical"
      className="form-with-antd-validation"
      validateMessages={validateMessages}
      onFinish={formik.handleSubmit}
    >
      <h1 className="sign-up__title">Please login here with your VIP assigned email and password </h1>

      <Form.Item
        label="First Name"
        name="firstName"
        required={false}
        rules={[{required: true}]}
      >
        <Input
          id="firstName"
          name="firstName"
          label="firstName"
          type="firstName"
          onChange={formik.handleChange}
          value={formik.values.firstName}
          style={inputStyles}
        />
      </Form.Item>
      <Form.Item
        label="Last Name"
        name="lastName"
        required={false}
        rules={[{required: true}]}
      >
        <Input
          id="lastName"
          name="lastName"
          label="lastName"
          type="lastName"
          onChange={formik.handleChange}
          value={formik.values.lastName}
          style={inputStyles}
        />
      </Form.Item>

      <Form.Item
        label="VIP Assigned Email Address"
        name="VIP Assigned Email Address"
        required={false}
        rules={[{required: true}]}
      >
        <Input
          id="email"
          name="email"
          label="email"
          type="email"
          onChange={formik.handleChange}
          value={formik.values.email}
          style={inputStyles}
        />
      </Form.Item>

      <Form.Item
        label="VIP Assigned Password"
        name="VIP Assigned Password"
        required={false}
        rules={[{required: true}]}
      >
        <Input
          id="password"
          name="password"
          type="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          style={inputStyles}
        />
      </Form.Item>

      <Form.Item
        name="tos"
        valuePropName="checked"
        required={false}
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error('You should agree with our Terms and Conditions to proceed')),
          }
        ]}
      >
        <Checkbox
          id="tos"
          name="tos"
          onChange={formik.handleChange}
          value={formik.values.tos}
        >
          <span>
            I have read and agree to the&nbsp;
            <Link to="/terms">Terms and Conditions</Link>
            &nbsp;and the&nbsp;
            <Link to="/privacy">Privacy Policy</Link>
            &nbsp;and the&nbsp;
            <Link to="/disclaimer">Legal Disclaimer.</Link>
          </span>
        </Checkbox>
      </Form.Item>

      <Form.Item>
        <Button style={inputStyles} type="primary" htmlType="submit">
          Begin Your Learning Journey
        </Button>
      </Form.Item>

      <p onClick={() => history.push('/login')} className="sign-up__link">
        Already have an account? Log in.
      </p>
    </Form>
  );
};

const App = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false)

  return (
    <PageLayout hideFooter>
      {loading && <Space style={{
        position: "fixed",
        backgroundColor: 'rgba(0, 0, 0, 0.45)',
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        zIndex: 100000
      }}>
        <Spin style={{ position: "absolute", top: '50%', left: '50%',transform:"translate(-50%, -50%)", width: '100px', height: "100px" }} className='login-spin'/>
      </Space>}
      <HeroHalfPartner
        rightColor="#fff"
        background={null}
        college
        leftComponents={(
          <div className="paddingTop">
            <SignupForm history={history} setLoading={setLoading}/>
          </div>
        )}
        title="A Message From Ed Ross"
        paragraph_one="On behalf of my entire team at <b>Michigan & Manchester</b>, we are incredibly excited to bring you a customized learning experience. Enter our Learning Academy to begin your journey in Visionary Storytelling."
        paragraph_two="Peter and Phillip went to the same college, earned the same degree, began the same profession, and started at equivalent firms. Years later, the two former classmates reunited at a conference. Both were immaculately groomed and rising professionals. But there was one key difference: Peter was on the stage giving a talk whereas Phillip was in the audience. Why?"
        paragraph_free="Peter learned how to paint the outcome using a narrative whereas Phillip was always trapped in the weeds talking about the data and the dots on the graph. Sure, analytics matter, but are you going to remember the numbers or how you felt after you heard a story about what the numbers mean?"
        video="https://stream.mux.com/7LUGs3tJADqPrerUqJNd2dwZgkOO311lP3w9ryFlNWY.m3u8"
        light="https://image.mux.com/7LUGs3tJADqPrerUqJNd2dwZgkOO311lP3w9ryFlNWY/thumbnail.png?width=2048&height=1024&fit_mode=pad&time=0"
      />
    </PageLayout>
  );
};

export default App;
