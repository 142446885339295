import { useState } from 'react';
import * as UpChunk from '@mux/upchunk';
import { message } from 'antd';

export default () => {
  const [progress, setProgress] = useState(null);
  const [finished, setFinished] = useState(false);

  const uploadVideo = (result, file, push) => {
    if (result?.data?.createOrUpdateJourney?.upload?.url) {
      const upload = UpChunk.createUpload({
        endpoint: result?.data?.createOrUpdateJourney?.upload?.url,
        file,
        chunkSize: 5120,
      });

      upload.on('progress', (value) => {
        setProgress(value.detail);
      });

      upload.on('success', () => {
        message.destroy();
        setFinished(true);
        message.success('Completed');
        if (typeof push === 'function') push();
      });
    } else {
      message.destroy();
      setFinished(true);
      message.success('Completed');
      if (typeof push === 'function') push();
    }
  };

  return [
    progress,
    uploadVideo,
    finished,
  ];
};
