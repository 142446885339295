import React from 'react';

import './styles.less';

const Spinner = ({children, loading, background = 'F4F5F9', className = ''}) => {
  if (children && loading !== void 0 && !loading) return <div>{children}</div>; // Wrap with div for get rid of re-render of the children

  const spinner = (
    <div className={`spinner${children ? ' spinner--absolute' : ''} ${className && !children ? className : ''}`}>
      <div className="spinner__item"/>
      <div className="spinner__item"/>
      <div className="spinner__item"/>
      <div className="spinner__item"/>
      <div className="spinner__item"/>
      <div className="spinner__item"/>
      <div className="spinner__item"/>
      <div className="spinner__item"/>
    </div>
  );

  if (children) {
    return (
      <div
        className={`spinner__container ${className ? className : ''}`}
        {...(background
          ? {style: {backgroundColor: `#${background}`}}
          : {})}
      >
        <div className="spinner__hidden-content">
          {children}
        </div>
        {spinner}
      </div>
    );
  }

  return spinner;
};

export default Spinner;
