import React, {useEffect, useState} from 'react';
import {
  Button, message, Form, Input, Select,
} from 'antd';
import {useFormik} from 'formik';
import {useMutation} from '@apollo/client';
import {modal} from '../../utils/modal';
import ModalScrollable from '../../components/ModalScrollable/ModalScrollable';
import {EDIT_BOOK} from "../mutations";
import { categories } from '../AdminDashboardTabs/BookTable';

const EditBookForm = ({id, push, book}) => {
  const [editBook] = useMutation(EDIT_BOOK);
  const [file, setFile] = useState('')

  const onChange = ({target: {validity, files: [file]}}) => {
    validity.valid && formik.setFieldValue('file', file)
    setFile(URL.createObjectURL(file))
  };

  useEffect(() => {
    if (book.avatar) setFile(book.avatar)
  }, [book.avatar])

  const initialValues = {
    initialValues: {
      title: book.title,
      category: book.category,
      capsuleTopic: book.capsuleTopic,
      author: book.author,
      link: book.link,
      avatar: book.avatar,
      file: null
    },
  }

  const onSubmit = async (values) => {
    message.loading({
      duration: 0,
      content: 'Uploading your changes',
      key: 'loading',
    });

    await editBook({
      variables: {
        id,
        title: values.title || book.title,
        category: values.category || book.category,
        capsuleTopic: values.capsuleTopic || book.capsuleTopic,
        author: values.author || book.author,
        link: values.link || book.link,
        avatar: values.avatar || book.avatar,
        file: values.file
      },
    });
    push()
    message.destroy('loading');
    message.success('Completed');
  }


  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  return (
    <Form layout="vertical" onFinish={formik.handleSubmit}>

      <Form.Item label="Book Title" name="title">
        <Input
          name="title"
          type="title"
          required
          onChange={formik.handleChange}
          value={formik.values.title}
          defaultValue={book.title}
        />
      </Form.Item>

      <Form.Item name="Category" label="Category">
        <Select
          value={formik.values.category}
          defaultValue={book.category}
          onChange={(e) => formik.setFieldValue('category', e)}
        >
          {categories.map(item=>(
            <Select.Option value={item}>{item}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Capsule Topic" name="capsuleTopic">
        <Input
          name="capsuleTopic"
          type="capsuleTopic"
          onChange={formik.handleChange}
          value={formik.values.capsuleTopic}
          defaultValue={book.capsuleTopic}
        />
      </Form.Item>
      <Form.Item label="Author" name="author">
        <Input
          name="author"
          type="author"
          required
          onChange={formik.handleChange}
          value={formik.values.author}
          defaultValue={book.author}
        />
      </Form.Item>

      <Form.Item label="Link" name="link">
        <Input
          name="link"
          type="link"
          required
          onChange={formik.handleChange}
          value={formik.values.link}
          defaultValue={book.link}
        />
      </Form.Item>
      <Form.Item label="Avatar Link" name="avatar">
        <Input
          name="avatar"
          type="avatar"
          onChange={formik.handleChange}
          value={formik.values.avatar}
          defaultValue={book.avatar}
        />
      </Form.Item>

      <Form.Item>
        <div className="book__avatar-block">
          {(file || formik.values.avatar) &&
            <img className="book__avatar" src={file || formik.values.avatar} alt="avatar" width="100%" height="50%"/>}
          <input
            type="file"
            name="file"
            accept="image/*, text/plain, .pdf, .csv, .xls, .xlsx, .doc, .docx"
            onChange={onChange}
          />
        </div>

      </Form.Item>


      <Form.Item>
        <Button
          disabled={formik.isSubmitting}
          type="primary"
          htmlType="submit"
        >
          Save Book
        </Button>
      </Form.Item>
    </Form>
  );
};

const EditBookModal = ({bookQuery, id, book}) => {

  const closeModal = () => {
    modal.hideModal();
    bookQuery.refetch();
  };

  return (
    <ModalScrollable title="Edit Book">
      <EditBookForm push={closeModal} id={id} book={book}/>
    </ModalScrollable>
  );
}

export default EditBookModal;
