import React from 'react';
import { Button } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { HELPipedia_ORANGE } from 'styles/colors';

const CapsuleCoachCard = ({ data }) => {
  const history = useHistory();
  const viewCoachProfile = () => {
    history.push(`/coach/${data?.firstName}-${data?.lastName}-${data?.userInt}`);
  };
  const isHelpipedia= useLocation().pathname.includes("helpipedia")
  return (
    <div className="capsule__coachCard">
      <div className="capsule__coachCardInner">
        <div className="capsule__coachCardHeader"  style={{backgroundColor:isHelpipedia?HELPipedia_ORANGE:''}}>
          <img className="capsule__coachCardImg" src={data?.avatarUrl} alt="coach" />
          <p className="capsule__coachCardName" >{data?.firstName + ' ' + data?.lastName}</p>
        </div>
        <div className="capsule__coachCardDescription">
          <p className="capsule__coachCardTitle" style={{color:isHelpipedia?'black':''}}>About {data?.firstName}</p>
          <p className="capsule__bio" style={{color:isHelpipedia?'black':''}}>{data?.bio}</p>
          {data?.coachingStyle && (
            <>
              <p className="capsule__coachCardTitle"  style={{color:isHelpipedia?'black':''}}>Coaching Style</p>
              <p style={{color:isHelpipedia?'black':''}}>{data?.coachingStyle}</p>
            </>
          )}
          {data?.certifications && data?.certifications.length > 0 && (
            <>
              <p className="capsule__coachCardTitle" style={{color:isHelpipedia?'black':''}}>Certifications</p>
              {data?.certifications.map((cert) => (
                <p key={cert} style={{color:isHelpipedia?'black':''}}>{cert}</p>
              ))}
            </>
          )}
          <Button className="capsule__coachProfileBtn" onClick={() => viewCoachProfile()}>
            View Coach's Profile
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CapsuleCoachCard;
