import React from 'react';
import { Link } from 'react-router-dom';
import './styles.less';

const SkillTags = ({ otherTags }) => {
  const tags = [
    {
      title: 'ADAPTABILITY',
      link: 'adaptability',
    },
    {
      title: 'COMMUNICATION',
      link: 'communication',
    },
    {
      title: 'CRITICAL THINKING AND PROBLEM SOLVING',
      link: 'problem_solving',
    },
    {
      title: 'DECISION MAKING',
      // link: 'leadership',
      link: 'decision_making',
    },
    {
      title: 'EMPATHY/EMOTIONAL INTELLIGENCE',
      link: 'eq',
    },
    
    
    {
      title: 'ENGAGEMENT',
      // link: 'relationship',
      link: 'engagement',
    },

    {
      title: 'PROFESSIONALISM',
      // link: 'attention_to_detail',
      link: 'professionalism',
    },
    {
      title: 'SELF MANAGEMENT',
      // link: 'time_management',
      link: 'self_management',
    },
    {
      title: 'TEAMWORK',
      link: 'teamwork',
    },
    
    
    
    
    
    /*{
      title: 'CREATIVITY',
      link: 'creativity',
    },
    {
      title: 'WORK ETHIC',
      link: 'work_ethic',
    },
    {
      title: 'RELATIONSHIPS',
      link: 'relationship',
    },
    {
      title: 'TIME MANAGEMENT',
      link: 'time_management',
    },
    {
      title: 'LEADERSHIP',
      link: 'leadership',
    },
    {
      title: 'ATTENTION TO DETAIL',
      link: 'attention_to_detail',
    },
    {
      title: 'RESILIENCE',
      link: 'resilience',
    },*/
    
  ];

  let tagsJSX;
  if (otherTags) {
    tagsJSX = otherTags.map((tag) => {
      return (
        <Link key={tag} to={`/search/${tag}`} className="skillTags__link">
          {tag}
        </Link>
      );
    });
  } else {
    tagsJSX = tags.map((tag) => {
      return (
        <Link key={tag.title} to={`/search/${tag.link}`} className="skillTags__link">
          {tag.title}
        </Link>
      );
    });
  }

  return <div className="skillTags__container">{tagsJSX}</div>;
};

export default SkillTags;