import React from 'react';
import { Popover } from 'antd';
import ButtonLink from '../../components/Buttons/ButtonLink';

const BookCard = ({ title, author, link, avatar }) => {
  const content = <img src={avatar} alt="book" className="bookCard__avatarHover" />;
  return (
    <div className="bookCard">
      <Popover content={content} placement="right">
        <div className="bookCard__avatarContainer" style={{ backgroundImage: `url(${avatar})` }} />
      </Popover>
      <div className="bookCard__info">
        <p className="bookCard__title">{title}</p>
        <p className="bookCard__author">by {author}</p>
      </div>
      <ButtonLink link={link} title="buy it now" />
    </div>
  );
};

export default BookCard;
