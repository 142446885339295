import React from 'react';
import { Link } from 'react-router-dom';

const JabordCapsule = ({ image, title, video }) => {
  return (
    <div className="jabordCapsule">
      <div className="jabordCapsule__imageContainer">
        <img src={image} alt="" className="jabordCapsule__image" />
      </div>
      <div className="jabordCapsule__inner">
        <div className="jabordCapsule__title">
          <Link to={`/jabord/video/${video}`} className="jabordCapsule__link">
            {title}
          </Link>
        </div>
        <div className="jabordCapsule__footer">
          <div className="jabordCapsule__round">
            <p>J</p>
          </div>
          <div className="jabordCapsule__info">
            <p>Jäbôrd</p>
            <p>1 videos &bull; 2min</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JabordCapsule;
