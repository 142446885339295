import React from 'react';
import {useLocation} from "react-router-dom";
import {PageLayout} from "../../components";
import {StandardMarginDiv} from "../../components";
import ReactPlayer from 'react-player';
import {Link} from "react-router-dom"

import "./styles.less";

const JabordVideoPage = () => {
  const location = useLocation();
  const index = location.pathname[location.pathname.length-1];
  const links = [
    "https://stream.mux.com/4kCY5q01iKgtLy4X00eUA34SMXUm8j2KdDN4jxTE9Rcks.m3u8",
    "https://stream.mux.com/ARI7MZ02CuunjCXgN7759RFi9ppIlTkF8WZ4tofjDsfs.m3u8",
    "https://youtu.be/ers1d2EOLM4"
  ];

  return (
    <PageLayout>
      <StandardMarginDiv>
        <div className="jabordVideo">
          <div className="jabordVideo__inner">
            <ReactPlayer
              controls
              height="50vh"
              url={links[index - 1]}
            />
          </div>
          <div className="jabordVideo__links">
            <Link
              to="/jabord/video/1"
              style={index == 1 ? {color: "#001f5f"} : null}
              className="jabordVideo__link">Up Next</Link>
            <Link
              to="/jabord/video/2"
              style={index == 2 ? {color: "#001f5f"} : null}
              className="jabordVideo__link">Interactive Resume</Link>
            <Link
              to="/jabord/video/3"
              style={index == 3 ? {color: "#001f5f"} : null}
              className="jabordVideo__link">Your Intro Video</Link>
          </div>
        </div>
      </StandardMarginDiv>
    </PageLayout>
  );
};

export default JabordVideoPage;
