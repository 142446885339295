import React from 'react';

import { Button } from 'components';

import './styles.less';

function CoachCard({
  firstName,
  lastName,
  avatarUrl,
  bio,
  coachingStyle,
  calendlyUserName,
  onViewProfileClick,
}) {
  return (
    <div className="coach_card">
      <div className="coach_card--header">
        <img src={avatarUrl} alt="coach_photo" className="coach_card--image" />
        <div className="coach_card--name">
          <p>{`${firstName} ${lastName}`}</p>
        </div>
      </div>

      <div className="coach_card--body">
        <div className="coach_card--title">About {firstName}</div>
        <div className="coach_card--description">{bio}</div>

        <div className="coach_card--title">Coaching Style</div>
        <div className="coach_card--description">{coachingStyle}</div>
      </div>

      <div className={`coach_card--action${calendlyUserName ? ' coach_card--action-space-between' : ''}`}>
        <Button
          className="coach_card--button"
          onClick={onViewProfileClick}
        >
          Experts profile
        </Button>
        {
          calendlyUserName && (
            <a
              href={`https://calendly.com/${calendlyUserName}`}
              className="button blue coach_card--button"
              target="_blank"
            >
              Book a meeting
            </a>
          )
        }
      </div>
    </div>
  );
}

export default CoachCard;
