// @ts-nocheck
import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Radio } from 'antd';
import { Formik } from 'formik';
import { StandardMarginDiv, PageLayout } from '../components';

import { STEERUS_GREEN } from '../styles/colors';
import { useMediaQuery } from 'react-responsive';

import './styles.less';

const GET_SKILLCHECK = gql`
  query getSkillCheck($capsuleContentId: ID, $index: Int) {
    getSkillCheck(capsuleContentId: $capsuleContentId, index: $index)
  }
`;

const CHECK_ANSWER = gql`
  mutation checkSkillCheckAnswer($capsuleContentId: ID, $index: Int, $answer: String) {
    checkSkillCheckAnswer(capsuleContentId: $capsuleContentId, index: $index, answer: $answer)
  }
`;

const CreateCapsule = () => {
  const history = useHistory();
  const [index, setIndex] = useState(0);
  const params: any = useParams();
  const {
    loading,
    data: skillCheckData,
    refetch,
  } = useQuery(GET_SKILLCHECK, {
    variables: {
      index,
      capsuleContentId: params?.capsuleContentId,
    },
  });
  const [checkAnswer, { data: checkAnswerData }] = useMutation(CHECK_ANSWER);
  const [didCheckAnswer, setDidCheckAnswer] = useState(false);

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-width: 800px)',
  });

  if (loading) {
    return null;
  }
  if (!skillCheckData.getSkillCheck.QuestionText) {
    return (
      <PageLayout>
        <StandardMarginDiv>
          <p className="heading"> Congrats! You finished the skill check! Great job </p>
          <Button onClick={() => history.push('/academy')}>Back to the Soft Skills Academy</Button>
        </StandardMarginDiv>
      </PageLayout>
    );
  }
  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{}}
      validate={() => ({})}
      onSubmit={async (values) => {
        await checkAnswer({
          variables: {
            index,
            answer: values.answer,
            capsuleContentId: params?.capsuleContentId,
          },
        });
      }}
    >
      {({ setFieldValue, handleSubmit }) => (
        <PageLayout>
          <StandardMarginDiv>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p className="heading skillcheck__title"> {skillCheckData.getSkillCheck.QuestionText} </p>
              <Radio.Group onChange={(e) => setFieldValue('answer', e.target.value)}>
                {skillCheckData.getSkillCheck.answers.map((question: any) => (
                  <div style={{ marginTop: 50 }}>
                    <Radio style={{ fontSize: isTabletOrMobileDevice ? '16px' : '20px' }} value={question}>
                      {question}
                    </Radio>
                  </div>
                ))}
              </Radio.Group>
              {!didCheckAnswer && (
                <Button
                  onClick={() => {
                    setDidCheckAnswer(true);
                    handleSubmit();
                  }}
                  style={{
                    backgroundColor: STEERUS_GREEN,
                    color: 'white',
                    marginTop: 40,
                  }}
                >
                  {' '}
                  Check Answer{' '}
                </Button>
              )}
              {didCheckAnswer && checkAnswerData?.checkSkillCheckAnswer && (
                <div style={{ marginTop: '10vh' }}>
                  <p className="heading"> {checkAnswerData?.checkSkillCheckAnswer?.correct ? 'You got it right' : "That wasn't the correct answer."}</p>
                  <p> {checkAnswerData?.checkSkillCheckAnswer?.explanation} </p>
                  <Button
                    onClick={() => {
                      refetch({
                        index: index + 1,
                        capsuleContentId: params?.capsuleContentId,
                      });
                      setDidCheckAnswer(false);
                      setIndex(index + 1);
                    }}
                  >
                    {' '}
                    Next question
                  </Button>
                </div>
              )}
            </div>
          </StandardMarginDiv>
        </PageLayout>
      )}
    </Formik>
  );
};

export default CreateCapsule;
