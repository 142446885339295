import React from 'react'

const communication = (
  <iframe src="https://steerus.io/wp-admin/admin-ajax.php?action=h5p_embed&id=16"
          width="1158"
          height="1265"
          frameBorder="0"
          allowFullScreen="allowfullscreen"
          title="So you think you are a communicator!"/>
);
const teamwork = (
  <iframe src="https://steerus.io/wp-admin/admin-ajax.php?action=h5p_embed&id=2"
          width="1158"
          height="1220"
          frameBorder="0"
          allowFullScreen="allowfullscreen"
          title="Be part of a winning team!"/>
);
const adaptability = (
  <iframe src="https://steerus.io/wp-admin/admin-ajax.php?action=h5p_embed&id=7"
          width="1158"
          height="1028"
          frameBorder="0"
          allowFullScreen="allowfullscreen"
          title="How well can you adapt?"/>
);
const problem_solving = (
  <iframe src="https://steerus.io/wp-admin/admin-ajax.php?action=h5p_embed&id=5"
          width="1158"
          height="1006"
          frameBorder="0"
          allowFullScreen="allowfullscreen"
          title="So you are good at solving problems? Let`s see"/>
);

const work_ethic = (
  <iframe src="https://steerus.io/wp-admin/admin-ajax.php?action=h5p_embed&id=9"
          width="1158"
          height="1011"
          frameBorder="0"
          allowFullScreen="allowfullscreen"
          title="Work ethic is the best ethic"/>
);

const creativity = (
  <iframe src="https://steerus.io/wp-admin/admin-ajax.php?action=h5p_embed&id=6"
          width="1158"
          height="1840"
          frameBorder="0"
          allowFullScreen="allowfullscreen"
          title=":кисточка: Let`s get creative "/>
);

const relationship = (
  <iframe src="https://steerus.io/wp-admin/admin-ajax.php?action=h5p_embed&id=10"
          width="1158"
          height="1679"
          frameBorder="0"
          allowFullScreen="allowfullscreen"
          title="Are you a people person?"/>
);

const time_management = (
  <iframe src="https://steerus.io/wp-admin/admin-ajax.php?action=h5p_embed&id=11"
          width="1158"
          height="1204"
          frameBorder="0"
          allowFullScreen="allowfullscreen"
          title="Tik-tok...Time management!"/>
);

const leadership = (
  <iframe src="https://steerus.io/wp-admin/admin-ajax.php?action=h5p_embed&id=12"
          width="1158"
          height="889"
          frameBorder="0"
          allowFullScreen="allowfullscreen"
          title="Are you a leader?"/>
);

const attention_to_detail = (
  <iframe src="https://steerus.io/wp-admin/admin-ajax.php?action=h5p_embed&id=13"
          width="1158"
          height="942"
          frameBorder="0"
          allowFullScreen="allowfullscreen"
          title="Do you have an eye for detail?"/>
);

const resilience = (
  <iframe src="https://steerus.io/wp-admin/admin-ajax.php?action=h5p_embed&id=14"
          width="1158"
          height="1010"
          frameBorder="0"
          allowFullScreen="allowfullscreen"
          title="Are you resilient?"/>
);

const eq = (
  <iframe src="https://steerus.io/wp-admin/admin-ajax.php?action=h5p_embed&id=15"
          width="1158"
          height="1276"
          frameBorder="0"
          allowFullScreen="allowfullscreen"
          title="Emotional intelligence matters"/>
);

export const quizzes = {
  communication,
  teamwork,
  adaptability,
  problem_solving,
  work_ethic,
  creativity,
  relationship,
  time_management,
  leadership,
  attention_to_detail,
  resilience,
  eq
}
