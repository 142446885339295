import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory, Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Input } from 'antd';
import { Search } from 'react-feather';
import SkillTags from '../components/SkillTags/SkillTags';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import { PageLayout, SnippetCard, PlaylistCard, CapsuleCard, JourneyCard, Button, HomeList, StandardMarginDiv } from '../components';
import { GET_ALL_PLAYLISTS, GET_RECOMMENDED_CAPSULES, GET_TOP_SUBCAPSULES_THIS_WEEK, GET_TOP_SNIPPETS_THIS_WEEK, GET_RECOMMENDED_JOURNEYS } from './query';
import ImageHelp from '../assets/images/help.png';

import './styles.less';

function Home({ scrollPosition }) {
  const history = useHistory();
  const [input, setInput] = useState(null);

  const {
    data: journeyData,
    loading: journeyLoading,
    error: journeyError,
  } = useQuery(GET_RECOMMENDED_JOURNEYS, {
    fetchPolicy: 'network-only',
    variables:{source:"steerus"}
  });
  const {
    data: recommendedPlaylists,
    loading: recommendedPlaylistsLoading,
    error: recommendedPlaylistsError,
  } = useQuery(GET_ALL_PLAYLISTS, {
    fetchPolicy: 'network-only',
    variables: { flag: "true",source:"steerus" }
  });
  const {
    data: recommendedCapsulesData,
    loading: recommendedCapsulesLoading,
    error: recommendedCapsulesError,
  } = useQuery(GET_RECOMMENDED_CAPSULES, {
    fetchPolicy: 'network-only',
  });

  const {
    data: topCapsulesData,
    loading: topCapsulesLoading,
    error: topCapsulesError,
  } = useQuery(GET_TOP_SUBCAPSULES_THIS_WEEK, {
    fetchPolicy: 'network-only',
  });
  const {
    data: topSnippetsData,
    loading: topSnippetsLoading,
    error: topSnippetsError,
  } = useQuery(GET_TOP_SNIPPETS_THIS_WEEK, {
    fetchPolicy: 'network-only',
  });

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      history.push(`/search/${input ?? 'all'}`);
    }
  };

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-width: 700px)',
  });
  const customText = `Learn critical workplace success skills like communication,
emotional intelligence, critical thinking – and more!`
  const professional = '3'

  useEffect(()=>{
    console.log("recommendedPlaylists?.getAllPlaylists",recommendedPlaylists?.getAllPlaylists);
  },[recommendedPlaylistsLoading])
  return (
    <PageLayout>
      <div>
        {!isTabletOrMobileDevice && (
          <StandardMarginDiv padding="2vw 0">
            <div className="home">
              <div className="home--search_box">
                <Search size="48" color="#001F5F" />
                <Input
                  className="home--search"
                  onChange={(e) => setInput(e.target.value)}
                  bordered={false}
                  onKeyDown={handleKeyDown}
                  placeholder="Find popular topics"
                />
                <Link to={`/search/${input ?? 'all'}`} className="button green">
                  {input ? 'Search' : 'View All Academy Content'}
                </Link>
              </div>
              <div className="home--header_image" />
            </div>
          </StandardMarginDiv>
        )}

        <div className="home--body">
          <div className="home--intro home--intro-top">
            <p className="heading">{customText}</p>

            <div className="home--welcome">
              <h1>Welcome to the STEERus Success Academy!</h1>
            </div>
          </div>

          <div style={{ marginBottom: '5vh' }}>
            <div className="home__steps">
              <div className="home__steps-item">
                <h3>STEP 1</h3>
                <p>Begin by seeing how your workplace success skills compare to the skill level of your peers. UP Assessment stands for Unlock Potential - these 9 workplace success skills help people move UP in their careers.</p>
              </div>

              <Link className="home__link-assessment" to="/assessments/4">
                Take the UP Assessment
              </Link>

              <div className="home__steps-item">
                <h3>STEP 2</h3>
                <p>After you complete the UP Assessment, check your email for your
                  report: it takes a few minutes to generate.</p>
              </div>
              <div className="home__steps-item">
                <h3>STEP 3</h3>
                <p>Review your report. Which skills do you need to improve? Click the green buttons
                  below to access the lessons you need to build those skills.</p>
              </div>
              <SkillTags />
              <div className="home__steps-item">
                <h3>STEP 4</h3>
                <p>Need more help? Consider booking time with one of our coaches, mentors, or other
                  professionals.</p>
              </div>

            </div>
            <div className="home__steps-item">

              <Link to={{
                pathname: "/marketplace",
                state: '3'
              }}  className="home__link-marketplace">
                CONSULT WITH A
                COACH
              </Link>
            </div>

          </div>

          {/* <p className="heading">Get matched to prospective employers. Take a skills assessment. Check out our Marketplace tab.</p> */}
          {/* <div className="home--intro"> */}
            {/* <p className="heading">Have a look at the content below.</p> */}

            {/* <p className="heading">And don’t forget to head over to the Marketplace for more goodies!</p> */}
          {/* </div> */}
          {/* <HomeList data={recommendedCapsulesData?.getRecommendedCapsules} loading={recommendedCapsulesLoading} error={recommendedCapsulesError}> */}
            {/* {(capsule) => (
              <CapsuleCard
                scrollPosition={scrollPosition}
                key={capsule.id}
                tag={new Date(capsule.createdAt + 12096e5) > new Date()}
                className="home--card"
                {...capsule}
              />
            )} */}
          {/* </HomeList> */}
          <div className="home--action_block">
            <p className="heading">Play along! Learn with these recommended playlists: </p>
          </div>
          <HomeList
            className="home_list-scroll"
            data={recommendedPlaylists?.getAllPlaylists}
            loading={recommendedPlaylistsLoading}
            error={recommendedPlaylistsError}
          >
            {(playlist) => <PlaylistCard playlist={playlist} isCompleted={playlist.isCollected?playlist.isCollected:false} key={playlist.id} id={playlist.id} title={playlist.title} />}
          </HomeList>

          <div className="home--action_block">
            <p className="heading">Take STEERus Journeys</p>
            <Link to="/search/journeys/all" className="button green">
              View All Journeys
            </Link>
          </div>
          {console.log(journeyData,"journeyData")}
          <HomeList data={journeyData?.getRecommendedJourneys} loading={journeyLoading} error={journeyError}>
            {(journey) => (
              <JourneyCard
              journey={journey}
                scrollPosition={scrollPosition}
                key={journey.id}
                id={journey.id}
                title={journey.title}
                imageUrl={journey.imageUrl}
                price={journey.price}
                time={journey.duration}
                status={journey.status}
                views={journey.views}
                description={{
                  videos: journey.videos,
                  snippets: journey.snippets,
                  capsules: journey.capsules,
                  skillChecks: journey.skillChecks,
                  selfReflections: journey.selfReflections,
                  workbooks: journey.workbooks,
                }}
                className="home--card"
              />
            )}
          </HomeList>

          <div className="home--intro">
            <p className="heading"> Get quick answers to tough questions.</p>
            <div className="home--action_block">
              <p className="heading">Here’s our Top 3 Snippets this week.</p>
              <Link to="/search/snippets/all" className="button green">
                View all snippets
              </Link>
            </div>
          </div>
          <HomeList data={topSnippetsData?.getTopSnippetsThisWeek} loading={topSnippetsLoading} error={topSnippetsError}>
            {(snippet) => (
              <SnippetCard
                key={snippet.id}
                id={snippet.id}
                coach={snippet.coach?.userProfile}
                title={snippet.title}
                time={snippet?.video?.length}
                views={snippet.views}
              />
            )}
          </HomeList>

          <div className="home--action_block">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p className="heading" style={{ margin: 0, padding: 0 }}>
                {/* Wondering what other people are learning about? */}
              </p>
              <p className="heading" style={{ padding: 0, margin: 0 }}>
                Here are the Top 3 Capsules:
              </p>
            </div>
            <Link to="/search/capsules/all" className="button green">
              View all capsules
            </Link>
          </div>
          <HomeList data={topCapsulesData?.getTopSubCapsulesThisWeek} loading={topCapsulesLoading} error={topCapsulesError}>
            {(capsule) => (
              <CapsuleCard
                scrollPosition={scrollPosition}
                key={capsule.id}
                tag={new Date(capsule.createdAt + 12096e5) > new Date()}
                className="home--card"
                {...capsule}
              />
            )}
          </HomeList>

          <div className="home--footer">
            <div className="home--info_box join">
              <p> Join our movement </p>
              <p> Apply to Join Our Network of Professionals </p>
              <a rel="noreferrer noopener" href="https://docs.google.com/forms/d/1Q7GLiEsGF-wZV5JKx4Go3gOEkgjhrBllHdB-0extaE8/" target="_blank">
                <Button color="white">Join STEERus</Button>
              </a>
            </div>

            <div className="home--info_box feedback">
              <p> Give us feedback! </p>
              <p> Your feedback helps us become stronger </p>
              <a href={"https://docs.google.com/forms/d/1x8OXT4uxnEmosNhVpDOXhf10jpn5bg887HsCshAP1PM/edit"} rel="noreferrer noopener" target="_blank" className="button white">
                Give feedback
              </a>
            </div>
            <div className="home--info_box join">
              <p> Recommend a book! </p>
              <p> Have a great book in mind? </p>
              <a href={"https://forms.gle/HhaXRpgJndCtw6Vj9"} rel="noreferrer noopener" target="_blank" className="button white">
                Recommend
              </a>
            </div>

            <div className="home--info_box ask">
              <p> Ask for help! </p>
              <p> We will respond </p>
              <a href={'https://docs.google.com/forms/d/124-RMtkN_m6MbFyokbdX38z3h77axbOyfkSt_sS0j3A/edit'} rel="noreferrer noopener" target="_blank" className="button white">
                Ask for help
              </a>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default trackWindowScroll(Home);