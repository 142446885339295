import React, { useState } from 'react';

import { Popover } from 'antd';
import CalendlyButton from './CalendlyButton';
import SimpleButton from '../../components/Buttons/SimpleButton';
import { useLocation } from 'react-router';
import { HELPipedia_ORANGE, STEERUS_GREEN } from 'styles/colors';

const CoachCard = ({ avatar, name, info, rate, link, coachId ,currentUser}) => {
  const [showInfo, setShowInfo] = useState(false);
  const isCalendlyEvent = (e) => {
    return e.data.event && e.data.event.indexOf('calendly') === 0;
  };
  window.addEventListener('message', function(e) {
    if (isCalendlyEvent(e)) {
      if (e.data.event === 'calendly.event_scheduled') {
        console.log({
          coachId: coachId,
          coachName: name,
          booked: true,
          user: currentUser,
        });
      }
    }
  });
  let infoText;
  if (showInfo) {
    infoText = info;
  } else {
    infoText = info && info.slice(0, 150);
  }

  const freeRate = rate === 'X' ? `$${rate}/h` : !parseInt(rate) ? rate : `$${rate}/h`;
  const isHelpipedia= useLocation().pathname.includes("helpipedia")
  console.log("isHelpipedia",isHelpipedia);
  const content = <img src={avatar} alt='book' className='bookCard__avatarHover' />;
  const btn =
    link === '#' ? (
      <SimpleButton title='coming soon' click={() => console.log('coming soon')} />
    ) : link.includes('calendly') ? (
      <CalendlyButton url={link} />
    ) : (
      <a href={link}  className={`${isHelpipedia?'simpleButtonHelpipedia':'simpleButton'}`} target='_blank'>
        Book Now
      </a>
    );


  return (
    <div className='coachCard'>
      <Popover content={content} placement='right'>
        <div className='coachCard__avatarContainer' style={{ backgroundImage: `url(${avatar})`, backgroundPosition: "center" }} />
      </Popover>
      <div className='coachCard__info'>
        <h4 className='coachCard__name'>{name}</h4>
        <p className='coachCard__description'>
          {infoText}
          {info && info.length > 150 && !showInfo ? <span>...</span> : null}
          {info && info.length > 150 ? (
            <span onClick={() => setShowInfo(!showInfo)} className='coachCard__more' style={{color:isHelpipedia?HELPipedia_ORANGE:STEERUS_GREEN}}>
              {showInfo ? ' less' : ' more'}
            </span>
          ) : null}
        </p>
        {/* <p className='coachCard__description'>RATE = {freeRate} </p> */}
      </div>
      {currentUser?.currentUser ? btn : null}
    </div>
  );
};

export default CoachCard;
