const STEERUS_GREEN = '#92D050';
const HELPipedia_ORANGE = '#DD8047';
const HELPipedia_GREEN = '#7BA79D';
const HELPipedia_ORANGE_ALPHA = '#80DD8047';
const STEERUS_GREEN_ALPHA = '#8092D050';
const STEERUS_GREEN_GRADIENT_ONE = 'linear-gradient(180deg, #61FF9B -20.96%, #52DC84 -20.95%, #92D050 53.06%)';
const STEERUS_BLUE = '#001F5F';
const STEERUS_BLUE_GRADIENT = 'linear-gradient(180deg, #4B86FF -26.2%, #001F5F 52.41%)';

export {
  STEERUS_BLUE,
  HELPipedia_GREEN,
  HELPipedia_ORANGE,
  STEERUS_GREEN,
  STEERUS_GREEN_GRADIENT_ONE,
  STEERUS_BLUE_GRADIENT,
  STEERUS_GREEN_ALPHA,
  HELPipedia_ORANGE_ALPHA
};
