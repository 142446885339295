import React from 'react';
import { useMediaQuery } from 'react-responsive';
import HeroHalfItems from './HeroHalfItems';


const HeroHalfPartner = ({ leftComponents, college, showSignup, rightColor, showRight, title = '', paragraph_one = '', paragraph_two = '', paragraph_free = '', video = '', paragraph_four = '', light }: any) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-width: 700px)',
  });

  if (college) {
    return <HeroHalfItems video={video} light={light} paragraph_four={paragraph_four} paragraph_one={paragraph_one} paragraph_two={paragraph_two} paragraph_free={paragraph_free} title={title} leftComponents={leftComponents} college={college} showSignup={showSignup} rightColor={rightColor} />
  }

  if (isTabletOrMobileDevice) {
    return <div style={{ margin: '20px' }}>{leftComponents}</div>;
  }

  return <HeroHalfItems leftComponents={leftComponents} college={college} rightColor={rightColor} showRight={showRight}></HeroHalfItems>
};

export default HeroHalfPartner;