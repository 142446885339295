import React from 'react';
import { useQuery } from '@apollo/client';
import InfoCard from './InfoCard';
import PaymentForm from './PaymentForm';
import { GET_PAYMENT_INTENT_FOR_ASSESSMENT } from './query';
import Spinner from '../Spinner';
import './styles.less';

function PaymentFormForAssessment({ price, onSuccess, email,setModal }) {
  const { data, loading } = useQuery(GET_PAYMENT_INTENT_FOR_ASSESSMENT, {
    variables: { price, email },
  });
console.log(data,"payment");
  const content = loading ? (
    <InfoCard />
  ) : (
    <PaymentForm
      paymentData={data}
      price={price}
      clientSecret={data?.getPaymentIntentForAssessment?.clientSecret}
      onSuccess={onSuccess}
      email={email}
      setModal={setModal}
      itemToPurchase="Purchase to unlock Report"
    />
  );

  return <Spinner loading={loading}>{content}</Spinner>;
}

export default PaymentFormForAssessment;