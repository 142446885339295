import React, { useState } from 'react';
import ModalScrollable from '../components/ModalScrollable/ModalScrollable';
import { Button, Form, Input, message, Spin } from 'antd';
import { useFormik } from 'formik';
import { gql, useMutation } from '@apollo/client';
import './styles.less';

const LOGIN = gql`
  mutation login($email: String, $password: String) {
    login(email: $email, password: $password) {
      tokens {
        accessToken
        refreshToken
      }
      error {
        field
        message
      }
      user {
        id
        confirmedEmail
        role
      }
    }
  }
`;

const LoginModal = ({ onClose, onCloseNoUser, refetch }) => {
  const [login, { loading }] = useMutation(LOGIN);
  const [isLoading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const result = await login({
          variables: values,
        });
        if (!result?.data?.login?.user?.confirmedEmail) {
        } else {
          if (result.data.login.error) {
            if (result.data.login.error.message === 'email') {
              message.error('Your email address or password is incorrect');
            } else if (result.data.login.error.message === 'password') {
              message.error('Your email address or password is incorrect');
            } else {
              message.error('There was an error. Try again later');
            }
          } else {
            localStorage.setItem('token', result.data.login.tokens.accessToken);
            localStorage.setItem('refreshToken', result?.data?.login?.tokens?.refreshToken);
            refetch();
            onClose();
          }
        }
      } catch (e) {
        setLoading(false);
      } finally {
        setTimeout(() => setLoading(false), 3000);
      }
    },
  });

  const defaultMargin = {
    marginTop: '22px',
  };
  const inputStyles = {
    width: '100%',
  };

  return (
    <div className="news login-marketplace">
      <ModalScrollable title="Login" className="login-marketplace__modal" onClose={onCloseNoUser}>
        <div className="login-marketplace__content">
          <Spin size="large" spinning={loading || isLoading}>
            <Form layout="vertical" onFinish={formik.handleSubmit}>
              <Form.Item label="Email" name="Email" style={defaultMargin}>
                <Input
                  id="email"
                  name="email"
                  label="email"
                  type="email"
                  required
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  style={inputStyles}
                />
              </Form.Item>

              <Form.Item label="Password" name="Password" style={defaultMargin}>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  required
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  style={inputStyles}
                />
              </Form.Item>

              <Form.Item style={defaultMargin}>
                <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                  Log in
                </Button>
              </Form.Item>

              <Button style={{ width: '100%', marginBottom: '20px' }} type="primary" htmlType="submit" onClick={() => history.push('/signup')}>
                Register now
              </Button>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit" onClick={() => history.push('/forgot-password')}>
                Forgot password?
              </Button>
            </Form>
          </Spin>
        </div>
      </ModalScrollable>
    </div>
  );
};

export default LoginModal;
