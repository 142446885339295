import React, {useState} from 'react';
import PaymentForm from "./PaymentForm";
import {Radio} from 'antd';
import {useMutation, useQuery} from '@apollo/client';
import {GET_INTENT_PAYMENT_BY_USER, BUY_SUBSCRIPTION} from "./query";
import InfoCard from "./InfoCard";
import Spinner from "../Spinner";
import './styles.less';

const SubscriptionPayment = () => {
  const [subscription, setSubscription] = useState('month');
  const {loading: monthLoading, data: monthSubscription} = useQuery(GET_INTENT_PAYMENT_BY_USER, {variables: {period: 'month'}});
  const {loading: yearLoading, data: yearSubscription} = useQuery(GET_INTENT_PAYMENT_BY_USER, {variables: {period: 'year'}});
  const [buySubscription] = useMutation(BUY_SUBSCRIPTION);
  const buy = () => {
    buySubscription({variables: {subscriptionType: subscription}}).then((response) => {
      console.log(response.data);
    }).catch((error) => {
      console.log(error)
    })
  };
  const onSuccess = () => buy();
  let content;
  if (subscription === 'month') {
    content = monthLoading ? <InfoCard/> :
      (<PaymentForm
        paymentData={monthSubscription}
        price={10}
        onSuccess={onSuccess}
        subscription={true}
      />)
  } else {
    content = yearLoading ? <InfoCard/> :
      (<PaymentForm
        paymentData={yearSubscription}
        price={100}
        onSuccess={onSuccess}
        subscription={true}
      />)
  }


  return (
    <div className="subscription">
      <div className="subscription__header">
        <h1>Buy Subscription</h1>
        <Radio.Group onChange={(e) => setSubscription(e.target.value)} value={subscription}>
          <Radio value={'month'}>Month (10$)</Radio>
          <Radio value={'year'}>Year (100$)</Radio>
        </Radio.Group>
      </div>
      <Spinner loading={subscription === 'month' ? monthLoading : yearLoading}>{content}</Spinner>
    </div>
  );
};

export default SubscriptionPayment;
