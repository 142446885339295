import React from 'react';

import { Layout } from 'antd';

import Spinner from "./Spinner";
import Footer from './Footer/Footer';

import './styles.less';
import { useLocation } from 'react-router';

const { Content } = Layout;

interface TestProps {
  hideFooter?: boolean;
  children: any;
  loading?: boolean;
  isHelpipedia?: boolean;
}

const PageLayout = ({
  hideFooter = false,
  children,
  loading = false,
  isHelpipedia=useLocation().pathname.includes("helpipedia")?true:false
}: TestProps) => (
  <Spinner loading={loading} className="layout-spinner">
    <div className="page_layout">
      <Content style={{ flex: 1, position: 'relative', background: isHelpipedia?'#EBDDC3': '#F4F5F9', }}>
        {children}
      </Content>
      {!loading && !hideFooter && <Footer />}
    </div>
  </Spinner>
);

export default PageLayout;
