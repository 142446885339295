// @ts-nocheck
import React, {useState} from "react";
import {Button, message, Form, Input, Select, Tooltip} from "antd";
import {useFormik} from "formik";
import {useMutation} from "@apollo/client";
import {modal} from "utils/modal";
import ModalScrollable from "../../components/ModalScrollable/ModalScrollable";
import {CREATE_BOOK} from "../mutations";
import {categories} from "../AdminDashboardTabs/BookTable";

const CreateBookForm = ({push}: any) => {
  const [createBook] = useMutation(CREATE_BOOK);

  const [file, setFile] = useState("");

  const onChange = ({target: {validity, files: [file]}}) => {
    validity.valid && formik.setFieldValue("file", file);
    setFile(URL.createObjectURL(file));
  };

  const removeHandler = () => {
    formik.setFieldValue("file", null);
    setFile(null);
  };

  const initialValues = {
    initialValues: {
      title: "",
      category: "",
      capsuleTopic: "",
      author: "",
      link: "",
      avatar: "",
      file: null,
    },
  };

  const onSubmit = async (values) => {
    message.loading({
      duration: 0,
      content: "Uploading your changes",
      key: "loading",
    });

    const result = await createBook({
      variables: {
        title: values.title,
        category: values.category,
        capsuleTopic: values.capsuleTopic,
        author: values.author,
        link: values.link,
        avatar: values.avatar,
        file: values.file,
      },
    });

    message.destroy("loading");
    message.success("Completed");
    push(result.data.createBook.id);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  return (
    <Form layout="vertical" onFinish={formik.handleSubmit}>
      <Form.Item label="Book Title" name="title">
        <Input name="title" type="title" required onChange={formik.handleChange} value={formik.values.title}/>
      </Form.Item>

      <Form.Item name="Category" label="Category">
        <Select value={formik.values.category} onChange={(e) => formik.setFieldValue("category", e)}>
          {categories.map(item=>(
            <Select.Option value={item}>{item}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Capsule Topic" name="capsuleTopic">
        <Input name="capsuleTopic" type="capsuleTopic" onChange={formik.handleChange}
               value={formik.values.capsuleTopic}/>
      </Form.Item>
      <Form.Item label="Author" name="author">
        <Input name="author" type="author" required onChange={formik.handleChange} value={formik.values.author}/>
      </Form.Item>

      <Form.Item label="Link" name="link">
        <Input name="link" type="link" required onChange={formik.handleChange} value={formik.values.link}/>
      </Form.Item>
      <Form.Item label="Avatar Link" name="avatar">
        <Input name="avatar" type="avatar" onChange={formik.handleChange} value={formik.values.avatar}/>
      </Form.Item>

      <Form.Item>
        <div className="book__avatar-block">
          {(formik.values.avatar || file) && (
            <Tooltip placement={"top"} title={"Remove File"}>
              <img className="book__avatar" src={file || formik.values.avatar} alt="avatar" width="100%" height="50%"
                   onClick={removeHandler}/>
            </Tooltip>
          )}

          <input type="file" name="file" accept="image/*, text/plain, .pdf, .csv, .xls, .xlsx, .doc, .docx"
                 onChange={onChange}/>
        </div>
      </Form.Item>

      <Form.Item>
        <Button disabled={formik.isSubmitting} type="primary" htmlType="submit">
          Save Book
        </Button>
      </Form.Item>
    </Form>
  );
};

const CreateBookModal = ({bookQuery}: any) => {
  const closeModal = () => {
    modal.hideModal();
    bookQuery.refetch();
  };

  return (
    <ModalScrollable title="Create Book">
      <CreateBookForm push={closeModal}/>
    </ModalScrollable>
  );
};

export default CreateBookModal;
