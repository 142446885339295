import React from 'react';

import { useNotification } from 'hooks';
import './styles.less';

function HomeList({
  data, loading, error, children, className = ''
}) {
  const notification = useNotification();

  if (loading) {
    return <p> Getting Data ... </p>;
  }

  if (error) {
    notification({ type: 'error' });
    return <p> Oops! Something went wrong... </p>;
  }

  return (
    <div className={`home_list ${className}`}>
      {data.map((item, index) => (
        <React.Fragment key={item.id + index}>{children(item)}</React.Fragment>
      ))}
    </div>
  );
}

export default HomeList;
