import { gql } from '@apollo/client';

export const GetJourneysQuery = gql`
  query Query {
    getJourneyList {
      id
      title
      publishStatus
      price
    }
  }
`;

export const GetCoachesQuery = gql`
  query getCoaches {
    getCoaches {
      CoachUser {
        id
        accountCreated
        calendlyLinks
        email
        coachActive
        userProfile {
          firstName
          bio
          certifications
          avatarUrl
          meetCoachVideo {
            id
          }
          calendlyUserName
          coachingStyle
          lastName
          rate
        }
      }
      CoachApply {
        id
        email
        firstName
        lastName
        linkedinUrl
        coach {
          id
          calendlyLinks
          userProfile {
            rate
          }
        }
      }
    }
  }
`;

export const GetCapsulesQuery = gql`
  query getAllCapsules {
    getAllCapsules {
      id
      maxVersion
      currentContent {
        id
        title
        version
        tags
        briefAbout
        status
      }
      coach {
        id
        userProfile {
          coachingStyle
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_ALL_SNIPPETS = gql`
  query getAllSnippets {
    getAllSnippets {
      id
      softskillTags
      isVisible
      coachId
      coach {
        userProfile {
          firstName
          lastName
        }
      }
      title
    }
  }
`;

export const GET_ALL_PLAYLISTS = gql`
  query getAllPlaylists {
    getAllPlaylists
  }
`;

export const GET_ALL_ATTACHMENTS = gql`
  query getAttachments {
    getAttachments {
      id
      name
      url
      type
    }
  }
`;

export const GET_ALL_QUESTIONS = gql`
  query getQuestions {
    getQuestions {
      id
      type
      title
      questions
      description
    }
  }
`;

export const GET_ALL_CERTIFICATIONS = gql`
  query getAllCertifications {
    getAllCertifications
  }
`;

export const GET_USERS_WAITING_APPROVE = gql`
  query getUsersWaitingApprove {
    getUsersWaitingApprove {
      id
      email
      userProfile {
        firstName
        lastName
        bio
      }
      educationalInstitution
    }
  }
`;

export const CREATE_ASSESSMENT = gql`
  mutation createAssessment($name: String!, $url: String!) {
    createAssessment(name: $name, url: $url) {
      id
    }
  }
`;

export const DELETE_ADMIN_VIEWS = gql`
  mutation deleteAdminViews {
    deleteAdminViews
  }
`;

export const UPLOAD_ATTACHMENT_FILE = gql`
  mutation UploadAttachmentFileMutation($file: Upload!, $type: AttachmentTypeEnum, $name: String) {
    uploadAttachmentFile(file: $file, type: $type, name: $name) {
      filename
      mimetype
      encoding
      url
    }
  }
`;

export const CREATE_SKILL_CHECK = gql`
  mutation createSkillCheckQuestions($title: String!, $description: String, $questions: JSON) {
    createSkillCheckQuestions(title: $title, description: $description, questions: $questions) {
      id
    }
  }
`;

export const CREATE_SELF_REFLECTION = gql`
  mutation createSelfReflectionQuestions($title: String!, $description: String, $questions: JSON) {
    createSelfReflectionQuestions(title: $title, description: $description, questions: $questions) {
      id
    }
  }
`;

export const DELETE_JOURNEY = gql`
  mutation deleteJourney($id: ID!) {
    deleteJourney(id: $id)
  }
`;

export const DELETE_QUESTION = gql`
  mutation deleteQuestions($id: ID!) {
    deleteQuestions(questionId: $id)
  }
`;

export const DELETE_ATTACHMENT = gql`
  mutation deleteAttachment($id: ID!) {
    deleteAttachment(attachmentId: $id)
  }
`;

export const DELETE_SNIPPET = gql`
  mutation deleteSnippet($id: ID!) {
    deleteSnippet(id: $id)
  }
`;

export const DELETE_COACH = gql`
  mutation deleteUser($userId: ID) {
    deleteUser(userId: $userId) {
      id
    }
  }
`;

export const GET_ALL_BOOKEDS = gql`
  query getAllBookeds {
    getAllBookeds {
      id
      userId
      userName
      createdAt
      coachName
      coachId
    }
  }
`;

export const GET_ALL_USERS = gql`
  query getAllUser {
    getAllUser {
      id
      email
      subscriptionStatus
      dateEnding
      userProfile {
        firstName
        lastName
        adult
      }
    }
  }
`;

export const GET_USER_BY_EMAIL = gql`
  query getUserByEmail($email: String) {
    getUserByEmail(email: $email) {
      id
      email
      subscriptionStatus
      dateEnding
      userProfile {
        firstName
        lastName
      }
    }
  }
`;

export const APPROVE_USER = gql`
  mutation approveUser($userId: ID, $status: String) {
    approveUser(userId: $userId, status: $status)
  }
`;

export const BUY_SUBSCRIPTION = gql`
  mutation buySubscription($userId: ID, $subscriptionType: String) {
    buySubscription(userId: $userId, subscriptionType: $subscriptionType)
  }
`;

export const GET_ALL_BOOKS = gql`
  query getAllBooks {
    getAllBooks {
      id
      title
      category
      capsule_topic
      author
      link
      avatar
    }
  }
`;

export const GET_BOOK = gql`
  query getBook($id: ID) {
    getBook(id: $id) {
      id
      title
      category
      capsule_topic
      author
      link
      avatar
    }
  }
`;

export const GET_BOOK_BY_CATEGORY = gql`
  query getBookByCategory($category: String) {
    getBookByCategory(cateogry: $category) {
      id
      title
      category
      capsule_topic
      author
      link
      avatar
    }
  }
`;

export const editActiveCoach = gql`
  mutation approveCoach($userId: ID, $active: Boolean) {
    approveCoach(userId: $userId, active: $active)
  }
`;
