import React from 'react';
import { gql, useQuery } from '@apollo/client';
import moment from 'moment';
import { useLocation } from 'react-router';


const MyBadges = (props) => {
    const GET_MY_BADGES = gql`
        query getUserBadges($userId: ID,$source:String) {
            getUserBadges(userId: $userId, source:$source)
        }
    `;

    const { data, refetch, loading } = useQuery(GET_MY_BADGES, {
        variables: { userId: props?.currentUser.id, source:useLocation().pathname.includes("helpipedia")?"helpipedia":"steerus" },
    });

    const BadgeCard = ({ item }) => {

        return <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
            <img style={{ width: 100, height: 100, borderRadius: 50 }} src={item.imageUrl} />
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                <p className='text'>{item.journeyId != null ? "Journey:" : "Playlist:"} {item.title}</p>

                <p className='text'>Awarded: {moment(item.createdAt).format('D/MM/YYYY')}</p>

            </div>
        </div>
    }
    return (<div>

        <h1 style={{ marginBottom: '30px' }}>Badges</h1>

        {data && data.getUserBadges && data.getUserBadges.length > 0 ? <div className='badgeContainer'>
            {!loading && data && data.getUserBadges && data.getUserBadges.map(item => {
                return <BadgeCard key={item.id} item={item} />
            })}
        </div> : <h1> {!loading && "No badges"}</h1>
        }
    </div>)
};

export default MyBadges;