import React from "react";
import { Table, Button, Space } from "antd";
import { useMutation } from "@apollo/client";
import { APPROVE_USER } from "../mutations";
import MobileTable from "../../MobileTable/MobileTable";
import { useMediaQuery } from "react-responsive";

const StudentRequests = ({ users }) => {
  const [approveUser] = useMutation(APPROVE_USER);

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 700px)",
  });

  const userDataHandler = () => {
    const usersData = [];

    if (users) {
      users.forEach((user) => {
        usersData.push({
          ...user,
          key: user.id,
        });
      });
    }

    return usersData;
  };


  const approve = (id, status) => {
    approveUser({ variables: { userId: id, status: status } })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const columns = [
    {
      title: "User Name",
      width: "25%",
      render: (value) => value.userProfile.firstName + " " + value.userProfile.lastName,
    },
    {
      title: "Email",
      width: "25%",
      render: (value) => value.email,
    },
    {
      title: "Education",
      width: "25%",
      render: (value) => (value.educationalInstitution ? value.educationalInstitution : "unknown"),
    },
    {
      title: "Action",
      width: "25%",
      render: (value) => {
        return (
          <Space>
            <Button onClick={() => approve(value.id, null)}>Reject</Button>
            <Button onClick={() => approve(value.id, ".edu")}>Full Access</Button>
          </Space>
        );
      },
    },
  ];
  return (
    <div>
      {!isTabletOrMobileDevice ? (
        <Table rowKey="id" columns={columns} dataSource={userDataHandler()} />
      ) : (
        <MobileTable data={userDataHandler()} columns={columns} />
      )}
    </div>
  );
};

export default StudentRequests;
