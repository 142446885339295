import { DownloadOutlined } from '@ant-design/icons';
import { gql, useQuery } from '@apollo/client';
import { Button, Col, Row } from 'antd';
import React, { useState } from 'react';
import './styles.less';
import * as XLSX from 'xlsx';

interface MyObject {
    [key: string]: any;
}

const MetricsDashboard = (props: any) => {
    const [totalActiveUsers, setTotalActiveUsers] = useState(0);
    const [totalSoftSkillAssessments, setTotalSoftSkillAssessments] = useState(0);
    const [totalSuperPowerAssessments, setTotalSuperPowerAssesments] = useState(0);
    const [softSkillAssessments, setSoftSkillAssessments] = useState([]);
    const [superPowerAssessments, setSuperPowerAssessments] = useState([]);

    const GET_CAPSULES_COUNT = gql`
        query getCapsulesCount {
            getCapsulesCount
        }
    `;

    const GET_JOURNEY_COUNT = gql`
        query getJourneyCount {
            getJourneyCount
        }
    `;

    const GET_SNIPPET_COUNT = gql`
        query getSnippetCount {
            getSnippetCount
        }
    `;

    const GET_VIDEOS_COUNT = gql`
        query getVideosCount {
            getVideosCount
        }
    `;

    const GET_VIDEOS_MINUTES = gql`
        query getVideosMinutes {
            getVideosMinutes
        }
    `;

    const GET_USERS_COUNT = gql`
        query getUsersCount {
            getUsersCount
        }
    `;

    const GET_SUBMITTED_SOFT_SKILL_ASSESSMENTS_COUNT = gql`
        query getSubmittedSoftSkillAssessmentsCount {
            getSubmittedSoftSkillAssessmentsCount
        }
    `;

    const GET_SUBMITTED_SUPER_POWER_ASSESSMENTS_COUNT = gql`
        query getSubmittedSuperPowerAssessmentsCount {
            getSubmittedSuperPowerAssessmentsCount
        }
    `;

    const GET_SUBMITTED_SOFT_SKILL_ASSESSMENTS = gql`
        query getSubmittedSoftSkillAssessments {
            getSubmittedSoftSkillAssessments
        }
    `;

    const GET_SUBMITTED_SUPER_POWER_ASSESSMENTS = gql`
        query getSubmittedSuperPowerAssessments {
            getSubmittedSuperPowerAssessments
        }
    `;

    const {data: capsulesCountData} = useQuery(GET_CAPSULES_COUNT);
    const {data: journeyCountData} = useQuery(GET_JOURNEY_COUNT);
    const {data: snippetCountData} = useQuery(GET_SNIPPET_COUNT);
    const {data: videosCountData} = useQuery(GET_VIDEOS_COUNT);
    const {data: videosMinutesData} = useQuery(GET_VIDEOS_MINUTES);
    const {data: usersCountData} = useQuery(GET_USERS_COUNT);
    const {data: submittedSoftSkillAssessmentsCountData} = useQuery(GET_SUBMITTED_SOFT_SKILL_ASSESSMENTS_COUNT);
    const {data: submittedSoftSkillAssessmentsData} = useQuery(GET_SUBMITTED_SOFT_SKILL_ASSESSMENTS);
    const {data: submittedSuperPowerAssessmentsCountData} = useQuery(GET_SUBMITTED_SUPER_POWER_ASSESSMENTS_COUNT);
    const {data: submittedSuperPowerAssessmentsData} = useQuery(GET_SUBMITTED_SUPER_POWER_ASSESSMENTS);

    const parseSuperPowerAssessments = () => {
        let records: MyObject[] = [];

        submittedSuperPowerAssessmentsData.getSubmittedSuperPowerAssessments.map((assessment: any) => {
            let obj = {...assessment};
            delete obj["data"];

            console.log("obj ", obj);
            console.log("assessment.data ", assessment.data);

            assessment.data.answers.map((answer: any, index: number) => {
                obj[`A${index + 1}`] = answer.answer;
            });

            records.push(obj);
        });

        return records;
    };

    const parseSoftSkillAssessments = () => {
        let records: MyObject[] = [];
        submittedSoftSkillAssessmentsData.getSubmittedSoftSkillAssessments.map((assessment: any) => {
            console.log("assessment ", assessment);
            let obj: MyObject = {...assessment};
            delete obj["data"];

            for (const data of assessment.data) {
                const title: string = data.title;
                data.content.map((content: any, index: number) => {
                    const key = `${title} Q${index + 1}`;
                    obj[key] = content.question
                    obj[`${title} A${index + 1}`] = content.value;
                })
            }

            records.push(obj);
        });

        return records;
    };

    const exportSuperPowerAssessments = () => {
        const parsedAssessments = parseSuperPowerAssessments();
        const worksheet = XLSX.utils.json_to_sheet(parsedAssessments);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'superPowerAssessments.xlsx');
    };

    const exportSoftSkillsAssessments = () => {
        const parsedAssessments = parseSoftSkillAssessments();
        const worksheet = XLSX.utils.json_to_sheet(parsedAssessments);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'softSkillsAssessments.xlsx');
    };

    return <React.Fragment>
        <Row>
            <Col className="metrics-dashboard-column" xs={8}>Total Users Registered</Col>
            <Col className="metrics-dashboard-column margin-left-20" xs={2}>{usersCountData && usersCountData.getUsersCount}</Col>
            <Col className="metrics-dashboard-column ant-col-xs-offset-1" xs={8}>Total Journeys</Col>
            <Col className="metrics-dashboard-column margin-left-20" xs={2}>{journeyCountData && journeyCountData.getJourneyCount}</Col>
        </Row>
        <Row>
            <Col className="metrics-dashboard-column" xs={8}>Total Snippets</Col>
            <Col className="metrics-dashboard-column margin-left-20" xs={2}>{snippetCountData && snippetCountData.getSnippetCount}</Col>
            <Col className="metrics-dashboard-column ant-col-xs-offset-1" xs={8}>Total Capsules</Col>
            <Col className="metrics-dashboard-column margin-left-20" xs={2}>{capsulesCountData && capsulesCountData.getCapsulesCount}</Col>
        </Row>
        <Row>
            <Col className="metrics-dashboard-column" xs={8}>Total Soft Skill Assessments</Col>
            <Col className="metrics-dashboard-column margin-left-20" xs={2}>{submittedSoftSkillAssessmentsCountData && submittedSoftSkillAssessmentsCountData.getSubmittedSoftSkillAssessmentsCount}</Col>
            <Col className="metrics-dashboard-column ant-col-xs-offset-1" xs={8}>Total Super Power Assessments</Col>
            <Col className="metrics-dashboard-column margin-left-20" xs={2}>{submittedSuperPowerAssessmentsCountData && submittedSuperPowerAssessmentsCountData.getSubmittedSuperPowerAssessmentsCount}</Col>
        </Row>
        <Row>
            <Col className="metrics-dashboard-column" xs={8}>Total Videos</Col>
            <Col className="metrics-dashboard-column margin-left-20" xs={2}>{videosCountData && videosCountData.getVideosCount}</Col>
            <Col className="metrics-dashboard-column ant-col-xs-offset-1" xs={8}>Total Video Minutes ±1 </Col>
            <Col className="metrics-dashboard-column margin-left-20" xs={2}>{videosMinutesData && parseInt(videosMinutesData.getVideosMinutes)}</Col>
        </Row>
        {
            console.log("super power assessment ", submittedSuperPowerAssessmentsData)
        }
        {
            console.log("softskill assessment ", submittedSoftSkillAssessmentsData)
        }
        {submittedSuperPowerAssessmentsData && <Row className="margin-top-20">
            <Col xs={8}>
                <Button onClick={exportSuperPowerAssessments}>Download Super power Assessments <DownloadOutlined /></Button>
            </Col>
        </Row>
        }
        {submittedSoftSkillAssessmentsData && <Row className="margin-top-20">
            <Col xs={8}>
                <Button onClick={exportSoftSkillsAssessments}>Download Soft Skill Assessments <DownloadOutlined /></Button>
            </Col>
        </Row>
        }
    </React.Fragment>
};

export default MetricsDashboard;