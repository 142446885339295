import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const ClassMaterialWrapper = ({isBlocked, link, isExternalLink, children, handleToggleModal}) => {
  if (isBlocked) {
    return (
      <div onClick={handleToggleModal}>
        {children}
      </div>
    )
  }

  return (
    <Fragment>
      {
        isExternalLink ? (
          <a href={link} target="_blank">
            {children}
          </a>
        ) : (
          <Link to={link}>
            {children}
          </Link>
        )
      }
    </Fragment>
  )
};

export default ClassMaterialWrapper;