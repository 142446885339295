// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { Button, Form, Input, message, Space, Spin } from 'antd';
import { STEERUS_GREEN } from '../styles/colors';
import { PageLayout, HeroHalf } from '../components';

const SEND_FORGOT_EMAIL = gql`
  mutation sendForgotPasswordEmail($email: String) {
    sendForgotPasswordEmail(email: $email) 
  }
`;

const SignupForm = ({ history, setLoading }) => {
  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted
  const [forgotPassword, { loading }] = useMutation(SEND_FORGOT_EMAIL);
  useEffect(() => {
    setLoading(loading)
  }, [loading])
  const defaultMargin = {
    marginTop: '22px',
  };
  const inputStyles = {
    width: '100%',
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values) => {
      try {
        await forgotPassword({ variables: { email: values?.email } });
        message.success('Check your email for a password reset link');
      } catch (e) {
        message.error('Email not found');
      }
    },
  });
  return (
    <Form layout="vertical" onFinish={formik.handleSubmit}>
      <h1> Forgot your password? No worries. </h1>
      <Form.Item label="Email" name="Email" style={defaultMargin}>
        <Input
          id="email"
          name="email"
          label="email"
          type="email"
          onChange={formik.handleChange}
          value={formik.values.email}
          style={inputStyles}
        />
      </Form.Item>

      <Form.Item style={defaultMargin}>
        <Button style={inputStyles} type="primary" htmlType="submit">
          Send Reset Email
        </Button>
      </Form.Item>

    </Form>
  );
};

const App = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false)

  return (
    <PageLayout hideFooter>
      {loading && <Space style={{ position: "fixed", backgroundColor: 'rgba(0, 0, 0, 0.45)', top: 0, left: 0, width: "100%", height: "100vh", zIndex: 100000 }}>
        <Spin style={{ position: "absolute", top: '50%', left: '50%',transform:"translate(-50%, -50%)", width: '100px', height: "100px" }} className='login-spin'/>

      </Space>}
      <HeroHalf
        rightColor={STEERUS_GREEN}
        leftComponents={(
          <div>
            <SignupForm history={history} setLoading={setLoading} />
          </div>
        )}
      />
    </PageLayout>
  );
};

export default App;
