import { Input, Col, Row, Button, Tag } from 'antd';
import InputFile from 'journey/JourneyEdit/components/InputFile';
import React from 'react';
import { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import "./CreateAssessmentModal.css";
import { UPLOAD_FILE } from 'uploads/query';

const CreateAssessmentModal = (props: any) => {
    interface ErrorState {
        name?: string;
        error?: string;
    }
    const [title, setTitle] = useState(props.selectedAssessment?.title || "");
    const [description, setDescription] = useState(props.selectedAssessment?.description || "");
    const [price, setPrice] = useState(props.selectedAssessment?.price || 0)
    const [file, setFile] = useState(props.selectedAssessment?.questionsCsv || {});
    const [error, setError] = useState<ErrorState>({});
    const [image, setImage] = useState(props.selectedAssessment?.image || {});
    const [slug, setSlug] = useState(props.selectedAssessment?.slug || "");

    const [score040Text, setScore040Text] = useState(props.selectedAssessment?.score040Text || "");
    const [score4160Text, setScore4160Text] = useState(props.selectedAssessment?.score4160Text || "");
    const [score6180Text, setScore6180Text] = useState(props.selectedAssessment?.score6180Text || "");
    const [score81100Text, setScore81100Text] = useState(props.selectedAssessment?.score81100Text || "");

    const [backgroundImage, setBackgroundImage] = useState(props.selectedAssessment?.backgroundImage || {});
    const [watermark, setWatermark] = useState(props.selectedAssessment?.watermark || {});
    const [lowNeedReport, setLowNeedReport] = useState(props.selectedAssessment?.lowNeedReport || {});
    const [someNeedReport, setSomeNeedReport] = useState(props.selectedAssessment?.someNeedReport || {});
    const [moderateNeedReport, setModerateNeedReport] = useState(props.selectedAssessment?.moderateNeedReport || {});
    const [highNeedReport, setHighNeedReport] = useState(props.selectedAssessment?.highNeedReport || {});

    const { TextArea } = Input;

    const CREATE_OR_UPDATE_ASSESSMENT = gql`
        mutation createOrUpdateSkillAssessment(
            $id: ID
            $description: String
            $title: String
            $image: String
            $questionsCsv: String
            $status: String
            $price: Int
            $slug: String
            $score040Text: String
            $score4160Text: String
            $score6180Text: String
            $score81100Text: String
            $backgroundImage: String
            $watermark: String
            $lowNeedReport: String
            $someNeedReport: String
            $moderateNeedReport: String
            $highNeedReport: String
        ) {
            createOrUpdateSkillAssessment(
                id: $id
                description: $description
                title: $title
                image: $image
                questionsCsv: $questionsCsv
                status: $status
                price: $price
                slug: $slug
                score040Text: $score040Text
                score4160Text: $score4160Text
                score6180Text: $score6180Text
                score81100Text: $score81100Text
                backgroundImage: $backgroundImage
                watermark: $watermark
                lowNeedReport: $lowNeedReport
                someNeedReport: $someNeedReport
                moderateNeedReport: $moderateNeedReport
                highNeedReport: $highNeedReport
            )
        }
        `;

    const [createOrUpdateAssessment] = useMutation(CREATE_OR_UPDATE_ASSESSMENT);
    const [uploadFile] = useMutation(UPLOAD_FILE);
    

    const onCsvUpload = (value: any) => {
        setFile(value?.originFileObj);
        setError({});
    };

    const onImageUpload = (value: any) => {
        console.log("I am called");
        setImage(value?.originFileObj);
        setError({});
    };

    const uploadDocument = (value: any, handler: any) => {
        uploadFile({ variables: { file: value?.originFileObj }}).then(resp => {
            handler(resp.data?.uploadFile?.imageUrl || "");
        }).catch(err => {
            console.log("error ", err);
        });
    };

    const onSubmit = () => {
        setError({});
        if (!title) {
            setError({name: "title", error: "Please fill in the required field"});
            return false;
        }
        if (!description) {
            console.log("called ", error );
            setError({name: "description", error: "Please fill in the required field"});
            return false;
        }

        if (!slug) {
            setError({name: "slug", error: "Please fill in the required field"});
            return false;
        }
        if (!score040Text) {
            setError({name: "score040Text", error: "Please fill in the required field"});
            return false;
        }
        if (!score4160Text) {
            setError({name: "score4160Text", error: "Please fill in the required field"});
            return false;
        }
        if (!score6180Text) {
            setError({name: "score6180Text", error: "Please fill in the required field"});
            return false;
        }
        if (!score81100Text) {
            setError({name: "score81100Text", error: "Please fill in the required field"});
            return false;
        }        
        if (!props.selectedAssessment?.questionsCsv && !Object.keys(file).length) {
            setError({name: "file", error: "Please upload the questions"});
            return false;
        }

        console.log("image ", image);
        console.log("payload ", {title, description, questionsCsv: file, image, status: "published"});
        let variables: any = {
            title, description, questionsCsv: file, image, status: "published",
            price: parseInt(price), slug, score040Text, score4160Text, score6180Text, score81100Text,
            backgroundImage, watermark,lowNeedReport, someNeedReport, highNeedReport, moderateNeedReport
        };
        if (props.selectedAssessment?.id)
            variables["id"] = props.selectedAssessment?.id;

        createOrUpdateAssessment({ variables }).then(resp => {
            props.onSuccess();
        }).catch(err => {
            console.log("Some error occurred while creating assessment ", err);
        });
    };

    return <div
        style={{ height: '100vh' }}
    >
        <h1 style={{"textAlign": "center"}}>{props.selectedAssessment ? `Edit` : `Create`} Assessment</h1>
        <Row className="create-assessment-row">
            <label>Title</label>
            <Input
                placeholder="Enter title"
                value={title}
                onChange={(e: any) => setTitle(e.target.value)}
            />
            {error && error.name == "title" && <Tag color="red">{error.error}</Tag>}
        </Row>
        <Row className="create-assessment-row">
            <label>Description</label>
            <TextArea
                rows={4}
                value={description}
                onChange={(e: any) => setDescription(e.target.value)}
            />
            {error && error.name == "description" && <Tag color="red">{error.error}</Tag>}
        </Row>
        <Row className="create-assessment-row">
            <label>Price</label>
            <Input
                type="number"
                value={price}
                onChange={e => setPrice(e.target.value)}
            />
        </Row>
        <Row className="create-assessment-row">
            <label>Slug</label>
            <Input
                value={slug}
                onChange={e => setSlug(e.target.value)}
            />
            {error && error.name == "slug" && <Tag color="red">{error.error}</Tag>}
        </Row>
        <Row className="create-assessment-row">
            <label>Score 0-40 Text</label>
            <Input
                value={score040Text}
                onChange={e => setScore040Text(e.target.value)}
            />
            {error && error.name == "score040Text" && <Tag color="red">{error.error}</Tag>}
        </Row>
        <Row className="create-assessment-row">
            <label>Score 41-60 Text</label>
            <Input
                value={score4160Text}
                onChange={e => setScore4160Text(e.target.value)}
            />
            {error && error.name == "score4160Text" && <Tag color="red">{error.error}</Tag>}
        </Row>
        <Row className="create-assessment-row">
            <label>Score 61-80 Text</label>
            <Input
                value={score6180Text}
                onChange={e => setScore6180Text(e.target.value)}
            />
            {error && error.name == "score6180Text" && <Tag color="red">{error.error}</Tag>}
        </Row>
        <Row className="create-assessment-row">
            <label>Score 81-100 Text</label>
            <Input
                value={score81100Text}
                onChange={e => setScore81100Text(e.target.value)}
            />
            {error && error.name == "score81100Text" && <Tag color="red">{error.error}</Tag>}
        </Row>
        <Row className="create-assessment-row">
            <label>Watermark Image</label>
            <InputFile
            file={{}}
            accept="image/*"
            onChange={(value: any) => uploadDocument(value, setWatermark)}
            />
            {typeof(watermark) == "string" ? watermark : (props.selectedAssessment && props.selectedAssessment.watermark)}
            {error && error.name == "watermark" && <Tag color="red">{error.error}</Tag>}
        </Row>
        <Row className="create-assessment-row">
            <label className="margin-right-15">Upload Image</label>
            <InputFile
            file={{}}
            accept="image/*"
            onChange={(value: any) => uploadDocument(value, setImage)}
            />
            {typeof(image) == "string" ? image : (props.selectedAssessment && props.selectedAssessment.image)}
            {error && error.name == "image" && <Tag color="red">{error.error}</Tag>}
        </Row>
        <Row className="create-assessment-row">
            <label className="margin-right-15">Upload Background Image</label>
            <InputFile
            file={{}}
            accept="image/*"
            onChange={(value: any) => uploadDocument(value, setBackgroundImage)}
            />
            {typeof(backgroundImage) == "string" ? backgroundImage : (props.selectedAssessment && props.selectedAssessment.backgroundImage)}
            {error && error.name == "backgroundImage" && <Tag color="red">{error.error}</Tag>}
        </Row>
        <Row className="create-assessment-row">
            <label className="margin-right-15">Upload Questions</label>
            <InputFile
            file={{}}
            accept=".csv"
            onChange={(value: any) => uploadDocument(value, setFile)}
            />
            {typeof(file) == "string" ? file : (props.selectedAssessment && props.selectedAssessment.questionsCsv)}
            {error && error.name == "file" && <Tag color="red">{error.error}</Tag>}
        </Row>
        <Row className="create-assessment-row">
            <label className="margin-right-15">Upload Report for (0-40)</label>
            <InputFile
            file={{}}
            accept=".pdf"
            onChange={(value: any) => uploadDocument(value, setHighNeedReport)}
            />
            {typeof(highNeedReport) == "string" ? highNeedReport : (props.selectedAssessment && props.selectedAssessment.highNeedReport)}
            {error && error.name == "file" && <Tag color="red">{error.error}</Tag>}
        </Row>
        <Row className="create-assessment-row">
            <label className="margin-right-15">Upload Report for (40-60)</label>
            <InputFile
            file={{}}
            accept=".pdf"
            onChange={(value: any) => uploadDocument(value, setModerateNeedReport)}
            />
            {typeof(moderateNeedReport) == "string" ? moderateNeedReport : (props.selectedAssessment && props.selectedAssessment.moderateNeedReport)}
            {error && error.name == "file" && <Tag color="red">{error.error}</Tag>}
        </Row>
        <Row className="create-assessment-row">
            <label className="margin-right-15">Upload Report for (60-80)</label>
            <InputFile
            file={{}}
            accept=".pdf"
            onChange={(value: any) => uploadDocument(value, setSomeNeedReport)}
            />
            {typeof(someNeedReport) == "string" ? someNeedReport : (props.selectedAssessment && props.selectedAssessment.someNeedReport)}
            {error && error.name == "file" && <Tag color="red">{error.error}</Tag>}
        </Row>
        <Row className="create-assessment-row">
            <label className="margin-right-15">Upload Report for (80-100)</label>
            <InputFile
            file={{}}
            accept=".pdf"
            onChange={(value: any) => uploadDocument(value, setLowNeedReport)}
            />
            {typeof(lowNeedReport) == "string" ? lowNeedReport : props.selectedAssessment && props.selectedAssessment.lowNeedReport}
            {error && error.name == "file" && <Tag color="red">{error.error}</Tag>}
        </Row>
        <Row className="create-assessment-row">
            <Button onClick={onSubmit}>Submit</Button>
        </Row>
    </div>
};

export default CreateAssessmentModal;