import React, {useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {userQuery} from "./query";
import {ACCESS_REQUEST} from "./mutation";
import {Link} from "react-router-dom";
import {StandardMarginDiv, PageLayout} from '../components';
import {Input, message, Alert} from 'antd';
import "./styles.less";

const {TextArea} = Input;

const SubscriptionPage = () => {
  const {data} = useQuery(userQuery, {pollInterval: 500});
  const [state, setState] = useState(false)
  const [institution, setInstitution] = useState('');
  const [bio, setBio] = useState('');
  const [accessRequest] = useMutation(ACCESS_REQUEST);
  if (data?.currentUser && data?.currentUser?.subscriptionStatus === 'payment' && parseInt(data?.currentUser?.dateEnding) > Date.now()) {
    window.location.href = '/academy';
  } else if (!data?.currentUser) {
    window.location.href = '/login';
  }

  const show = () => setState(true);
  setTimeout(() => show(), 1000);

  const request = (name, bio) => {
    accessRequest({variables: {educationalInstitution: name, bio: bio}}).then(() => {
      message.success("Your request is being processed. We will send you an email.");
    }).catch((e) => {
      message.error("Something went wrong");
    })
  };

  const formSubmit = (e) => {
    e.preventDefault();
    if (bio !== '' && institution !== '') {
      request(institution, bio);
      setBio('');
      setInstitution('');
    }
  };

  return (
    <PageLayout loading={!state}>
      <StandardMarginDiv>
        {state ? <>
          <div className="policy subscr">
            <h1 className="policy__title">Ready to upskill?</h1>
            <p className="policy__description" style={{margin: '30px 0'}}>
              As social entrepreneurs, we’ve made a commitment to making our Academy free to students, educators, and
              underserved communities. To gain free access, please register with your student email address.
            </p>
            <p className="policy__description" style={{marginBottom: '30px'}}>
              If you’re a coach, we’d love to meet you! Please complete thebrief application here:
              &nbsp;
              <a href="https://docs.google.com/forms/d/1Q7GLiEsGF-wZV5JKx4Go3gOEkgjhrBllHdB-0extaE8/viewform?edit_requested=true"
                target="_blank">STEERus Coaching Application</a>
            </p>
            <Link to="/settings" className="policy__subscription">Buy Subscription</Link>
            <p className="policy__description" style={{margin: '30px 0'}}>
              If you are a student and want to get free access to the academy, please fill out this form
            </p>
            {data?.currentUser && data?.currentUser?.subscriptionStatus !== 'waiting' ? <form className="policy__subscriptionForm" onSubmit={formSubmit}>
                <Input
                  value={institution}
                  required
                  onChange={(e) => setInstitution(e.target.value)}
                  placeholder="What's the name of the institution you've graduated?"
                  style={{marginBottom: '20px'}}/>
                <TextArea
                  rows={4}
                  value={bio}
                  required
                  onChange={(e) => setBio(e.target.value)}
                  style={{marginBottom: '20px'}}
                  placeholder="Bio"/>
                <button
                  type="submit"
                  className="policy__requestBtn"
                >Send request
                </button>
              </form> :
              <Alert
                message="Your request is being processed. We will send you an email."
                style={{margin: '100px auto 0', width: '500px',}}
                type="warning"
                showIcon/>}
          </div>
        </> : null}
      </StandardMarginDiv>
    </PageLayout>
  );
};

export default SubscriptionPage;
