import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import checkRoundGreenImg from 'assets/icons/check_round_green.svg';
import lockImg from 'assets/icons/lock.svg';
import { JourneyPaymentForm, Modal } from 'components';
import { formatTime } from 'utils';
import { CapsuleStatusesEnum } from 'capsule/capusleStatuses.enum';
import { useNotification } from '../../hooks';
import { gql, useQuery } from '@apollo/client';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './styles.less';
import { HELPipedia_ORANGE } from 'styles/colors';

export const GET_JOURNEY_BY_ID = gql`
  query Query($getUserJourneyById: ID!) {
    getUserJourneyById(id: $getUserJourneyById) {
      price
    }
  }
  `

const CapsuleCard = ({
  id,
  coach,
  currentContent,
  status,
  type,
  journeyId,
  tag,
  className = '',
  onClick,
  views,
                       scrollPosition
}) => {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const notification = useNotification();
  const [modal, setModal] = useState(false)

  const data = journeyId ? useQuery(GET_JOURNEY_BY_ID, {
    variables: { getUserJourneyById: journeyId, },
  }) : null;

  let isBlocked = !status && type !== 'free';
  if (journeyId) {
    isBlocked = !status;
  }


  if (!currentContent) {
    return null;
  }

  const handleClick = () => {
    const isJourneyPage = location.pathname.indexOf('journey') !== -1 && params.id;

    if (status !== 'purchased' && type === 'paid') {
      setModal(true)

      return
    }

    if (type === 'paid' && status === 'purchased') {
      
      history.push(location.pathname.includes("helpipedia")?`/helpipedia/journey/${journeyId}`:`/journey/${journeyId}`);
    }


    if (isBlocked && !journeyId && !isJourneyPage) {
      notification({ type: 'warning', message: 'Blocked for now' });
      return;
    }

    if (isJourneyPage) {
      if (type === 'free' || status) {
       history.push(location.pathname.includes("helpipedia")?`/helpipedia/capsule/${id}`:`/capsule/${id}`);
      }
      return;
    }

    if (journeyId) {
      history.push(location.pathname.includes("helpipedia")?`/helpipedia/journey/${journeyId}`:`/journey/${journeyId}`);
      return;
    }

    if (!journeyId && (status === CapsuleStatusesEnum.PASSED || status === CapsuleStatusesEnum.PURCHASED)) {
      history.push(location.pathname.includes("helpipedia")?`/helpipedia/capsule/${id}`:`/capsule/${id}`);
      
      return;
    }

    if (!type || type === 'free') {
      history.push(location.pathname.includes("helpipedia")?`/helpipedia/capsule/${id}`:`/capsule/${id}`);
    }
  };

  return (
    <div
      className={`defaultDiv capsule_card ${className}`}
      onClick={onClick || handleClick}
      style={{ cursor: isBlocked && !journeyId ? 'auto' : 'pointer' }}
    >
      {tag && (
        <div className="capsule_card__tags-new">
          <p style={{ margin: 'auto', paddingTop: '4px', paddingBottom: '4px' }}>
            NEW
          </p>
        </div>
      )}
      <div style={{ marginBottom: '5px', position: 'relative', minHeight:"150px" }}>
        <LazyLoadImage
          scrollPosition={scrollPosition}
          style={{
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
            filter: views && 'grayscale(100%)',
            WebkitFilter: views && 'grayscale(100%)',
            width: '100%',
            maxHeight: '20vh',
            objectFit: 'cover',
          }}
          width={'100%'}
          src={currentContent.imageHeaderUrl}
        />
        {
          journeyId && (
            <div className="premium-label" style={{backgroundColor:useLocation().pathname.includes("helpipedia")?HELPipedia_ORANGE:"#91cf4f"}}>
              <img src={require('../../assets/icons/star.svg')} alt="star" className="premium-label__icon" />
              <span>Premium</span>
            </div>
          )
        }
      </div>
      <div style={{ padding: '15px' }}>
        <p className='capsule_card-title'
          style={{

            color: views && "#ccc"
          }}
        >
          {currentContent.title}
        </p>
        <p style={{ fontSize: '20px', marginBottom: '70px' }}>
          {/* {currentContent.briefAbout} */}
        </p>
        {coach !== undefined && (
          <div
            style={{
              display: 'flex',
              marginTop: '30px',
              alignItems: 'center',
              position: 'absolute',
              bottom: 0,
            }}
          >
            <img
              style={{
                borderRadius: '100%', height: '50px', width: '50px', filter: views && 'grayscale(100%)',
                WebkitFilter: views && 'grayscale(100%)',
              }}
              src={coach?.userProfile?.avatarUrl}
            />
            <div
              style={{
                marginLeft: '15px',
                marginBottom: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
                justifyContent: 'center',
                color: views && "#ccc"
              }}
            >
              <p className='capsule_card-coach' >
                {coach?.userProfile?.firstName}
                {' '}
                {coach?.userProfile?.lastName}
              </p>
              {currentContent.videoInfo && (
                <p className='capsule_card-coach' >
                  {' '}
                  {currentContent.videoInfo.numberOfVideos}
                  {' '}
                  videos •
                  {' '}
                  {formatTime(currentContent?.videoInfo?.time)}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
      {
        status === CapsuleStatusesEnum.PASSED ? (
          <img
            src={checkRoundGreenImg}
            alt="check_round_green"
            className="capsule_card--check_icon"
          />
        ) : isBlocked ? (
          <img
            src={lockImg}
            alt="lock_image"
            className="capsule_card--check_icon"
          />
        ) : null
      }


      {journeyId && <Modal open={modal} onClose={() => setModal(false)}>
        <JourneyPaymentForm
          className="journey--modal"
          price={data && data.data?.getUserJourneyById.price}
          journeyId={journeyId}
          onSuccess={() => setModal(false)}
        />
      </Modal>}
    </div>
  );
};

export default CapsuleCard;
