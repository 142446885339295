import React, {useRef, useState} from 'react'
import {Link, useLocation} from 'react-router-dom';
import HamburgerMenu from 'react-hamburger-menu';
import Logo from 'components/Logo/Logo';
import {useOnClickOutside} from 'utils/useOutsideClick';

const MobileNavBar = ({getLinks}: any) => {
  const [open, setOpen] = useState(false);

  const ref = useRef(null);
  useOnClickOutside(ref, () => setOpen(false));
const isHelpipedia=useLocation().pathname.includes("helpipedia")
  return (
    <div className="navbar" ref={ref}>
      {open && (
        <div className="navbar__container" >
          <Link to={isHelpipedia?"/helpipedia":"/"} className="navbar__link">
            Home
          </Link>
          {getLinks()}
         { isHelpipedia?<Link
            to="/about"
            className="navbar__link"
          >
            About HELPipedia
          </Link>: <Link
            to="/about"
            className="navbar__link"
          >
            About Steerus
          </Link>}
        </div>
      )}
      <div className="navbar__burger-container">
        <HamburgerMenu
          isOpen={open}
          menuClicked={() => setOpen(!open)}
          width={18}
          height={15}
          strokeWidth={3}
          rotate={0}
          color="black"
          borderRadius={0}
          animationDuration={0.5}
        />
      </div>
      <Logo/>
    </div>
  )
}

export default MobileNavBar