import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Button, Space, Table } from "antd";
import { GET_ALL_PLAYLISTS } from "dashboard/query";
import { modal } from "utils/modal";
import CreatePlaylistModal from "../modals/createPlaylistModal";
import MobileTable from "../../MobileTable/MobileTable";
import { useMediaQuery } from "react-responsive";


const PlaylistTable = () => {
  const query = useQuery(GET_ALL_PLAYLISTS, { fetchPolicy: "network-only" });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 700px)",
  });

  const GET_BADGES = gql`
    query getBadges {
        getBadges {
          id
          imageUrl
          caption
      }
    }
  `;

  const { data: badges, loading } = useQuery(GET_BADGES);

  const columns: any = [
    {
      title: "Playlist Title",
      render: (value: any) => value.title,
    },

    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          <Button
            onClick={() =>
              modal.showModal(CreatePlaylistModal, {
                playlist: record,
                badges: badges.getBadges
              })
            }
          >
            View
          </Button>
        </Space>
      ),
    },
  ];

  if (!query.loading && query.data) {
    return (
      <>
        <div className="admin__table">
          <h1 style={{ flex: 1, margin: "auto" }}> Playlists </h1>
          <div>
            <Button
              onClick={() =>
                modal.showModal(CreatePlaylistModal, {
                  playlistQuery: query,
                  badges: badges.getBadges
                })
              }
            >
              Create Playlist
            </Button>
          </div>
        </div>

        {!isTabletOrMobileDevice ? (
          <Table rowKey="id" columns={columns} dataSource={query?.data?.getAllPlaylists} />
        ) : (
          <MobileTable data={query?.data?.getAllPlaylists} columns={columns} />
        )}
      </>
    );
  }

  return null;
};

export default PlaylistTable;
