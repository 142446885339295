import React from 'react'

const ProgressBar = ({ progress, height }) => {

    const Parentdiv = {
        height: height,
        width: '100%',
        backgroundColor: 'whitesmoke',
        borderRadius: 40,
    
    }

    const Childdiv = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: '#92D050',
        borderRadius: 40,
        textAlign: 'right'
    }

    return (
        <div style={Parentdiv}>
            <div style={Childdiv}>
            </div>
        </div>
    )
}

export default ProgressBar;