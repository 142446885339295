import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Space, Table } from 'antd';
import { useNotification } from 'hooks';
import { GET_ALL_ATTACHMENTS, DELETE_ATTACHMENT } from 'dashboard/query';
import { modal } from 'utils/modal';
import CreateAttachmentsModal from '../modals/createAttachmentsModal';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import MobileTable from '../../MobileTable/MobileTable';
import { useMediaQuery } from 'react-responsive';

const AttachmentsTable = () => {
  const query = useQuery(GET_ALL_ATTACHMENTS, { fetchPolicy: 'network-only' });
  const notification = useNotification();
  const [deleteAttachment] = useMutation(DELETE_ATTACHMENT);

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-width: 700px)',
  });

  const onDeleteAttachment = (id: string, title: string) => {
    deleteAttachment({ variables: { id } }).then((response) => {
      if (response?.data?.deleteAttachment) {
        notification({ type: 'success', message: `${title} has been successfully deleted` });
        query.refetch();
      } else {
        notification({ type: 'error' });
      }
    });
  };

  const columns: any = [
    {
      title: 'Attachment Name',
      render: (value: any) => value?.name,
      key: 'name',
    },
    {
      title: 'Type',
      render: (value: any) => value?.type,
      key: 'type',
    },
    {
      title: 'Url',
      render: (value: any) => value?.url,
      key: 'url',
    },
    {
      title: 'Action',
      key: 'action',
      render: (value: any) => (
        <Space size="middle">
          <Button
            onClick={() =>
              modal.showModal(ConfirmationModal, {
                title: 'Are you sure?',
                message: 'Do you want to delete an attachment?',
                onDelete: () => {
                  onDeleteAttachment(value.id, value.name);
                  modal.hideModal();
                },
              })
            }
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  if (!query?.loading && query?.data) {
    return (
      <>
        <div className="admin__table">
          <h1 style={{ flex: 1, margin: 'auto' }}> Attachments </h1>
          <div>
            <Button
              onClick={() =>
                modal.showModal(CreateAttachmentsModal, {
                  attachmentQuery: query,
                })
              }
            >
              Add attachments
            </Button>
          </div>
        </div>
        {!isTabletOrMobileDevice ? (
          <Table columns={columns} rowKey="id" dataSource={[...query?.data?.getAttachments]} scroll={{ x: 1300 }} />
        ) : (
          <MobileTable data={query?.data?.getAttachments} columns={columns} />
        )}
      </>
    );
  }

  return null;
};

export default AttachmentsTable;
