import {gql} from "@apollo/client";

export const GET_SKILL_CHECK = gql`
  query getSkillCheckByQuestionId($id: ID!) {
    getUserSkillCheckByQuestionId(questionId: $id) {
      id
      title
      description
      questions
      answers
    }
  }
`;

export const SET_SKILL_CHECK_BY_QUESTION = gql`
  mutation SetUserSkillCheckByQuestionIdMutation($id: ID!, $answers: JSON) {
  setUserSkillCheckByQuestionId(questionId: $id, answers: $answers) {
    id
    title
    description
  }
}
`;