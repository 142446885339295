import React from 'react';
import { modal } from 'utils/modal';
import { Modal } from 'antd';

const ModalScrollable = ({ title = '', visible = true, footer = [], children, onClose, className = '', ...rest }: any) => {
  return (
    <Modal
      visible={visible}
      title={title}
      centered
      style={{ animation: 'none' }}
      footer={footer}
      className={`${footer.length ? '' : 'hide-footer'} ${className}`}
      onCancel={onClose || modal.hideModal}
      {...rest}
    >
      {children}
    </Modal>
  );
};

export default ModalScrollable;