import React from 'react';

import './styles.less';

function Button({
  children,
  color = 'green',
  disabled = false,
  type = 'button',
  fullWidth = false,
  className = '',
  onClick,
}) {
  return (
    <button
      type={type}
      className={`button ${color} ${disabled && 'disabled'} ${fullWidth && 'fullWidth'} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default Button;
