// @ts-nocheck
import React from 'react';
import { ModalWrapper } from 'reoverlay';
import ReactPlayer from 'react-player';

import 'reoverlay/lib/ModalWrapper.css';

const ConfirmModal = ({ videoUrl }: { videoUrl: string }) => (
  <ModalWrapper animation="fade">
    <div style={{ padding: '20px', backgroundColor: 'white', borderRadius: '6px' }}>
      <ReactPlayer
        controls
        width="70vw"
        height="50vh"
        style={{
          borderBottomLeftRadius: '6px',
          overflow: 'hidden',
        }}
        url={[
          {
            src: videoUrl,
            type: 'video/mp4',
          },
        ]}
      />
    </div>
  </ModalWrapper>
);

export default ConfirmModal;
