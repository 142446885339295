// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { Button, Form, Input, message, Modal, Space, Spin } from 'antd';
import { STEERUS_GREEN } from '../styles/colors';
import { HeroHalf, PageLayout } from '../components'
import './styles.less';

const LOGIN = gql`
  mutation login($email: String, $password: String) {
    login(email: $email, password: $password) {
      tokens {
        accessToken
        refreshToken
      }
      error {
        field
        message
      }
      user {
        id
        confirmedEmail
        role
      }
    }
  }
`;

const CONFIRM_EMAIL = gql`
  mutation confirmEmail($tokenInput: String) {
    confirmEmail(tokenInput: $tokenInput)
  }
`;

const SEND_CONFIRM_EMAIL = gql`
  mutation sendConfirmEmail($email: String, $file: String) {
    sendConfirmEmail(email: $email, file: $file)
  }
`;

const SignupForm = ({submitConfirmEmail, history, setLoading, setModal, setEmail }) => {
  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted
  const [confirmEmail] = useMutation(CONFIRM_EMAIL);
  const [login, { loading }] = useMutation(LOGIN);
  const params = useParams();

  useEffect(() => {
    setLoading(loading);
    if (params.token) {
      const effect = async () => {
        await confirmEmail({ variables: { tokenInput: params?.token } });
      };
      effect().catch((e) => console.log(e));
    }
  }, [params.token, loading]);

  const defaultMargin = {
    marginTop: '22px',
  };
  const inputStyles = {
    width: '100%',
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        setEmail(values.email);
        const result = await login({
          variables: values,
        });
        if (result?.data?.login?.user) {
          if (!result?.data?.login?.user?.confirmedEmail) {
            setModal(true);
            submitConfirmEmail(values.email)
          } else {
            if (result.data.login.error) {
              if (result.data.login.error.message === 'email') {
                message.error('Your email address or password is incorrect');
              } else if (result.data.login.error.message === 'password') {
                message.error('Your email address or password is incorrect');
              } else {
                message.error('There was an error. Try again later');
              }
            } else {
              localStorage.setItem('token', result.data.login.tokens.accessToken);
              localStorage.setItem('refreshToken', result?.data?.login?.tokens?.refreshToken);

              history.push('/academy');
            }
          }
        } else {
          message.error('Email or password not correct!');
        }
      } catch (e) {
        setLoading(false);
      } finally {
        setTimeout(() => setLoading(false), 3000);
      }
    },
  });

  return (
    <Form layout="vertical" onFinish={formik.handleSubmit}>
      <h1 className="login__title"> None of the magic happens until you sign in </h1>
      <Form.Item label="Email" name="Email" style={defaultMargin}>
        <Input id="email" name="email" label="email" type="email" required onChange={formik.handleChange} value={formik.values.email} style={inputStyles} />
      </Form.Item>

      <Form.Item label="Password" name="Password" style={defaultMargin}>
        <Input id="password" name="password" type="password" required onChange={formik.handleChange} value={formik.values.password} style={inputStyles} />
      </Form.Item>

      <Form.Item style={defaultMargin}>
        <Button style={{ width: '100%' }} type="primary" htmlType="submit">
          Log in
        </Button>
      </Form.Item>

      {/*<p style={{color: STEERUS_GREEN, textAlign: 'center'}}>New to STEERus?</p>*/}
      <Button style={{ width: '100%', marginBottom: '20px' }} type="primary" htmlType="submit" onClick={() => history.push('/signup')}>
        Register now
      </Button>
      <Button style={{ width: '100%' }} type="primary" htmlType="submit" onClick={() => history.push('/forgot-password')}>
        Forgot password?
      </Button>
    </Form>
  );
};

const App = () => {
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendConfirmEmail, { loading: sendLoading }] = useMutation(SEND_CONFIRM_EMAIL);
  const [email, setEmail] = useState('');

  const submitConfirmEmail = async (emailParam) => {
    await sendConfirmEmail({
      variables: { email: emailParam, file: "loginContainer.tsx" },
    });
    setModal(false);
    message.success('Confirmation email has been sent to you');
  };

  return (
    <PageLayout hideFooter>
      <Modal onCancel={() => setModal(false)} visible={modal} footer={null} title="Your email is not confirmed.">
        <div className="login-modal__body">
          <p className="login-modal__body-text">Please check your Inbox or Spam folders to verify your account.</p>
          {sendLoading && <Spin size="large" style={{ margin: '10px auto' }} />}
          <div style={{ marginTop: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <button disabled={sendLoading} onClick={() => submitConfirmEmail(email)} className="ant-btn ant-btn-primary">
              Resend confirmation email
            </button>
            <button disabled={sendLoading} onClick={() => setModal(false)} className="ant-btn ant-btn-primary">
              OK
            </button>
          </div>
        </div>
      </Modal>
      {loading && (
        <Space
          style={{
            position: 'fixed',
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            zIndex: 100000,
          }}
        >
          <Spin
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '100px',
              height: '100px',
            }}
            className="login-spin"
          />
        </Space>
      )}
      <HeroHalf
        rightColor={STEERUS_GREEN}
        leftComponents={
          <div>
            <SignupForm submitConfirmEmail={submitConfirmEmail} history={history} modal={modal} setModal={setModal} setLoading={setLoading} setEmail={setEmail} />
          </div>
        }
      />
    </PageLayout>
  );
};

export default App;
