import React from 'react';
import toucan from '../../assets/logo/toucan.png';
import ButtonLink from '../../components/Buttons/ButtonLink';
import ReactPlayer from 'react-player';

const MentalHealth = () => {
  return (
    <div className="games">
      {/*<h2 className="games__title">*/}
      {/*  We are so excited to partner with 2DaysMood!*/}
      {/*</h2>*/}
      {/*<div className="games__inner">*/}
      {/*  <div className="games__card games__getStarted">*/}
      {/*    <img src={daysmood} alt="2Days Mood Logo" className="games__logo" />*/}
      {/*    <p className="games__slogan">What’s your mood?</p>*/}
      {/*    <ButtonLink*/}
      {/*      style="center"*/}
      {/*      title="GO FOR IT"*/}
      {/*      link="steerusbefore.2daysmood.com"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  <div className="games__card games__introduce">*/}
      {/*    <p className="games__slogan">*/}
      {/*      Watching how your mood changes over time in response to various*/}
      {/*      experiences builds your self-awareness. And it can build your*/}
      {/*      resilience. We’re pleased to partner with 2DaysMood as a quick*/}
      {/*      1-click way to track mood. Just click an emoji. Done!*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <h2 className="games__title">Your well-being is critical. Without it, career readiness and everything else fades to the background.</h2>
      <div className="games__inner">
        <div className="games__card" style={{height:250,width:400}}>
          <ReactPlayer
          controls
          // light="https://image.mux.com/nlRRK025x2V3CdXw6SNW01yPr8wM9HO9HSrls00YkJUtQI/thumbnail.png?width=512&height=512&fit_mode=pad"
          style={{
            maxWidth: '100%',
            height: 50,
            overflow: 'hidden',
            paddingBottom: 10,
          }}
          url="https://youtu.be/C0IeJShmUj0"
        />
          {/* <img src={toucan} alt="The Toucan App Logo" className="games__logo" /> */}
          {/* <p className="games__slogan">Talk to someone now.</p> */}
          <ButtonLink style="centre" title="GO TO PREVENTION PORTAL" link="https://www.longislandprep.org/" />
        {/* < BtnLink/> */}
        </div>
        <div className="games__card games__introduce">
          <p className="games__slogan">
          We invite you to explore our online solution for addiction prevention (RICAPP). Our team has designed programs for students and for teachers as we all know that addiction can happen at any age. RICAPP utilizes advanced assistive technology software, driven by data, to provide a robust framework for intervention with mental health and substance abuse. It has proven to be an invaluable asset to educators and counselors like yourselves. 
          </p>
        </div>
      </div>
    </div>
  );
};

export default MentalHealth;