import React from 'react';
import { useQuery } from '@apollo/client';
import InfoCard from './InfoCard';
import PaymentForm from './PaymentForm';
import { GET_PAYMENT_INTENT_BY_JOURNEY_ID } from './query';
import Spinner from '../Spinner';
import './styles.less';

function JourneyPayment({ price, journeyId, onSuccess, email }) {
  const { data, loading } = useQuery(GET_PAYMENT_INTENT_BY_JOURNEY_ID, {
    variables: { getPaymentIntentByJourneyIdJourneyId: journeyId, email },
  });

  const content = loading ? (
    <InfoCard />
  ) : (
    <PaymentForm
      paymentData={data}
      price={price}
      onSuccess={onSuccess}
      email={email}
      itemToPurchase="Purchase to unlock Journey"
    />
  );

  return <Spinner loading={loading}>{content}</Spinner>;
}

export default JourneyPayment;