import React from 'react';
import { useQuery } from '@apollo/client';
import {
  Button, Form, Input, Select,
} from 'antd';
import { GetCoachesQuery } from '../../query';
import InputFile from './InputFile';
import InputNumber from '../../../components/InputNumber/InputNumber';
import { SOFT_SKILLS_TAGS, TAGS } from 'utils';

const { TextArea } = Input;

const BasicDetails = ({
  title, description, price, coachId, coachData, file, image, imageUrl, handleChange, setFieldValue, setFileIsSelected,
  softskillTags, tags, skillCheck, skillCheckUrl
}) => {
  const { data: coaches } = useQuery(GetCoachesQuery);
  const isNotDeletedCoach = coaches?.getCoaches?.CoachUser?.some(coach => coach.id === coachId);

  const onChangeFile = (value) => {
    setFileIsSelected(!!value?.originFileObj);
    setFieldValue('file', value?.originFileObj);
  }
  const onChangeImage = (value) => setFieldValue('image', value?.originFileObj);

  const onChangeSkillCheck = (value) => setFieldValue('skillCheck', value?.originFileObj);

  return (
    <div className="edit-form__column">
      <h1>Basic Details</h1>
      <Form.Item label="Title" name="title">
        <Input
          id="title"
          name="title"
          label="Title"
          required
          onChange={handleChange}
          value={title}
          defaultValue={title}
        />
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
      >
        <TextArea
          id="description"
          name="description"
          onChange={handleChange}
          value={description}
          defaultValue={description}
          rows={5}
        />
      </Form.Item>

      <Form.Item label="Journey Price">
        <InputNumber
          id="price"
          name="price"
          min={1}
          required
          value={price}
          onChange={(event) => {
            event.persist();
            handleChange({ target: { value: parseFloat(event.target.value), name: 'price' } });
          }}
        />
      </Form.Item>

      {
        coaches?.getCoaches && (
          <Form.Item name="coachId" label="Coach">
            <Select
              defaultValue={isNotDeletedCoach ? coachId : `${coachData.firstName} ${coachData.lastName}`}
              onChange={(e) => setFieldValue('coachId', e)}
            >
              {coaches?.getCoaches?.CoachUser?.map((coach) => (
                <Select.Option value={coach.id} key={coach.id}>
                  {coach.userProfile.firstName} {coach.userProfile.lastName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )
      }
      <Form.Item name="softskillTags" label="SoftSkill Tags">
        <Select
          onChange={(e) => setFieldValue('softskillTags', e)}
          type="text"
          defaultValue={softskillTags}
          value={softskillTags}
          mode="tags"
          placeholder="SoftSkill Tags"
        >
          {SOFT_SKILLS_TAGS.map((tag) => (
            <Select.Option key={tag} value={tag}>
              {tag}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="tags" label="Tags">
        <Select
          onChange={(e) => setFieldValue('tags', e)}
          type="text"
          defaultValue={tags}
          value={tags}
          mode="tags"
          placeholder="Tags"
        >
          {TAGS.map((tag) => (
            <Select.Option key={tag} value={tag}>
              {tag}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Journey Preview Image" name="image">
        <InputFile
          file={{ name: image || imageUrl, url: image || imageUrl }}
          accept="image/*"
          onChange={onChangeImage}
        />
      </Form.Item>

      <Form.Item label="Journey Intro Video" name="filePresent">
        <InputFile
          file={file}
          accept="video/*"
          onChange={onChangeFile}
        />
      </Form.Item>
    <Form.Item label="Upload a Quiz" name="skillCheck">
        {skillCheckUrl && 
          <Button style={{ marginBottom: '10px' }} onClick={() => window.open(skillCheckUrl, '_Blank')}>
          Download Uploaded Quiz
          </Button>
        }
        <InputFile
          file={skillCheck || {}}
          accept=".csv"
          onChange={onChangeSkillCheck}
        />
      </Form.Item>
    </div>
  );
};

export default BasicDetails;
