import React from 'react';
import { StandardMarginDiv, PageLayout } from '../components';
import './styles.less';

const TermsOfUse = () => {
  return (
    <PageLayout>
      <StandardMarginDiv>
        <div className="policy">
          <p className="policy__description">
            Effective: 5-May-2024
            <br />
            <br />
            Thank you for selecting STEERus as part of your personal learning and growth. This STEERus Agreement (this “Agreement”) is entered into by and
            between STEERus INC, a New Jersey corporation, with a principal place of business at 679 Westwood Ave #2155 River Vale, NJ 07675 (“STEERus”), and
            the entity agreeing to these terms (“Organization”) be it an individual or place of business. This Agreement is effective as of the date you click
            the “I Accept” button below or, if applicable, the date this Agreement is countersigned (the “Effective Date”). If you are accepting on behalf of
            your employer or another entity, you represent and warrant that: (a) you have full legal authority to bind your employer, or the applicable entity,
            to these terms and conditions; (b) you have read and understand this Agreement; and (c) you agree, on behalf of the party that you represent, to
            this Agreement. If you don&#39;t have the legal authority to bind your employer or the applicable entity, please do not click the “I Accept” button
            below (or, if applicable, do not sign this Agreement). This Agreement governs Organization&#39;s access to and use of the Services (as defined
            below).
          </p>
          <h3 className="policy__title">
            THESE TERMS INCLUDE AN ARBITRATION AGREEMENT AND CLASS ACTION WAIVER THAT APPLY TO ALL CLAIMS BROUGHT AGAINST STEERus. PLEASE READ THEM CAREFULLY.
          </h3>

          {/* <p className="policy__description">We know – we get it – legal beagle stuff makes your eyes roll and glaze
            over. But this stuff is important. Please read these Terms and Conditions ("Terms", "Terms and Conditions")
            carefully before using our <a href="www.STEERus.io">www.STEERus.io</a> website and accompanying platform (collectively, the "Service")
            currently owned and operated by Toasted Marshmellow, LLC ("us", "we", or "our"). </p>
          <p className="policy__description">Your access to and use of the Service is conditioned on your acceptance of
            and compliance with these Terms. And yes, these Terms apply to you and all visitors, users and others who
            access or use our website and/or platform.</p>
          <h3 className="policy__subtitle">
            By accessing or using the Service you agree to be bound by these Terms. We’ve kept things simple. If you
            disagree with any part of the terms, then you should not access or use the Service. By engaging in our
            Service, you have agreed to all terms and conditions. Simple, right?
          </h3> */}
          <h3 className="policy__subtitle">Services</h3>
          <p className="policy__description">
            1. Where applicable, Organization will pay STEERus the fees (“Fees”) for the STEERus services (the “Services”) selected by Organization. Except as
            otherwise explicitly indicated by STEERus, the Fees shall be non-refundable and non-cancelable. An “Unlimited” option means access for a single
            participant to view the content purchased in an unlimited manner.
            <br />
            <br />
            2. STEERus may make commercially reasonable changes to the Services from time to time. If STEERus makes a material change to the Services, STEERus
            will inform Organization via reasonable method(s).
          </p>
          <h3 className="policy__subtitle">Obligations</h3>
          <p className="policy__description">
            1. STEERus will provide the Services specified above to Organization.
            <br />
            <br />
            2. STEERus may use Organization’s name, logos, and other brand features to list Organization as a customer and create and make publicly available
            case studies highlighting the relationship of the parties subject to mutual agreement as to the specific content.
            <br />
            <br />
            3. Organization will cooperate to ensure each User’s compliance with STEERus’ user policies, as described below. Each party will respect the
            confidentiality and privacy of such User data and operate in accordance with applicable law with respect to its use and handling of same. The data
            protection terms are found in the accompanying document and shall be incorporated into this Agreement.
            <br />
            <br />
            4. Organization agrees to implement and maintain technical and organizational measures and procedures to ensure an appropriate level of security for
            participants’ personal information, including protecting such personal information against the risks of accidental, unlawful, or unauthorized
            destruction, loss, alteration, disclosure, dissemination or access.
            <br />
            <br />
            5. Organization will not (either directly or indirectly): (i) copy, sublicense, rent, lease, barter, swap, resell, or commercialize the Platform,
            Courses, or Specializations, in whole or in part; (ii) transfer, transmit, enable, or allow access to or use of the Platform, Courses, or
            Specializations, whether in whole or in part, by any means, to a third party; (iii) create external derivative works of the Platform, Courses, or
            Specializations; (iv) use the Platform, Courses, or Specializations in any manner that is fraudulent, deceptive, threatening, harassing, defamatory,
            unlawful, illegal, obscene, or otherwise objectionable in STEERus’ reasonable discretion; (v) “crawl,” “scrape,” “spider,” or otherwise copy or
            store any portion of the Platform, Courses, or Specializations for any purpose not contemplated under this Agreement (e.g., in order to mimic the
            functionality and/or output of the Platform, Courses, or Specializations, in whole or in part); (vi) disassemble, reverse engineer, decompile, or
            otherwise attempt to obtain the source code or underlying logic of any portion of the Platform, Courses, or Specializations; (vii) use the Platform,
            Courses, or Specializations as part of any machine learning or similar algorithmic activity; or (viii) publish or distribute the Platform, Courses,
            or Specializations, or materials derived from the Platform, Courses, or Specializations, to third parties.
          </p>
          <h3 className="policy__subtitle">Billing</h3>
          <p className="policy__description">
            When placing its order for the Services, Organization may elect from among the billing options offered by STEERus on the order page. STEERus may
            change its offering of billing options, including by limiting or ceasing to offer any billing option. Unless otherwise stated, all fees are quoted
            in U.S. Dollars. When paying in currencies other than U.S. Dollars, the actual fees billed will depend on then current exchange rates. STEERus
            reserves the right to change any fees at any time at its sole discretion. Any change, update, or modification will be effective immediately upon
            posting through the relevant Services.
          </p>
          <h3 className="policy__subtitle">Refunds and Taxes</h3>
          <p className="policy__description">
            STEERus does not offer refunds (even if Organization has opted to pay their Fees in multiple installments). To request a refund, Organization must
            contact customer support to discuss why a refund has been requested. Except as otherwise explicitly indicated by STEERus, all Fees hereunder are
            non-cancelable and non- refundable. Organization will be responsible for the payment of all federal, state, and local sales, use, value added, or
            other taxes that are levied or imposed on it by reason of the transactions under this Agreement (other than for taxes based on STEERus’ income). If
            a party is required to pay any taxes for which the other party is responsible, then the taxes will be billed to and paid by the responsible party.
            <s>
              {' '}
              If Organization is claiming tax exempt status, Organization shall provide sufficient evidence of tax exemption status from the applicable tax
            </s>
            .
          </p>
          <h3 className="policy__subtitle">Term</h3>
          <p className="policy__description">
            The term of this Agreement shall commence on the Effective Date and shall continue in full force and effect in accordance with the termination
            provisions below (the “Term”). Access may be subject to change as content and services may change over the course of the term.
          </p>
          <h3 className="policy__subtitle">Termination</h3>
          <p className="policy__description">
            1. <b>Termination</b>. Either party may suspend performance or terminate this Agreement if: (i) the other party is in material breach of this
            Agreement and fails to cure that breach within 60 days after receipt of written notice; or (ii) the other party ceases its business operations or
            becomes subject to insolvency proceedings and the proceedings are not dismissed within 90 days. In addition, STEERus may immediately suspend
            performance if payment for any Fees is overdue and may immediately suspend performance or terminate this Agreement if Organization facilitates any
            participant’s violation of the STEERus Terms of Use or other policies or if STEERus determines that its association with Organization has an adverse
            impact on the business or reputation of STEERus, its licensors, or other partners. STEERus may at any time for any reason suspend or terminate use
            of the Services by any participant or other individual. THIS IS TOO STRONG WITHOUT ANY REIMBURSEMENT
            <br />
            <br />
            2. <b>Effects of Termination</b>. If this Agreement terminates, then: (i) all rights granted and obligations incurred by one party to the other that
            are intended to cease upon termination will cease immediately (and Organization shall forfeit all unused Enrollments and uncompleted Courses); (ii)
            Organization will promptly pay STEERus all outstanding amounts due and payable as of the expiration date; and (iii) upon request, each party will
            promptly return or destroy all Confidential Information of the other party.
          </p>
          <h3 className="policy__subtitle">Acceptable Use</h3>
          <p className="policy__description">
            1. <b>Removal</b>. STEERus may remove or edit inappropriate content or activity identified by or reported. STEERus may separately suspend, disable,
            or terminate Organization’s access to all or part of the Services. Organization is You are prohibited from using STEERus Content and Services to
            share content that: &#x2022; Contains illegal content or promotes illegal activities with the intent to commit such activities.
            <br />
            &#x2022; Contains credible threats or organizes acts of real-world violence. STEERus does not allow content that creates a genuine risk of physical
            injury or property damage, credibly threatens people or public safety, or organizes or encourages harm.
            <br />
            &#x2022; Harasses others: STEERus encourages commentary about people and matters of public interest, but abusive or otherwise inappropriate content
            directed at private individuals is not allowed.
            <br />
            &#x2022; Violates intellectual property, privacy, or other rights. Organization is not permitted to share content that it does not have the legal
            rights to share, claim content that it did not create as its own, or otherwise infringe or misappropriate someone else’s intellectual property or
            other rights. Organization is strongly encouraged to attribute materials used or quoted to the original copyright owner.
            <br />
            &#x2022; Spams others. Organization is prohibited from sharing irrelevant or inappropriate advertising, promotional, or solicitation content.
            <br />
            &#x2022; Otherwise violates the STEERus Terms of Use.
            <br />
            <br />
            2. <strong>Breach</strong>. Please note that specific Content Offerings may have additional rules and requirements. Organization is not allowed to:
            &#x2022; Do anything that violates local, state, national, or international law or breaches any of its contractual obligations or fiduciary duties.
            <br />
            &#x2022; Share its password, allow others to access your account, or do anything that might put its account at risk.
            <br />
            &#x2022; Attempt to access any other user&#39;s account.
            <br />
            &#x2022; Reproduce, transfer, sell, resell, or otherwise misuse any content from STEERus Services, unless specifically authorized to do so.
            <br />
            &#x2022; Access, tamper with, or use non-public areas of STEERus’ systems, unless specifically authorized to do so.
            <br />
            &#x2022; Break or circumvent STEERus authentication or security measures or otherwise test the vulnerability of STEERus systems or networks, unless
            specifically authorized to do so.
            <br />
            &#x2022; Try to reverse engineer any portion of STEERus Services, Platform, or other materials offered.
            <br />
            &#x2022; Try to interfere with any user, host, or network, for example by sending a virus, overloading, spamming, or mail-bombing.
            <br />
            &#x2022; Use STEERus Services to distribute malware.
            <br />
            &#x2022; Use STEERus Services or any functionality of the platform for anything other than for the intended purpose of learning and improving
            skills.
            <br />
            &#x2022; Impersonate or misrepresent its affiliation with any person or entity.
            <br />
            &#x2022; Encourage or help anyone do any of the prohibited things on this list.
            <br />
            &#x2022; Solicit any of STEERus professionals or their content for reuse on its own platform, site, or as part of its own programs and services.
            <br />
            <br />
            NOTE: STEERus may disclose personal may disclose potentially personally identifying information only in response to a subpoena, court order, or
            other governmental requests, or when we believe in good faith that disclosure is reasonably necessary to protect our property or rights, third
            parties or the public at large.
            <br />
            <br />
            3. <strong>Copyrights and Trademarks</strong>. Copyrights and all other intellectual property rights in the content of this Site and registered and
            unregistered trademarks, names, logos displayed on this Site are owned by or licensed to STEERus. As expressly set out below, all intellectual
            property rights are reserved by STEERus. &#x2022; Content included on the Site may be accessed by Organization for its own internal non-commercial
            use only.
            <br />
            &#x2022; Organization may not copy, perform, publish, modify, transfer, create derivative works from, commercially exploit or otherwise use in whole
            or part any content, software, products, or services obtained from this Site.
            <br />
            &#x2022; Organization may not copy, publish, transfer, delete or alter, in part or in whole, any copyright, trademark, intellectual property or
            legal notices from any part of this Site.
            <br />
            &#x2022; Organization is not permitted to do anything that violates local, state, national, or international law or breaches any of its contractual
            obligations or fiduciary duties.
            <br />
            &#x2022; When Organization submits content to STEERus or posts any comment, photo, image, video or any other submission for use on or through
            STEERus Programs, Product, Services, or Program Materials, Organization is granting STEERus and anyone authorized by STEERus, an unlimited,
            royalty-free, perpetual, irrevocable, non-exclusive, unrestricted worldwide license to use, copy, modify, transmit, sell, exploit, create derivative
            works from, distribute, and/or publicly perform or display such contributions in whole or in part.
          </p>

          <h3 className="policy__subtitle">Confidential Information</h3>
          <p className="policy__description">
            1. <strong>Obligations</strong>. Each party will: (i) protect the other party’s Confidential Information with the same standard of care it uses to
            protect its own Confidential Information; and (ii) not disclose the Confidential Information, except to affiliates, participants, and agents who
            need to know it and who have agreed in writing to keep it confidential and who are trained and reliable. Each party (and any affiliates,
            participants, and agents to whom it has disclosed Confidential Information) may use Confidential Information only to exercise rights and fulfill
            obligations under this Agreement, while using reasonable care to protect it. Each party is responsible for any actions of its affiliates,
            participants, and agents in violation of this section. “Confidential Information” means information disclosed by a party to the other party under
            this Agreement that is marked as confidential or would normally be considered confidential under the circumstances.
            <br />
            <br />
            2. <strong>Exceptions</strong>. Confidential Information does not include information that: (i) the recipient of the Confidential Information
            already knew; (ii) becomes public through no fault of the recipient; (iii) was independently developed by the recipient; or (iv) was rightfully
            given to the recipient by another party.
            <br />
            <br />
            3. <strong>Required Disclosure</strong>. Each party may disclose the other party’s Confidential Information when required by law and must notify the
            other party of such disclosure.
          </p>

          <h3 className="policy__subtitle">Representations and Disclaimers</h3>
          <p className="policy__description">
            1. <strong>Representations</strong>. Each party represents that: (i) it has full power and authority to enter into this Agreement; and (ii) it will
            comply with all laws and regulations applicable to its performance of its obligations under this Agreement. Notwithstanding any other provisions of
            this Agreement, neither Party shall take action under this Agreement or in connection with its business that would cause it to be in violation in
            any applicable jurisdiction, of (i) anti-corruption laws and regulations, including but not limited to the Foreign Corrupt Practices Act (U.S) and
            The Bribery Act 2010 (U.K.); (ii) anti- money laundering laws or regulations; or (iii) the various economic sanctions programs administered by the
            U.S. Department of Treasury’s Office of Foreign Assets Control (OFAC regulations) and the U.S. Department of State’s Office of Terrorism Finance and
            Economic Sanctions Policy. Organization represents that it is not currently listed on any Excluded or Denied Party List maintained by any U.S.
            government agency.
            <br />
            <br />
            2. <strong>Non-Academic and Academic Credit Use</strong>. Courses under this Agreement shall be used for Organization’s standard learning and
            development training purposes. Such courses are to be used as supplemental materials to in-person instruction. If Organization chooses to assign
            credit for such content, Organization is solely responsible for ensuring adequate pathways towards degree completion based on content availability.
            Organization acknowledges that: 1) STEERus does not represent or warrant that the content meets any accreditation or regulated learning time
            standards, 2) STEERus will not be responsible to update Organization on any substantive changes or availability of content, and 3) STEERus does not
            guarantee the availability of the content.
            <br />
            <br />
            3. <strong>Limitations</strong>. Coaching, counseling, mentoring, and providing any other forms of advice on this platform or Site, in regard to
            STEERus content, or via STEERus services has its limitations. The information provided is intended to be instructional, directional, and educational
            – what to do with it is at the discretion of the Organization. There are no guarantees for a positive outcome. Outcomes are dependent on
            Organization actions, decisions, and choices. If the Organization has a negative experience with one of the professionals on this platform, negative
            being defined as harassing or abusive, contact STEERus immediately to begin an investigation into the alleged misconduct.
            <br />
            <br />
            4. <strong>Disclaimers</strong>. EXCEPT AS EXPRESSLY PROVIDED FOR HEREIN, NEITHER PARTY MAKES ANY OTHER WARRANTY OF ANY KIND, WHETHER EXPRESS,
            IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE, AND NONINFRINGEMENT. STEERUS PROVIDES ITS
            PRODUCTS AND SERVICES AS IS AND DOES NOT WARRANT THAT THE OPERATION OF ITS PRODUCTS AND SERVICES WILL BE ERROR-FREE OR UNINTERRUPTED. STEERus MAKES
            NO REPRESENTATIONS ABOUT ANY CONTENT OR INFORMATION MADE ACCESSIBLE BY OR THROUGH ITS PRODUCTS AND SERVICES.
          </p>
          <h3 className="policy__subtitle">Indemnification</h3>
          <p className="policy__description">
            1.<strong> By Organization</strong>. Organization will indemnify, defend, and hold harmless STEERus from and against all liabilities, damages, and
            costs (including settlement costs and reasonable attorneys’ fees) arising out of a third party claim: (i) that any Organization brand features or
            other content used in accordance with this Agreement infringe or misappropriate any intellectual property rights of a third party; or (ii) involving
            actions by participants or other individuals associated with Organization (e.g., harassment on forums, plagiarism).
            <br />
            <br />
            2. <strong>By STEERus</strong>. STEERus will indemnify, defend, and hold harmless Organization from and against all liabilities, damages, and costs
            (including settlement costs and reasonable attorneys’ fees) arising out of a third-party claim that STEERus’ technology used to provide the Services
            or any STEERus brand features used in accordance with this Agreement infringe or misappropriate any intellectual property rights of such third
            party. Notwithstanding the foregoing, in no event shall STEERus have any obligations or liability under this section arising from: (i) use of the
            Services or STEERus brand features in a modified form or in combination with materials not furnished by STEERus; or (ii) any content, information,
            or data provided by Organization, end users, or other third parties.
            <br />
            <br />
            3. <strong>General</strong>. The party seeking indemnification will promptly notify the other party of the claim and cooperate with the other party
            in defending the claim. The indemnifying party has full control and authority over the defense, except that: (i) any settlement requiring the party
            seeking indemnification to admit liability or to pay any money will require that party’s prior written consent, such consent not to be unreasonably
            withheld or delayed; and (ii) the other party may join in the defense with its own counsel at its own expense. THE INDEMNITIES ABOVE ARE THE ONLY
            REMEDY UNDER THIS AGREEMENT FOR VIOLATION OF A THIRD PARTY’S INTELLECTUAL PROPERTY RIGHTS.
          </p>
          <h3 className="policy__subtitle">Limitation of Liability</h3>
          <p className="policy__description">
            1. <strong>Limitation on Indirect Liability</strong>. NEITHER PARTY WILL BE LIABLE UNDER THIS AGREEMENT FOR LOST REVENUES OR INDIRECT, SPECIAL,
            INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES, EVEN IF THE PARTY KNEW OR SHOULD HAVE KNOWN THAT SUCH DAMAGES WERE POSSIBLE AND EVEN IF
            DIRECT DAMAGES DO NOT SATISFY A REMEDY.
            <br />
            <br />
            2. <strong>Limitation on Amount of Liability</strong>. NEITHER PARTY MAY BE HELD LIABLE UNDER THIS AGREEMENT FOR MORE THAN THE AMOUNT PAID OR
            PAYABLE BY ORGANIZATION TO STEERus FOR THE 12 MONTHS IMMEDIATELY PRECEDING THE DATE A CLAIM ALLEGING LIABILITY UNDER THIS SECTION IS RAISED BY
            EITHER PARTY.
            <br />
            <br />
            3. <strong>Exceptions to Limitations</strong>. These limitations of liability do not apply to breaches of confidentiality obligations, violations of
            a party’s intellectual property rights by the other party, or indemnification obligations.
          </p>
          <h3 className="policy__subtitle">Binding Arbitration and Class Action Waiver</h3>
          <p className="policy__description">
            Organization and STEERus agrees to submit to binding arbitration any and all disputes, claims, or controversies of any kind, whether based on
            statute, regulation, constitution, common law, equity, or any other legal basis or theory, and whether pre-existing, present, or future, that arise
            out of or relate to our Services, these Terms, and/or any other relationship or dispute between you and us, including without limitation (i) the
            scope, applicability, or enforceability of these Terms and/or this arbitration provision, and (ii) relationships with third parties who are not
            parties to these Terms or this arbitration provision to the fullest extent permitted by applicable law (each a “Claim,” and collectively the
            “Claims”).
            <br />
            <br />
            Any and all Claims shall be submitted for binding arbitration in accordance with the Consumer Arbitration Rules of the American Arbitration
            Association (the “AAA Rules”), as amended, in effect at the time arbitration is initiated. The AAA Rules are available online at www.adr.org or by
            calling (800) 778-7879, and are hereby incorporated by reference. In the event of any inconsistency between this arbitration provision and the AAA
            Rules, such inconsistency shall be resolved in favor of this provision. If Organization decides to initiate arbitration, Organization agree to pay
            the initiation fee of $200 (or the amount otherwise required by the AAA Rules), and we agree to pay the remaining arbitration initiation fee and any
            additional deposit required by AAA to initiate the arbitration. STEERus will pay the costs of the arbitration proceeding, including the arbitrator’s
            fees; however, other fees, such as attorney’s fees and expenses of travel to the arbitration, shall be paid in accordance with the AAA Rules and
            applicable law. STEERus will pay all costs associated with any arbitration commenced by STEERus by hiring a single, neutral arbitrator selected in
            accordance with the AAA Rules shall decide all Claims.
            <br />
            <br />
            The arbitrator shall be an active member in good standing of the bar for any state in the continental United States and shall be either actively
            engaged in the practice of law for at least five years or a retired judge. The arbitrator shall honor claims of privilege recognized at law. Unless
            inconsistent with applicable law, and except as otherwise provided herein, each party shall bear the expense of its respective attorney, expert, and
            witness fees, regardless of which party prevails in the arbitration. Any arbitration proceedings shall be conducted in the federal judicial district
            of your residence, and you will be given the opportunity to attend the proceeding and be heard. The arbitrator’s decision will be final and binding
            upon the parties and may be enforced in any federal or state court that has jurisdiction. Organization and STEERus agree that the arbitration shall
            be kept confidential and that the existence of the proceeding and any element of it (including, without limitation, any pleadings, briefs or other
            documents submitted or exchanged and any testimony or other oral submissions and awards) will not be disclosed beyond the arbitration proceedings,
            except as may lawfully be required in judicial proceedings relating to the arbitration or by applicable disclosure rules and regulations of
            governmental agencies.
            <br />
            <br />
            Neither Organization nor STEERus may act as a class representative, nor participate as a member of a class of claimants, with respect to any Claim.
            The Claims may not be arbitrated on a class or representative basis. The arbitrator can decide only Organization’s and/or STEERus individual Claims.
            The arbitrator may not consolidate or join the claims of other persons or parties who may be similarly situated. Accordingly, Organization and
            STEERus agrees that the AAA Supplementary Rules for Class Arbitrations do not apply to our arbitration. This arbitration provision and the
            procedures applicable to the arbitration contemplated by this provision are governed by the Federal Arbitration Act, notwithstanding any state law
            that may be applicable. This arbitration agreement does not preclude Organization or STEERus from seeking action by federal, state, or local
            government agencies. Organization and STEERus also has the right to exercise self-help remedies, such as set-off, or to bring qualifying claims in
            small claims court so long as the matter remains in such court and advances only on an individual (non-class, non-representative) basis. In
            addition, Organization and STEERus retains the right to apply to any court of competent jurisdiction for provisional or ancillary relief, including
            pre-arbitral attachments or preliminary injunctions, and any such request shall not be deemed incompatible with any of these Terms, nor a waiver of
            the right to have disputes submitted to arbitration as provided in this provision.
            <br />
            <br />A court may sever any portion of this Binding Arbitration and Class Action Waiver Section that it finds to be unenforceable, except for the
            prohibitions on any Claim being handled on a class or representative basis, and the remaining portions of this arbitration provision will remain
            valid and enforceable. No waiver of any provision of this Section will be effective or enforceable unless recorded in a writing signed by the party
            waiving such a right or requirement. Such a waiver shall not waive or affect any other portion of these Terms. THIS BINDING ARBITRATION AND CLASS
            ACTION WAIVER SECTION LIMITS CERTAIN RIGHTS, INCLUDING THE RIGHT TO MAINTAIN A COURT ACTION, THE RIGHT TO A JURY TRIAL, THE RIGHT TO PARTICIPATE IN
            ANY FORM OF CLASS OR REPRESENTATIVE CLAIM, AND THE RIGHT TO ENGAGE IN DISCOVERY EXCEPT AS PROVIDED IN THE AAA RULES. OTHER RIGHTS THAT YOU OR
            STEERUS WOULD HAVE IN COURT ALSO MAY NOT BE AVAILABLE IN ARBITRATION.
          </p>
          <h3 className="policy__subtitle">Miscellaneous</h3>
          <p className="policy__description">
            1. <strong>Notices</strong>. All notices must be in writing and addressed to the attention of the other party’s legal department and primary point
            of contact. Notice will be deemed given: when verified by written receipt.
            <br />
            2. <strong>Assignment</strong>. Neither party may assign or transfer any part of this Agreement without the written consent of the other party,
            except to an affiliate, but only if: (i) the assignee agrees in writing to be bound by the terms of this Agreement; and (ii) the assigning party
            remains liable for obligations incurred under this Agreement prior to the assignment. Any other attempt to transfer or assign is void.
            <br />
            3. <strong>Force Majeure</strong>. Neither party will be liable for inadequate performance to the extent caused by a condition (for example, natural
            disaster, act of war or terrorism, riot, labor condition, governmental action, and Internet disturbance) that was beyond the party’s reasonable
            control. 4. <strong>No Waiver</strong>. Failure to enforce any provision of this Agreement will not constitute a waiver.
            <br />
            5. <strong>Severability</strong>. If any provision of this Agreement is found unenforceable, it and any related provisions will be interpreted to
            best accomplish the unenforceable provision’s essential purpose.
            <br />
            6. <strong>No Agency</strong>. The parties are independent contractors, and this Agreement does not create an agency, partnership, or joint venture.
            <br />
            7. <strong>No Third-party Beneficiaries</strong>. There are no third-party beneficiaries to this Agreement.
            <br />
            8. <strong>Equitable Relief</strong>. Nothing in this Agreement will limit either party’s ability to seek equitable relief.
            <br />
            9. <strong>Governing Law and Venue</strong>. This Agreement is governed by New Jersey law, excluding that state’s choice of law rules. FOR ANY
            DISPUTE RELATING TO THIS AGREEMENT, THE PARTIES CONSENT TO PERSONAL JURISDICTION IN, AND THE EXCLUSIVE VENUE OF, THE COURTS IN NEW JERSEY.
            <br />
            10.<strong>Amendments</strong>. Any amendments or modifications to this Agreement may only be effected by: (i) written agreement of both parties;
            (ii) Organization’s online acceptance of updated terms, amendments, or addenda; or (iii) Organization’s continued use of the Services after the
            terms of this Agreement have been updated by STEERus and Organization has been notified.
            <br />
            11. <strong>Survival</strong>. Those provisions that by their nature should survive termination of this Agreement, will survive termination of this
            Agreement.
            <br />
            12. <strong>Entire Agreement</strong>. This Agreement, and all documents referenced herein, is the parties’ entire agreement relating to its subject
            and supersedes any prior or contemporaneous agreements on that subject. The terms located at a URL and referenced in this Agreement are hereby
            incorporated by this reference.
            <br />
            13. <strong>Counterparts</strong>. The parties may enter into this Agreement in counterparts, including facsimile, PDF, or other electronic copies,
            which taken together will constitute one instrument.
          </p>
          <h3 className="policy__subtitle">For U.S. government agencies, the following additional terms apply:</h3>
          <p className="policy__description">
            1. Organization may terminate this Agreement for convenience. In the event of such termination, STEERus will immediately stop all work hereunder and
            STEERus will be entitled to receive and retain payment in the amount on the order page, plus reasonable charges that resulted from the termination.
            STEERus shall not be required to comply with the cost accounting standards or contract cost principles for this purpose.
            <br />
            2. Any provisions that require Organization to keep certain information confidential are subject to the Freedom of Information Act, 5 U.S.C. § 552.
            <br />
            3. Indemnification by Organization above does not apply.
            <br />
            4. All clauses regarding assignment are subject to FAR clause 52.232-23, Assignment of Claims (JAN 1986) and FAR 42.12 Novation and Change-of-Name
            Agreements.
            <br />
            5. This Agreement is governed by applicable federal law. Any disputes involving this Agreement will be handled in accordance with FAR clause
            52.212-4(d), “Disputes.”
          </p>
        </div>
      </StandardMarginDiv>
    </PageLayout>
  );
};

export default TermsOfUse;
