import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {Document, Page, pdfjs} from 'react-pdf';
import {PageLayout, StandardMarginDiv} from '../components';
import './styles.less'
import {useMediaQuery} from "react-responsive";

function PDFViewerTwo() {
  const [numPages, setNumPages] = useState(null);
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 500px)",
  });

  const isTabletOrMobileDeviceSmall = useMediaQuery({
    query: "(max-width: 400px)",
  });

  const params: any = useLocation();
  console.log("params ", params);
  const bookUrl = params?.state?.url;

  function onDocumentLoadSuccess({numPages: numberPages}: any) {
    setNumPages(numberPages);
  }

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  return (
    <PageLayout>
      <StandardMarginDiv>
        {bookUrl ? (
          <div className="pdf">
            <Document
              onLoadError={console.error}
              file={{
                url: bookUrl,
              }}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {numPages !== null &&
                Array.from(Array(numPages).keys()).map((item: any) => (
                  <div key={item}>
                    <Page scale={isTabletOrMobileDeviceSmall ? 0.5 : isTabletOrMobileDevice ? 0.7 : 1} pageIndex={item}/>
                  </div>
                ))}
            </Document>
          </div>
        ) : (
          'No pdf found'
        )}
      </StandardMarginDiv>
    </PageLayout>
  );
}

export default PDFViewerTwo;
