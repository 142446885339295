import React, { useEffect } from 'react';
import ModalScrollable from '../ModalScrollable/ModalScrollable';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import './styles.less';
import { gql, useMutation } from '@apollo/client';

const CANCELED = gql`
  mutation cancledNovetly($assessment: Boolean) {
    cancledNovetly(assessment: $assessment)
  }
`;

const Novelty = ({ onClose, setFirstLogin, refetch }) => {
  const [canceledNovelty] = useMutation(CANCELED, {
    variables: {
      assessment: true,
    },
  });
  const history = useHistory();

  const clickHandler = () => {
    onClose();
    refetch();
    setFirstLogin(true);
  };

  useEffect(() => {
    canceledNovelty().then((r) => r);
  }, []);

  return (
    <div className="news">
      <ModalScrollable title="Soft Skills Assessment" className="news__modal" onClose={clickHandler}>
        <div className="news__content">
          <p className="heading">
            We noticed that you didn’t take our Soft Skills Assessment yet. Why don’t we take 10 minutes right now to look at your skills?
          </p>
          <div className="news__content-buttons">
            <Button
              onClick={() => {
                clickHandler();
                history.push('/assessments/4');
              }}
            >
              Soft Skills Assessment Take it now!
            </Button>
            <Button onClick={clickHandler}>Skip for now but I will do it later!</Button>
          </div>
        </div>
      </ModalScrollable>
    </div>
  );
};

export default Novelty;
