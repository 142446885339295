import AssessmentCard from 'Marketplace/MarketplaceComponents/AssessmentCard';
import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import softskills from '../assets/assessments/1.png';


const SkillAssessments = props => {
    const [loading, setLoading] = useState(true);
    const GET_SKILL_ASSESSMENTS = gql`
        query getPublishedSkillAssessments {
            getPublishedSkillAssessments {
                id
                title
                description
                image
                questionsCsv
                status
                slug
                price
            }
        }
    `;

    const { data, refetch } = useQuery(GET_SKILL_ASSESSMENTS);

    console.log("data ", data && data.getPublishedSkillAssessments);
    return <React.Fragment>
        {data && data.getPublishedSkillAssessments && data.getPublishedSkillAssessments.length > 0 && data.getPublishedSkillAssessments.map(assessment => <AssessmentCard
            title={assessment.title}
            subtitle={assessment.description}
            avatar={softskills}
            link={`skill-assessments/${assessment.id}`}
        />)}
    </React.Fragment>
};

export default SkillAssessments;