import React from 'react';
import { Input } from 'antd';

const Question13 = ({
  index = 13,
  associate,
  schoolHigh,
  setSchoolHigh,
  schoolTrade,
  setSchoolTrade,
  setAssociate,
  bachelor,
  setBachelor,
  setMaster,
  master,
  doctoral,
  setDoctoral,
  other,
  setOther,
}) => {
  return (
    <div className="soft-skills-field">
      <h6>
        {index}. Are you currently attending school? Please indicate degree in progress.
        {/* <b className={'required__star'}>*</b> */}
      </h6>
      <label className="label">
        <span>High School</span>
        <Input size="medium" placeholder="" value={schoolHigh} onChange={(e) => setSchoolHigh(e.target.value)} style={{ marginBottom: '12px' }} />
      </label>
      <label className="label">
        <span>Trade School</span>
        <Input size="medium" placeholder="" value={schoolTrade} onChange={(e) => setSchoolTrade(e.target.value)} style={{ marginBottom: '12px' }} />
      </label>
      <label className="label">
        <span>Associate Degree</span>
        <Input size="medium" placeholder="" value={associate} onChange={(e) => setAssociate(e.target.value)} style={{ marginBottom: '12px' }} />
      </label>
      <label className="label">
        <span>Bachelor's Degree</span>
        <Input size="medium" placeholder="" value={bachelor} onChange={(e) => setBachelor(e.target.value)} style={{ marginBottom: '12px' }} />
      </label>
      <label className="label">
        <span>Master's Degree</span>
        <Input size="medium" placeholder="" value={master} onChange={(e) => setMaster(e.target.value)} style={{ marginBottom: '12px' }} />
      </label>
      <label className="label">
        <span>Doctoral Degree</span>
        <Input size="medium" placeholder="" value={doctoral} onChange={(e) => setDoctoral(e.target.value)} style={{ marginBottom: '12px' }} />
      </label>
      <label className="label">
        <span>Other(Certificate/No Schooling Done)</span>
        <Input size="medium" placeholder="" value={other} onChange={(e) => setOther(e.target.value)} style={{ marginBottom: '12px' }} />
      </label>
    </div>
  );
};

export default Question13;
