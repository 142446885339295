import React from "react";
import ButtonLink from "../../components/Buttons/ButtonLink";

const SwagCard = ({ image, title, price, button }) => {
  return (
    <div className="swagCard">
      <div className="swagCard__imageContainer">
        <img src={image} alt="" className="swagCard__image" />
      </div>
      <div className="swagCard__inner">
        <div className="swagCard__title">
          <div className="swagCard__link">{title}</div>
        </div>
        <div className="swagCard__footer">
          <div className="swagCard__footerPrice">
            <ButtonLink
              disabled
              style="center"
              title={button}
              link="https://www.jabord.com/sign-up?utm_source=SteerUs&utm_medium=Button&utm_campaign=Partner#Signup-Tab-1"
            />
            <p>${price}/ea</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwagCard;
