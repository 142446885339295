// @ts-nocheck
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Input } from 'antd';

import {
  StandardMarginDiv,
  PageLayout,
  JourneyCard,
} from '../components';

import {
  GET_JOURNEY_LIST_BY_TERM,
} from './query';
import './styles.less';

const SearchJourneysContainer = () => {
  const params = useParams();
  const [searchTimeout, setSearchTimeout] = useState(null);

  const { data: journeysData, refetch: journeyRefetch } = useQuery(
    GET_JOURNEY_LIST_BY_TERM,
    {
      variables: { term: params.term },
      fetchPolicy: 'network-only',
    },
  );

  const handleInputChange = (e) => {
    e.persist();
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(
      setTimeout(() => {
        journeyRefetch({ term: e.target.value });
      }, 500),
    );
  };

  const totalResults = journeysData?.getJourneyListByTerm?.length ?? 0;

  return (
    <PageLayout>
      <StandardMarginDiv>
        <Input
          placeholder={params.term === 'all' ? 'Search for terms' : params.term}
          onChange={handleInputChange}
        />
        <p>
          {totalResults}
          {' '}
          Total Results
        </p>

        <h1 className="search--title" style={{ marginTop: '25px' }}>
          Journeys:
          {' '}
          {journeysData?.getJourneyListByTerm?.length}
          {' '}
          Results
        </h1>
        <div className="search--list">
          {journeysData?.getJourneyListByTerm?.map((journey: any) => (
            <JourneyCard
            journey={journey}
              key={journey.id}
              id={journey.id}
              title={journey.title}
              description={{
                videos: journey.videos,
                snippets: journey.snippets,
                capsules: journey.capsules,
                skillChecks: journey.skillChecks,
                selfReflections: journey.selfReflections,
                workbooks: journey.workbooks,
              }}
              status={journey.status}
              isBlocked={journey.status === 'blocked'}
              time={journey.duration}
              imageUrl={journey.imageUrl}
              price={journey.price}
            />
          ))}
        </div>
      </StandardMarginDiv>
    </PageLayout>
  );
};

export default SearchJourneysContainer;
