import React from 'react';
import ButtonLink from '../../components/Buttons/ButtonLink';

const GamesCard = ({ title, link }) => {
  return (
    <div className="gamesCapsule">
      <div className="gamesCapsule__inner">
        <div className="gamesCapsule__title">
          <div className="gamesCapsule__link">{title}</div>
        </div>
        <div className="gamesCapsule__footer">
          <div className="gamesCapsule__info">
            <ButtonLink style="center" title="Play now" link={link} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GamesCard;
