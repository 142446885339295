import React from 'react';
import { Button } from 'antd';
import ModalScrollable from "../ModalScrollable/ModalScrollable";
import { modal } from "../../utils/modal";

import './styles.less';

type Props = {
  title: string,
  message: string,
  onDelete: React.MouseEventHandler<HTMLElement>
}

const ConfirmationModal = ({title, message, onDelete}: Props) => {
  return (
    <ModalScrollable
      title={title}
      className="confirmation-modal"
      footer={[
        <Button
          className="confirmation-modal__button"
          onClick={modal.hideModal}
        >
          No
        </Button>,
        <Button
          className="confirmation-modal__button"
          type="primary"
          onClick={onDelete}
        >
          Yes
        </Button>
      ]}
    >
      <p className="confirmation-modal__message">{message}</p>
    </ModalScrollable>
  )
};

export default ConfirmationModal;