export const QUESTION_12 = [
    {id: 1, value: "High School Completion or Equivalence", key: "high_school"},
   /* {id: 2, value: "Trade School", key: "trade_school"},*/
    { id: 3, value: "Associate's Degree Earned", key: "associate" },
    { id: 4, value: "Bachelor's Degree Earned", key: "bachelor" },
    { id: 7, value: "Master's Degree Earned", key: 'master' },
    { id: 5, value: "Doctoral Degree Earned", key: "doctoral" },
    { id: 6, value: "Teaching Credential Earned", key: "teaching_credential" },  
    { id: 8, value: "Certificate", key: "certificate" },

]





export const vals = [
    { value: 5, id: 1, isChecked:false },
    { value: 4, id: 2, isChecked:false  },
    { value: 3, id: 3, isChecked:false  },
    { value: 2, id: 4, isChecked:false  },
    { value: 1, id: 5, isChecked:false  },
];

export const values = [
    { value: 1, id: 1 },
    { value: 2, id: 2 },
    { value: 3, id: 3 },
    { value: 4, id: 4 },
];


export const answers = [
    { id: 0, answer: 'Yes' },
    { id: 1, answer: 'No' }
];



export const sum = (obj, num) => {
    let sum = 0;
    for (let el in obj) {
        if (obj.hasOwnProperty(el)) sum += Number(obj[el].value) / num;
    }
    return sum;
};

export const empty = (obj, setState) => {
    let error
    Object.keys(obj).forEach(item => {
        if (!obj[item].value) {
            error = true
            setState((prevState) => ({
                ...prevState,
                [item]: { error: true, value: '' }
            }))
        }
    })

    return error
};



