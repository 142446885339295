import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ALL_USERS, GET_USER_BY_EMAIL } from '../query';
import { BUY_SUBSCRIPTION, APPROVE_USER } from '../mutations';
import { Table, Button, Space, Tag, Spin } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import './styles.less';
import MobileTable from '../../MobileTable/MobileTable';
import { useMediaQuery } from 'react-responsive';

const UsersTable = () => {
  const [email, setEmail] = useState('');
  const [showData, setShowData] = useState(false);
  const { data: users, loading } = useQuery(GET_ALL_USERS, { fetchPolicy: 'network-only' });
  const { data: singleUser } = useQuery(GET_USER_BY_EMAIL, { variables: { email: email.trim() } });
  const [approveUser] = useMutation(APPROVE_USER);
  const [buySubscription] = useMutation(BUY_SUBSCRIPTION);

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-width: 700px)',
  });

  const approve = (id, status) => {
    approveUser({ variables: { userId: id, status: status } })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const addDemo = (id, subscriptionType) => {
    buySubscription({ variables: { userId: id, subscriptionType: subscriptionType } })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const actionButtons = (value) => {
    if (value.subscriptionStatus === '.edu') {
      return <Button onClick={() => approve(value.id, null)}>Remove Access</Button>;
    } else if (value.subscriptionStatus === 'demo' && parseInt(value.dateEnding) > Date.now()) {
      return (
        <div className="admin__table-action">
          <Button onClick={() => approve(value.id, null)}>Remove Access</Button>
          <Button onClick={() => approve(value.id, '.edu')}>Full Access</Button>
        </div>
      );
    } else if (value.subscriptionStatus === 'demo' && (parseInt(value.dateEnding) < Date.now() || !value.dateEnding)) {
      return (
        <div className="admin__table-action">
          <Button onClick={() => addDemo(value.id, 'week')}>7 days Access</Button>
          <Button onClick={() => approve(value.id, '.edu')}>Full Access</Button>
        </div>
      );
    } else if (value.subscriptionStatus === null) {
      return (
        <div className="admin__table-action">
          <Button onClick={() => addDemo(value.id, 'week')}>7 days Access</Button>
          <Button onClick={() => approve(value.id, '.edu')}>Full Access</Button>
        </div>
      );
    } else if (value.subscriptionStatus === 'payment' && parseInt(value.dateEnding) > Date.now()) {
      return <Button onClick={() => approve(value.id, null)}>Remove Access</Button>;
    } else if (value.subscriptionStatus === 'payment' && parseInt(value.dateEnding) < Date.now()) {
      return (
        <div className="admin__table-action">
          <Button onClick={() => addDemo(value.id, 'week')}>7 days Access</Button>
          <Button onClick={() => approve(value.id, '.edu')}>Full Access</Button>
        </div>
      );
    }
  };
  const columns = [
    {
      title: 'User Name',
      width: '25%',
      render: (value) => value.userProfile.firstName + ' ' + value.userProfile.lastName,
    },
    {
      title: 'Email',
      width: '25%',
      render: (value) => value.email,
    },
    {
      title: 'Subscription Status',
      render: (value) => {
        let color = 'green';
        if (value.subscriptionStatus === null || !value.dateEnding) color = 'volcano';
        if (value.subscriptionStatus === '.edu') color = 'green';
        if (parseInt(value.dateEnding) < Date.now()) color = 'volcano';

        let tagText;
        if (value.subscriptionStatus === '.edu') tagText = 'Active';
        if (value.subscriptionStatus === null) tagText = 'Not Active';
        if (value.subscriptionStatus === 'demo' && parseInt(value.dateEnding) > Date.now()) tagText = 'Active (demo)';
        if (value.subscriptionStatus === 'demo' && (parseInt(value.dateEnding) < Date.now() || !value.dateEnding)) tagText = 'Not Active';
        if (value.subscriptionStatus === 'payment' && (parseInt(value.dateEnding) < Date.now() || !value.dateEnding)) tagText = 'Not Active';
        if (value.subscriptionStatus === 'payment' && parseInt(value.dateEnding) > Date.now()) tagText = 'Active (payment)';
        return <Tag color={color}>{tagText}</Tag>;
      },
    },
    {
      title: 'Expiration Date',
      render: (value) => {
        if (value.subscriptionStatus === 'demo' || value.subscriptionStatus === 'payment') {
          return parseInt(value.dateEnding) > Date.now() ? new Date(parseInt(value.dateEnding)).toISOString().slice(0, 10) : null;
        } else if (value.subscriptionStatus === '.edu') {
          return 'infinitely';
        } else return null;
      },
    },
    {
      title: 'Adult',

      sorter: (a, b) => {
        let aSorting = a.userProfile.adult ? 'Yes' : 'No';
        let bSorting = b.userProfile.adult ? 'Yes' : 'No';
        return aSorting.length - bSorting.length;
      },
      render: (value, context) => {
        return context?.userProfile.adult ? 'Yes' : 'No';
      },
    },
    {
      title: 'Action',
      width: '25%',
      render: (value) => <Space>{actionButtons(value)}</Space>,
    },
  ];
  let data;
  if (!showData) {
    data = users?.getAllUser;
  } else if (showData && singleUser?.getUserByEmail) {
    data = [{ ...singleUser?.getUserByEmail, key: singleUser?.getUserByEmail.id }];
  }

  const formSubmit = (e) => {
    e.preventDefault();
    setShowData(true);
  };

  const clearForm = () => {
    setShowData(false);
    setEmail('');
  };

  return (
    <div className="usersTable">
      <div className="usersTable__header">
        <h1 className="usersTable__title"> Users </h1>
        <form className="books__searchForm usersTable__searchForm" onSubmit={formSubmit}>
          <button type="submit" className="usersTable__searchBtn">
            <SearchOutlined className="books__searchIcon" style={{ color: '#92d050' }} />
          </button>
          <input type="text" className="books__searchInput" value={email} onChange={(e) => setEmail(e.target.value)} />
          <CloseOutlined className="usersTable__clearIcon" onClick={() => clearForm()} />
        </form>

        {isTabletOrMobileDevice && (
          <Button onClick={formSubmit} className="search__button">
            Search
          </Button>
        )}
      </div>

      {loading ? (
        <div style={{ margin: 'auto' }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          {!isTabletOrMobileDevice ? (
            <Table rowKey="id" columns={columns} dataSource={data} scroll={{ x: 1300 }} />
          ) : (
            <MobileTable data={data} columns={columns} cappitalize={false} />
          )}
        </>
      )}
    </div>
  );
};
export default UsersTable;
