import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Table, Button, Modal, Row, Input, Card, Spin } from 'antd';
import { useState } from 'react';
import { UPLOAD_FILE } from 'uploads/query';
import InputFile from 'journey/JourneyEdit/components/InputFile';
import "./styles.less";

const Badges = (props: any) => {
    const [showModal, setShowModal] = useState(false);
    const [image, setImage] = useState({});
    const [caption, setCaption] = useState("");

    const { Meta } = Card;

    const GET_BADGES = gql`
        query getBadges {
            getBadges {
                id
                imageUrl
                caption
            }
        }
    `;

    const CREATE_BADGE = gql`
        mutation createBadge($caption:String, $imageUrl: String){
            createBadge(caption:$caption, imageUrl: $imageUrl)
        }
    `;

    const [createBadge] = useMutation(CREATE_BADGE);

    const { data: badges, refetch, loading } = useQuery(GET_BADGES);
    const [uploadFile] = useMutation(UPLOAD_FILE);

    const uploadDocument = (value: any, handler: any) => {
        uploadFile({ variables: { file: value?.originFileObj }}).then(resp => {
            handler(resp.data?.uploadFile?.imageUrl || "");
        }).catch(err => {
            console.log("error ", err);
        });
    };

    const onSubmit = () => {
        console.log("caption ", caption);
        console.log("image_url ", image);

        createBadge({ variables: { caption, imageUrl: image }}).then((resp: any) => {
            console.log("badge created ");
            setShowModal(false);
        }).catch((err: any) => {
            console.log("Some error occurred while creating badge ", err.message);
        });
    };

    console.log("badges ", loading, badges);

    return <React.Fragment>
        <h1>Badges</h1>
        <Button onClick={() => setShowModal(true)}>Create Badge</Button>
        {loading ? <Spin tip="Loading..." /> :
            <div className="padding-20">
                {badges.getBadges.map((badge: any) => {
                    return <Card
                        hoverable
                        style={{ width: 240 }}
                        cover={<img alt={badge.caption} src={badge.imageUrl} />}
                    >
                        <Meta title={badge.caption} />
                    </Card>
                })}
            </div>
        }
        {
            showModal && <Modal
                visible={showModal}
                footer={null}
                onCancel={() => setShowModal(false)}
                destroyOnClose
                centered
            >
                <h1>Upload Badge</h1>
                <Row className="create-assessment-row">
                    <label className="margin-right-15">Caption</label>
                    <Input
                        onChange={(e: any) => setCaption(e.target.value)}
                        value={caption}
                        placeholder="Enter Caption"
                    />
                </Row>
                <Row className="create-assessment-row">
                    <label className="margin-right-15">Upload Image</label>
                    <InputFile
                        file={{}}
                        accept="image/*"
                        onChange={(value: any) => uploadDocument(value, setImage)}
                    />
                    {typeof(image) == "string" ? image : (props.selectedAssessment && props.selectedAssessment.image)}
                    {/* {error && error.name == "image" && <Tag color="red">{error.error}</Tag>} */}
                </Row>
                <div className="text-center">
                    <Button onClick={onSubmit}>Submit</Button>
                </div>
            </Modal>
        }
    </React.Fragment>

};

export default Badges;