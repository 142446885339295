import React, {useEffect} from 'react';
import InfoCard from "./InfoCard";
import {CheckCircleFilled} from "@ant-design/icons";
import ButtonInsideLink from "../Buttons/ButtonInsideLink";

const PurchaseSubscription = ({onSuccess}) => {
  useEffect(() => {
    onSuccess()
  }, []);

  return (
    <InfoCard>
      <h1>Purchased!</h1>
      <CheckCircleFilled className="payment_info--icon"/>
      <ButtonInsideLink
        title="go to academy"
        link="/academy"
        style="center"
      />
    </InfoCard>
  );
};

export default PurchaseSubscription;
