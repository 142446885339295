// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Checkbox, Form, Input, message, Select, Space, Spin } from 'antd';

import { userQuery } from '../hoc/withCurrentUserQuery';
import { HeroHalfPartner, PageLayout } from '../components';

const CREATE_STUDENT = gql`
  mutation createStudentJabord($email: String, $password: String, $firstName: String, $lastName: String, $meta: JSON, $partner: String, $adult: Boolean) {
    createStudentJabord(email: $email, password: $password, firstName: $firstName, lastName: $lastName, meta: $meta, partner: $partner, adult: $adult) {
      tokens {
        accessToken
        refreshToken
      }
      error {
        field
        message
      }
      user {
        id
      }
    }
  }
`;

const SEND_CONFIRM_EMAIL_JABORD = gql`
  mutation sendConfirmEmail($email: String, $file: String) {
    sendConfirmEmail(email: $email, file: $file)
  }
`;

const SignupForm = ({ history, setLoading }) => {
  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted
  const [createStudentJabord, { data, loading }] = useMutation(CREATE_STUDENT);
  const setUser = useQuery(userQuery);
  const [sendConfirmEmail] = useMutation(SEND_CONFIRM_EMAIL_JABORD);
  useEffect(() => {
    setLoading(loading);
  }, [loading]);
  const inputStyles = {
    width: '100%',
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      tos: false,
      password: '',
    },
    onSubmit: async (values) => {
      if (!values.tos) {
        message.error('You must agree to our Terms of Service');
      } else {
        const result = await createStudentJabord({
          variables: { ...values, email: values.email.toLowerCase(), partner: 'jabord' },
          refetchQueries: [{ query: userQuery }],
        });
        if (result.data.createStudentJabord.error) {
          message.error(result.data.createStudentJabord.error.message);
        } else {
          await sendConfirmEmail({ variables: { email: values.email, file: "jabordContainer.tsx" } });
          history.push('/email-confirm');
        }
      }
    },
  });

  const validateMessages = {
    required: '${label} is required.',
  };

  return (
    <Form layout="vertical" className="form-with-antd-validation" validateMessages={validateMessages} onFinish={formik.handleSubmit}>
      <h1 className="sign-up__title"> Create your account </h1>

      <Form.Item label="First Name" name="firstName" required={false} rules={[{ required: true }]}>
        <Input
          id="firstName"
          name="firstName"
          label="firstName"
          type="firstName"
          onChange={formik.handleChange}
          value={formik.values.firstName}
          style={inputStyles}
        />
      </Form.Item>

      <Form.Item label="Last Name" name="lastName" required={false} rules={[{ required: true }]}>
        <Input
          id="lastName"
          name="lastName"
          label="lastName"
          type="lastName"
          onChange={formik.handleChange}
          value={formik.values.lastName}
          style={inputStyles}
        />
      </Form.Item>

      <Form.Item label="Email" name="Email" required={false} rules={[{ required: true }]}>
        <Input id="email" name="email" label="email" type="email" onChange={formik.handleChange} value={formik.values.email} style={inputStyles} />
      </Form.Item>

      <Form.Item label="Password" name="Password" required={false} rules={[{ required: true }]}>
        <Input id="password" name="password" type="password" onChange={formik.handleChange} value={formik.values.password} style={inputStyles} />
      </Form.Item>

      <Form.Item label="Education" name="education" style={{ width: '100%' }} required={false} rules={[{ required: true }]}>
        <Select
          placeholder="Where are you coming from?"
          onChange={(e) =>
            formik.setFieldValue('meta', {
              ...formik.values.meta,
              education: e,
            })
          }
        >
          <Select.Option value="High School">High School</Select.Option>
          <Select.Option value="College">College</Select.Option>
          <Select.Option value="Educator">Educator</Select.Option>
          <Select.Option value="Other">Other</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="tos"
        valuePropName="checked"
        required={false}
        rules={[
          {
            validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('You should agree with our Terms and Conditions to proceed'))),
          },
        ]}
      >
        <Checkbox id="tos" name="tos" onChange={formik.handleChange} value={formik.values.tos}>
          <span>
            I have read and agree to the&nbsp;
            <Link to="/terms">Terms and Conditions</Link>
            &nbsp;and the&nbsp;
            <Link to="/privacy">Privacy Policy</Link>
            &nbsp;and the&nbsp;
            <Link to="/disclaimer">Legal Disclaimer.</Link>
          </span>
        </Checkbox>
      </Form.Item>

      <Form.Item>
        <Button style={inputStyles} type="primary" htmlType="submit">
          Create your account
        </Button>
      </Form.Item>

      <p onClick={() => history.push('/login')} className="sign-up__link">
        Already have an account? Log in.
      </p>
    </Form>
  );
};

const App = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  return (
    <PageLayout hideFooter>
      {loading && (
        <Space
          style={{
            position: 'fixed',
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            zIndex: 100000,
          }}
        >
          <Spin
            style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100px', height: '100px' }}
            className="login-spin"
          />
        </Space>
      )}
      <HeroHalfPartner
        rightColor="#fff"
        background={null}
        college
        leftComponents={
          <div className="paddingTop">
            <SignupForm history={history} setLoading={setLoading} />
          </div>
        }
        title="Soft skills are the skills that will get you hired - or fired if you don't have them."
        paragraph_one="STEERus trains you on the skills you need like communication, time management, EQ, and critical thinking."
        paragraph_two="You're taking a big step by enrolling in our online Academy to upskill. Now you can get certified in soft skills and get noticed by employers."
        paragraph_free="With our partner, Jabord, you can build an interactive digital resume that puts you in control of who sees it and gives you analytics about who else has viewed it. Sign up now and take control of your future!"
        video="https://stream.mux.com/6L01bOxOLQ7TnMkEhG3oCLs003sjCyBPL02ScrA00Hjf9Ms.m3u8"
        light="https://image.mux.com/6L01bOxOLQ7TnMkEhG3oCLs003sjCyBPL02ScrA00Hjf9Ms/thumbnail.png?width=2048&height=2048&fit_mode=pad&time=0"
      />
    </PageLayout>
  );
};

export default App;
