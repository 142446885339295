import React from 'react'
import {Link} from 'react-router-dom'

const UserNavbar = () => {

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    location.pathname.includes("helpipedia") ? window.location.replace('/helpipedia/login'):
    window.location.replace('/login')
  };

  return (
      <>
        { location.pathname.includes("helpipedia") ?
         <Link to="/helpipedia/dashboard" className="navbar__link">
         Dashboard
       </Link>: <Link to="/dashboard" className="navbar__link">
          Dashboard
        </Link>
        }
        { location.pathname.includes("helpipedia") ?
        <Link to="/helpipedia/settings" className="navbar__link">
        Settings
      </Link>:<Link to="/settings" className="navbar__link">
          Settings
        </Link>
        }
        <p className="navbar__link" onClick={logout}>
          Logout
        </p>
      </>
  )
}

export default UserNavbar