import React from 'react';
import { Layout } from 'antd';
import { Twitter, Instagram, Linkedin, Facebook } from 'react-feather';
import { Link, useLocation } from 'react-router-dom';
import DiscordIcon from './DiscordIcon'
import { useMediaQuery } from 'react-responsive';

import './styles.less';

const { Footer } = Layout;

const Bullet = () => (
  <p className="footer__bullet">•</p>
);
interface FootProps {
  isHelpipedia?: boolean;
}

const DesktopFooter = ({
  isHelpipedia = useLocation().pathname.includes("helpipedia")?true:false}: FootProps) => (
  <Footer className={isHelpipedia?"footerHelpipedia":"footer"}>
    &copy; {new Date().getFullYear()} STEERus
    <Bullet />
    <a
      href={process.env.REACT_APP_AIRTABLE_FEEDBACK}
      rel="noreferrer"
      className="footer__link"
      target="_blank"
    >
      Send us Feedback
    </a>
    <Bullet />
    <Link
      to="/about"
      className="footer__link"
    >
      About us
    </Link>
    <Bullet />
    <Link
      to="/terms"
      className="footer__link"
    >
      Terms of Use
    </Link>
    <Bullet />
    <Link
      to="/privacy"
      className="footer__link"
    >
      Privacy Policy
    </Link>
    <Bullet />
    <a
      href="https://www.instagram.com/steerus.io/"
      rel="noreferrer"
      className="footer__social-link"
      target="_blank"
    >
      <Instagram />
    </a>
    <Bullet />
    <a
      href="https://www.facebook.com/steerus"
      rel="noreferrer"
      className="footer__social-link"
      target="_blank"
    >
      <Facebook />
    </a>
    <Bullet />
    <a
      href="https://www.linkedin.com/company/steerus"
      rel="noreferrer"
      className="footer__social-link"
      target="_blank"
    >
      <Linkedin />
    </a>
    <Bullet />
    <a
      href="https://www.twitter.com/steerus_io"
      rel="noreferrer"
      className="footer__social-link"
      target="_blank"
    >
      <Twitter />
    </a>
    <Bullet />

    <a
      href={'https://discord.com/invite/JSEeHWEq'}
      rel="noreferrer"
      className="footer__social-link"
      target="_blank"
    >
      <DiscordIcon />
    </a>
  </Footer>
)


const MobileFooter = ({
  isHelpipedia = useLocation().pathname.includes("helpipedia")?true:false}: FootProps) => (
  <Footer className={isHelpipedia?"footerHelpipedia":"footer"}>
    {/*<div className="footer__inner">*/}
    {/*  <Link to="/" className="footer__link">*/}
    {/*    &copy; {new Date().getFullYear()} STEERus*/}
    {/*  </Link>*/}
    {/*  <a*/}
    {/*    href={process.env.REACT_APP_AIRTABLE_FEEDBACK}*/}
    {/*    rel="noreferrer"*/}
    {/*    className="footer__link"*/}
    {/*    target="_blank"*/}
    {/*  >*/}
    {/*    Send us Feedback*/}
    {/*  </a>*/}
    {/*  <Link*/}
    {/*    to="/about"*/}
    {/*    className="footer__link"*/}
    {/*  >*/}
    {/*    About us*/}
    {/*  </Link>*/}
    {/*  <Link*/}
    {/*    to="/terms"*/}
    {/*    className="footer__link"*/}
    {/*  >*/}
    {/*    Terms of Use*/}
    {/*  </Link>*/}
    {/*  <Link*/}
    {/*    to="/privacy"*/}
    {/*    className="footer__link"*/}
    {/*  >*/}
    {/*    Privacy Policy*/}
    {/*  </Link>*/}
    {/*</div>*/}

    <div className="footer__inner">
      <div className='footer__inner-link'>
        <a
          href="https://www.instagram.com/steerus.io/"
          rel="noreferrer"
          className="footer__social-link"
          target="_blank"
        >
          <Instagram />
        </a>
      </div>
      <div className='footer__inner-link'>
        <a
          href="https://www.facebook.com/steerus"
          rel="noreferrer"
          className="footer__social-link"
          target="_blank"
        >
          <Facebook />
        </a>
      </div>
      <div className='footer__inner-link'><a
        href="https://www.linkedin.com/company/steerus"
        rel="noreferrer"
        className="footer__social-link"
        target="_blank"
      >
        <Linkedin />

      </a>
      </div>
      <div className='footer__inner-link'> <a
        href="https://www.twitter.com/steerus_io"
        rel="noreferrer"
        className="footer__social-link"
        target="_blank"
      >
        <Twitter />
      </a>

      </div>
      <a
        href={'https://discord.com/invite/JSEeHWEq'}
        rel="noreferrer"
        className="footer__social-link"
        target="_blank"
      >
        <DiscordIcon />
      </a>
    </div>
  </Footer>
)


const CustomFooter = () => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-width: 800px)',
  });
 
  return (
    <>{!isTabletOrMobileDevice ? <DesktopFooter /> : <MobileFooter />}</>
  )
};

export default CustomFooter;
