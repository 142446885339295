import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  StandardMarginDiv,
  PageLayout,
  SnippetCard,
  CapsuleCard,
  Modal,
  Button,
} from '../components';
import { userQuery } from 'hoc/withCurrentUserQuery';
import './styles.less';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import { HELPipedia_ORANGE } from 'styles/colors';

const GET_PLAYLIST = gql`
  query getPlaylist($id: ID!) {
    getPlaylist(id: $id) {
      id
      content
      badgeId
    }
  }
`;
const submitBadgeCollect = gql`

mutation  saveUserBadge($userId: ID, $badgeId: ID, $journeyId: ID, $playlistId: ID, $source: String){
  saveUserBadge(userId:$userId, badgeId: $badgeId, journeyId: $journeyId, playlistId:$playlistId, source: $source)
}`

const PlaylistComponent = ({ scrollPosition }) => {
  const { state,pathname } = useLocation();
  const params = useParams();
  const isHelpipedia=pathname.includes('helpipedia')
  const { data, refetch } = useQuery(GET_PLAYLIST, {
    variables: { id: params.id },
  });
  const { data: user } = useQuery(userQuery);
  const [mutateBadge] = useMutation(submitBadgeCollect);
  const [modal, setModal] = useState(false)
  let history = useHistory();
  useEffect(() => {
    refetch()
    checkAllStatus()
  }, [data])

  const checkAllStatus = () => {

    if (data != undefined) {
      const newArr = data?.getPlaylist?.content.filter(item => item.status == 'passed')
    
      if (data?.getPlaylist?.content.length == newArr.length) {
        {
          if (data?.getPlaylist?.badgeId != null && state != undefined && !state.isCompleted) {
            setModal(true)
          }
        }
      }
    }
  }

  const collectBagdge = async () => {
    const submitData = {
      userId: user.currentUser.id,
      badgeId: data?.getPlaylist?.badgeId,
      playlistId: data?.getPlaylist?.id,
      journeyId: null,
      source:isHelpipedia?"helpipedia":"steerus"
    }

    await mutateBadge({
      variables: submitData,
    });

    if(isHelpipedia){
      history.push({
        pathname: '/helpipedia/lesson',
      })
    }else{
      history.push({
        pathname: '/academy',
      })
    }
   


  }
  return (
    <PageLayout>
      <StandardMarginDiv>
        <div className='playlist__block'>
          {data?.getPlaylist?.content?.map((item, idx) => {
            const cardItem = item.hasOwnProperty('capsuleProperId') ? (
              <CapsuleCard
                key={item.id}
                tag={null}
                scrollPosition={scrollPosition}
                {...item}
              />
            ) : (
              <SnippetCard
                key={item.id}
                id={item.id}
                coach={item?.coach?.userProfile}
                title={item.title}
                time={item?.video?.length}
                views={item.views}
              />
            );

            return (
              <div className='playlist__block-item' key={item.id}>
                <h1 style={{color:useLocation().pathname.includes('helpipedia')?'black':''}}>
                  {' '}
                  {idx + 1}
                  .
                  {' '}
                </h1>
                {cardItem}
                {idx !== data?.getPlaylist?.content?.length - 1 && (
                  <div  className={`${useLocation().pathname.includes('helpipedia')?'playlist__block-linesHelpipedia':'playlist__block-lines'}`} >
                    <div className={`${useLocation().pathname.includes('helpipedia')?'playlist__block-lineHelpipedia ':'playlist__block-line'}`} />
                  </div>
                )}
              </div>
            );
          })}
          <Modal open={modal} onClose={() => setModal(false)}>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <h1>Congratulations</h1>
              <p>You have completed the task. Please collect the badge</p>
              <Button onClick={collectBagdge}>Collect</Button>
            </div>
          </Modal>
        </div>
      </StandardMarginDiv>
    </PageLayout>
  );
};

export default PlaylistComponent;
