import React from 'react';

import { graphql, gql } from '@apollo/react-hoc';

const getPlaylists = graphql(gql`
  query getAllPlaylists {
    getAllPlaylists {
      id
      title
    }
  }
`);

export default getPlaylists;
