import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import TextCard from 'components/TextCard';

function PlaylistCard({playlist, title, id, isCompleted }) {
  const history = useHistory();
  const location= useLocation()
  const handleClick = useCallback(() => {
    history.push({ pathname: location.pathname.includes("helpipedia")?`/helpipedia/playlist/${id}`:`/playlist/${id}`, state: { isCompleted: isCompleted } });
  }, [history, id]);

  return <TextCard playlist={playlist} isCompleted={isCompleted} onClick={handleClick} title={title} color="blue" className="playlist-card" />;
}

export default PlaylistCard;
