import React from 'react';

const ProductItem = ({image, title, description}) => {
  return (
    <div className="productItem">
      <div className="productItem__avatarContainer" style={{backgroundImage: `url(${image})`}}/>
      <div className="productItem__info">
        <h3 className="productItem__title">{title}</h3>
        <p className="productItem__description">{description}</p>
      </div>
    </div>
  );
};

export default ProductItem;
