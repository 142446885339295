import { gql } from '@apollo/client';

export const GET_CAPSULES_BY_TERM = gql`
  query getCapsulesByTerm($term: String) {
    getCapsulesByTerm(term: $term) {
      id
      journeyId
      status
      type
      views
      coach {
        id
        userProfile {
          firstName
          avatarUrl
          lastName
        }
      }
      currentContent {
        title
        imageHeaderUrl
        videoInfo {
          numberOfVideos
          time
        }
      }
    }
  }
`;
