import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { StandardMarginDiv, PageLayout, ProtectedFeature } from '../components';
import SelfReflectionContent from './SelfReflectionContent';
import { GET_USER_SELF_REFLECTION_BY_ID } from './query';

const SelfReflection = () => {
  const { id } = useParams();
  const { loading, data } = useQuery(GET_USER_SELF_REFLECTION_BY_ID, {
    variables: { id },
    fetchPolicy: 'cache-first',
  });

  return (
    <PageLayout loading={loading}>
      {!loading && data && (
        <StandardMarginDiv>
          <SelfReflectionContent
            data={data.getUserSelfReflectionByQuestionId}
          />
        </StandardMarginDiv>
      )}
    </PageLayout>
  );
};

export default SelfReflection;
