import React from 'react';
import { StandardMarginDiv, PageLayout } from '../components';
import './styles.less';

const PrivacyPolicy = () => {
  return (
    <PageLayout>
      <StandardMarginDiv>
        <div className="policy">
          <h1 className="policy__title">Privacy Policy</h1>
          <p className="policy__description">Last updated: 5 May 2024</p>
          <p className="policy__description">
            STEERus INC™, a corporate registered in New Jersey, USA, operates &nbsp;
            <a href="https://www.STEERus.io" target="_blank">
              www.STEERus.io
            </a>
            &nbsp; (the “Marketing Site”) and &nbsp;
            <a style={{color:'blue'}} href="https://www.steer.us" target="_blank">
              www.STEER.us
            </a>
            &nbsp; (the &quot;Academy&quot;) and collectively “the Sites.” Data privacy is important and STEERus understands our obligations to data
            responsibly. This page informs you of STEERus’ policies regarding the collection, use and disclosure of Personal Information received from users of
            the Sites. STEERus uses Personal Information only for providing and improving the Sites. By using the Sites, you agree to the collection and use of
            information in accordance with this policy.
          </p>
          <h3 className="policy__subtitle">Information Collection and Use</h3>
          <p className="policy__description">
            While using the Sites, STEERus may ask you to provide certain personally identifiable information that can be used to contact or identify you.
            Personally identifiable information may include but is not limited to your name and email address (&quot;Personal Information&quot;).
          </p>
          <h3 className="policy__subtitle">Log Data</h3>
          <p className="policy__description">
            Like all site operators, STEERus collects information that your browser sends whenever you visit our Sites (&quot;Log Data&quot;). This Log Data may
            include information such as your computer&#39;s Internet Protocol (&quot;IP&quot;) address, browser type, browser version, the pages of our Sites
            that you visit, the time and date of your visit, the time spent on those pages and other statistics. In addition, STEERus may use third party
            services such as Google Analytics that collect, monitor and analyze these data.
          </p>
          <h3 className="policy__subtitle">Communications</h3>
          <p className="policy__description">
            STEERus uses personal data wisely and not for spamming you. STEERus may use your Personal Information to contact you with behavioral assessment
            results, newsletters, marketing or promotional materials and other information that STEERus feel is important for you to receive. All users can opt-
            out of communications.
          </p>
          <h3 className="policy__subtitle">Cookies</h3>
          <p className="policy__description">
            Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and
            stored on your computer&#39;s hard drive. Like many sites, STEERus uses &quot;cookies&quot; to collect information. You can instruct your browser to
            refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of
            our Sites.
          </p>
          <h3 className="policy__subtitle">Security</h3>
          <p className="policy__description">
            The security of your Personal Information is important to us but remember that no method of transmission over the Internet, or method of electronic
            storage, is 100% secure. While STEERus strives to use commercially acceptable means to protect your Personal Information, we cannot guarantee its
            absolute security. Nobody can – even the most secure databases in the world have been hacked.
          </p>
          <h3 className="policy__subtitle">Changes To This Privacy Policy</h3>
          <p className="policy__description">
            This Privacy Policy is effective as of 5 May 2024 and will remain in effect except with respect to any changes in its provisions in the future,
            which will be in effect immediately after being posted on this page. STEERus reserves the right to update or change our Privacy Policy at any time
            and you should check this Privacy Policy periodically. Your continued use of the Service and Sites after we post any modifications to the Privacy
            Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.
            If STEERus make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing
            a prominent notice on our website.
          </p>
          <h3 className="policy__subtitle">Contact Us</h3>
          <p className="policy__description">If you have any questions about this Privacy Policy, please reach out to STEERus at hello@steerus.io.</p>
        </div>
      </StandardMarginDiv>
    </PageLayout>
  );
};

export default PrivacyPolicy;
