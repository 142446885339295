import { notification } from 'antd';

export default () => {
  function openNotification({ type, message = 'Oops! Something went wrong', description = null }) {
    notification[type]({
      message,
      description,
      duration: 5,
    });
  }

  return openNotification;
};
