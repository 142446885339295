import { gql } from '@apollo/react-hoc';

export const userQuery = gql`
  query currentUser {
    currentUser {
      role
        novelty
      email
      dateEnding
      subscriptionStatus
      userProfile {
        firstName
        lastName
        bio
        calendlyUserName
        lastWatchedCapsule {
          id
          title
          nextSubcapsules {
            id
          }
        }
      }
      id
    }
  }
`;
