// @ts-nocheck
import React, { useState } from 'react';
import { Button, message, Form, Input } from 'antd';
import { useFormik } from 'formik';
import { gql, useMutation } from '@apollo/client';
import * as UpChunk from '@mux/upchunk';
import { modal } from 'utils/modal';
import ModalScrollable from '../../components/ModalScrollable/ModalScrollable';

const CREATE_COACH = gql`
  mutation createCoach($input: coachInput) {
    createCoach(input: $input)
  }
`;

const CoachCreateForm = ({ push }: any) => {
  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted
  const [createUser] = useMutation(CREATE_COACH);
  const [progress, setProgress] = useState(null);
  const onChange = ({
    target: {
      validity,
      files: [file],
    },
  }: any) => validity.valid && formik.setFieldValue('file', file);

  const inputStyles = {
    width: '100%',
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
    },
    onSubmit: async (values) => {
      try {
        message.loading({
          duration: 0,
          content: 'Uploading your changes',
          key: 'loading',
        });

        const result = await createUser({
          variables: {
            input: {
              firstName: values.firstName,
              lastName: values.lastName,
              hasFile: values.file !== undefined,
            },
          },
        });

        if (result?.data?.createCoach?.upload?.url) {
          const upload = UpChunk.createUpload({
            endpoint: result?.data?.createCoach?.upload?.url,
            file: values.file,
            chunkSize: 5120, // Uploads the file in ~5mb chunks
          });
          upload.on('progress', (progressEvent) => {
            setProgress(progressEvent.detail);
          });

          upload.on('success', () => {
            message.destroy('loading');
            message.success('Completed');
            push(result.data.createCoach.id);
          });
        } else {
          message.destroy('loading');
          message.success('Completed');
          push(result.data.createCoach.id);
        }
      } catch (e) {
        message.destroy('loading');
        message.error('Error occured');
      }
    },
  });
  return (
    <Form layout="vertical" onFinish={formik.handleSubmit}>
      {progress && <h1>{progress}</h1>}
      <Form.Item label="First Name" name="firstName">
        <Input
          id="firstName"
          name="firstName"
          label="firstName"
          type="firstName"
          required
          onChange={formik.handleChange}
          value={formik.values.firstName}
          style={inputStyles}
        />
      </Form.Item>

      <Form.Item label="Last Name" name="lastName">
        <Input id="lastName" name="lastName" type="lastName" required onChange={formik.handleChange} value={formik.values.lastName} style={inputStyles} />
      </Form.Item>

      <Form.Item label="Intro Video">
        <input type="file" accept="video/*" onChange={onChange} />
      </Form.Item>

      <Form.Item>
        <Button style={inputStyles} type="primary" htmlType="submit">
          Create Expert
        </Button>
      </Form.Item>
    </Form>
  );
};

const CreateCoachModal = ({ coachQuery }: any) => {
  const closeModal = () => {
    modal.hideModal();
    coachQuery.refetch();
  };

  return (
    <ModalScrollable title="Create Expert">
      <CoachCreateForm push={closeModal} />
    </ModalScrollable>
  );
};

export default CreateCoachModal;
