import React from 'react';
import { Checkbox } from "antd";

const CheckboxAnswer = ({isSubmitted, values, answer, question}) => {
  const isIncorrect = isSubmitted &&
    ((values[question]?.includes(answer.title) && !answer.isCorrect) ||
      (!values[question]?.includes(answer.title) && answer.isCorrect));
  const isCorrect = isSubmitted && answer.isCorrect && !isIncorrect;

  return (
    <Checkbox
      className={`${isCorrect ? 'is-correct' : ''}${isIncorrect ? 'is-incorrect' : ''}`}
      value={answer.title}
    >
      {answer.title}
    </Checkbox>
  )
};

export default CheckboxAnswer;