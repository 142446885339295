import React from 'react';
import './styles.less';
import { Link, useLocation } from 'react-router-dom';

const ButtonInsideLink = ({ title, link, style = 'flex-end', targetBlank = true }) => {
  return (
    <>
      {targetBlank ? (
        <a className={`buttonLink ${useLocation().pathname.includes("helpipedia")?"buttonBg":""}`} href={link} target="_blank" style={{ alignSelf: style, textAlign: 'center' }}>
          {link !== '#' ? title : 'coming soon'}
        </a>
      ) : (
        <Link to={link} className={`buttonLink ${useLocation().pathname.includes("helpipedia")?"buttonBg":""}`} style={{ alignSelf: style, textAlign: 'center' }}>
          {link !== '#' ? title : 'coming soon'}
        </Link>
      )}
    </>
  );
};

export default ButtonInsideLink;
