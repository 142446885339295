import React from 'react';
import { InputNumber } from 'antd';

const Question14 = ({ years, setYears, index = 14 }) => {
  return (
    <div className="soft-skills-field">
      <h6>{index}. How many years of work experience do you have ?</h6>
      <InputNumber size="medium" value={years} onChange={(value) => setYears(value)} min={0} max={50} style={{ marginBottom: '12px' }} />
    </div>
  );
};

export default Question14;
