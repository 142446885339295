import { gql } from '@apollo/client';

export const GET_JOURNEY_LIST_BY_TERM = gql`
  query getJourneyListByTerm($term: String) {
    getJourneyListByTerm(term: $term) {
      id
      title
      imageUrl
      price
      videos
      snippets
      capsules
      skillChecks
      selfReflections
      workbooks
      duration
      status
    }
  }
`;

export const GET_CAPSULES_BY_TERM = gql`
  query getCapsulesByTerm($term: String) {
    getCapsulesByTerm(term: $term) {
      createdAt
      id
      journeyId
      status
      type
      views
      coach {
        id
        userProfile {
          firstName
          avatarUrl
          lastName
        }
      }
      currentContent {
        title
        imageHeaderUrl
        videoInfo {
          numberOfVideos
          time
        }
      }
    }
  }
`;

export const GET_SNIPPETS_BY_TERM = gql`
  query getSnippetsByTerm($term: String) {
    getSnippetsByTerm(term: $term) {
      id
      coachId
      title
      journeyId
      views
      video {
        length
      }
      coach {
        userProfile {
          firstName
          lastName
        }
      }
    }
  }
`;
