// @ts-nocheck
import React, { useState } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { useFormik } from 'formik';
import { gql, useMutation, useQuery } from '@apollo/client';
import { modal } from 'utils/modal';
import { get } from 'lodash';
import ReactPlayer from 'react-player';
import ModalScrollable from '../components/ModalScrollable/ModalScrollable';

const SINGLE_UPLOAD = gql`
  mutation ($file: Upload!, $type: String) {
    singleUpload(file: $file, type: $type) {
      filename
      mimetype
      encoding
      url
    }
  }
`;

const UploadFile = ({ formik }) => {
  const [mutate, { loading, error }] = useMutation(SINGLE_UPLOAD);
  const onChange = ({
    target: {
      validity,
      files: [file],
    },
  }: any) => validity.valid && formik.setFieldValue('video', file);

  if (loading) return <div>Uploading Video...</div>;
  if (error) return <div>{JSON.stringify(error, null, 2)}</div>;

  return (
    <>
      <p> Upload new video </p>
      <input type="file" accept="video/*" onChange={onChange} />
    </>
  );
};

const App: React.FC = ({ formik }) => <UploadFile formik={formik} />;

const UploadAttachment = ({ formik }) => {
  const [mutate, { loading, error }] = useMutation(SINGLE_UPLOAD);
  const onChange = ({
    target: {
      validity,
      files: [file],
    },
  }: any) => validity.valid && formik.setFieldValue('attachment', file);

  if (loading) return <div>Uploading Attachment</div>;
  if (error) return <div>{JSON.stringify(error, null, 2)}</div>;

  return (
    <>
      <Form.Item name="type" label="Attachment Type">
        <Select mode="tags" defaultValue={formik.values.attachments} onChange={(e) => formik.setFieldValue('type', e[0])}>
          <Select.Option value="Workbook">Workbook</Select.Option>
          <Select.Option value="Video">Video</Select.Option>
          <Select.Option value="Other">Other</Select.Option>
        </Select>
      </Form.Item>

      <p> Upload new attachment </p>
      <input type="file" required accept="image/*, video/*, .pdf, .txt, .doc, .docx" onChange={onChange} />
    </>
  );
};

const AppAttachment: React.FC = ({ formik }) => <UploadAttachment formik={formik} />;

const GET_ALL_VIDEOS = gql`
  query getVideos {
    getVideos {
      id
      url
      name
    }
  }
`;

const GET_ALL_ATTACHMENTS = gql`
  query getAttachments {
    getAttachments {
      id
      url
      name
    }
  }
`;

const SelectVideo = ({ formik }) => {
  const query = useQuery(GET_ALL_VIDEOS, {
    pollInterval: '0',
  });

  if (query.loading || !query.data || !query.data.getVideos) {
    return null;
  }

  return (
    <Form.Item name="videoId" label="Video">
      <Select defaultValue={formik.values.videoId} onChange={(e) => formik.setFieldValue('videoId', e)}>
        {query.data.getVideos.map((video) => (
          <Select.Option key={video.id} value={video.id}>
            {video.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const SelectAttachment = ({ formik }) => {
  const query = useQuery(GET_ALL_ATTACHMENTS);

  if (query.loading || !query.data || !query.data.getAttachments) {
    return null;
  }

  return (
    <Form.Item name="attachments" label="Attachments">
      <Select mode="tags" defaultValue={formik.values.attachments} onChange={(e) => formik.setFieldValue('attachments', e)}>
        {query.data.getAttachments.map((attachment) => (
          <Select.Option key={attachment.id} value={attachment.id}>
            {attachment.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const CREATE_COACH = gql`
  mutation createCoach($input: coachInput) {
    createCoach(input: $input)
  }
`;

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const CoachCreateForm = ({ subContent, status, index, push }: any) => {
  let url = "";
  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted

  const [createUser, { data }] = useMutation(CREATE_COACH);

  const defaultMargin = {
    marginTop: '22px',
  };
  const inputStyles = {
    width: '100%',
  };

  const [showVideo, setShowVideo] = useState(false);
  const [showVideoUpload, setShowVideoUpload] = useState(false);
  const [showAttachment, setShowAttachment] = useState(false);
  const [showAttachmentUpload, setShowAttachmentUpload] = useState(false);

  const formik = useFormik({
    initialValues: {
      title: subContent.title,
      position: index,
      book_url: subContent?.bookUrl || subContent?.book_url,
      videoId: get(subContent, 'video.id', get(subContent, 'videoId', null)),
      id: subContent.id,
      attachments: subContent.attachments || [],
      description: subContent.description,
      tags: subContent.tags,
      video: subContent.video
    },
    onSubmit: async (values) => {
      push(values);
    },
  });
  
  if (formik.values && formik.values.video)
    url = URL.createObjectURL(formik.values.video);

  return (
    <div>
      <Form layout="vertical" onFinish={formik.handleSubmit}>
        <Form.Item label="Class title" name="title" style={defaultMargin}>
          <Input
            id="title"
            name="title"
            label="title"
            type="title"
            onChange={formik.handleChange}
            value={formik.values.title}
            defaultValue={formik.values.title}
            style={inputStyles}
          />
        </Form.Item>

        <Form.Item label="Book URL" name="book_url" style={defaultMargin}>
          <Input
            id="book_url"
            name="book_url"
            label="book_url"
            type="book_url"
            onChange={formik.handleChange}
            value={formik.values.book_url}
            defaultValue={formik.values.book_url}
            style={inputStyles}
          />
        </Form.Item>

        <Form.Item label="Description" name="description" style={defaultMargin}>
          <Input.TextArea
            id="description"
            name="description"
            type="description"
            onChange={formik.handleChange}
            defaultValue={formik.values.description}
            value={formik.values.description}
            style={inputStyles}
          />
        </Form.Item>
        {status == "approved" && subContent.url_of_video && <ReactPlayer controls url={subContent.url_of_video} width="100%" height="auto" />}

        {formik.values.id !== undefined && status === 'creation' && (
          <div>
            <Button
              onClick={() => {
                setShowVideoUpload(true);
                setShowVideo(false);
              }}
            >
              Upload New Video
            </Button>

            {/* {subContent.url_of_video && <ReactPlayer controls url={subContent.url_of_video} width="100%" height="auto" />} */}
            {subContent.url_of_video ? <ReactPlayer controls url={subContent.url_of_video} width="100%" height="auto" /> : url ? <ReactPlayer controls url={url} width="100%" height="auto" /> : null}

            {showVideoUpload && <App formik={formik} />}
            <br />
            <br />
            <Button
              onClick={() => {
                setShowAttachment(true);
                setShowAttachmentUpload(false);
              }}
            >
              Select Attachment
            </Button>
            <Button
              onClick={() => {
                setShowAttachment(false);
                setShowAttachmentUpload(true);
              }}
            >
              Upload New Attachment
            </Button>

            {showAttachment && <SelectAttachment formik={formik} />}
            {showAttachmentUpload && <AppAttachment formik={formik} />}

            {/*
        <Form.Item
          name="video"
          label="Video"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          extra=""
        >
          <Upload name="logo" action="/upload.do" listType="picture">
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item> */}
          </div>
        )}

        {status !== 'creation' && (
          <div>
            <p> Create a new version of this capsule to upload a new video </p>
          </div>
        )}

        {formik.values.id == null && (
          <div>
            <p> Save your changes to upload a video </p>
          </div>
        )}

        <Form.Item style={defaultMargin}>
          <Button style={inputStyles} type="primary" htmlType="submit">
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const CreateCoachModal = ({ subContent, status, index, func }: any) => {
  const [state, setState] = useState({});
  const closeModal = (values) => {
    modal.hideModal(values);
    func(values, index);
  };

  return (
    <ModalScrollable title="Edit Content" width="620px">
      <CoachCreateForm status={status} subContent={subContent} index={index} push={closeModal} />
    </ModalScrollable>
  );
};

export default CreateCoachModal;
