import React from 'react';
import "./styles.less";
import { useLocation } from 'react-router';

const SimpleButton = ({title, click}) => {
  const isHelpipedia= useLocation().pathname.includes("helpipedia")
 
  return <button onClick={click}  className={`${isHelpipedia?'simpleButtonHelpipedia':'simpleButton'}`}>{title}</button>
};

export default SimpleButton;
