import React from 'react';
import { Input } from 'antd';

const { TextArea } = Input;

const SelfReflectionCard = ({ question, values, data, onChange }) => (
  <div className="self-reflection__form__input-block">
    <h1> {question} </h1>
    <TextArea
      name={question}
      rows={4}
      maxLength={200}
      className="self-reflection__form__answer"
      value={values[question]}
      defaultValue={data?.answers?.[question]}
      placeholder="Your answer"
      onChange={onChange}
    />
  </div>
);

export default SelfReflectionCard;
