import React from 'react';
import { useHistory } from 'react-router-dom';
import { Result, Button } from 'antd';
import { withCurrentUser } from '../hoc';
import { StandardMarginDiv, PageLayout } from '../components';

const App = () => {
  const history = useHistory();
  return (
    <PageLayout>
      <StandardMarginDiv>
        <Result
          status="404"
          title="404"
          subTitle="Sorry, we couldn't find what you were looking for."
          extra={(
            <Button onClick={() => history.push('/')} type="primary">
              Back Home
            </Button>
          )}
        />
      </StandardMarginDiv>
    </PageLayout>
  );
};

export default withCurrentUser(App);
