// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Checkbox, Form, Input, message, Select, Space, Spin } from 'antd';
import { userQuery } from '../hoc/withCurrentUserQuery';
import { HeroHalfPartner, PageLayout } from '../components';

const CREATE_STUDENT = gql`
  mutation createStudentUs4Warriors(
    $email: String
    $password: String
    $firstName: String
    $lastName: String
    $meta: JSON
    $status: JSON
    $service: JSON
    $partner: String
    $adult: Boolean
  ) {
    createStudentUs4Warriors(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      meta: $meta
      status: $status
      service: $service
      partner: $partner
      adult: $adult
    ) {
      tokens {
        accessToken
        refreshToken
      }
      error {
        field
        message
      }
      user {
        id
      }
    }
  }
`;
const SEND_CONFIRM_EMAIL_US4WARRIORS = gql`
  mutation sendConfirmEmail($email: String, $file: String) {
    sendConfirmEmail(email: $email, file: $file)
  }
`;
const SignupForm = ({ history, setLoading }) => {
  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted
  const [createStudentUs4Warriors, { data, loading }] = useMutation(CREATE_STUDENT);
  const setUser = useQuery(userQuery);
  const [sendConfirmEmail] = useMutation(SEND_CONFIRM_EMAIL_US4WARRIORS);
  useEffect(() => {
    setLoading(loading);
  }, [loading]);
  const inputStyles = {
    width: '100%',
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      tos: false,
      password: '',
    },
    onSubmit: async (values) => {
      if (!values.tos) {
        message.error('You must agree to our Terms of Service');
      } else {
        const result = await createStudentUs4Warriors({
          variables: {
            ...values,
            email: values.email.toLowerCase(),
            partner: 'us4warriors',
            meta: { education: 'veteran' },
          },
          refetchQueries: [{ query: userQuery }],
        });
        if (result.data.createStudentUs4Warriors.error) {
          message.error(result.data.createStudentUs4Warriors.error.message);
        } else {
          await sendConfirmEmail({ variables: { email: values.email, file: "us4warriorsContainer.tsx" } });
          history.push('/email-confirm');
        }
      }
    },
  });

  const validateMessages = {
    required: '${label} is required.',
  };

  return (
    <Form layout="vertical" className="form-with-antd-validation" validateMessages={validateMessages} onFinish={formik.handleSubmit}>
      <h1 className="sign-up__title"> Create your account </h1>

      <Form.Item label="First Name" name="firstName" required={false} rules={[{ required: true }]}>
        <Input
          id="firstName"
          name="firstName"
          label="firstName"
          type="firstName"
          onChange={formik.handleChange}
          value={formik.values.firstName}
          style={inputStyles}
        />
      </Form.Item>

      <Form.Item label="Last Name" name="lastName" required={false} rules={[{ required: true }]}>
        <Input
          id="lastName"
          name="lastName"
          label="lastName"
          type="lastName"
          onChange={formik.handleChange}
          value={formik.values.lastName}
          style={inputStyles}
        />
      </Form.Item>

      <Form.Item label="Email" name="Email" required={false} rules={[{ required: true }]}>
        <Input id="email" name="email" label="email" type="email" onChange={formik.handleChange} value={formik.values.email} style={inputStyles} />
      </Form.Item>

      <Form.Item label="Password" name="Password" required={false} rules={[{ required: true }]}>
        <Input id="password" name="password" type="password" onChange={formik.handleChange} value={formik.values.password} style={inputStyles} />
      </Form.Item>
      <Form.Item label="Duty Status" name="dutyStatus" style={{ width: '100%' }} required={false} rules={[{ required: true }]}>
        <Select
          placeholder="Where are you coming from?"
          onChange={(status) =>
            formik.setFieldValue('status', {
              ...formik.values.meta,
              status,
            })
          }
        >
          <Select.Option value="Active Duty Military">Active Duty Military</Select.Option>
          <Select.Option value="Post Service Veteran">Post Service Veteran</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label="Branch of Service:" name="branchOfService" style={{ width: '100%' }} required={false} rules={[{ required: true }]}>
        <Select
          placeholder="Where are you coming from?"
          onChange={(service) =>
            formik.setFieldValue('service', {
              ...formik.values.meta,
              service,
            })
          }
        >
          <Select.Option value="Army">Army</Select.Option>
          <Select.Option value="Air Force">Air Force</Select.Option>
          <Select.Option value="Marine Corps">Marine Corps</Select.Option>
          <Select.Option value="Navy">Navy</Select.Option>
          <Select.Option value="Coast Guard">Coast Guard</Select.Option>
          <Select.Option value="Space Force">Space Force</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="tos"
        valuePropName="checked"
        required={false}
        rules={[
          {
            validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('You should agree with our Terms and Conditions to proceed'))),
          },
        ]}
      >
        <Checkbox id="tos" name="tos" onChange={formik.handleChange} value={formik.values.tos}>
          <span>
            I have read and agree to the&nbsp;
            <Link to="/terms">Terms and Conditions</Link>
            &nbsp;and the&nbsp;
            <Link to="/privacy">Privacy Policy</Link>
            &nbsp;and the&nbsp;
            <Link to="/disclaimer">Legal Disclaimer.</Link>
          </span>
        </Checkbox>
      </Form.Item>

      <Form.Item>
        <Button style={inputStyles} type="primary" htmlType="submit">
          Create your account
        </Button>
      </Form.Item>

      <p className="sign-up__link" onClick={() => history.push('/login')}>
        Already have an account? Log in.
      </p>
    </Form>
  );
};

const App = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  return (
    <PageLayout hideFooter>
      {loading && (
        <Space
          style={{
            position: 'fixed',
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            zIndex: 100000,
          }}
        >
          <Spin
            style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100px', height: '100px' }}
            className="login-spin"
          />
        </Space>
      )}
      <HeroHalfPartner
        rightColor="#fff"
        background={null}
        college
        leftComponents={
          <div className="paddingTop">
            <SignupForm history={history} setLoading={setLoading} />
          </div>
        }
        title="First, thank you for your service!"
        paragraph_one="I’m Loralyn Mears, PhD, founder of STEERus, and I’m committed to helping students,
        underserved communities, and veterans rise by offering free soft skills training."
        paragraph_two="You may already know about Us4Warriors and their dedicated team helping warriors and their
        families live stronger lives. They are truly committed to helping veterans succeed. We are
        excited to partner with their team to bring our special resources to the Veteran's community."
        paragraph_free="Come on into the Academy! Work your way through our content to become better
        communicators, stronger leaders, and inspired entrepreneurs. Together, we can help all prosper
        for a brighter future!"
        light="https://image.mux.com/UaQgleQ00j2BDLG3owtxnzskRgleL01zXoBTk64FDzsJs/thumbnail.png?width=1024&height=1024&fit_mode=pad&time=18.5"
        video="https://stream.mux.com/UaQgleQ00j2BDLG3owtxnzskRgleL01zXoBTk64FDzsJs.m3u8"
      />
    </PageLayout>
  );
};

export default App;
