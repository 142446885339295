import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Formik, Form } from 'formik';
import { Button } from 'antd';
import { useNotification, useProgress } from '../../hooks';
import { ASSIGN_MATERIALS_TO_JOURNEY, GET_JOURNEY_BY_ID } from '../query';
import { CREATE_OR_UPDATE_JOURNEY } from '../../dashboard/modals/createJourneyModal';
import { PageLayout, StandardMarginDiv } from '../../components';
import { formattingDefaultValue } from './functions/formattingDefaultValue';
import BasicDetails from './components/BasicDetails';
import AssignMaterials from './components/AssignMaterials';

import './styles.less';

const JourneyEdit = () => {
  const params = useParams();
  const { data } = useQuery(GET_JOURNEY_BY_ID, {
    variables: { getUserJourneyById: params.id },
    fetchPolicy: 'network-only',
  });
  const [status, setStatus] = useState(null);
  const [fileIsSelected, setFileIsSelected] = useState(false);
  const [createOrUpdateJourney] = useMutation(CREATE_OR_UPDATE_JOURNEY);
  const [assignMaterialsToJourney] = useMutation(ASSIGN_MATERIALS_TO_JOURNEY);
  const notification = useNotification();
  const journey = data?.getUserJourneyById;
  const [progress, uploadVideo, finished] = useProgress();
  const {
    skillChecks = [], selfReflections = [], workbooks = [], assessments = [],
  } = journey || {};

  useEffect(() => {
    if (journey?.publishStatus) {
      setStatus(journey.publishStatus);
    }
  }, [journey?.publishStatus]);

  const initialValues = {
    title: journey?.title,
    description: journey?.description,
    price: journey?.price,
    coachId: journey?.coach?.userId,
    file: journey?.video,
    imageUrl: journey?.imageUrl,
    image: null,
    capsules: formattingDefaultValue(journey?.capsules),
    snippets: formattingDefaultValue(journey?.snippets),
    attachments: formattingDefaultValue([...workbooks, ...assessments]),
    questions: formattingDefaultValue([...skillChecks, ...selfReflections]),
    softskillTags: journey?.softskillTags,
    tags: journey?.tags,
    skillCheck: null,
    skillCheckUrl: journey?.skillCheckUrl,
  };

  const onSuccess = () => {
    notification({
      type: 'success',
      message: 'Journey have been saved',
    });
  };

  const onSubmit = (values, { setSubmitting }) => {
    const basicDetails = {
      id: journey.id,
      title: values.title,
      description: values.description,
      price: values.price,
      coachId: values.coachId,
      image: values.image,
      ...fileIsSelected ? {
        filePresent: true,
      } : {},
      publishStatus: status,
      softskillTags: values.softskillTags,
      tags: values.tags,
      skillCheck: values.skillCheck,
    };
    const assignMaterials = {
      id: journey.id,
      capsules: values.capsules,
      snippets: values.snippets,
      attachments: values.attachments,
      questions: values.questions,
    };

    Promise
      .all([
        createOrUpdateJourney({ variables: basicDetails }),
        assignMaterialsToJourney({ variables: assignMaterials }),
      ])
      .then(([updateJourney]) => {
        if (fileIsSelected) {
          uploadVideo(updateJourney, values.file, onSuccess);
          return false;
        }
        onSuccess();
      })
      .catch(() => {
        notification({ type: 'error' });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onChangeStatus = ({ title, description, price, coachId }) => {
    const variables = {
      id: journey.id,
      title,
      description,
      price,
      coachId,
      publishStatus: 'approved',
    };
    createOrUpdateJourney({ variables })
      .then(() => {
        setStatus('approved');
        notification({
          type: 'success',
          message: 'Journey status have been saved',
        });
      });
  };

  const onChangeStatusPending = ({ title, description, price, coachId }) => {
    const variables = {
      id: journey.id,
      title,
      description,
      price,
      coachId,
      publishStatus: 'pending',
    };
    createOrUpdateJourney({ variables })
      .then(() => {
        setStatus('pending');
        notification({
          type: 'success',
          message: 'Journey status have been saved',
        });
      });
  };

  if (!journey) return null;

  return (
    <PageLayout>
      <StandardMarginDiv>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ values, handleChange, setFieldValue, isSubmitting }) => (
            <Form className="edit-form ant-form-vertical">
              <BasicDetails
                title={values.title}
                description={values.description}
                price={values.price}
                coachId={values.coachId}
                coachData={journey.coach}
                file={values.file}
                image={values.image}
                imageUrl={values.imageUrl}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                setFileIsSelected={setFileIsSelected}
                softskillTags={values.softskillTags || []}
                tags={values.tags || []}
                skillCheck={values.skillCheck}
                skillCheckUrl={values.skillCheckUrl}
              />
              <AssignMaterials
                capsules={values.capsules}
                snippets={values.snippets}
                attachments={values.attachments}
                questions={values.questions}
                setFieldValue={setFieldValue}
              />
              <div className="edit-form__column edit-form__column-buttons">
                <Button
                  disabled={isSubmitting}
                  type="primary"
                  htmlType="submit"
                  className="edit-form__button"
                >
                  Save changes
                </Button>

                <Button
                  className="edit-form__button"
                  onClick={() => onChangeStatusPending(values)}
                >
                  Change status to pending
                </Button>

                <Button
                  className="edit-form__button"
                  onClick={() => onChangeStatus(values)}
                >
                  Change status to approved
                </Button>
                {progress && !finished && (
                  <h1>Uploading{Math.round(progress)}%</h1>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </StandardMarginDiv>
    </PageLayout>
  );
};

export default JourneyEdit;
