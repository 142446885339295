import React, { useState, useCallback, useEffect } from "react";
import { Progress } from "antd";
import ReactPlayer from "react-player";
import { useMutation, useQuery, gql } from "@apollo/client";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

import {
  PageLayout,
  AboutCoach,
  TextCollapse,
  Button,
  CapsuleCard,
  SnippetCard,
  ClassMaterial,
  JourneyPaymentForm,
  Modal,
  StandardMarginDiv,
} from "components";
import { formatTime } from "utils";
import { withCurrentUser } from "hoc";
import { CapsuleStatusesEnum } from "capsule/capusleStatuses.enum";
import { SnippetStatusesEnum } from "capsule/snippetStatuses.enum";
import { UserRolesEnum } from "users/userRoles.enum";
import {
  GET_JOURNEY_BY_ID,
  VIEW_JOURNEY,
} from "./query";
import { JourneyStatusesEnum } from "./journeyStatuses.enum";
import ClassMaterialWrapper from "./ClassMaterialWrapper";
import "./styles.less";
import { useMediaQuery } from "react-responsive";
import { CaretRightOutlined, FrownOutlined, MehOutlined, SmileOutlined } from "@ant-design/icons";
import { STEERUS_GREEN, STEERUS_GREEN_ALPHA } from '../styles/colors';
import { userQuery } from 'hoc/withCurrentUserQuery';
import Tags from '../components/SkillTags/tags';


const isContentBlocked = (user, journey) => {
 
  if (journey?.getUserJourneyById === null) {
    const history = useHistory();
    history.replace("/not-found");
  }

  const [handleViewCapsule] = useMutation(VIEW_JOURNEY, {
    variables: { id: journey?.getUserJourneyById.id },
  });

  const journeyStatus = journey?.getUserJourneyById?.status;
  let isBlocked = true;
  if (
    journeyStatus === JourneyStatusesEnum.PURCHASED ||
    journeyStatus === JourneyStatusesEnum.PASSED
  ) {
    isBlocked = false;
    handleViewCapsule();
  }
  if (
    user.currentUser.role === UserRolesEnum.COACH &&
    user.currentUser.id === journey?.getUserJourneyById.coach.userId
  ) {
    isBlocked = false;
    handleViewCapsule();
  }

  return isBlocked;
};

const calcJourneyProgress = (journey) => {
  if (!journey || journey?.status === JourneyStatusesEnum.BLOCKED) {
    return 0;
  }
  if (journey?.status === JourneyStatusesEnum.PASSED) {
    return 100;
  }

  const passedCapsulesDuration = journey.capsules.reduce(
    (prev, curr) =>
      curr.status === CapsuleStatusesEnum.PASSED
        ? curr.currentContent.videoInfo.time + prev
        : prev,
    0
  );
  const passedSnippetsDuration = journey.snippets.reduce(
    (prev, curr) =>
      curr.status === SnippetStatusesEnum.PASSED
        ? curr.video.length + prev
        : prev,
    0
  );

  const percent = Math.round(
    (100 * (passedCapsulesDuration + passedSnippetsDuration)) / journey.duration
  );

  return percent;
};

function Journey({ data: userData }) {
  if (!userData?.currentUser) {
    return null;
  }
  const { state } = useLocation();
  const { data: user } = useQuery(userQuery);
  const [emojiClicked, setEmojiClicked] = useState(false);
  const [reviewSelected, setReviewSelected] = useState(0);
  const history = useHistory();
  const params = useParams();

  const [modal, setModal] = useState(false);
  const { data, loading, refetch } = useQuery(GET_JOURNEY_BY_ID, {
    variables: { getUserJourneyById: params.id },
  });

  const isHelpipedia= useLocation().pathname.includes("helpipedia")

  const REVIEW_JOURNEY = gql`
    mutation reviewJourney($journeyId: ID!, $review: Int) {
      reviewJourney(journeyId: $journeyId, review: $review)
    }
  `;
  const submitBadgeCollect = gql`

  mutation  saveUserBadge($userId: ID, $badgeId: ID, $journeyId: ID, $playlistId: ID, $source: String){ 
    saveUserBadge(userId:$userId, badgeId: $badgeId, journeyId: $journeyId, playlistId:$playlistId, source: $source)
  }`

  const [mutateBadge] = useMutation(submitBadgeCollect);
  const [reviewJourney] = useMutation(REVIEW_JOURNEY);

  const reviews = data?.getUserJourneyById?.allReviews || [];
  let totalVotes = reviews.length;
  let avg = reviews.reduce(function (acc, obj) {
    return acc + obj.reviews;
  }, 0);
  let totalAvg = reviews.length === 0 ? 0 : Number(avg / totalVotes).toFixed(2);

  const isBlocked = isContentBlocked(userData, data);

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 800px)",
  });

  useEffect(() => {
  
    if (data?.getUserJourneyById.status === 'blocked') history.push('/academy')
    collectBagdge()
  }, [data?.getUserJourneyById.status])

  const passedPercents = calcJourneyProgress(data?.getUserJourneyById);

  useEffect(() => {
    refetch({ getUserJourneyById: params.id });
  }, []);
  const collectBagdge = async () => {
   
    if( data?.getUserJourneyById?.badgeId && data?.getUserJourneyById?.badgeId!=null && data?.getUserJourneyById && !data?.getUserJourneyById.isCollected){
    const submitData = {
      userId: user.currentUser.id,
      badgeId: data?.getUserJourneyById?.badgeId?data?.getUserJourneyById?.badgeId:null,
      playlistId:null,
      journeyId: params.id,
      source:isHelpipedia?"helpipedia":"steerus"
    }

    await mutateBadge({
      variables: submitData,
    });

  }

  }
  const handleViewProfile = useCallback(() => {
    history.push(
      `/coach/${data.getUserJourneyById?.coach?.firstName}-${data?.getUserJourneyById?.coach?.lastName}-${data?.getUserJourneyById?.coach?.userInt}`
    );
  }, [data, history]);

  const handleToggleModal = () => {
    setModal((prev) => !prev);
  };

  const handlePaymentConfirmation = useCallback(() => {

    if (data?.getUserJourneyById?.status !== 'purchased') {
      history.push('/academy')
    }

    setModal((prev) => !prev);
    refetch();
  });

  const handleWorkbookClick = (workbookUrl) => {
    if (workbookUrl.indexOf("https://prod-new-steerus") == 0)
      workbookUrl = workbookUrl.replace("https://prod-new-steerus", "https://s3.amazonaws.com/prod-new-steerus");
    if (workbookUrl.includes('pdf')) {
      console.log("url ", workbookUrl);
      history.push('/pdf', {
        url: workbookUrl,
      });
    } else {
      window.open(workbookUrl, '_Blank');
    }
  };

  const review = (rating) => {
    setReviewSelected(rating)
    setEmojiClicked(true)
    reviewJourney({
      variables: { journeyId: data?.getUserJourneyById?.id, review: rating },
    })
      .then((response) => {
        setEmojiClicked(false)
        setReviewSelected(0)

        try {
          refetch().then(res => {
            const reviews = res?.data?.getUserJourneyById?.allReviews || [];
            let totalVotes = reviews.length;
            let avg = reviews.reduce(function (acc, obj) {
              return acc + obj.reviews;
            }, 0);

            totalAvg = reviews.length == 0 ? 0 : Number(avg / totalVotes).toFixed(2);
          })
        } catch (error) {
          console.log(error);
        }

      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <PageLayout loading={loading}>
      {!loading && (
        <StandardMarginDiv>
          <div className="journey">
            <div className="journey--title">
            {data?.getUserJourneyById?.title}
            </div>
            
            <div>
              {isBlocked ? (
                <div className="journey--purchase_box">
                  <Button onClick={handleToggleModal}>Buy Journey</Button>
                  <div>
                    Unlock for <u>{data?.getUserJourneyById?.price}$</u>
                  </div>
                </div>
              ) : (
                <div className="journey--purchased">Purchased</div>
              )}
            </div>
            <div className="journey--main_box">
              <div className="journey--main_header">
                <ReactPlayer
                  controls
                  height={isTabletOrMobileDevice ? "auto" : "450px"}
                  width="100%"
                  url={data?.getUserJourneyById?.video?.url}
                />
                <AboutCoach
                  {...data?.getUserJourneyById?.coach}
                  onViewProfileClick={handleViewProfile}
                />
              </div>

              <div className={`journey--main_body ${isBlocked && "blocked"}`}>
                <div className="journey--main_body_description">
                  <TextCollapse collapseWidth={600}>
                    {data?.getUserJourneyById?.description}
                  </TextCollapse>
                </div>
                {/* {!isBlocked && (
                  <iframe src="https://e.widgetbot.io/channels/955994758092845086/958174257215594496" height="400" width="100%"></iframe>
                )} */}
              </div>
              {
              data?.getUserJourneyById?.softskillTags && data.getUserJourneyById.softskillTags.length > 0 && <div className="capsule__tags">
                <h2 className="capsule__subtitle">Soft Skills</h2>
                <Tags tags={data?.getUserJourneyById?.softskillTags || []} />
              </div>
              }
              {data?.getUserJourneyById?.tags && data.getUserJourneyById.tags.length > 0 && <div className="capsule__tags">
                <h2 className="capsule__subtitle">Tags</h2>
                <Tags tags={data?.getUserJourneyById?.tags || []} />
              </div>
              }
              <div className={`journey--main_footer ${isBlocked && "blocked"}`}>
                <div className="journey--footer_time">
                  <img
                    src={require("assets/icons/clock-circle.svg")}
                    alt="clock"
                    className="journey--footer_icon"
                  />
                  <span className="journey--footer_time_value">
                    {formatTime(data?.getUserJourneyById?.duration)}
                  </span>
                </div>
                <span className="journey--footer_time_title">
                  Total Journey time
                </span>
                {!isBlocked && (
                  <Progress
                    percent={passedPercents}
                    strokeColor="#92d050"
                    trailColor="#e5e5e5"
                  />
                )}
              </div>
            </div>

            <div className="journey--additional">
              <p className="journey--additional_title">Lessons</p>
              <div className="journey--additional_list">
                {data?.getUserJourneyById?.capsules.length ? (
                  data.getUserJourneyById?.capsules.map((capsule) => (
                    <CapsuleCard
                      key={capsule.id}
                      tag={capsule.status}
                      className="journey--capsule-card"
                      {...(isBlocked && { onClick: handleToggleModal })}
                      {...capsule}
                      journeyId={params.id}
                    />
                  ))
                ) : (
                  <p>No capsules right now</p>
                )}
              </div>
            </div>

            {/* <div className="journey--additional">
              <p className="journey--additional_title">Snippets</p>
              <div className="journey--additional_list">
                {data?.getUserJourneyById?.snippets.length ? (
                  data?.getUserJourneyById?.snippets.map((snippet) => (
                    <SnippetCard
                      key={snippet.id}
                      id={snippet.id}
                      coach={snippet.coach.userProfile}
                      title={snippet.title}
                      time={snippet.video.length}
                      views={snippet.views}
                      className="journey--snippet_card"
                    />
                  ))
                ) : (
                  <p>No Snippets right now</p>
                )}
              </div>
            </div> */}

            <div className="journey--additional">
              {/* <p className="journey--additional_title">Class Materials</p>
              <p>
                Before you start thinking, "Here's where the learning fun
                stops", we'd encourage you to take a look.
                <br />
                These materials are here for a reason!
                <br />
                Our coaching journeys would be incomplete without
                self-reflection questions and exercises that enable you to begin
                applying what you've learned.
                <br />
                Skill-checks are not graded like quizzes - they are here to
                reinforce what you've learned.
                <br />
                Assessments are an evidence-based approach that helps you learn
                things about your own behaviors, uncover blind spots, and shine
                the spotlight on character strengths that you can leverage to
                foster greater success in your personal and professional lives.
                <br />
                Because you want to be the best you can be, right? Then go for
                it!
              </p> */}

              <div className="capsule__subContentItem">
                <CaretRightOutlined style={{ color: STEERUS_GREEN }} className="capsule__caretIcon" />
                <h2
                  className="capsule__subtitle capsule__subtitleContent"
                  onClick={() => {
                    history.push(`/journey/${data.getUserJourneyById?.id}/skillCheck`);
                  }}
                >
                  Take the quiz
                </h2>
              </div>
              <div className="capsule__contentInnerLeft">
                <div className="capsule__rating">
                  <h2 className="capsule__subtitle">Did you learn from this Journey?</h2>
                  <div className="capsule__ratingIcons">
                    <FrownOutlined className="capsule__ratingIcon" style={{ color: emojiClicked && reviewSelected == 1 ? STEERUS_GREEN_ALPHA : STEERUS_GREEN }} onClick={() => review(1)} />
                    <MehOutlined className="capsule__ratingIcon" style={{ color: emojiClicked && reviewSelected == 3 ? STEERUS_GREEN_ALPHA : STEERUS_GREEN }} onClick={() => review(3)} />
                    <SmileOutlined className="capsule__ratingIcon" style={{ color: emojiClicked && reviewSelected == 5 ? STEERUS_GREEN_ALPHA : STEERUS_GREEN }} onClick={() => review(5)} />
                  </div>
                  <p className="capsule__description">
                    Avg. = {totalAvg} #Votes = {totalVotes}
                  </p>
                </div>
              </div>
              <div className="journey--materials">
                <div className="journey--material_list" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <p>Handouts</p>
                  {data?.getUserJourneyById?.workbooks?.length ? (
                    data?.getUserJourneyById?.workbooks.map((workbook) => {
                      console.log("workbook ", workbook);
                      return <div className="handout-box" key={workbook.id} style={{ margin: '0 8px' }}>
                        {/* <Link onClick={() => setSelectedPdf(workbook.url)}>
                          {workbook.name}
                        </Link> */}
                        {/* <ClassMaterialWrapper
                          isBlocked={isBlocked}
                          link={{
                            pathname: "/pdf",
                            state: { url: workbook.url },
                          }}
                          handleToggleModal={handleToggleModal}
                        > */}
                        <span
                          // to={workbook.url}
                          onClick={() => handleWorkbookClick(workbook.url)}
                          // to={`/pdf?data=${encodeURIComponent(JSON.stringify({ url: workbook.url }))}`}
                          >

                          <ClassMaterial
                            iconUrl={require("assets/icons/workbook.svg")}
                          >
                            {workbook.name}
                          </ClassMaterial>
                          </span>
                        {/* </ClassMaterialWrapper> */}
                      </div>
                    })
                  ) : (
                    <span className="empty_value">No workbooks right now</span>
                  )}
                </div>

                {/* <div className="journey--material_list">
                  <p>Skill-check</p>
                  {data?.getUserJourneyById?.skillChecks?.length ? (
                    data?.getUserJourneyById?.skillChecks?.map((skillCheck) => (
                      <ClassMaterialWrapper
                        key={skillCheck.id}
                        isBlocked={isBlocked}
                        link={`/journey/${data?.getUserJourneyById.id}/quiz/${skillCheck.id}`}
                        handleToggleModal={handleToggleModal}
                      >
                        <ClassMaterial
                          iconUrl={require("assets/icons/skill-check.svg")}
                        >
                          {skillCheck.title}
                        </ClassMaterial>
                      </ClassMaterialWrapper>
                    ))
                  ) : (
                    <span className="empty_value">No skill checks right now</span>
                  )}
                </div> */}

                {/* <div className="journey--material_list">
                  <p>Reflection Questions</p>
                  {data?.getUserJourneyById?.selfReflections?.length ? (
                    data?.getUserJourneyById?.selfReflections.map(
                      (selfReflection) => (
                        <ClassMaterialWrapper
                          key={selfReflection.id}
                          isBlocked={isBlocked}
                          link={`/journey/${data?.getUserJourneyById.id}/self-reflection/${selfReflection.id}`}
                          handleToggleModal={handleToggleModal}
                        >
                          <ClassMaterial
                            iconUrl={require("assets/icons/question.svg")}
                          >
                            {selfReflection.title}
                          </ClassMaterial>
                        </ClassMaterialWrapper>
                      )
                    )
                  ) : (
                    <span className="empty_value">No reflection questions right now</span>
                  )}
                </div> */}

                {/* <div className="journey--material_list">
                  <p>Assessments</p>
                  {data?.getUserJourneyById?.assessments?.length ? (
                    data?.getUserJourneyById?.assessments.map((assessment) => (
                      <ClassMaterialWrapper
                        key={assessment.id}
                        isBlocked={isBlocked}
                        isExternalLink
                        link={assessment.url}
                        handleToggleModal={handleToggleModal}
                      >
                        <ClassMaterial
                          iconUrl={require("assets/icons/assessment.svg")}
                        >
                          {assessment.name}
                        </ClassMaterial>
                      </ClassMaterialWrapper>
                    ))
                  ) : (
                    <span className="empty_value">No assessments right now</span>
                  )}
                </div> */}
              </div>
            </div>

            <div className="journey--footer">
              <div className={`journey--unlock ${!isBlocked && "disabled"}`}>
                <div className="journey--footer_title">
                  Want to unlock this journey?
                </div>
                <div className="journey--footer_description">
                  You can start learning today
                </div>
                <Button
                  color="white"
                  disabled={!isBlocked}
                  onClick={handleToggleModal}
                >
                  Buy Journey
                </Button>
              </div>

              <div className="journey--feedback">
                <div className="journey--footer_title">Give us feedback</div>
                <div className="journey--footer_description">
                  Your feedback helps us become stronger
                </div>
                <a
                  href={"https://urldefense.com/v3/__https://forms.gle/iNM8DjmsuFDvqe7Z8__;!!DLa72PTfQgg!OzF7qnHBKJKfbcNtBrN5KWXWhVK0mZKCWKFRf0W8bnxk0kq7Sd-nbgt9RUvvskgnXdEKv_rXA-fclGtaW9rT$"}
                  rel="noreferrer noopener"
                  target="_blank"
                  className="button white"
                >
                  Give feedback
                </a>
              </div>
            </div>
          </div>
          <Modal open={modal || data?.getUserJourneyById?.status !== 'purchased'} onClose={handlePaymentConfirmation}>
            <JourneyPaymentForm
              className="journey--modal"
              price={data?.getUserJourneyById?.price}
              journeyId={data?.getUserJourneyById?.id}
              onSuccess={handlePaymentConfirmation}
            />
          </Modal>
        </StandardMarginDiv>
      )}
    </PageLayout>
  );
}

export default withCurrentUser(Journey);
