// @ts-nocheck
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Input } from 'antd';

import {
  StandardMarginDiv,
  PageLayout,
  SnippetCard,
} from '../components';

import {
  GET_SNIPPETS_BY_TERM,
} from './query';
import './styles.less';

const SearchSnippetsContainer = () => {
  const params = useParams();
  const [searchTimeout, setSearchTimeout] = useState(null);

  const { data: snippetsData, refetch: snippetsRefetch } = useQuery(
    GET_SNIPPETS_BY_TERM,
    {
      variables: { term: params.term },
      fetchPolicy: 'network-only',
    },
  );

  const handleInputChange = (e) => {
    e.persist();
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(
      setTimeout(() => {
        snippetsRefetch({ term: e.target.value });
      }, 500),
    );
  };

  const totalResults = snippetsData?.getSnippetsByTerm?.length ?? 0;

  return (
    <PageLayout>
      <StandardMarginDiv>
        <Input
          placeholder={params.term === 'all' ? 'Search for terms' : params.term}
          onChange={handleInputChange}
        />
        <p>
          {totalResults}
          {' '}
          Total Results
        </p>

        <h1 style={{ marginTop: '25px' }}>
          Snippets:
          {' '}
          {snippetsData?.getSnippetsByTerm?.length}
          {' '}
          Results
        </h1>
        <div className="search--list">
          {snippetsData?.getSnippetsByTerm?.map((snippet) => {            
            return(
              <SnippetCard
              key={snippet.id}
              id={snippet.id}
              journeyId={snippet.journeyId}
              coach={snippet.coach.userProfile}
              title={snippet.title}
              time={snippet.video?.length}
              views={snippet.views}
            />
            )
          })}
        </div>
      </StandardMarginDiv>
    </PageLayout>
  );
};

export default SearchSnippetsContainer;
