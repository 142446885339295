// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import './styles/main.less';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ApolloClient, ApolloProvider, from, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { ModalContainer } from 'reoverlay';
import ScrollToTop from 'components/ScrollToTop';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';
import Home from './home/container';
import CapsuleContainer from './capsule/capsuleContainer';
import SearchContainer from './search/searchContainer';
import SearchCapsulesContainer from './searchCapsules/searchCapsulesContainer';
import SearchSnippetsContainer from './searchSnippets/searchSnippetsContainer';
import SearchJourneysContainer from './searchJourneys/searchJourneysContainer';
import CoachRequest from './coachRequest/coachRequest';
import CapsuleVideoPage from './capsule/capsuleVideoPage';
import LegalContainer from './legal/legalContainer';
import NotFound from './miscPages/notFound';
import SettingsContainer from './settings/settingsContainer';
import AboutContainer from './about/aboutContainer';
import CoachContainer from './coach/coachContainer';
import DashboardContainer from './dashboard/dashboardContainer';
import MarketplaceContainer from './Marketplace/marketplaceContainer';
import CapsuleCreate from './capsule/createCapsule';
import LoginContainer from './login/loginContainer';
import Us4warriorsContainer from './login/us4warriorsContainer';
import GeContainer from './login/GeContainer';
import JabordContainer from './login/jabordContainer';
import JabordVideoPage from './jabord/JabordVideoPage/JabordVideoPage';
import ForgotPasswordContainer from './login/forgotPasswordContainer';
import CreatePasswordContainer from './login/createPasswordContainer';
import ResetPasswordContainer from './login/resetPasswordContainer';
import NJITContainer from './login/njitContainer';
import PlaylistView from './playlists/playlist';
import PDFViewer from './miscPages/pdfViewer';
import StudentContainer from './login/studentContainer';
import AssessmentContainer from './assessments/SuperPower/assessmentContainer';
import LandingPage from './LandingPage/LandingPage';
import QuizPage from './QuizPage/QuizPage';
import JoinForLife from './login/joinForLife';
import WelcomeVideo from './home/welcomePage';
import SkillCheckPage from './capsule/skillCheckPage';
import SnippetView from './capsule/snippetView';
import Journey from './journey';
import JourneyEdit from './journey/JourneyEdit/JourneyEdit';
import Quiz from './quiz';
import SelfReflection from './selfReflection';
import NavBar from './components/Navbar/Navigation';
import PrivacyPolicy from './PolicyPages/PrivacyPolicy';
import TermsOfUse from './PolicyPages/TermsOfUse';
import LegalDisclaimer from './PolicyPages/LegalDisclaimer';
import EmailConfirm from 'login/EmailConfirm';
import SubscriptionPage from './PolicyPages/SubscriptionPage';
import AssessmentSoftSkills from './assessments/SoftSkillsAssesment/AssessmentSoftSkills';
import { withCurrentUser } from './hoc';
import { AppContext, AppContextProvider } from './Context';
import { modal } from './utils/modal';
import Novelty from './components/Novelty/Novelty';
import './App.less';
import AssessmentMainScreen from 'assessments/SoftSkillsAssesment/AssessmentMainScreen';
import JourneySkillCheckPage from 'journey/journeySkillCheckpage';
import CommonAssessmentScreen from 'assessments/MushyMiddle/CommonAssessmentScreen';
import CommonQuestionsScreen from 'assessments/MushyMiddle/CommonQuestionsScreen';
import CommonResultScreen from 'assessments/MushyMiddle/CommonResultScreen';
import PaymentAPITest from './test/PaymentAPITest';

//helpipedia
import LandingPageHelpipedia from 'HELPipedia/LandingPage/LandingPageHelpipedia';
import LoginContainerHelpipedia from 'HELPipedia/login/loginContainerHelpipedia'
import StudentContainerHelpipedia from 'HELPipedia/login/studentContainer'
import HomeHelpipedia from 'HELPipedia/home/container'


const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_HTTP_URL,
  // fetch: customFetch
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await window.localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, forward }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.extensions.code) {
        case 'FORBIDDEN':
          return forward((window.location.href = '/not-found'));
        default:
          return;
      }
    }
  }
});

const client = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
});

function PrivateAdminRouteFunc({ children, ...rest }) {
  if (rest.data.loading) {
    return null;
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        rest.data.currentUser.role === 'admin' && !rest.data.currentUser.educationalInstitution ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/academy',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function PrivateRouteFunc({ children, ...rest }: any) {
  const { firstLogin, setFirstLogin } = useContext(AppContext);

  if (rest.data.loading) {
    return null;
  }

  if (!firstLogin && rest?.data?.currentUser?.novelty === 'false') {
    modal.showModal(Novelty, { onClose: () => modal.hideModal(), setFirstLogin, refetch: () => rest.data.refetch() });
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        rest.data.currentUser ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
function PrivateRouteHelpipediaFunc({ children, ...rest }: any) {
  const { firstLogin, setFirstLogin } = useContext(AppContext);

  if (rest.data.loading) {
    return null;
  }

  if (!firstLogin && rest?.data?.currentUser?.novelty === 'false') {
    modal.showModal(Novelty, { onClose: () => modal.hideModal(), setFirstLogin, refetch: () => rest.data.refetch() });
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        rest.data.currentUser ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/helpipedia/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function LoginRouteFunc({ children, ...rest }) {
  let pathname = '/academy';
  if (rest.data.loading) {
    return null;
  }

  if (rest?.data?.currentUser?.partner === 'GE') {
    pathname = '/journey/10';
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !rest.data.currentUser ? (
          children
        ) : (
          <Redirect
            to={{
              pathname,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
function LogineHelpipediaRouteFunc({ children, ...rest }) {
  let pathname = '/helpipedia/lesson';
  if (rest.data.loading) {
    return null;
  }

  if (rest?.data?.currentUser?.partner === 'GE') {
    pathname = '/journey/10';
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !rest.data.currentUser ? (
          children
        ) : (
          <Redirect
            to={{
              pathname,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const PrivateRoute = withCurrentUser(PrivateRouteFunc);
const PrivateHelpipediaRoute = withCurrentUser(PrivateRouteHelpipediaFunc);
const NoLogineHelpipediaRoute = withCurrentUser(LogineHelpipediaRouteFunc);
const NoLoginRoute = withCurrentUser(LoginRouteFunc);
const PrivateAdminRoute = withCurrentUser(PrivateAdminRouteFunc);
// const SubscriptionRoute = withCurrentUser(SubscriptionRouteFunc);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function App() {
  const [firstLogin, setFirstLogin] = useState(true);

  return (
    <ApolloProvider client={client}>
      <Elements stripe={stripePromise}>
        <AppContextProvider firstLogin={firstLogin} setFirstLogin={setFirstLogin}>
          <Router>
            <ModalContainer />
            <ErrorBoundary>
              <ScrollToTop />
              <div>
                <NavBar />
                <Switch>
                  <NoLoginRoute exact path="/">
                    <LandingPage />
                  </NoLoginRoute>
                  
                  {/* HELPipedia */}
                  <NoLogineHelpipediaRoute exact path="/helpipedia">
                    <LandingPageHelpipedia />
                  </NoLogineHelpipediaRoute>
                  <NoLogineHelpipediaRoute exact path="/helpipedia/login">
                    <LoginContainerHelpipedia />
                    </NoLogineHelpipediaRoute>

                    <Route exact path="/helpipedia/signup">
                    <StudentContainerHelpipedia />
                  </Route>
                  <PrivateHelpipediaRoute exact path="/helpipedia/lesson">
                    <HomeHelpipedia />
                  </PrivateHelpipediaRoute>
                  <Route exact path="/helpipedia/marketplace">
                    <MarketplaceContainer />
                  </Route>
                  <PrivateRoute path="/helpipedia/dashboard">
                    <DashboardContainer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/helpipedia/settings">
                    <SettingsContainer />
                  </PrivateRoute>
                  
                  <Route exact path="/helpipedia/about">
                    <AboutContainer />
                  </Route>
                  <Route exact path="/helpipedia/privacy">
                    <PrivacyPolicy />
                  </Route>
                  <Route exact path="/helpipedia/terms">
                    <TermsOfUse />
                  </Route>
                  <PrivateRoute exact path="/helpipedia/subscriptionNotice">
                    <SubscriptionPage />
                  </PrivateRoute>
                  <Route exact path="/helpipedia/disclaimer">
                    <LegalDisclaimer />
                  </Route>
                  <PrivateRoute exact path="/helpipedia/assessments/3">
                    <AssessmentContainer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/helpipedia/assessments/4">
                    <AssessmentSoftSkills />
                  
                  </PrivateRoute>
                 
                  <Route exact path="/helpipedia/legal/terms-of-service">
                    <LegalContainer />
                  </Route>
                  <Route exact path="/helpipedia/legal/two-of-service">
                    <LegalContainer />
                  </Route>
            
                 
                  <Route exact path="/helpipedia/users">
                    <Home />
                  </Route>
                  <Route exact path="/helpipedia/signup/us4warriors">
                    <Us4warriorsContainer />
                  </Route>
                  <NoLoginRoute exact path="/helpipedia/signup/ge">
                    <GeContainer />
                  </NoLoginRoute>


                
                  <Route   path="/helpipedia/skill-assessment/:slug/question">
                    <CommonQuestionsScreen/>
                  </Route>
                  <Route   path="/helpipedia/skill-assessment/:slug/result">
                    <CommonResultScreen/>
                  </Route>
                  <Route  path="/helpipedia/skill-assessment/:slug/:coachId?">
                    <CommonAssessmentScreen/>
                  </Route>
                  
                  <NoLoginRoute exact path="/helpipedia/signup/jabord">
                    <JabordContainer />
                  </NoLoginRoute>
                  <NoLoginRoute exact path="/helpipedia/forgot-password">
                    <ForgotPasswordContainer />
                  </NoLoginRoute>
                  <NoLoginRoute exact path="/helpipedia/create-password/:token">
                    <CreatePasswordContainer />
                  </NoLoginRoute>
                  <NoLoginRoute exact path="/helpipedia/reset-password/:token">
                    <ResetPasswordContainer />
                  </NoLoginRoute>
                  <NoLoginRoute exact path="/helpipedia/njit">
                    <NJITContainer />
                  </NoLoginRoute>
                  <Route exact path="/helpipedia/jabord/video/:id">
                    <JabordVideoPage />
                  </Route>
                  <Route exact path="/helpipedia/signup">
                    <StudentContainer />
                  </Route>
                  <NoLoginRoute exact path="/helpipedia/email-confirm">
                    <EmailConfirm />
                  </NoLoginRoute>
                  <NoLoginRoute exact path="/helpipedia/login/:token">
                    <LoginContainer />
                  </NoLoginRoute>
                  <PrivateRoute path="/helpipedia/dashboard">
                    <DashboardContainer />
                  </PrivateRoute>

                  <PrivateRoute path="/helpipedia/welcome">
                    <WelcomeVideo />
                  </PrivateRoute>



                  <PrivateRoute exact path="/helpipedia/capsule/:id">
                    <CapsuleContainer />
                  </PrivateRoute>

                  <Route exact path="/helpipedia/join-for-life">
                    <JoinForLife />
                  </Route>

                  <PrivateRoute exact path="/helpipedia/capsule/preview/:id/:version">
                    <CapsuleContainer />
                  </PrivateRoute>

                  <PrivateAdminRoute exact path="/helpipedia/capsule/edit/:id/version/:version">
                    <CapsuleContainer />
                  </PrivateAdminRoute>

                  <PrivateRoute exact path="/helpipedia/capsule/class/:id">
                    <CapsuleVideoPage />
                  </PrivateRoute>

                  <PrivateAdminRoute exact path="/helpipedia/capsules/create">
                    <CapsuleCreate />
                  </PrivateAdminRoute>

                  <Route exact path="/helpipedia/assessments">
                    <CapsuleCreate />
                  </Route>

                  <PrivateRoute exact path="/helpipedia/settings">
                    <SettingsContainer />
                  </PrivateRoute>

                  <PrivateRoute exact path="/helpipedia/coach/:firstName-:lastName-:id">
                    <CoachContainer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/helpipedia/search/:term">
                    <SearchContainer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/helpipedia/search/capsules/:term">
                    <SearchCapsulesContainer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/helpipedia/search/snippets/:term">
                    <SearchSnippetsContainer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/helpipedia/search/journeys/:term">
                    <SearchJourneysContainer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/helpipedia/capsule/:capsuleContentId/skillCheck">
                    <SkillCheckPage />
                  </PrivateRoute>
                  <PrivateRoute exact path="/helpipedia/journey/:journeyId/skillCheck">
                    <JourneySkillCheckPage />
                  </PrivateRoute>

                  <PrivateRoute exact path="/helpipedia/quiz/:term">
                    <QuizPage />
                  </PrivateRoute>

                  <PrivateRoute exact path="/helpipedia/snippet/:id">
                    <SnippetView />
                  </PrivateRoute>

                  <PrivateRoute exact path="/helpipedia/playlist/:id">
                    <PlaylistView />
                  </PrivateRoute>

                  <Route exact path="/helpipedia/pdf">
                    <PDFViewer />
                  </Route>

                  <PrivateRoute exact path="/helpipedia/journey/:id">
                    <Journey />
                  </PrivateRoute>

                  <PrivateRoute exact path="/helpipedia/journey/:journeyId/self-reflection/:id">
                    <SelfReflection />
                  </PrivateRoute>

                  <PrivateRoute exact path="/helpipedia/journey/:journeyId/quiz/:id">
                    <Quiz />
                  </PrivateRoute>

                  <PrivateAdminRoute exact path="/helpipedia/journey/edit/:id">
                    <JourneyEdit />
                  </PrivateAdminRoute>
                  <NoLoginRoute exact path="/helpipedia/join">
                    <CoachRequest />
                  </NoLoginRoute>



{/* ================================== */}

                  <NoLoginRoute exact path="/login">
                    <LoginContainer />
                  </NoLoginRoute>
                  <Route exact path="/about">
                    <AboutContainer />
                  </Route>
                  <Route exact path="/privacy">
                    <PrivacyPolicy />
                  </Route>
                  <Route exact path="/terms">
                    <TermsOfUse />
                  </Route>
                  <PrivateRoute exact path="/subscriptionNotice">
                    <SubscriptionPage />
                  </PrivateRoute>
                  <Route exact path="/test">
                    <PaymentAPITest />
                  </Route>
                  <Route exact path="/disclaimer">
                    <LegalDisclaimer />
                  </Route>
                  <PrivateRoute exact path="/assessments/3">
                    <AssessmentContainer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/assessments/4">
                    <AssessmentSoftSkills />
                  
                  </PrivateRoute>
                 
                  <Route exact path="/legal/terms-of-service">
                    <LegalContainer />
                  </Route>
                  <Route exact path="/legal/two-of-service">
                    <LegalContainer />
                  </Route>
                  <Route exact path="/marketplace">
                    <MarketplaceContainer />
                  </Route>
                 
                  <Route exact path="/users">
                    <Home />
                  </Route>
                  <Route exact path="/signup/us4warriors">
                    <Us4warriorsContainer />
                  </Route>
                  <NoLoginRoute exact path="/signup/ge">
                    <GeContainer />
                  </NoLoginRoute>


                
                  <Route   path="/skill-assessment/:slug/question">
                    <CommonQuestionsScreen/>
                  </Route>
                  <Route   path="/skill-assessment/:slug/result">
                    <CommonResultScreen/>
                  </Route>
                  <Route  path="/skill-assessment/:slug/:coachId?">
                    <CommonAssessmentScreen/>
                  </Route>
                  
                  <NoLoginRoute exact path="/signup/jabord">
                    <JabordContainer />
                  </NoLoginRoute>
                  <NoLoginRoute exact path="/forgot-password">
                    <ForgotPasswordContainer />
                  </NoLoginRoute>
                  <NoLoginRoute exact path="/create-password/:token">
                    <CreatePasswordContainer />
                  </NoLoginRoute>
                  <NoLoginRoute exact path="/reset-password/:token">
                    <ResetPasswordContainer />
                  </NoLoginRoute>
                  <NoLoginRoute exact path="/njit">
                    <NJITContainer />
                  </NoLoginRoute>
                  <Route exact path="/jabord/video/:id">
                    <JabordVideoPage />
                  </Route>
                  <Route exact path="/signup">
                    <StudentContainer />
                  </Route>
                  <NoLoginRoute exact path="/email-confirm">
                    <EmailConfirm />
                  </NoLoginRoute>
                  <NoLoginRoute exact path="/login/:token">
                    <LoginContainer />
                  </NoLoginRoute>
                  <PrivateRoute path="/dashboard">
                    <DashboardContainer />
                  </PrivateRoute>

                  <PrivateRoute path="/welcome">
                    <WelcomeVideo />
                  </PrivateRoute>

                  <PrivateRoute exact path="/academy">
                    <Home />
                  </PrivateRoute>

                  <PrivateRoute exact path="/capsule/:id">
                    <CapsuleContainer />
                  </PrivateRoute>

                  <Route exact path="/join-for-life">
                    <JoinForLife />
                  </Route>

                  <PrivateRoute exact path="/capsule/preview/:id/:version">
                    <CapsuleContainer />
                  </PrivateRoute>

                  <PrivateAdminRoute exact path="/capsule/edit/:id/version/:version">
                    <CapsuleContainer />
                  </PrivateAdminRoute>

                  <PrivateRoute exact path="/capsule/class/:id">
                    <CapsuleVideoPage />
                  </PrivateRoute>

                  <PrivateAdminRoute exact path="/capsules/create">
                    <CapsuleCreate />
                  </PrivateAdminRoute>

                  <Route exact path="/assessments">
                    <CapsuleCreate />
                  </Route>

                  <PrivateRoute exact path="/settings">
                    <SettingsContainer />
                  </PrivateRoute>

                  <PrivateRoute exact path="/coach/:firstName-:lastName-:id">
                    <CoachContainer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/search/:term">
                    <SearchContainer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/search/capsules/:term">
                    <SearchCapsulesContainer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/search/snippets/:term">
                    <SearchSnippetsContainer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/search/journeys/:term">
                    <SearchJourneysContainer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/capsule/:capsuleContentId/skillCheck">
                    <SkillCheckPage />
                  </PrivateRoute>
                  <PrivateRoute exact path="/journey/:journeyId/skillCheck">
                    <JourneySkillCheckPage />
                  </PrivateRoute>

                  <PrivateRoute exact path="/quiz/:term">
                    <QuizPage />
                  </PrivateRoute>

                  <PrivateRoute exact path="/snippet/:id">
                    <SnippetView />
                  </PrivateRoute>

                  <PrivateRoute exact path="/playlist/:id">
                    <PlaylistView />
                  </PrivateRoute>

                  <Route exact path="/pdf">
                    <PDFViewer />
                  </Route>

                  <PrivateRoute exact path="/journey/:id">
                    <Journey />
                  </PrivateRoute>

                  <PrivateRoute exact path="/journey/:journeyId/self-reflection/:id">
                    <SelfReflection />
                  </PrivateRoute>

                  <PrivateRoute exact path="/journey/:journeyId/quiz/:id">
                    <Quiz />
                  </PrivateRoute>

                  <PrivateAdminRoute exact path="/journey/edit/:id">
                    <JourneyEdit />
                  </PrivateAdminRoute>
                  <NoLoginRoute exact path="/join">
                    <CoachRequest />
                  </NoLoginRoute>

                  <Route>
                    <NotFound />
                  </Route>
                </Switch>
              </div>
            </ErrorBoundary>
          </Router>
        </AppContextProvider>
      </Elements>
    </ApolloProvider>
  );
}

export default App;
