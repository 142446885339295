import React, { useState } from 'react';
import { get } from 'lodash';
import { useMutation, useQuery } from '@apollo/client';
import {
  APPROVE_RELEASE,
  CREATE_NEW_CAPSULE_VERSION_WITH_CHANGES,
  DELETE_CAPSULE,
  SAVE_CAPSULE_PURCHASING_TYPE,
  SAVE_CHANGES_TO_CAPSULE,
  SUBMIT_CAPSULE_FOR_APPROVAL,
} from '../mutation';
import { useHistory, useParams } from 'react-router-dom';
import { FieldArray, Formik } from 'formik';
import { Button, Form, Input, message, Select } from 'antd';
import * as UpChunk from '@mux/upchunk';
import { modal } from '../../utils/modal';
import subcapsuleModal from '../subcapsuleModal';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import { UserRolesEnum } from '../../users/userRoles.enum';
import { GET_ALL_TAGS, GetCoachesQuery } from '../query';
import { SOFT_SKILLS_TAGS, TAGS } from '../../utils';

const SelectTags = ({ values, setFieldValue }) => {
  return (
    <Form.Item name="tags" label="Tags">
      <Select onChange={(e) => setFieldValue('tags', e)} defaultValue={values.tags} type="text" mode="tags" placeholder="Tags">
      {TAGS.map((tag) => (
        <Select.Option key={tag} value={tag}>
          {tag}
        </Select.Option>
      ))}
      </Select>
    </Form.Item>
  );
};

const SelectCoach = ({ values, coachData, setFieldValue }) => {
  const query = useQuery(GetCoachesQuery);

  if (query.loading || !query?.data || !query?.data?.getCoaches) {
    return null;
  }

  const isNotDeletedCoach = query?.data?.getCoaches.CoachUser.some((coach) => coach.id === values.coachId);

  return (
    <Form.Item name="coachId" label="Coach">
      <Select defaultValue={isNotDeletedCoach ? values.coachId : `${coachData.firstName} ${coachData.lastName}`} onChange={(e) => setFieldValue('coachId', e)}>
        {query?.data?.getCoaches?.CoachUser.map((coach) => (
          <Select.Option value={coach.id} key={coach.id}>
            {coach.userProfile.firstName + ' ' + coach.userProfile.lastName}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const CapsuleEditForm = ({ capsuleQuery, statusCapsule, content, id, currentUser, capsuleId }) => {
  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted
  const [saveChangesToCapsule, { data }] = useMutation(SAVE_CHANGES_TO_CAPSULE);
  const [saveCapsulePurchasingType] = useMutation(SAVE_CAPSULE_PURCHASING_TYPE);
  const [myProgressItems, setProgressItems] = useState([]);

  const history = useHistory();
  const params = useParams();

  const [createNewCapsuleVersion, { subcapsuleData }] = useMutation(CREATE_NEW_CAPSULE_VERSION_WITH_CHANGES);

  const [deleteCapsule] = useMutation(DELETE_CAPSULE);

  const [submitCapsuleVersionForApproval, { data: approvalData }] = useMutation(SUBMIT_CAPSULE_FOR_APPROVAL);
  const [approveRelease, { data: releaseData }] = useMutation(APPROVE_RELEASE);

  const defaultMargin = {
    marginTop: '22px',
  };
  const inputStyles = {
    width: '100%',
  };

  const [visible, setVisible] = useState(false);

  return (
    <>
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          const { status, ...fields } = values;
          const newContent = JSON.parse(JSON.stringify(fields));
          newContent.subcapsuleContent.forEach((subContent, idx) => {
            let videoId;
            try {
              videoId = get(subContent, 'video.id', null);
              if (videoId === null) videoId = get(subContent, 'videoId');
            } catch (e) {
              console.log(e);
            }
            subContent.videoId = videoId;
            delete subContent.url_of_video;
            subContent.video = fields?.subcapsuleContent[idx]?.video;
            subContent.attachment = fields?.subcapsuleContent[idx]?.attachment;

            delete subContent.__typename;

            if (typeof subContent?.video?.name === 'string') {
              subContent.video = 'NEW_VIDEO_REQUEST';
            } // true
          });

          await saveCapsulePurchasingType({
            variables: {
              id: capsuleId,
              status,
            },
          });

          if (content.status === 'approved' || content.status === 'rejected') {
            message.loading({
              duration: 0,
              content: 'Uploading your changes',
              key: 'loading',
            });
            const result = await createNewCapsuleVersion({
              variables: {
                input: {
                  ...newContent,
                  imageHeader: fields.imageHeader,
                  skillCheck: fields.skillCheck,
                  capsuleId,
                  capsuleContentId: id,
                },
              },
            });

            const data = JSON.parse(result?.data?.createNewCapsuleVersionWithChanges);

            if (data?.uploadInfo?.length > 0) {
              const waitingSet = new Set(data.uploadInfo.map((x) => x.upload.id));
              data?.uploadInfo?.forEach((item) => {
                const upload = UpChunk.createUpload({
                  endpoint: item.upload.url,
                  file: fields.subcapsuleContent[item.idx].video,
                  chunkSize: 5120, // Uploads the file in ~5mb chunks
                });

                // subscribe to events
                upload.on('error', (err) => {
                  console.error('💥 🙀', err.detail);
                });

                upload.on('progress', (progress) => {
                  const filteredItem = myProgressItems.filter((pItem) => pItem.id == item.idx);
                  if (filteredItem.length) {
                    const index = myProgressItems.indexOf(filteredItem[0]);
                    const myProgressItemsToModify = myProgressItems;
                    myProgressItemsToModify[index] = {
                      id: item.idx,
                      progress: progress.detail,
                    };
                    setProgressItems(myProgressItemsToModify);
                  } else {
                    const exsitingItems = myProgressItems;
                    exsitingItems.push({
                      id: item.idx,
                      progress: progress.detail,
                    });
                    setProgressItems(exsitingItems);
                  }
                });

                upload.on('success', () => {
                  waitingSet.delete(item.upload.id);
                  if (waitingSet.size == 0) {
                    message.destroy('loading');
                    message.success(' Completed ');

                    history.push(`/capsule/edit/${params.id}/version/${data.nextVersion}`);
                  }
                });
              });
            } else {
              message.destroy('loading');
              message.success(' Completed ');

              history.push(`/capsule/edit/${params.id}/version/${data.nextVersion}`);
            }
          } else {
            message.loading({
              duration: 0,
              content: 'Uploading your changes',
              key: 'loading',
            });
            const input = {
              input: {
                ...newContent,
                imageHeader: fields.imageHeader,
                skillCheck: fields.skillCheck,
                capsuleId,
                capsuleContentId: id,
              },
            };

            const result = await saveChangesToCapsule({
              variables: {
                input: {
                  ...newContent,
                  imageHeader: fields.imageHeader,
                  capsuleId,
                  skillCheck: fields.skillCheck,
                  capsuleContentId: id,
                },
              },
            });
            const data = JSON.parse(result.data.saveChangesToCapsule);

            if (data?.uploadInfo?.length > 0) {
              const waitingSet = new Set(data?.uploadInfo?.map((x) => x.upload.id));
              data?.uploadInfo?.forEach((item) => {
                const upload = UpChunk.createUpload({
                  endpoint: item.upload.url,
                  file: fields.subcapsuleContent[item.idx].video,
                  chunkSize: 5120, // Uploads the file in ~5mb chunks
                });

                // subscribe to events
                upload.on('error', (err) => {
                  console.error('💥 🙀', err.detail);
                });

                upload.on('progress', (progress) => {
                  const filteredItem = myProgressItems.filter((pItem) => pItem.id == item.idx);
                  if (filteredItem.length) {
                    const index = myProgressItems.indexOf(filteredItem[0]);
                    const myProgressItemsToModify = myProgressItems;
                    myProgressItemsToModify[index] = {
                      id: item.idx,
                      progress: progress.detail,
                    };
                    setProgressItems(myProgressItemsToModify);
                  } else {
                    const exsitingItems = myProgressItems;
                    exsitingItems.push({
                      id: item.idx,
                      progress: progress.detail,
                    });
                    setProgressItems(exsitingItems);
                  }
                });

                upload.on('success', () => {
                  waitingSet.delete(item.upload.id);
                  if (waitingSet.size == 0) {
                    message.destroy('loading');
                    message.success(' Completed ');
                    capsuleQuery.refetch();
                    window.location.reload();
                  }
                });
              });
            } else {
              message.destroy('loading');
              message.success(' Completed ');
              capsuleQuery.refetch();
              window.location.reload();
            }
          }
        }}
        initialValues={{
          title: content.title,
          briefAbout: content.briefAbout,
          about: content.about,
          tags: content.tags || [],
          coachId: capsuleQuery.data.getCapsule.coach.id,
          featured: content.isFeatured,
          imageHeader: null,
          imageHeaderUrl: content.imageHeaderUrl,
          skillCheck: null,
          status: statusCapsule || 'paid',
          skillCheckUrl: content.skillCheckUrl,
          removing: [],
          type: content.type,
          subcapsuleContent: content?.subcapsuleContent
            ? JSON.parse(JSON.stringify(content.subcapsuleContent)).map((info, idx) => {
                const x = info;
                x.url_of_video = content?.subcapsuleContent[idx]?.video?.url;
                x.videoId = content?.subcapsuleContent[idx]?.video?.id;

                if (x?.video) {
                  delete x.video;
                }
                x.book_url = x.bookUrl;
                delete x.bookUrl;

                return x;
              })
            : [],
          coachingStyle: content.coachingStyle,
          learnGoals: content.learnGoals || [],
          softskillTags: content.softskillTags || [],
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          handleBlur,
          handleSubmit,
          dirty,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form layout="vertical" onFinish={handleSubmit}>
            <div className="defaultFlex" style={{ display: 'flex' }}>
              <div className="capsule__edit__version">
                <p className="heading"> Basic Details </p>
                {myProgressItems.map((item) => (
                  <p className="heading" key={item.id}>
                    Capsule {item.id} is at {item.progress}
                  </p>
                ))}
                <Form.Item label="Title" name="title" style={defaultMargin}>
                  <Input
                    id="title"
                    name="title"
                    defaultValue={values.title}
                    label="Title"
                    type="title"
                    required
                    onChange={handleChange}
                    value={values.title}
                    style={inputStyles}
                  />
                </Form.Item>

                <Form.Item label="Description" name="briefAboute" style={defaultMargin}>
                  <Input
                    id="briefAbout"
                    name="briefAbout"
                    type="briefAbout"
                    defaultValue={values.briefAbout}
                    onChange={handleChange}
                    value={values.briefAbout}
                    style={inputStyles}
                  />
                </Form.Item>

                <Form.Item label="Why you need to take this capsule" name="about" style={defaultMargin}>
                  <Input.TextArea
                    id="about"
                    name="about"
                    type="about"
                    defaultValue={values.about}
                    onChange={handleChange}
                    value={values.about}
                    style={inputStyles}
                  />
                </Form.Item>

                <SelectTags setFieldValue={setFieldValue} values={values} />
                <SelectCoach setFieldValue={setFieldValue} values={values} coachData={capsuleQuery.data.getCapsule.coach.userProfile} />

                <Form.Item name="status" label="Status">
                  <Select placeholder="Select a status" defaultValue={values.status} onChange={(e) => setFieldValue('status', e)}>
                    <Select.Option value="free">Free</Select.Option>
                    <Select.Option value="paid">Paid</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item name="softskillTags" label="SoftSkill Tags">
                  <Select
                    onChange={(e) => setFieldValue('softskillTags', e)}
                    type="text"
                    defaultValue={values.softskillTags}
                    mode="tags"
                    placeholder="SoftSkill Tags"
                  >
                    {SOFT_SKILLS_TAGS.map((tag) => (
                      <Select.Option key={tag} value={tag}>
                        {tag}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item name="learnGoals" label="Learning Objectives">
                  <Select
                    onChange={(e) => setFieldValue('learnGoals', e)}
                    type="text"
                    defaultValue={values.learnGoals}
                    mode="tags"
                    placeholder="Learning Objectives"
                  />
                </Form.Item>

                <Form.Item name="type" label="Type">
                  <Select onChange={(e) => setFieldValue('type', e)} defaultValue={values.type} type="text" mode="select" placeholder="Type">
                    <Select.Option value="Personal Development">Personal Development</Select.Option>
                    <Select.Option value="Professional Development">Professional Development</Select.Option>
                  </Select>
                </Form.Item>
                <p> Header Image </p>
                <img
                  style={{
                    height: '100px',
                    width: '100px',
                    marginBottom: '20px',
                  }}
                  src={content.imageHeaderUrl}
                />

                <input
                  type="file"
                  accept="image/*"
                  onChange={({
                    target: {
                      validity,
                      files: [file],
                    },
                  }) => validity.valid && setFieldValue('imageHeader', file)}
                />
                <br />
                <p> Upload a skill check </p>
                {values.skillCheckUrl && (
                  <Button style={{ marginBottom: '10px' }} onClick={() => window.open(values.skillCheckUrl, '_Blank')}>
                    Download Uploaded Skill Check
                  </Button>
                )}
                <input
                  type="file"
                  accept=".csv"
                  onChange={({
                    target: {
                      validity,
                      files: [file],
                    },
                  }) => validity.valid && setFieldValue('skillCheck', file)}
                />
              </div>

              <div className="capsule__edit__version">
                <p className="heading"> Capsule Content </p>

                <FieldArray
                  name="subcapsuleContent"
                  render={(arrayHelpers) => {
                    const setContent = (changes, index) => {
                      const modifiedArray = values.subcapsuleContent.slice();
                      modifiedArray[index] = changes;
                      setFieldValue('subcapsuleContent', modifiedArray);
                    };

                    return (
                      <div className="subcapsule__content__container">
                        {values.subcapsuleContent && values.subcapsuleContent.length > 0
                          ? values.subcapsuleContent.map((subcapsule, index) => (
                              <div className="subcapsule__content__edit" key={subcapsule.id}>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                  <p> {subcapsule.title || 'Unnamed'} </p>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <Button
                                    className={'subcapsule__content__edit-btn'}
                                    onClick={() =>
                                      modal.showModal(subcapsuleModal, {
                                        subContent: subcapsule,
                                        index,
                                        status: content.status,
                                        func: setContent,
                                      })
                                    }
                                  >
                                    Edit
                                  </Button>

                                  <Button
                                    className={'subcapsule__content__edit-btn'}
                                    type="button"
                                    onClick={() => {
                                      setFieldValue('removing', [...values.removing, parseInt(subcapsule.id, 10)]);
                                      arrayHelpers.remove(index);
                                    }}
                                  >
                                    Delete Lesson
                                  </Button>
                                  <Button
                                    className={'subcapsule__content__edit-btn'}
                                    type="button"
                                    onClick={() =>
                                      arrayHelpers.insert(index, {
                                        title: 'Unnamed',
                                      })
                                    }
                                  >
                                    Insert Lesson Before
                                  </Button>
                                </div>
                              </div>
                            ))
                          : null}
                        <Button type="button" onClick={() => arrayHelpers.push({ video: null })}>
                          Add lesson
                        </Button>
                      </div>
                    );
                  }}
                />
              </div>
              <div className="capsule__edit__version">
                <p className="heading"> Advanced </p>

                {content.status === 'approved' && !dirty && (
                  <div>
                    <p className="heading"> Capsule Published </p>

                    <Button style={{ marginTop: '5px' }} htmlType="submit" type="primary">
                      {' '}
                      Create new version with changes{' '}
                    </Button>
                  </div>
                )}

                {content.status === 'rejected' && !dirty && (
                  <div>
                    <p className="heading"> Capsule Rejected </p>
                    <Button style={{ marginTop: '5px' }} htmlType="submit" type="primary">
                      {' '}
                      Create new version with changes{' '}
                    </Button>
                  </div>
                )}

                <Button
                  onClick={() =>
                    modal.showModal(ConfirmationModal, {
                      title: 'Are you sure?',
                      message: 'Do you want to delete a capsule?',
                      onDelete: async () => {
                        await deleteCapsule({
                          variables: { capsuleId: params.id },
                        });
                        history.push('/dashboard');
                        modal.hideModal();
                      },
                    })
                  }
                >
                  Delete Capsule
                </Button>

                {content.status === 'creation' && (
                  <>
                    <p className="heading"> Capsule Unpublished </p>
                    {dirty && (
                      <Button disabled={isSubmitting} style={{ marginTop: '5px', marginRight: '5px' }} htmlType="submit" type="primary">
                        Save changes
                      </Button>
                    )}
                    <Button
                      onClick={() => {
                        submitCapsuleVersionForApproval({
                          variables: { capsuleContentId: id },
                        }).then(() => {
                          capsuleQuery.refetch();
                        });
                      }}
                      style={{ marginTop: '5px', marginRight: '5px' }}
                      type="primary"
                    >
                      Submit for approval
                    </Button>

                    <Button onClick={() => history.push(`/capsule/preview/${params.id}/${params.version}`)}>Preview</Button>
                  </>
                )}

                {content.status === 'pending' && !dirty && (
                  <>
                    <p className="heading"> Capsule Pending Review </p>
                    {dirty && (
                      <Button style={{ marginTop: '5px' }} htmlType="submit" type="primary">
                        Create new version with changes
                      </Button>
                    )}

                    {currentUser.role === UserRolesEnum.ADMIN && !currentUser?.educationalInstitution && (
                      <div>
                        <Button
                          style={{ marginRight: '10px' }}
                          onClick={() => {
                            approveRelease({
                              variables: {
                                approved: true,
                                notes: null,
                                capsuleContentId: id,
                              },
                            });
                            capsuleQuery.refetch();
                          }}
                        >
                          Approve Release
                        </Button>
                        <Button
                          style={{ marginRight: '10px' }}
                          onClick={() => {
                            approveRelease({
                              variables: {
                                approved: false,
                                notes: null,
                                capsuleContentId: id,
                              },
                            });
                            capsuleQuery.refetch();
                          }}
                        >
                          Reject Release
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CapsuleEditForm;
