// @ts-nocheck
import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Input } from 'antd';
import ButtonInsideLink from '../components/Buttons/ButtonInsideLink';
import { StandardMarginDiv, PageLayout, CapsuleCard, SnippetCard, JourneyCard } from '../components';

import { GET_JOURNEY_LIST_BY_TERM, GET_CAPSULES_BY_TERM, GET_SNIPPETS_BY_TERM } from './query';
import './styles.less';
import { trackWindowScroll } from 'react-lazy-load-image-component';

const SearchContainer = ({ scrollPosition }) => {
  const params = useParams();
  const [searchTimeout, setSearchTimeout] = useState(null);
const isHelpipedia= useLocation().pathname.includes("helpipedia")
  const { data: journeysData, refetch: journeyRefetch } = useQuery(GET_JOURNEY_LIST_BY_TERM, {
    variables: { term: params.term.toLowerCase() === 'emotional intelligence' ? 'eq' : params.term },
    fetchPolicy: 'network-only',
  });
  const {
    data: capsulesData,
    refetch: capsulesRefetch,
    loading,
  } = useQuery(GET_CAPSULES_BY_TERM, {
    variables: { term: params.term.toLowerCase() === 'emotional intelligence' ? 'eq' : params.term },
    fetchPolicy: 'network-only',
  });
  const { data: snippetsData, refetch: snippetsRefetch } = useQuery(GET_SNIPPETS_BY_TERM, {
    variables: { term: params.term.toLowerCase() === 'emotional intelligence' ? 'eq' : params.term },
    fetchPolicy: 'network-only',
  });

  const handleInputChange = (e) => {
    e.persist();
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(
      setTimeout(() => {
        journeyRefetch({ term: e.target.value.toLowerCase() === 'emotional intelligence' ? 'eq' : e.target.value });
        capsulesRefetch({ term: e.target.value.toLowerCase() === 'emotional intelligence' ? 'eq' : e.target.value });
        snippetsRefetch({ term: e.target.value.toLowerCase() === 'emotional intelligence' ? 'eq' : e.target.value });
      }, 500)
    );
  };

  const totalResults =
    (journeysData?.getJourneyListByTerm?.length ?? 0) + (capsulesData?.getCapsulesByTerm?.length ?? 0) + (snippetsData?.getSnippetsByTerm?.length ?? 0);

  return (
    <PageLayout loading={loading} hideFooter={loading}>
      {!loading ? (
        <StandardMarginDiv>
          <Input placeholder={params.term === 'all' ? 'Search for terms' : params.term} onChange={handleInputChange} />
          <p>{totalResults} Total Results</p>
          {params.term !== 'all' ? (
            <div style={{ display: 'flex', alignItems: 'center', margin: '15px 0' }}>
              {/* Issue Number 21 start */}
              {/* * <p className="heading" style={{ marginRight: '30px', marginBottom: 0 }}>  */}
                 {/* Take the quiz  */}
               {/* </p>  */}
              {/* <ButtonInsideLink title="Take the Quiz" style="center" link={'/quiz/' + params.term} />  */}
              {/* Issue Number 21 End */}
            </div>
          ) : null}
          {params.term === 'all' ? (
            <>
              <p className="heading" style={{color:isHelpipedia?"black":''}}>Journeys: {journeysData?.getJourneyListByTerm?.length} Results</p>
              <div className="search--list">
                {journeysData?.getJourneyListByTerm?.map((journey: any) => (
                  <JourneyCard
                  journey={journey}
                    scrollPosition={scrollPosition}
                    key={journey.id}
                    id={journey.id}
                    title={journey.title}
                    description={{
                      videos: journey.videos,
                      snippets: journey.snippets,
                      capsules: journey.capsules,
                      skillChecks: journey.skillChecks,
                      selfReflections: journey.selfReflections,
                      workbooks: journey.workbooks,
                    }}
                    status={journey.status}
                    isBlocked={journey.status === 'blocked'}
                    time={journey.duration}
                    imageUrl={journey.imageUrl}
                    price={journey.price}
                  />
                ))}
              </div>
            </>
          ) : null}

          <p className="heading" style={{color:isHelpipedia?"black":''}}>Capsules: {capsulesData?.getCapsulesByTerm?.length} Results</p>
          <div className="search--list">
            {capsulesData?.getCapsulesByTerm?.map((capsule) => (
              <CapsuleCard scrollPosition={scrollPosition} key={capsule.id} tag={new Date(capsule.createdAt + 12096e5) > new Date()} {...capsule} />
            ))}
          </div>

          <p className="heading" style={{color:isHelpipedia?"black":''}}>Snippets: {snippetsData?.getSnippetsByTerm?.length} Results</p>
          <div className="search--list">
            {snippetsData?.getSnippetsByTerm?.map((snippet) => (
              <SnippetCard
                key={snippet.id}
                id={snippet.id}
                journeyId={snippet.journeyId}
                coach={snippet.coach.userProfile}
                title={snippet.title}
                time={snippet.video?.length}
              />
            ))}
          </div>
        </StandardMarginDiv>
      ) : null}
    </PageLayout>
  );
};

export default trackWindowScroll(SearchContainer);
