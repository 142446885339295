// @ts-nocheck
import React, { useState } from 'react';
import { Button, message, Form, Input, Select } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';
import { modal } from 'utils/modal';
import { CREATE_SKILL_CHECK, CREATE_SELF_REFLECTION } from 'dashboard/query';
import { useNotification } from '../../hooks';
import ModalScrollable from '../../components/ModalScrollable/ModalScrollable';

const CreateQuestionForm = ({ push }: any) => {
  const [createSkillCheck] = useMutation(CREATE_SKILL_CHECK);
  const [createSelfReflection] = useMutation(CREATE_SELF_REFLECTION);
  const [textareaError, setTextareaError] = useState(false);
  const [formIsSubmit, setFormSubmit] = useState(false);
  const notification = useNotification();

  const initialValues = {
    title: '',
    type: 'self_reflection',
    description: '',
    questions: null,
  };

  const onTextareaErrorHandling = (value) => {
    let hasError = false;
    try {
      const result = !Array.isArray(JSON.parse(value));
      setTextareaError(result);
      hasError = result;
    } catch {
      setTextareaError(true);
      hasError = true;
    }
    return hasError;
  };

  const onSubmit = async (values) => {
    setFormSubmit(true);
    const hasError = onTextareaErrorHandling(values.questions);
    if (hasError) return false;
    message.loading({
      duration: 0,
      content: 'Uploading your changes',
      key: 'loading',
    });
    const { type, ...variables } = values;

    const action = type === 'self_reflection' ? createSelfReflection : createSkillCheck;
    variables.questions = JSON.parse(variables.questions);

    action({ variables })
      .then(() => {
        message.destroy('loading');
        message.success('Completed');
        push();
      })
      .catch(() => {
        notification({ type: 'error' });
      });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  const onTextareaChange = (e) => {
    formik.handleChange(e);
    onTextareaErrorHandling(e.target.value);
  };

  return (
    <Form layout="vertical" onFinish={formik.handleSubmit}>
      <Form.Item label="Question Title" name="title">
        <Input name="title" label="title" required onChange={formik.handleChange} value={formik.values.title} />
      </Form.Item>

      <Form.Item name="description" label="Description">
        <Input.TextArea id="description" name="description" rows={3} value={formik.values.description} onChange={formik.handleChange} />
      </Form.Item>

      <Form.Item name="type" label="Question type">
        <Select value={formik.values.type} defaultValue={formik.values.type} onChange={(e) => formik.setFieldValue('type', e)}>
          <Select.Option value="self_reflection">Self reflection</Select.Option>
          <Select.Option value="skill_check">Skill check</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item name="questions" label="Questions(Array)">
        <Input.TextArea
          id="questions"
          name="questions"
          className={`${textareaError && formIsSubmit ? 'invalid-input' : ''}`}
          rows={6}
          value={formik.values.questions}
          onChange={onTextareaChange}
        />
        {textareaError && formIsSubmit && (
          <div className="alert-error ant-form-item-explain">
            <div role="alert">Questions must be array</div>
          </div>
        )}
      </Form.Item>

      <Form.Item>
        <Button disabled={formik.isSubmitting} type="primary" htmlType="submit">
          Save Question
        </Button>
      </Form.Item>
    </Form>
  );
};

const CreateQuestionModal = ({ questionsQuery }: any) => {
  const closeModal = () => {
    modal.hideModal();
    questionsQuery.refetch();
  };

  return (
    <ModalScrollable title="Create Question">
      <CreateQuestionForm push={closeModal} />
    </ModalScrollable>
  );
};

export default CreateQuestionModal;
