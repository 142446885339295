// @ts-nocheck
import React from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Button, Form, Input, message, Checkbox, Select,
} from 'antd';

import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { userQuery } from '../hoc/withCurrentUserQuery';
import { PageLayout, HeroHalf } from '../components';

const CREATE_STUDENT = gql`
  mutation createStudent($email: String, $password: String) {
    createStudent(email: $email, password: $password) {
      tokens {
        accessToken
        refreshToken
      }
      error {
        field
        message
      }
      user {
        id
      }
    }
  }
`;

const SignupForm = ({ history }) => {
  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted
  const [createStudent, { data }] = useMutation(CREATE_STUDENT);
  const setUser = useQuery(userQuery);

  const defaultMargin = {
    marginTop: '22px',
  };
  const inputStyles = {
    width: '100%',
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      tos: false,
      password: '',
    },
    onSubmit: async (values) => {
      if (!values.email.includes('njit.edu')) {
        message.error('Sign up with your NJIT email');
      } if (!values.tos) {
        message.error('You must agree to our Terms of Service');
      } else {
        const result = await createStudent({
          variables: values,
          refetchQueries: [{ query: userQuery }],
        });
        if (result.data.createStudent.error) {
          message.error(result.data.createStudent.error.message);
        } else {
          localStorage.setItem(
            'token',
            result.data.createStudent.tokens.accessToken,
          );
          localStorage.setItem(
            'refreshToken',
            result.data.createStudent.tokens.refreshToken,
          );

          const httpLink = createUploadLink({
            uri: process.env.REACT_APP_API_HTTP_URL,
          });

          const authLink = setContext(async (_, { headers }) => {
            // get the authentication token from local storage if it exists
            const token = await window.localStorage.getItem('token');
            // return the headers to the context so httpLink can read them
            return {
              headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : '',
              },
            };
          });

          history.push('/');
        }
      }
    },
  });
  return (
    <Form layout="vertical" onFinish={formik.handleSubmit}>
      <h1> Create your free account </h1>
      <Form.Item label="Email" name="Email" style={defaultMargin}>
        <Input
          id="email"
          name="email"
          label="email"
          type="email"
          onChange={formik.handleChange}
          value={formik.values.email}
          style={inputStyles}
        />
      </Form.Item>

      <Form.Item label="Password" name="Password" style={defaultMargin}>
        <Input
          id="password"
          name="password"
          type="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          style={inputStyles}
        />
      </Form.Item>

      <Form.Item style={{ ...defaultMargin, width: '100%' }}>
        <Select placeholder="Where are you coming from?" defaultValue={formik.values?.meta?.education} onChange={(e) => formik.setFieldValue('meta', { ...formik.values.meta, education: e })}>
          <Select.Option value="High School">High School</Select.Option>
          <Select.Option value="College">College</Select.Option>
          <Select.Option value="Educator">Educator</Select.Option>
          <Select.Option value="Other">Other</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item>
        <Checkbox id="tos" onChange={formik.handleChange} value={formik.values.tos}>
          <span>
            I have read and agree to the&nbsp;
            <a
              href="https://prod-new-steerus.s3.us-east-1.amazonaws.com/static/STEERus%20Terms%20and%20Conditions_30jan2021.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Terms and Conditions
            </a>
            {' '}
            and the
            <a
              href="https://prod-new-steerus.s3.amazonaws.com/static/Privacy+Policy_27oct2020+1.pdf"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
&nbsp;Privacy Policy.
              {' '}
            </a>
          </span>
        </Checkbox>
      </Form.Item>

      <Form.Item style={defaultMargin}>
        <Button style={inputStyles} type="primary" htmlType="submit">
          Create your free account
        </Button>
      </Form.Item>

      {/* <p style={{ color: STEERUS_GREEN, textAlign: "center" }}>
        New to STEERus? Register now
      </p> */}
    </Form>
  );
};

const App = () => {
  const history = useHistory();
  return (
    <PageLayout hideFooter>
      <HeroHalf
        rightColor="#D22630"
        background={null}
        college="NJIT"
        leftComponents={(
          <div className="paddingTop">
            <SignupForm history={history} />
          </div>
        )}
      />
    </PageLayout>
  );
};

export default App;
