// @ts-nocheck
import React from 'react';
import { Button, message, Form, Input, Select } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';
import { useNotification } from 'hooks';
import { CREATE_ASSESSMENT, UPLOAD_ATTACHMENT_FILE } from 'dashboard/query';
import { modal } from 'utils/modal';
import ModalScrollable from '../../components/ModalScrollable/ModalScrollable';

const CreateAttachmentForm = ({ push }: any) => {
  const [createAssessment] = useMutation(CREATE_ASSESSMENT);
  const [uploadAttachmentFile] = useMutation(UPLOAD_ATTACHMENT_FILE);
  const notification = useNotification();

  const initialValues = {
    type: 'workbook',
    name: '',
    url: '',
    file: null,
  };

  const onSubmit = async (values) => {
    message.loading({
      duration: 0,
      content: 'Uploading your changes',
      key: 'loading',
    });

    const variables = {};
    variables.name = values.title;
    if (values.type === 'workbook') {
      const result = await uploadAttachmentFile({
        variables: {
          type: 'Workbook',
          name: variables.name,
          file: values.file,
        },
      });

      if (result?.data?.uploadAttachmentFile?.url) {
        message.destroy('loading');
        message.success('Completed');
        push();
      }
      return;
    }

    if (values.type === 'assessment') {
      variables.url = values.url;
      const result = await createAssessment({ variables });
      if (result?.data?.createAssessment?.id) {
        message.destroy('loading');
        message.success('Completed');
        push();
      }
      return;
    }

    notification({ type: 'error' });
    message.destroy('loading');
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  const onChange = ({ target }) => {
    target.validity.valid && formik.setFieldValue('file', target.files[0]);
  };

  return (
    <Form layout="vertical" onFinish={formik.handleSubmit}>
      <Form.Item label="Attachment Title" name="title">
        <Input name="title" label="title" required onChange={formik.handleChange} value={formik.values.title} />
      </Form.Item>

      <Form.Item name="type" label="Assessment type">
        <Select value={formik.values.type} defaultValue={formik.values.type} onChange={(e) => formik.setFieldValue('type', e)}>
          <Select.Option value="workbook">Workbook</Select.Option>
          <Select.Option value="assessment">Assessment</Select.Option>
        </Select>
      </Form.Item>

      {formik.values.type === 'workbook' ? (
        <Form.Item label="File" name="file">
          <input type="file" name="file" accept="image/*, text/plain, .pdf, .csv, .xls, .xlsx, .doc, .docx" required onChange={onChange} />
        </Form.Item>
      ) : (
        <Form.Item label="Url of assessment" name="url">
          <Input id="url" name="url" label="url" required onChange={formik.handleChange} value={formik.values.title} />
        </Form.Item>
      )}

      <Form.Item>
        <Button disabled={formik.isSubmitting} type="primary" htmlType="submit">
          Save Attachment
        </Button>
      </Form.Item>
    </Form>
  );
};

const CreateAttachmentsModal = ({ attachmentQuery }: any) => {
  const closeModal = () => {
    modal.hideModal();
    attachmentQuery.refetch();
  };

  return (
    <ModalScrollable title="Create Attachment">
      <CreateAttachmentForm push={closeModal} />
    </ModalScrollable>
  );
};

export default CreateAttachmentsModal;
