import React, { useEffect, useState } from 'react';
import './mushymiddle.css';
import { PageLayout } from 'components';
import { gql, useQuery } from '@apollo/client';
import { GET_NEW_ASSESSMENTS } from 'assessments/SoftSkillsAssesment/request';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import back from './../../assets/assessments/left_arrow.png'
import next from './../../assets/assessments/right_arrow.png'
import mushyLogo from './../../assets/assessments/mushy_test_logo.png'
import MushyMiddleImage from './../../assets/assessments/mushy_middle_image.jpg'
import ActiveRadio from './../../assets/assessments/radio_active.png'
import InActiveRadio from './../../assets/assessments/radio_inactive.png'
export default function CommonQuestionsScreen() {

    const { state } = useLocation();
    const [index, setIndex] = useState(0)
    const [enableSubmit, setEnableSubmit] = useState(false)
    const [assessmentData, setAssessmentData] = useState(["question one", "question two"])
    const [price, setPrice] = useState(5);
    const [pathname,setPathname]=useState('')

    const { loading, data: assessmentNewData } = useQuery(GET_NEW_ASSESSMENTS, {
        variables: { assessmentId: state!=undefined? state.id:-1 },
    });

    useEffect(() => {
        if (!loading) {
            if (assessmentNewData!=undefined && assessmentNewData.getSkillAssessmentQuestions!=undefined) {
                setPrice(assessmentNewData.getSkillAssessmentQuestions.price);
                setAssessmentData(assessmentNewData.getSkillAssessmentQuestions.questions.map((item, i) => {
                    return {
                        id: i, question: item, options: [
                            { value: "Strongly Disagree", checked: false },
                            { value: "Disagree", checked: false },
                            { value: "Neutral", checked: false },
                            { value: "Agree", checked: false },
                            { value: "Strongly Agree", checked: false }]
                    }
                }));
            }
            setPathname(state!=undefined ?state.pathname:"")
        }
    }, [loading])

    const radioHandler = (value, id) => {
        var updatedArray = assessmentData.map(item => {
            if (item.id == id) {
                return {
                    ...item, options: item.options.map(opt => {
                        if (value == opt.value) {
                            return { ...opt, checked: true }
                        }
                        else {
                            return { ...opt, checked: false }
                        }
                    })
                }
            }
            else {
                return { ...item }
            }

        })

        setAssessmentData(updatedArray)

        if (index == assessmentData.length - 1)
            setEnableSubmit(true)
        else setEnableSubmit(false)

    }
    const RadioButton = ({ isChecked, choice }) => {
        const id = assessmentData[index]?.id

        return <div className='radio-div' onClick={() => {

            radioHandler(choice, id)
        }}>
            {isChecked ? <img src={ActiveRadio} /> : <img src={InActiveRadio} />
            }
            <p>{choice}</p>
        </div>
    }
    const updateNext = () => {
        var filterArray = assessmentData[index].options.filter(item => item.checked == true)

        if (filterArray.length > 0) {
            setIndex(index + 1)
        }
    }
    const updatePrevious = () => {

        setIndex(index - 1)

    }

    return (
        <PageLayout>
            <div className='backColor'>
                {state != undefined && state.email != "" ? <div className='assessment-main-div'>
                    <div className='image-div'>
                        <img src={state!=undefined && state.landingData.backgroundImage} className='img' />
                        <img src={state!=undefined && state.landingData.watermark} className='img-2' />
                    </div>

                    <div className='assessment-div'>
                        <p className='p-question'>{assessmentData[index].question}</p>
                        <div className='radio-container'>

                            {!loading && assessmentData[index]?.options != undefined && assessmentData[index]?.options.map((value) => (
                                <RadioButton
                                    isChecked={value.checked}
                                    key={value.value}
                                    choice={value.value}
                                />
                            ))}
                        </div>
                    </div>

                    <div className='prev-forw-btn-div'>
                        <div className='prev'>
                            {index != 0 && <div onClick={updatePrevious} className='btn-div'>

                                <img className='prev-forw-img' src={back} />
                                <div className='p-prev-forw'>Previous</div>
                            </div>}
                        </div>
                        <div className='prev'>
                        {enableSubmit && 
                            <Link key={'get_skills'} to={{ pathname: `${pathname}/result`, state: {coachId:state!=undefined? state.coachId!=undefined?state.coachId:null:null, id:state!=undefined? state.id:-1 , price: price, answers: assessmentData, email: state != undefined && state.email != ""?state.email:"" } }}  className='btn-div-link' style={{marginTop:0, alignSelf:'center'}}>
                                Submit Assessment
                            </Link>
                            }
                            </div>
                        <div className='prev'>
                            {index != assessmentData.length - 1 && <div onClick={updateNext} className='btn-div'>
                                <div className='p-prev-forw'>Next</div>
                                <img className='prev-forw-img' src={next} />
                            </div>}
                            
                        </div>
                    </div>
                    
                  
                </div> : !loading && <div style={{ flexDirection: 'column', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                    <p style={{ color: 'black' }}>No email found.</p>
                    <Link key={'get_skills'} to={`/marketplace`} className="assessment_get_skill">
                        Go Back
                    </Link>  </div>}
            </div>

        </PageLayout>
    )
}