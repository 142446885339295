import React from 'react';
import './styles.less';
import { PageLayout, StandardMarginDiv } from 'components';
import BigButton from 'components/Buttons/BigButton';

const LandingPageHelpipedia = () => {
  // const productsJSX = productData
  //   .sort((a, b) => (a.title > b.title ? 1 : -1))
  //   .map((product) => {
  //     return <ProductItem key={product.title} image={product.image} title={product.title} description={product.description} />;
  //   });

  return (
    <PageLayout>
      <StandardMarginDiv>
        <div className="landingPage">
          <p className="landingPage__description">
            Welcome to the <b>Hub-for-Help</b>. If you believe in a better future for our youth and want to be a part of a heart-centered community of
            changemakers, you’ve come to the right place.
            <br />
            <br />
            HELPipedia is a 501(c)3 nonprofit registered in New Jersey, USA.
            <br />
            <br />
            You can proceed without registering. However, those who do create a free profile
            have access to more features and content.
          </p>
          
          <div className="landingPage__upskill">
          
            <div className="button-container">
              <BigButton padding="15px" font="20px" title="Register now" link="/helpipedia/signup" style="center" />
            </div>
            <div className="button-container">
              <BigButton padding="15px" title="Enter the HUB" link="/helpipedia/LOGIN" style="center" font="20px" />
            </div>
          </div>

  
        </div>
      </StandardMarginDiv>
    </PageLayout>
  );
};

export default LandingPageHelpipedia;
