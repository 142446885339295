import { Reoverlay } from 'reoverlay';

export const modal = {
  showModal: (action, options) => {
    document.body.classList.add('modal-open');
    Reoverlay.showModal(action, options);
  },
  hideModal: () => {
    Reoverlay.hideModal();
    setTimeout(() => {
      if (!document.querySelector('.ant-modal-root')) {
        document.body.classList.remove('modal-open');
      }
    })
  },
};
