import React from 'react';
import { QUESTION_12, values as val } from '../SoftSkillsAssesment/utils';
import { Checkbox, Radio } from 'antd';

const Question12 = ({ index = 12, question12, isTabletOrMobileDevice, softSkills, setQuestion12 }) => {
  return (
    <div className='soft-skills-field'>
      <h6>
        {index}.We would like to understand your educational attainment. What degrees or certificates have you earned to
        date?{' '} Skip if you haven't completed education.
        {/* <b className={'required__star'}>*</b> */}
      </h6>
      {!isTabletOrMobileDevice && (
        <div className='soft-skills-field__header'>
          {softSkills.map((item) => (
            <span key={item}>{item}</span>
          ))}
        </div>
      )}
      {QUESTION_12.map((question, index) => (
        <div className='test__questions-block' key={question.value + index}>
          <h4 className='test__questions-block-title'>{question?.value}</h4>
          <div className='test__questions-block-radio'>
            <Radio.Group
              size='large'
              onChange={(e) => {
                setQuestion12((prev) => ({
                  ...prev,
                  [question.key]: e.target.value,
                }));
              }}
            >
              {val.map((value, index) => (
                <Radio key={value + index} value={value.value}>
                  {isTabletOrMobileDevice && softSkills[index]}
                </Radio>
              ))}
            </Radio.Group>
          </div>
        </div>
      ))}

    {/* <div className="test__questions-block"> */}
        {/* <p className={"test__questions-block-title"} style={{width:'initial'}}>I have not completed high school or an equivalent program   </p> */}
        {/* <Checkbox value={question12.uneducated} onChange={(e) => setQuestion12(prev => ({ */}
          {/* ...prev, */}
          {/* uneducated: e.target.checked, */}
        {/* }))} /> */}
      {/* </div> */}
      
    </div>
  );
};

export default Question12;
