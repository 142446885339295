import { gql } from '@apollo/react-hoc';

export const GET_RECOMMENDED_JOURNEYS = gql`
  query Query {
    getRecommendedJourneys
  }
`;

export const GET_ALL_PLAYLISTS = gql`
  query getAllPlaylists($flag: String) {
    getAllPlaylists(flag: $flag)
  }
`;

export const GET_RECOMMENDED_CAPSULES = gql`
  query getRecommendedCapsules {
    getRecommendedCapsules {
      id
      createdAt
      views
      coach {
        id
        userProfile {
          firstName
          avatarUrl
          lastName
        }
      }

      currentContent {
        videoInfo {
          numberOfVideos
          time
        }
        imageHeaderUrl
        id
        briefAbout
        title
      }
      status
      journeyId
      type
    }
  }
`;

export const GET_TOP_SUBCAPSULES_THIS_WEEK = gql`
  query getTopSubCapsulesThisWeek {
    getTopSubCapsulesThisWeek {
      id
      createdAt
      views
      coach {
        id
        userProfile {
          firstName
          avatarUrl
          lastName
        }
      }

      currentContent {
        videoInfo {
          numberOfVideos
          time
        }
        imageHeaderUrl
        id
        briefAbout
        title
      }
      status
      journeyId
      type
    }
  }
`;

export const GET_RECOMMENDED_SNIPPETS = gql`
  query getRecommendedSnippets {
    getRecommendedSnippets {
      id
      views
      coach {
        id
        userProfile {
          firstName
          lastName
        }
      }
      title
      video {
        length
      }
      status
      journeyId
    }
  }
`;

export const GET_TOP_SNIPPETS_THIS_WEEK = gql`
  query getTopSnippetsThisWeek {
    getTopSnippetsThisWeek {
      views
      id
      coach {
        id
        userProfile {
          firstName
          lastName
        }
      }
      title
      video {
        length
      }
      status
      journeyId
    }
  }
`;
