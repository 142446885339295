export const productData = [
  {
    image: require('./assets/accessiblity.png'),
    title: 'Accessibility',
    description: 'Our Academy supports use by those with fine motor and visual impairment to make our content, coaching, and services more accessible to a wider audience.',
  },
  {
    image: require('./assets/img.png'),
    title: 'Assessments',
    description: 'How do you know what you don’t know? Our assessments shine the light on your strengths and opportunities for improvement. We all have both –what are yours?',
  },
  {
    image: require('./assets/digital.jpg'),
    title: 'Digital Resume Builder',
    description: 'PDF resumes are old school. Forget that! Our tech partner makes the “black hole of job applications” a thing of the past with their blockchain enabled digital resume tool.',
  },
  {
    image: require('./assets/joystick.jpg'),
    title: 'Games & Interactive Quizzes',
    description: 'Everyone loves games, right? We’ve got a trivia game backed with AI, interactive quizzes, and an Alexa voice-enabled game to reinforce everything that you’ve learned. ',
  },
  {
    image: require('./assets/cert.jpg'),
    title: 'Certifications',
    description: 'Get your badges for your digital resume and Linkedin profile. Show the world that you’re certified in Soft Skills, eager to learn, and ready to take on workplace challenges.',
  },
  {
    image: require('./assets/coach.jpg'),
    title: 'Coaching',
    description: 'Book time with coaches, mentors and other experts for help and advice on career, leadership abilities, life issues and more.',
  },
  {
    image: require('./assets/learning.jpg'),
    title: 'Asynchronous Learning',
    description: 'Learn on your own time –take one lesson a day to improve your skills. We’ve packed each lesson with social emotional learning (SEL) and coaching principles. Go for it!',
  },
];
