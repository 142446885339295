import React from 'react';
import huddle1 from '../../assets/logo/1huddle.png';
import ButtonLink from '../../components/Buttons/ButtonLink';
import GamesCard from '../MarketplaceComponents/GamesCard';

const Games = () => {
  return (
    <div className="games">
      <h2 className="games__title">We are so excited to partner with 1huddle!</h2>
      <div className="games__inner">
        <div className="games__card games__getStarted">
          <img src={huddle1} alt="games" className="games__logo" />
          <p className="games__slogan">Play to win!</p>
          <ButtonLink style="center" title="Play now" link="https://info.1huddle.co/steerus-1huddle" />
        </div>
        <div className="games__card games__introduce">
          <p className="games__slogan">
            We are so pleased to partner with 1huddle! They are a leader in gamified learning bringing Artificial Intelligence and trivia fun together to
            reinforce everything you’re learning here. Play all our games! Compete with your friends. Who gets the trophy first?
          </p>
        </div>
      </div>
      <h2 className="games__title">Not feeling like playing Trivia? Then play these fun, interactive quizzes instead. Earn badges!</h2>
      <div className="games__capsules">
        <GamesCard title="ADAPTABILITY" link="/quiz/adaptability" />
        <GamesCard title="ATTENTION TO DETAIL" link="/quiz/attention_to_detail" />
        <GamesCard title="COMMUNICATION" link="/quiz/communication" />
        <GamesCard title="CREATIVITY" link="quiz/creativity" />
      </div>
      <div className="games__capsules">
        <GamesCard title="EMOTIONAL INTELLIGENCE" link="/quiz/eq" />
        <GamesCard title="LEADERSHIP" link="/quiz/leadership" />
        <GamesCard title="PROBLEM SOLVING" link="/quiz/problem_solving" />
        <GamesCard title="RELATIONSHIPS" link="quiz/relationship" />
      </div>
      <div className="games__capsules">
        <GamesCard title="RESILIENCE" link="/quiz/resilience" />
        <GamesCard title="TEAMWORK" link="/quiz/teamwork" />
        <GamesCard title="TIME MANAGEMENT" link="/quiz/time_management" />
        <GamesCard title="WORK ETHICS" link="/quiz/work_ethic" />
      </div>
      <h2 className="games__title">Improving your skills takes work. Keep at it and your efforts will pay off!</h2>
    </div>
  );
};

export default Games;
