import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Button, Space, Table } from 'antd';
import { GetCapsulesQuery, DELETE_ADMIN_VIEWS } from 'dashboard/query';
import React from 'react';
import { modal } from 'utils/modal';
import { Spin } from 'antd';
import CreateCapsuleModal from '../modals/createCapsuleModal';
import MobileTable from '../../MobileTable/MobileTable';
import { useMediaQuery } from 'react-responsive';

const CapsuleTable = () => {
  const [deleteAdminViews] = useMutation(DELETE_ADMIN_VIEWS);
  const { data, loading } = useQuery(GetCapsulesQuery, { fetchPolicy: 'network-only'});
  const history = useHistory();

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-width: 700px)',
  });

  const deleteViews = () => {
    deleteAdminViews().then(() => window.location.reload());
  };

  const capsuleColumns: any = [
    {
      key: 'name',
      title: 'Capsule Name',
      render: (value: any) => value?.currentContent?.title,
    },
    {
      key: 'couch',
      title: 'By Coach',
      render: (value: any) => `${value?.coach?.userProfile?.firstName} ${value?.coach?.userProfile?.lastName}`,
    },
    {
      key: 'status',
      title: 'Status',
      render: (value: any) => value?.currentContent?.status,
    },
    {
      key: 'version',
      title: 'Version',
      render: (value: any) => value?.maxVersion,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any) => (
        <Space size="middle">
          <Button
            onClick={() => {
              history.push(`/capsule/edit/${record.id}/version/${record.maxVersion}`);
            }}
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  if (loading) {
    return (
      <div className="capsuleTab__spinner">
        <Spin size="large" />
      </div>
    );
  } else if (!loading && data) {
    return (
      <>
        <div className="capsuleTab__data">
          <h1 style={{ flex: 1, margin: 'auto' }}> Capsules </h1>
          <div className={'capsuleTab__data-title'}>
            <Button
              onClick={() =>
                modal.showModal(CreateCapsuleModal, {
                  capsulesQuery: data,
                })
              }
            >
              Add Capsule
            </Button>
            <Button onClick={() => deleteViews()}>Clear Viewed Capsules</Button>
          </div>
        </div>
        {!isTabletOrMobileDevice ? (
          <Table rowKey="id" columns={capsuleColumns} dataSource={data?.getAllCapsules} />
        ) : (
          <MobileTable data={data?.getAllCapsules} columns={capsuleColumns} />
        )}
      </>
    );
  } else if (!data) return null;
};

export default CapsuleTable;
