import React from 'react';
import { v4 as uuid } from "uuid";
import { Radio, Checkbox } from "antd";
import CheckboxAnswer from "./CheckboxAnswer";
import RadioAnswer from "./RadioAnswer";

const Question = ({values, question: {question, explanation, answers}, isSubmitted, setFieldValue}) => {
  const isMultipleAnswers = answers.filter((item) => item.isCorrect).length > 1;
  return (
    <li className="quiz__list-item">
      <span className="quiz__question-title">{question}</span>
      {
        isSubmitted
          ? <p className="quiz__question-explanation">
            <span className="quiz__explanation">Explanation:</span> {explanation}
          </p>
          : null
      }
      {
        isMultipleAnswers
          ? <Checkbox.Group
            name={question}
            value={values[question]}
            disabled={isSubmitted}
            onChange={(event) => setFieldValue(question, event)}
          >
            {
              answers.map((answer) => (
                <CheckboxAnswer
                  key={uuid()}
                  isSubmitted={isSubmitted}
                  values={values}
                  question={question}
                  answer={answer}
                />
              ))
            }
          </Checkbox.Group>
          : <Radio.Group
            name={question}
            value={values[question]}
            disabled={isSubmitted}
            onChange={(event) => setFieldValue(event.target.name, event.target.value)}
          >
            {
              answers.map((answer) => (
                <RadioAnswer
                  key={uuid()}
                  isSubmitted={isSubmitted}
                  values={values}
                  question={question}
                  answer={answer}
                />
              ))
            }
          </Radio.Group>
      }
    </li>
  )
}

export default Question;