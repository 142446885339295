import React from 'react';
import { Button, Input } from 'antd';
import { StandardMarginDiv, PageLayout } from '../components';

const JoinForLife = () => (
  <PageLayout>
    <StandardMarginDiv>
      <div
        style={{
          width: '100vw',
          display: 'flex',
          flexDirection: 'row',
          height: '100vh',
        }}
      >
        <div style={{ width: '50%', padding: 25 }}>
          <h1> Join For Life </h1>
          <h2>
            If you have a coupon code to join STEERus for life, enter it
            below.
          </h2>
          <Input
            style={{ marginBottom: 20 }}
            id="email"
            placeholder="Your email"
            name="email"
            type="email"
          />
          <Input
            style={{ marginBottom: 20 }}
            id="firstName"
            placeholder="Your First Name"
            name="firstName"
            type="firstName"
          />
          <Input
            style={{ marginBottom: 20 }}
            id="lastName"
            placeholder="Your Last Name"
            name="lastName"
            type="lastName"
          />
          <Input
            style={{ marginBottom: 20 }}
            id="couponCode"
            placeholder="Your Coupon Code"
            name="couponCode"
            type="couponCode"
          />
          <Button style={{ width: '100%', marginBottom: 20 }}>
            {' '}
            Join for Life
            {' '}
          </Button>
        </div>
      </div>
    </StandardMarginDiv>
  </PageLayout>
);

export default JoinForLife;
