import React, { useEffect, useState } from 'react';
import './styles.css'
import { Cell, FunnelChart, LabelList, Funnel } from 'recharts';

const SemiPieChart = ({ data }) => {

  
    return (
        <div className='funnel-div'>
            <FunnelChart width={750} height={250}>

                <Funnel
                    dataKey="value"
                    data={data}
                    isAnimationActive
                    stroke='#92d050'
                    strokeWidth={2}

                >
                    {
                        data.map((entry, index) => (
                            <Cell key={`slice-${index}`} fill='#002160' opacity={entry.isActive ? 1 : 0.5} />
                        ))
                    }



                    <LabelList fontWeight='bold' fill="#fff" stroke="none" dataKey="name" />

                </Funnel>
            </FunnelChart>
        </div>
    );
};

export default SemiPieChart;

