// @ts-nocheck
import React from 'react';
import { Button, message, Form, Input, Select } from 'antd';
import { useFormik } from 'formik';
import { SOFT_SKILLS_TAGS } from 'utils';
import { modal } from 'utils/modal';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useProgress } from 'hooks';
import ModalScrollable from '../../components/ModalScrollable/ModalScrollable';
import InputNumber from '../../components/InputNumber/InputNumber';

export const CREATE_OR_UPDATE_JOURNEY = gql`
  mutation createOrUpdateJourney(
    $id: ID
    $description: String
    $title: String!
    $publishStatus: JourneyPublishStatusEnum
    $price: Float!
    $image: Upload
    $filePresent: Boolean
    $coachId: ID
    $softskillTags: [String]
  ) {
    createOrUpdateJourney(
      id: $id
      description: $description
      title: $title
      publishStatus: $publishStatus
      price: $price
      image: $image
      coachId: $coachId
      filePresent: $filePresent
      softskillTags: $softskillTags
    )
  }
`;

const GetJourneysQuery = gql`
  query getCoaches {
    getCoaches {
      CoachUser {
        id
        userProfile {
          firstName
          lastName
        }
      }
    }
  }
`;

const CreateJourneyForm = ({ push, query }: any) => {
  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted
  const [progress, uploadVideo] = useProgress();
  const [createOrUpdateJourney] = useMutation(CREATE_OR_UPDATE_JOURNEY);
  const initCoachId = query.data.getCoaches.CoachUser[0].id;

  const inputStyles = {
    width: '100%',
  };

  const initialValues = {
    id: null,
    price: '',
    softskillTags: [],
    title: '',
    coachId: initCoachId || '',
  };

  const onSubmit = async (values) => {
    message.loading({
      duration: 0,
      content: 'Uploading your changes',
      key: 'loading',
    });

    const variables = {
      filePresent: values?.filePresent !== undefined,
      title: values?.title,
      softskillTags: values?.softskillTags,
      price: values?.price,
      coachId: values?.coachId,
    };

    if (values.id) {
      variables.id = values.id;
    }

    try {
      const result = await createOrUpdateJourney({ variables });
      uploadVideo(result, values.filePresent, push);
      message.success({ content: 'Your changes loaded!', key: 'loading', duration: 2 });
    } catch (e) {
      message.error({ content: 'Error!', key: 'loading', duration: 2 });
    }
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  const onChange = ({
    target: {
      validity,
      files: [file],
    },
  }: any) => {
    validity.valid && formik.setFieldValue('filePresent', file);
  };

  return (
    <Form layout="vertical" onFinish={formik.handleSubmit}>
      {progress && (
        <h1>
          Uploading
          {Math.round(progress)}%
        </h1>
      )}
      <Form.Item label="Journey Title" name="title">
        <Input
          id="title"
          name="title"
          label="title"
          type="title"
          required
          placeholder="Journey Title"
          onChange={formik.handleChange}
          value={formik.values.title}
          style={inputStyles}
        />
      </Form.Item>

      <Form.Item name="coachId" label="Coach">
        <Select
          value={formik.values.coachId || query.data.getCoaches.CoachUser[0].id}
          defaultValue={formik.values.coachId || query.data.getCoaches.CoachUser[0].id}
          onChange={(e) => formik.setFieldValue('coachId', e)}
        >
          {query.data.getCoaches.CoachUser.map((coach) => (
            <Select.Option value={coach.id} key={coach.id}>
              {coach.userProfile.firstName} {coach.userProfile.lastName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Journey Price" name="price">
        <InputNumber
          id="price"
          name="price"
          min={1}
          required
          value={formik.values.price}
          placeholder="Journey Price"
          onChange={(event) => {
            event.persist();
            formik.handleChange({ target: { value: parseFloat(event.target.value), name: 'price' } });
          }}
        />
      </Form.Item>

      <Form.Item name="softskillTags" label="SoftSkill Tags">
        <Select
          onChange={(e) => formik.setFieldValue('softskillTags', e)}
          type="text"
          value={formik.values.softskillTags}
          mode="tags"
          placeholder="SoftSkill Tags"
        >
          {SOFT_SKILLS_TAGS.map((tag) => (
            <Select.Option key={tag} value={tag}>
              {tag}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Journey intro video" name="intro_video">
        <input type="file" name="intro_video" accept="video/*" onChange={onChange} />
      </Form.Item>

      <Form.Item>
        <Button disabled={progress !== null} style={inputStyles} type="primary" htmlType="submit">
          Save Journey
        </Button>
      </Form.Item>
    </Form>
  );
};

const CreateJourneyModal = ({ journeyQuery }: any) => {
  const closeModal = () => {
    modal.hideModal();
    journeyQuery.refetch();
  };

  const query = useQuery(GetJourneysQuery);

  if (query.loading && !query.data?.getCoaches) {
    return <p> Loading </p>;
  }

  return (
    <ModalScrollable title="Create Journey">
      <CreateJourneyForm push={closeModal} query={query} />
    </ModalScrollable>
  );
};

export default CreateJourneyModal;
