import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { formatTime } from 'utils';
import lockImg from 'assets/icons/lock.svg';
import { JourneyPaymentForm, Modal } from "components";

import './styles.less';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { HELPipedia_ORANGE, STEERUS_GREEN } from 'styles/colors';

function JourneyCard({journey, id, title, imageUrl, price, status, time, className = '', isBlocked, description = {}, views,scrollPosition }) {
  const history = useHistory();
  const [modal, setModal] = useState(false)
  const location= useLocation()

  let statusOutput = `Unlock for ${price || ''}$`;
  if (status === 'purchased') statusOutput = 'Purchased';
  if (status === 'passed') statusOutput = 'Passed';


  const handleCardClick = () => {
    if (status === 'purchased') {
     
      history.push({pathname: location.pathname.includes("helpipedia")?`/helpipedia/journey/${id}`:`/journey/${id}`,state:{journey:journey}})
    }
    else {
      setModal(true)
    }
  }

  return (
    <div className={`journey_card ${className}`} onClick={handleCardClick}>
      
      <div className="journey_card--image_box">
        <div className="premium-label" style={{backgroundColor:useLocation().pathname.includes("helpipedia")?HELPipedia_ORANGE:STEERUS_GREEN}}>
          <img src={require('../../assets/icons/star.svg')} alt="star" className="premium-label__icon" />
          <span>Premium</span>
        </div>
        <LazyLoadImage scrollPosition={scrollPosition} className="journey_card--image" src={imageUrl} alt="hero" style={{
          filter: views && 'grayscale(100%)',
          WebkitFilter: views && 'grayscale(100%)',
        }} />
      </div>
      {journey.isCollected && <img style={{ position: 'absolute', width: 100, height:100, marginTop:10, display: 'flex', alignSelf: 'center' }} src={journey.badgeImageUrl} />}

      <div className="journey_card--body">
     
        <p className="journey_card--title" style={{ color: views && '#ccc' }}>{title}</p>
        <div className="journey_card--description">
          <div className="journey_card--counters" style={{ color: views && '#ccc' }}>
            <span>{description.capsules} capsule(s)</span>
            &nbsp;&bull;&nbsp;
            <span>{description.snippets} snippet(s)</span>
            &nbsp;&bull;&nbsp;
            <span>{description.skillChecks} skill check(s)</span>
            &nbsp;&bull;&nbsp;
            <span>{description.selfReflections} reflection question(s)</span>
            &nbsp;&bull;&nbsp;
            <span>{description.workbooks} workbook(s)</span>
          </div>
        </div>
        <div className="journey_card--footer">
          <div className="journey_card--time">{formatTime(time)}</div>
          <div className="journey_card--price">{statusOutput}</div>
          <div />
        </div>
      </div>
      {isBlocked && (
        <img
          src={lockImg}
          alt="lock_image"
          className="journey_card--check_icon"
        />
      )}

      <Modal open={modal} onClose={() => setModal(false)}>
        <JourneyPaymentForm
          className="journey--modal"
          price={price}
          journeyId={id}
          onSuccess={() => setModal(false)}
        />
      </Modal>
    
    </div>
  );
}

export default JourneyCard;
