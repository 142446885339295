import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

import './styles.less';

function Modal({ open, children, onClose }) {
  const mount = document.getElementById('portal');
  const el = document.createElement('div');

  useEffect(() => {
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el, mount]);

  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onClose();
  };

  const modal = open
    && (
    <div className="modal" onClick={handleClose}>
      <div className="modal--body" onClick={(e) => e.stopPropagation()}>
        <img
          src={require('assets/icons/close.svg')}
          alt="close_icon"
          className="modal--close_icon"
          onClick={onClose}
        />
        {children}
      </div>
    </div>
  );
  return createPortal(modal, el);
}

export default Modal;
