import { gql } from '@apollo/client';

export const VIEW_SNIPPET = gql`
  mutation Mutation($viewSnippetSnippetId: ID!) {
    viewSnippet(snippetId: $viewSnippetSnippetId)
  }
`;

export const VIEW_CAPSULE = gql`
  mutation Mutation($viewCapsuleCapsuleId: ID!) {
    viewCapsule(capsuleId: $viewCapsuleCapsuleId)
  }
`;

export const SAVE_CAPSULE_PURCHASING_TYPE = gql`
  mutation SaveCapsulesPurchasingTypeMutation($id: ID, $status: CapsuleTypeEnum) {
    saveCapsulesPurchasingType(capsuleId: $id, purchasedStatus: $status)
  }
`;

export const SAVE_CHANGES_TO_CAPSULE = gql`
  mutation saveChangesToCapsule($input: NewCapsuleVersionInput) {
    saveChangesToCapsule(input: $input)
  }
`;

export const CREATE_NEW_CAPSULE_VERSION_WITH_CHANGES = gql`
  mutation createNewCapsuleVersionWithChanges($input: NewCapsuleVersionInput) {
    createNewCapsuleVersionWithChanges(input: $input)
  }
`;

export const SUBMIT_CAPSULE_FOR_APPROVAL = gql`
  mutation submitCapsuleVersionForApproval($capsuleContentId: ID) {
    submitCapsuleVersionForApproval(capsuleContentId: $capsuleContentId) {
      id
    }
  }
`;

export const DELETE_CAPSULE = gql`
  mutation deleteCapsule($capsuleId: ID) {
    deleteCapsule(capsuleId: $capsuleId)
  }
`;

export const APPROVE_RELEASE = gql`
  mutation manageRelease($approved: Boolean, $notes: String, $capsuleContentId: ID) {
    manageRelease(notes: $notes, approved: $approved, capsuleContentId: $capsuleContentId) {
      id
    }
  }
`;

export const SAVE_EVENT_MUTATION = gql`
  mutation saveEvent($eventName: String, $misc: String) {
    saveEvent(eventName: $eventName, misc: $misc)
  }
`;

export const MarkSubcapsuleVideoAsCompletedQuery = gql`
  mutation markSubcapsuleVideoAsCompleted($subCapsuleId: ID) {
    markSubcapsuleVideoAsCompleted(subCapsuleId: $subCapsuleId)
  }
`;

export const CreateMessageMutation = gql`
  mutation createCapsuleMessage($subCapsuleId: ID, $message: String) {
    createCapsuleMessage(subCapsuleId: $subCapsuleId, message: $message) {
      message
    }
  }
`;

export const saveCapsuleExitEvent = gql`
  mutation saveCapsuleExitEvent($capsuleId: ID, $duration: String) {
    saveCapsuleExitEvent(capsuleId: $capsuleId, duration: $duration)
  }
`;

export const REVIEW_CAPSULE = gql`
  mutation reviewCapsule($capsuleId: ID!, $review: Int) {
    reviewCapsule(capsuleId: $capsuleId, review: $review)
  }
`;
