import React from 'react';

import { formatTime } from 'utils';
import './styles.less';
import ImageHelp from './../../assets/images/help.png'
import { useLocation } from 'react-router';

function TextCard({playlist, isCompleted = false, onClick, title, time, footerText, color = 'green', className = '', }) {
  
  const isHelpipedia=useLocation().pathname.includes("helpipedia")
  const colors=isHelpipedia && color!='grey'?"orange":color
  return (
    <div onClick={onClick} className={`text_card ${className}`}>
      <div className={`text_card--header ${colors}`} />
      <div className="text_card--body">
        <div className="text_card--title" style={{color:isHelpipedia&&'black'}}>{title}</div>
        <div className="text_card--body_footer">
          {footerText && <div className="text_card--name">{footerText}</div>}
          {time && (
            <div className="text_card--time">
              &nbsp;&bull;&nbsp;{formatTime(time)}
            </div>
          )}
        </div>
        {isCompleted && <img style={{ position: 'absolute', width: 80, height:80, marginTop:0, display: 'flex', alignSelf: 'end' }} src={playlist?.imageUrl} />}

      </div>

    </div>
  );
}

export default TextCard;
