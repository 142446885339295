import React from 'react';
import InfoCard from './InfoCard';
import Spinner from '../Spinner';

const PaymentInProcess = () => {
  return (
    <InfoCard>
      <h1>Payment in process</h1>
      <Spinner />
      <h2>Please wait and do not close the page</h2>
    </InfoCard>
  );
};

export default PaymentInProcess;
