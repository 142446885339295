import React, { useState, useCallback } from 'react';

import './styles.less';

const TextCollapse = ({ children, collapseWidth = 400 }) => {
  const text = children || '';
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = useCallback(() => {
    setIsReadMore(!isReadMore);
  }, [isReadMore]);

  const slicedText = text.slice(0, collapseWidth);

  return (
    <p className="text_collapse">
      {isReadMore ? text.slice(0, collapseWidth) : text}
      {
        text.length > slicedText.length && (
          <span onClick={toggleReadMore} className="text_collapse--hide">
            {isReadMore ? ' ...See More' : ' ...See Less'}
          </span>
        )
      }
    </p>
  );
};

export default TextCollapse;
