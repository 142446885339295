import React from 'react';
import { useMutation } from '@apollo/client';
import { Button } from 'antd';
import { Formik } from 'formik';
import { SET_USER_SELF_REFLECTION_BY_ID } from './query';
import './styles.less';
import { useNotification } from 'hooks';
import Spinner from '../components/Spinner';
import SelfReflectionCard from './SelfReflectionCard';

const SelfReflectionContent = ({ data }) => {
  const notification = useNotification();
  const [setUserSelfReflectionById] = useMutation(
    SET_USER_SELF_REFLECTION_BY_ID,
  );

  const initialValues = {};
  const initialAnswers = Object.entries(data?.answers ? data.answers : {});
  if (initialAnswers.length && data?.questions) {
    initialAnswers.forEach(([key, val]) => {
      const foundQuestion = data.questions.find(
        ({ question }) => question === key,
      );
      if (foundQuestion) {
        initialValues[key] = val;
      }
    });
  }

  const submitForm = async (values) => {
    try {
      await setUserSelfReflectionById({
        variables: { id: data.id, answers: values },
      });
      notification({
        type: 'success',
        message: 'self-reflection answers have been saved',
      });
    } catch (error) {
      notification({ type: 'error', message: error.message });
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submitForm}>
      {({ values, handleChange, handleSubmit, isSubmitting }) => (
        <Spinner loading={isSubmitting}>
          <form onSubmit={handleSubmit}>
            <div className="self-reflection__form">
              <div className="self-reflection__form__header">
                <h1 className="page-title"> {data?.title} </h1>
                <h2 className="page-description"> {data?.description} </h2>
              </div>
              {data?.questions?.map(({ question }) => (
                <SelfReflectionCard
                  key={question}
                  question={question}
                  values={values}
                  data={data}
                  onChange={handleChange}
                />
              ))}
              <Button
                type="button"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </form>
        </Spinner>
      )}
    </Formik>
  );
};

export default SelfReflectionContent;
