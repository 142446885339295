import React from 'react';
import SwagCard from '../MarketplaceComponents/SwagCard';

const Swag = () => {
  return (
    <div className="jabord">
      <div className="jabord__capsules">
        <SwagCard
          image="https://www.pngitem.com/pimgs/m/538-5388701_custom-tshirt-png-t-shirt-transparent-png.png"
          title="Because you need another t-shirt"
          price={12}
          button="XL"
        />
        <SwagCard
          image="https://mms-images.out.customink.com/mms/images/catalog/1e74cea21e97500d05451613f4af6624/colors/853200/views/alt/front_medium_extended.jpg?autoNegate=1&design=djn0-00by-wu36&digest=0000000016&ixbg=%23ffffff&ixfm=jpeg&ixq=60&ixw=412&placeMax=1&placeMaxPct=0.8&placeUseProduct=1&placeUseView=front"
          title="Everyone loves flare, right?"
          price={5}
          button="Buy Now"
        />
        <SwagCard
          image="https://www.thecrystalman.com/wp-content/uploads/2016/05/products-Word_Soothing_Stones_1b.JPG"
          title="Soothing stones are, well -- soothing"
          price={5}
          button="Buy Now"
        />
      </div>
    </div>
  );
};

export default Swag;
