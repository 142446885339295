// @ts-nocheck
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { Button, Checkbox, Form, Input, message, Modal, Radio, Select, Space, Spin } from 'antd';
import { HeroHalfPartner, PageLayout } from '../components';
import './styles.less';
// import helpipediaVideo from './../../assets/video/helpipedia_video.mp4';
import helpipediaVideo from '../assets/video/helpipedia_video.mp4';
import videoThumbnail from '../assets/video/video_thumbnail.png';
const CREATE_STUDENT = gql`
  mutation createStudent(
    $email: String
    $password: String
    $firstName: String
    $lastName: String
    $meta: JSON
    $city: String
    $state: String
    $country: String
    $school: String
    $student_role: String
    $adult: Boolean
  ) {
    createStudent(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      meta: $meta
      city: $city
      state: $state
      country: $country
      school: $school
      student_role: $student_role
      adult: $adult
    ) {
      tokens {
        accessToken
        refreshToken
      }
      error {
        field
        message
      }
      user {
        id
        city
        state
      }
    }
  }
`;

const SEND_CONFIRM_EMAIL = gql`
  mutation sendConfirmEmail($email: String, $file: String) {
    sendConfirmEmail(email: $email, file: $file)
  }
`;

const roles = [
  // { id: 0, role: 'Advocate' },
  // { id: 3, role: 'Coach' },
  // { id: 4, role: 'Counselor' },
  // { id: 2, role: 'Educator' },
  // { id: 5, role: 'Mentor' },
  // { id: 6, role: 'Parent' },
  {id:0,role:" Service Partner"},
  // { id: 7, role: 'Social Worker' },
  // { id: 1, role: 'Student' },
  // { id: 8, role: 'Therapist' },
  {id:1, role:"User"},
  // { id: 9, role: 'Veteran' },
  // { id: 10, role: 'Other ...' },
];

const SignupForm = ({
  history,
  setLoading,
  radio,
  setRadio,
  setIsModalOpen,
  country,
  state,
  school,
  city,
  setError,
  setSchool,
  setCountry,
  setState,
  setCity,
}) => {
  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted
  const [createStudent] = useMutation(CREATE_STUDENT);
  const [sendConfirmEmail] = useMutation(SEND_CONFIRM_EMAIL);
  const inputStyles = {
    width: '100%',
    marginTop: '5px',
  };
  const [adult, setAdult] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      tos: false,
      password: '',
    },
    onSubmit: async (values) => {
      radio = "User";
      if (radio === 'Student' || radio === 'Educator') {
        if (!school || !city || !state || !country) {
          setError(true);
          setIsModalOpen(true);
          return;
        }
      }

      try {
        setLoading(true);
        if (!values.tos) message.error('You must agree to our Terms of Service');
        else {
          const result = await createStudent({
            variables: {
              ...values,
              country,
              meta: {...values.meta, education: "User"},
              city,
              school,
              state,
              student_role: "User",
              email: values.email.toLowerCase(),
              adult,
            },
          });
          if (result?.data?.createStudent?.error) {
            message.error(result?.data?.createStudent?.error?.message);


          } else {
            await sendConfirmEmail({ variables: { email: values?.email, file: "studentContainer.tsx" } });
            setSchool('');
            setCountry('');
            setState('');
            setCity('');
            history.push('/login');
          }
        }
      } catch (e) {
        // message.error('Error. Something went wrong');
        toast.error(result.data.createStudent.error.message)

      } finally {
        setLoading(false);
      }
    },
  });

  const validateMessages = {
    required: '${label} is required.',
  };

  return (
    <Form layout="vertical" className="form-with-antd-validation" validateMessages={validateMessages} onFinish={formik.handleSubmit}>
      <h1 className="sign-up__title"> Create your account </h1>

      <Form.Item
        label="First Name"
        name="firstName"
        required={false}
        rules={[
          { required: true },
          {
            validator: (_, value) => (value.trim().length > 2 ? Promise.resolve() : Promise.reject(new Error('Minimum length 3 characters'))),
          },
        ]}
      >
        <Input
          id="firstName"
          name="firstName"
          label="firstName"
          type="firstName"
          onChange={formik.handleChange}
          value={formik.values.firstName}
          style={inputStyles}
        />
      </Form.Item>

      <Form.Item
        label="Last Name"
        name="lastName"
        required={false}
        rules={[
          { required: true },
          {
            validator: (_, value) => (value.trim().length > 2 ? Promise.resolve() : Promise.reject(new Error('Minimum length 3 characters'))),
          },
        ]}
      >
        <Input
          id="lastName"
          name="lastName"
          label="lastName"
          type="lastName"
          onChange={formik.handleChange}
          value={formik.values.lastName}
          style={inputStyles}
        />
      </Form.Item>

      <Form.Item label="Email" name="Email" required={false} rules={[{ required: true }]}>
        <Input id="email" name="email" label="email" type="email" onChange={formik.handleChange} value={formik.values.email} style={inputStyles} />
      </Form.Item>

      <Form.Item label="Password" name="Password" required={false} rules={[{ required: true }]}>
        <Input id="password" name="password" type="password" onChange={formik.handleChange} value={formik.values.password} style={inputStyles} />
      </Form.Item>

      {/* <Form.Item label="Your Role" name="education" style={{ width: '100%' }} required={false} rules={[{ required: false }]}>
        <Select
          placeholder="Your role"
          onChange={(e) => {
            formik.setFieldValue('meta', {
              ...formik.values.meta,
              education: e,
            });
            if (e === 'Student' || e === 'Educator') setIsModalOpen(true);
            setRadio(e);
          }}
        >
          {roles.map((role) => (
            <Select.Option key={role.id} value={role.role}>
              {role.role}
            </Select.Option>
          ))}
        </Select>
        <>
          {(country || city || school || state) && (
            <Button style={inputStyles} type="primary" onClick={() => setIsModalOpen(true)}>
              change
            </Button>
          )}
        </>
      </Form.Item> */}

      <Form.Item
        name="tos"
        valuePropName="checked"
        required={false}
        rules={[
          {
            validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('You should agree with our Terms and Conditions to proceed'))),
          },
        ]}
      >
        <Checkbox id="tos" name="tos" onChange={formik.handleChange} value={formik.values.tos}>
          <span>
            I have read and agree to the&nbsp;
            <Link to="/terms">Terms and Conditions</Link>
            &nbsp;and the&nbsp;
            <Link to="/privacy">Privacy Policy</Link>
            &nbsp;and the&nbsp;
            <Link to="/disclaimer">Legal Disclaimer.</Link>
          </span>
        </Checkbox>
      </Form.Item>

      <Form.Item
        label="How old are you?"
        name="adult"
        valuePropName="checked"
        required={false}
        id="adult"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Radio.Group id={'adult'} size="large" onChange={(e) => setAdult(e.target.value)} value={adult}>
          <Radio value={true}>18+</Radio>
          <Radio value={false}>14-17</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item>
        <Button style={inputStyles} type="primary" htmlType="submit">
          Create your account
        </Button>
      </Form.Item>

      <p className="sign-up__link" onClick={() => history.push('/login')}>
        Already have an account? Log in.
      </p>
    </Form>
  );
};

const App = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [radio, setRadio] = useState('');
  const [school, setSchool] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [error, setError] = useState(false);

  const onModalOk = () => {
    if (school === '' || city === '' || state === '' || country === '') {
      setError(true);
    } else {
      setError(false);
      setIsModalOpen(false);
    }
  };

  const onModalCancel = () => {
    if (school === '' || city === '' || state === '' || country === '') {
      setError(true);
    }
    setIsModalOpen(false);
    setError(false);
  };

  return (
    <PageLayout hideFooter>
      {loading && (
        <Space
          style={{
            position: 'fixed',
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            zIndex: 100000,
          }}
        >
          <Spin
            style={{
              position: 'absolute',
              top: '45%',
              left: '50%',
              width: '100px',
              height: '100px',
            }}
            className="login-spin"
          ></Spin>
        </Space>
      )}
      <HeroHalfPartner
        rightColor="#fff"
        background={null}
        college
        leftComponents={
          <div className="paddingTop">
            <SignupForm
              history={history}
              setLoading={setLoading}
              setIsModalOpen={setIsModalOpen}
              city={city}
              country={country}
              radio={radio}
              setRadio={setRadio}
              state={state}
              school={school}
              setError={setError}
              setCity={setCity}
              setCountry={setCountry}
              setState={setState}
              setSchool={setSchool}
            />
          </div>
        }
        title="A Message From our Founder, Loralyn Mears, PhD"
        paragraph_one="I know how important soft skills are. That's why I'm committed to keeping our Success Academy FREE to students, educators, and underserved communities."
        paragraph_two="Here’s what I ask of YOU. <b>Give STEERus a chance</b> And tell your friends!"
        paragraph_free="Each week, we add more content. Each Quarter, we do a software upgrade."
        paragraph_four="Enter our Academy with a positive attitude. Give us feedback; help us help you. And please, be kind to people and pets."
        video={helpipediaVideo}
        light={videoThumbnail}
      />
      <Modal title="Where are you from?" visible={isModalOpen} onOk={onModalOk} onCancel={onModalCancel}>
        <Input
          size="large"
          style={{ marginBottom: '8px', border: error && '1px solid red' }}
          onChange={(e) => setSchool(e.target.value)}
          placeholder="Name of school"
          value={school}
        />
        <Input
          size="large"
          style={{ marginBottom: '8px', border: error && '1px solid red' }}
          onChange={(e) => setCity(e.target.value)}
          placeholder="City"
          value={city}
        />
        <Input
          size="large"
          style={{ marginBottom: '8px', border: error && '1px solid red' }}
          onChange={(e) => setState(e.target.value)}
          placeholder="State"
          value={state}
        />
        <Input
          size="large"
          style={{ marginBottom: '8px', border: error && '1px solid red' }}
          onChange={(e) => setCountry(e.target.value)}
          placeholder="Country"
          value={country}
        />
      </Modal>
    </PageLayout>
  );
};

export default App;
