import React from 'react';
import { useQuery } from '@apollo/client';
import { Form, Select } from 'antd';
import {
  GetAllAttachments, GetAllApprovedCapsules, GetAllQuestions, GetAllSnippets,
} from '../../query';

const AssignMaterials = ({
  capsules, snippets, questions, attachments, setFieldValue,
}) => {
  const { data: capsulesList } = useQuery(GetAllApprovedCapsules);
  const { data: snippetsList } = useQuery(GetAllSnippets);
  const { data: attachmentsList } = useQuery(GetAllAttachments);
  const { data: questionsList } = useQuery(GetAllQuestions);

  return (
    <div className="edit-form__column">
      <h1>Materials</h1>
      <Form.Item label="Capsules" name="capsules">
        <Select
          mode="multiple"
          optionFilterProp="children"
          placeholder="Please select capsules"
          onChange={(e) => setFieldValue('capsules', e)}
          defaultValue={capsules}
        >
          {
            capsulesList?.getAllApprovedCapsules?.length ? (
              capsulesList.getAllApprovedCapsules.map((item) => (
                <Select.Option
                  key={item.id}
                  value={item.id}
                >
                  {item.currentContent.title}
                </Select.Option>
              ))
            ) : <Select.Option disabled value="default">No capsules</Select.Option>
          }
        </Select>
      </Form.Item>

      <Form.Item label="Snippets" name="snippets">
        <Select
          mode="multiple"
          optionFilterProp="children"
          placeholder="Please select snippets"
          onChange={(e) => setFieldValue('snippets', e)}
          defaultValue={snippets}
        >
          {
            snippetsList?.getAllSnippets?.length ? (
              snippetsList.getAllSnippets.map((item) => (
                <Select.Option
                  key={item.id}
                  value={item.id}
                >
                  {item.title}
                </Select.Option>
              ))
            ) : <Select.Option disabled value="default">No snippets</Select.Option>
          }
        </Select>
      </Form.Item>

      <Form.Item label="Attachments" name="attachments">
        <Select
          mode="multiple"
          optionFilterProp="children"
          placeholder="Please select attachments"
          onChange={(e) => setFieldValue('attachments', e)}
          defaultValue={attachments}
        >
          {
            attachmentsList?.getAttachments?.length ? (
              attachmentsList.getAttachments.map((item) => (
                <Select.Option
                  key={item.id}
                  value={item.id}
                >
                  {item.name}
                </Select.Option>
              ))
            ) : <Select.Option disabled value="default">No attachments</Select.Option>
          }
        </Select>
      </Form.Item>

      <Form.Item label="Questions" name="questions">
        <Select
          mode="multiple"
          optionFilterProp="children"
          placeholder="Please select questions"
          onChange={(e) => setFieldValue('questions', e)}
          defaultValue={questions}
        >
          {
            questionsList?.getQuestions?.length ? (
              questionsList.getQuestions.map((item) => (
                <Select.Option
                  key={item.id}
                  value={item.id}
                >
                  {item.title}
                </Select.Option>
              ))
            ) : <Select.Option disabled value="default">No questions</Select.Option>
          }
        </Select>
      </Form.Item>
    </div>
  );
};

export default AssignMaterials;
