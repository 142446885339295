import React from 'react';
import { StandardMarginDiv, PageLayout } from '../components';

const CreateCapsule = () => (
  <PageLayout>
    <StandardMarginDiv>
      <h1> Edit: </h1>
      <p> Hello </p>
    </StandardMarginDiv>
  </PageLayout>
);

export default CreateCapsule;
