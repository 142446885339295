// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { uniqBy } from 'lodash';
import { StandardMarginDiv, PageLayout, ProtectedFeature } from '../components';
import ReactPlayer from 'react-player';
import SimpleButton from '../components/Buttons/SimpleButton';
import { SAVE_EVENT_MUTATION, MarkSubcapsuleVideoAsCompletedQuery, saveCapsuleExitEvent } from './mutation';
import { GetSubcapsuleContentQuery, GET_MESSAGES_QUERY } from './query';
import { STEERUS_GREEN } from '../styles/colors';
import { CaretRightOutlined } from '@ant-design/icons';
import './styles.less';
import { useMediaQuery } from 'react-responsive';

const CapsuleVideoPage = () => {
  const params = useParams();
  const history = useHistory();
  const [progress, setProgress] = useState(0);
  const [messages, setMessages] = useState([]);

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-width: 800px)',
  });

  const query = useQuery(GetSubcapsuleContentQuery, {
    variables: {
      id: params.id,
    },
  });

  const capsuleMessageQuery = useQuery(GET_MESSAGES_QUERY, {
    variables: {
      subCapsuleId: params.id,
      limit: 20,
      skip: 0,
    },
  });

  const newCapsuleMessageQuery = useQuery(GET_MESSAGES_QUERY, {
    variables: {
      subCapsuleId: params.id,
      limit: 20,
      skip: 0,
    },
    pollInterval: 1000,
  });

  useEffect(() => {
    if (capsuleMessageQuery.data) {
      const uniqueMessages = uniqBy([...messages, ...capsuleMessageQuery.data.getMessagesForCapsule], 'id').sort((a, b) => b.createdAt - a.createdAt);
      setMessages(uniqueMessages);
    }
  }, [capsuleMessageQuery.data]);

  useEffect(() => {
    if (newCapsuleMessageQuery.data) {
      const uniqueMessages = uniqBy([...messages, ...newCapsuleMessageQuery.data.getMessagesForCapsule], 'id').sort((a, b) => b.createdAt - a.createdAt);
      setMessages(uniqueMessages);
    }
  }, [newCapsuleMessageQuery.data]);

  const refAttr = useRef();

  const [saveCapsuleExitEventMutation] = useMutation(saveCapsuleExitEvent);

  useEffect(
    () => async () => {
      await saveCapsuleExitEventMutation({
        variables: {
          capsuleId: params.id,
          duration: `${refAttr.current}`,
        },
      });
    },
    [params.id, saveCapsuleExitEventMutation]
  );

  const [mutateVideos] = useMutation(MarkSubcapsuleVideoAsCompletedQuery);

  const [saveEvent] = useMutation(SAVE_EVENT_MUTATION);

  window.addEventListener('unload', async () => {
    await saveCapsuleExitEventMutation({
      variables: {
        capsuleId: params.id,
        duration: `${progress}`,
      },
    });
  });

  const redirectToNextContent = (subCapsule) => {
    if (!query?.data?.getSubcapsuleContent?.capsuleContentId && !subCapsule?.id) {
      return;
    }

    const path =
      subCapsule.title === 'Skillcheck' ? `/capsule/${query?.data?.getSubcapsuleContent?.capsuleContentId}/skillCheck` : `/capsule/class/${subCapsule.id}`;
    history.push(path);
  };

  const onEnded = () => {
    mutateVideos({
      variables: { subCapsuleId: query?.data?.getSubcapsuleContent?.id },
    });

    redirectToNextContent(query?.data?.getSubcapsuleContent?.nextSubcapsules[0]);
  };

  const setProgressFunc = ({ playedSeconds }) => {
    setProgress(playedSeconds);
    refAttr.current = progress;
  };

  const downloadBook = (attachment) => {
    saveEvent({
      variables: {
        eventName: 'ATTACHMENT_CLICKED',
        misc: JSON.stringify({
          url: attachment.url,
          capsule_id: query?.data?.getSubcapsuleContent?.id,
        }),
      },
    });
    window.open(attachment.url, '_Blank');
  };

  return (
    <PageLayout loading={query.loading}>
      <StandardMarginDiv>
        <>
          {!query.loading && (
            <ProtectedFeature featureDesc="to access all of the STEERus soft skills academy.">
              <div className="capsuleVideo">
                <div className="capsuleVideo__inner">
                  <div className="capsuleVideo__video">
                    <ReactPlayer
                      controls
                      onEnded={onEnded}
                      height="50vh"
                      width="100%"
                      onProgress={setProgressFunc}
                      url={query?.data?.getSubcapsuleContent?.video?.url}
                    />
                  </div>

                  <div className="capsuleVideo__links">
                    <p className="capsuleVideo__title">Play Next</p>
                    {query?.data?.getSubcapsuleContent?.nextSubcapsules?.map((subCapsule) => (
                      <div key={subCapsule.id} className="capsuleVideo__link" onClick={() => redirectToNextContent(subCapsule)}>
                        <CaretRightOutlined style={{ color: STEERUS_GREEN }} className="capsule__caretIcon" />
                        <h2 className="capsule__subtitle capsule__subtitleContent">{subCapsule.title}</h2>
                      </div>
                    ))}
                    {query?.data?.getSubcapsuleContent?.attachmentObjects?.length > 0 && (
                      <>
                        <h2 className="capsule__subtitle capsule__subtitleContent"> Class Materials </h2>
                        {query?.data?.getSubcapsuleContent?.attachmentObjects?.map((attachment) => (
                          <SimpleButton key={attachment.id} title={'Download ' + (attachment.type || attachment.name)} click={() => downloadBook(attachment)} />
                        ))}
                      </>
                    )}
                  </div>
                </div>
                <div
                  className="defaultFlex"
                  style={{
                    display: 'flex',
                    marginRight: '5vw',
                    paddingTop: '20px',
                  }}
                >
                  <div>
                    {query?.data?.getSubcapsuleContent?.bookUrl && (
                      <p style={{ cursor: 'pointer' }}>
                        <a style={{ cursor: 'pointer' }} href={query?.data?.getSubcapsuleContent?.bookUrl}>
                          Recommended Reading
                        </a>
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="capsuleVideo">
                <iframe
                  src="https://e.widgetbot.io/channels/955994758092845086/958174257215594496"
                  height={isTabletOrMobileDevice ? '400' : '600'}
                  width="100%"
                ></iframe>
              </div>
            </ProtectedFeature>
          )}
        </>
      </StandardMarginDiv>
    </PageLayout>
  );
};

export default CapsuleVideoPage;
