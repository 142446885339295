import React from 'react'
import { useFormik } from 'formik';
import { Button, Checkbox, Form, Input, message } from 'antd';
import { useMutation } from '@apollo/client';
import { UPDATE_USER } from '../query';
import { HELPipedia_ORANGE } from 'styles/colors';
import { useLocation } from 'react-router';

const ChangeEmail = ({ currentUser = {} }) => {
  const [updateUser] = useMutation(UPDATE_USER);

  const isHelpipedia= useLocation().pathname.includes("helpipedia")
  const formik = useFormik({
    initialValues: {
      currentEmail: currentUser.email,
      email: '',
      checkbox: false
    },
    onSubmit: async (values) => {
      if (currentUser.email === values.currentEmail) {
        if (!values.checkbox) message.error('You must agree that this will be a new email address');
        else {
          updateUser({
            variables: {
              input: {
                email: values.email,
                id: currentUser.id,
              },
            },
          })
            .then(() => {
              message.success('Updated Profile');
            })
            .catch(() => {
              message.error('There was an error. Try again later');
            });
        }
      } else {
        message.error('Your email address is incorrect');
      }
    },
  });

  return (
    <div>
      <h1 style={{ marginBottom: '30px', color:isHelpipedia && 'black'  }}>EMAIL ADDRESS</h1>
      <Form className='settings__form' onFinish={formik.handleSubmit}>
        <Form.Item style={{ width: '100%', margin: 0 }}>
          <p className='heading' style={{ fontSize: '17px', marginLeft: '10px', color:isHelpipedia && HELPipedia_ORANGE  }}>Current Email Address</p>
          <Input
            id="currentEmail"
            name="currentEmail"
            required
            className='settings__fields'
            defaultValue={formik.values.currentEmail}
            onChange={formik.handleChange}
            value={formik.values.currentEmail}
            type="email"
            style={{borderColor: isHelpipedia && HELPipedia_ORANGE}}
          />
        </Form.Item>

        <Form.Item style={{ width: '100%', margin: '0 0 30px 0' }}>
          <p className='heading' style={{ fontSize: '17px', marginLeft: '10px', color:isHelpipedia && HELPipedia_ORANGE }}>New Email Address</p>
          <Input
            id="email"
            name="email"
            required
            className='settings__fields'
            defaultValue={formik.values.email}
            onChange={formik.handleChange}
            value={formik.values.email}
            type="email"
            style={{borderColor: isHelpipedia && HELPipedia_ORANGE}}
          />
        </Form.Item>
        <div className='settings__checkbox-content'>
          <Checkbox
            id="checkbox"
            name="checkbox"
            className='settings__checkbox'
            onChange={formik.handleChange}
            checked={formik.values.checkbox}
          />
          <b className='heading  settings__checkbox-label' style={{color:isHelpipedia && HELPipedia_ORANGE }}>Make this my new emails address</b>
        </div>

        <Form.Item style={{ width: '100%' }}>
          <Button type="primary" htmlType="submit" className="settings__button-submit" style={{backgroundColor :isHelpipedia && HELPipedia_ORANGE, borderColor: isHelpipedia && HELPipedia_ORANGE }}>
            Change Email
          </Button>
        </Form.Item>
      </Form >
    </div>
  )
}



export default ChangeEmail