import { PageLayout } from '../components'
import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Result } from 'antd'


const EmailConfirm = () => {
  return (
    <PageLayout>
      <div style={{ display: 'flex', background: "inherit" }}>
        <div style={{ margin: "5% auto auto auto" }}>
          <Result status="success"
            extra={[
              <>
                <p>We have sent a letter to your email </p>
                <p>Please check your email address</p>
                <Link to="/login"> Back to Login page</Link>
              </>
            ]}>
          </Result>
        </div>
      </div>
    </PageLayout>
  )
}



export default EmailConfirm