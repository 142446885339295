import React from 'react';
import jabord from '../../assets/logo/Jabord_LOGO.png';
import ButtonLink from '../../components/Buttons/ButtonLink';
import JabordCapsule from '../MarketplaceComponents/JabordCapsule';

const Jobs = () => {
  return (
    <div className="jabord">
      <h2 className="jabord__title">We are so excited to partner with Jäbôrd!</h2>
      <div className="jabord__inner">
        <div className="jabord__card jabord__getStarted">
          <img src={jabord} alt="Jabord" className="jabord__logo" />
          <p className="jabord__slogan">
            Don’t send your resume.
            <br />
            Send YOU!
          </p>
          <ButtonLink
            style="center"
            title="get started"
            link="https://www.jabord.com/sign-up?utm_source=SteerUs&utm_medium=Button&utm_campaign=Partner#Signup-Tab-1"
          />
        </div>
        <div className="jabord__card jabord__introduce">
          <p className="jabord__slogan">
            We are pleased to introduce our partner to help you stand out. Use the power of video to tell your next employer who you are and what you can do:
            you are more than words on a page! Jäbôrd’s interactive digital resume builder allows you to add a personal intro and cover video, badges,
            certificates, transcripts, portfolio, and references. You can tag content and create a unique link to apply to jobs or to send to recruiters so that
            you can track views.
          </p>
        </div>
      </div>
      <h2 className="jabord__title">Get noticed by employers with these tools and tips from Jäbôrd.</h2>
      <div className="jabord__capsules">
        <JabordCapsule image="https://s3.amazonaws.com/prod-new-steerus/jabord-1.png" title="Want to get noticed by employers?" video={1} />
        <JabordCapsule image="https://s3.amazonaws.com/prod-new-steerus/jabord-2.png" title="How do I build an interactive resume?" video={2} />
        <JabordCapsule
          image="https://s3.amazonaws.com/prod-new-steerus/jabord-3.png"
          title="How do I create a good personal intro & cover video?"
          video={3}
        />
      </div>
    </div>
  );
};

export default Jobs;
