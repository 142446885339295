import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { withCurrentUser } from '../hoc';
import { StandardMarginDiv, PageLayout } from '../components';

import './styles.less';

const DashboardContainer = () => {
  const TeamMember = ({
    name,
    title,
    img,
  }: {
    name: string;
    title: string;
    img: string;
  }) => (
    <div
      className="defaultDiv about__item"
      style={{
        padding: '20px',
        marginRight: '10px',
        backgroundColor: '#C4C4C4',
        borderRadius: '6px',
      }}
    >
      <img
        alt=""
        style={{ height: '100px', width: '100px', borderRadius: '50%' }}
        src={img}
      />
      <p className="heading">
        {' '}
        {name}
        {' '}
      </p>
      <p>{title}</p>
    </div>
  );

  const GetCoachesQuery = gql`
    query getCoaches {
      getCoaches {
        CoachUser {
          id
          email
          userProfile {
            firstName
            avatarUrl
            lastName
          }
        }
      }
    }
  `;

  const query = useQuery(GetCoachesQuery);
  const data = query.data?.getCoaches?.CoachUser || [];
  const coachPictures = data
    .map((coach: any) => coach?.userProfile?.avatarUrl)
    .filter((pic: string) => pic !== null && pic !== '');

  return (
    <PageLayout loading={query.loading}>
      <StandardMarginDiv>
        <p className="heading"> We Steer. You Rise. </p>
        <p style={{ fontSize: '20px' }}>
          {' '}
          STEERus has a grand vision - to make coaching affordable and widely
          available for everyone.
          <br />
          <br />
          Why is it that only the Top 1% of executives at Fortune 500 companies
          get coached? Okay, maybe some mid-level managers get coached, too, but
          what about everybody else? And who&apos;s coaching the students to prepare
          them for success in school, at work and in life?
        </p>
      </StandardMarginDiv>
      <div style={{ backgroundColor: '#92D050', padding: '20px' }}>
        <p className='about__title '> Our Experts </p>
        <div
          className="about__list"
        >
          {coachPictures.map((coach: string) => (
            <div>
              <img
                src={coach}
                alt=""
                style={{
                  objectFit: 'fill',
                  marginRight: Math.random() * (35 - 20) + 20,
                  marginBottom: '30px',
                  borderRadius: '50%',
                  height: '100px',
                  width: '100px',
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <StandardMarginDiv>
        <p className="heading"> Our Team </p>
        <div
          className="defaultFlex about"
          style={{
            marginLeft: '10vw',
            marginRight: '10vw',
            display: 'flex',
            justifyContent: 'space-between',
            textAlign: 'center',
          }}
        >
          <TeamMember
            name="Loralyn Mears"
            title="Founder & CEO"
            img="https://prod-new-steerus.s3.amazonaws.com/profile/loralyn.webp"
          />
          <TeamMember
            name="Poonam Gole"
            title="Co-founder and Director Coaching & Programs"
            img="https://prod-new-steerus.s3.amazonaws.com/profile/poonam.webp"
          />
          <TeamMember
            name="Sandra Cruze"
            title="Head of Product Engineering"
            img="https://prod-new-steerus.s3.amazonaws.com/profile/Sandy+Cruze.jpg"
          />
        </div>
      </StandardMarginDiv>
    </PageLayout>
  );
};

export default withCurrentUser(DashboardContainer);
