import React, { useEffect, useState } from 'react';
import './mushymiddle.css';
import { Link, useLocation } from 'react-router-dom';
import { PageLayout, Modal } from 'components';
import {  gql, useMutation } from '@apollo/client';
import SemiPieChart from 'components/SemiChart/SemiPieChart';
import {  submitSkillAssessment } from 'assessments/SoftSkillsAssesment/request';
import PaymentFormForAssessment from 'components/PaymentForm/PaymentFormForAssessment';

export default function CommonResultScreen() {

  const { state } = useLocation();
  const [modal, setModal] = useState(false)
  let answers = []
  const CAPTURE_PAYMENT_FOR_SKILL_ASSESSMENT = gql`
  mutation capturePaymentForSkillAssessment(
      $assessmentId: ID
      $email: String
      $price: Int
  ) {
      capturePaymentForSkillAssessment(
          assessmentId: $assessmentId
          price: $price
          email: $email
      )
  }
  `;
  const [mutateAnswer, { loading: loadingTest }] = useMutation( submitSkillAssessment);
  const [mutatePostAnswer] = useMutation( CAPTURE_PAYMENT_FOR_SKILL_ASSESSMENT);
  const [activeScore,setActiveScore]=useState("")
  const [pageFocused,setPageFocused]=useState(true)
  const [mushyResponse, setMushyResponse] = useState()
  
  const chartData = [
    {
      "name": "0-20",
      "value": 20,
      "fill": "#fff",
      isActive: false
    },
    {
      "name": "21-40",
      "value": 40,
      "fill": "#fff",
      isActive: false
    },
    {
      "name": "41-60",
      "value": 60,
      "fill": '#fff',
      isActive: false


    },
    {
      "name": "61-80",
      "value": 80,
      "fill": '#fff',
      isActive: false
    },
    {
      "name": "81-100",
      "value": 100,
      "fill": "#fff",
      isActive: false
    }
  ]
  const [funnelData, setFunnelData] = useState(chartData)
  const [postText,setPostText]=useState('')
  useEffect(() => {
    
    answers = []
    state != undefined && state.answers.map(item => {
      let answer = item.options.filter(item => item.checked == true)[0].value

      switch (answer) {
        case "Strongly Disagree":
          answers.push(1)
          break;

        case "Disagree":
          answers.push(2)
          break;

        case "Neutral":
          answers.push(3)
          break;


        case "Agree":
          answers.push(4)
          break;
        case "Strongly Agree":
          answers.push(5)
          break;

        default:
          answers.push(0)
      }

    })
    if (answers.length > 0) {

      submitAssessment(answers)
    }

  }, [])

  const submitAssessment = async (answers) => {
    const data = {
      email: state.email,
      assessmentId: state.id,
      answers: answers,
      coachId: state.coachId
    }

    const response = await mutateAnswer({
      variables: data,
    });

    setMushyResponse(response)
    if (response.data.submitSkillAssessment.assessmentResponse.length > 0) {
      setTimeout(() => {
        let score = response.data.submitSkillAssessment.assessmentResponse[0].score
       let activeValue = ""
        if (score >= 0 && score <= 20) {
          activeValue = "0-20"
          const postT= response.data.submitSkillAssessment.assessmentPostText.score040Text=!undefined ?response.data.submitSkillAssessment.assessmentPostText.score040Text:""
          setPostText(postT)
        }
        else if (score >= 21 && score <= 40) {

          activeValue = "21-40"
          const postT= response.data.submitSkillAssessment.assessmentPostText.score040Text=!undefined ?response.data.submitSkillAssessment.assessmentPostText.score040Text:""
          setPostText(postT)
         
        }
        else if (score >= 41 && score <= 60) {
          activeValue = "41-60"
          const postT= response.data.submitSkillAssessment.assessmentPostText.score4160Text=!undefined ?response.data.submitSkillAssessment.assessmentPostText.score4160Text:""
          setPostText(postT)
      
        }
        else if (score >= 61 && score <= 80) {
          activeValue = "61-80"
          const postT= response.data.submitSkillAssessment.assessmentPostText.score6180Text=!undefined ?response.data.submitSkillAssessment.assessmentPostText.score6180Text:""
          setPostText(postT)
        }
        else if (score >= 81 && score <= 100) {
          activeValue = "81-100"
          const postT= response.data.submitSkillAssessment.assessmentPostText.score81100Text=!undefined ?response.data.submitSkillAssessment.assessmentPostText.score81100Text:""
          setPostText(postT)
        }
       
        setActiveScore(activeValue)
        const arr=funnelData.map(item=>{
          if(item.name==activeValue){
            return {...item,isActive:true}
          }
          else{
            return {...item,isActive:false}
          }
        })
      
        setFunnelData(arr)
      }, 2000);

    }
  }

const postSubmit=async()=>{
   setModal(false)
  const data = {
    email: state.email,
    assessmentId: state.id,
   price:state.price || 5
  }

  const response = await mutatePostAnswer({
    variables: data,
  });

}
useEffect(()=>{
  setTimeout(() => {
      setPageFocused(pageFocused=>!pageFocused)
  }, 1500);
},[])
  return (
    <PageLayout loading={loadingTest}>
      <div className='backCover'>

        { pageFocused ?<></>: mushyResponse != undefined && state != undefined ? <div className='result-main-div'>
          <div className='result-div'>
            <p className='result-p'>Thank you for taking our assessment.<br/>You scored: {mushyResponse.data.submitSkillAssessment.assessmentResponse[0].score}</p>

            <SemiPieChart data={funnelData} />
            <p style={{ color: '#001f5f', fontWeight: 'bold' }}>{postText}</p>
            <p >If you’d like to receive a personalized report with recommendations on what to do next, you can purchase it now.</p>
            <div onClick={() => { setModal(true) }} className="assessment_get_skill">Buy now</div>

          </div>
          <Modal open={modal} onClose={() => setModal(false)}>
          <PaymentFormForAssessment
            className="journey--modal"
            price={state.price || "5"}
            journeyId={2}
            email={state.email}
            onSuccess={postSubmit}
            setModal={setModal}
          />
        </Modal>
        </div> : <div style={{ flexDirection: 'column', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
          <p style={{ color: 'black' }}>No data found.</p>
          <Link key={'get_skills'} to={`/marketplace`} className="assessment_get_skill">
            Take the assessment
          </Link>  </div> }
        
      </div>

    </PageLayout>
  )
}
